import { LocalizationProvider, MobileDateRangePicker } from "@mui/lab";
import { Fragment, useState } from "react";
import RootStore from "stores/RootStore";
import { observer } from "mobx-react";
import { Box, Chip, Divider, TextField, Typography } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { de } from "date-fns/locale";

const StatisticDateRangePicker = observer(({ classes, dividerTextAlign }) => {
  const {
    startTimeHour,
    startTimeMinutes,
    endTimeHour,
    endTimeMinutes,
    statisticDateRange,
    adjustDateTime,
    setStatisticDateRange,
  } = RootStore.statisticStore;

  const [dateRangeTemp, setDateRangeTemp] = useState(statisticDateRange);

  const handleAcceptDateRange = (newRange) => {
    // if (isRangeValid(newRange)) {
    adjustDateTime(newRange);
    setDateRangeTemp(newRange);
    setStatisticDateRange(newRange);
    // }
  };

  return (
    <Box id="statistic-interval">
      <Divider className={classes.divider} textAlign={dividerTextAlign}>
        <Chip className={classes.chip} label={"Zeitraum"} />
      </Divider>
      <LocalizationProvider
        locale={de}
        dateAdapter={AdapterDateFns}
        localeText={{ start: "Start", end: "End" }}
      >
        <MobileDateRangePicker
          toolbarTitle={"Zeitraum auswählen"}
          // showToolbar={false}
          disableCloseOnSelect
          value={dateRangeTemp}
          // maxDate={new Date()}
          disableFuture
          // inputFormat={"dd.MM.yyyy HH:mm"}
          // mask={"__.__.____ __:__"}
          inputFormat={"dd.MM.yyyy"}
          mask={"__.__.____"}
          onChange={(newValue) => {
            // console.log("For displaying changes on the Fly", newValue);
            // newValue[1] && newValue[1].setHours(endTimeHour);
            // newValue[1] && newValue[1].setMinutes(endTimeMinutes);
            setDateRangeTemp(newValue);
          }}
          onAccept={(newRange) => {
            console.log("Accepted date");
            // console.log(newRange);
            handleAcceptDateRange(newRange);
          }}
          cancelText="Abbrechen"
          onClose={() => {
            console.log("onClose");
            setDateRangeTemp(statisticDateRange);
          }}
          renderInput={(startProps, endProps) => (
            <Fragment>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                  <TextField
                    {...startProps}
                    // inputProps={{
                    //   ...startProps.inputProps,
                    //   sx: {
                    //     pl: "3px", pr: "3px"
                    //   },
                    // }}
                  />
                  <Typography fontSize={"small"} pl={2}>
                    {startTimeHour}:{startTimeMinutes} Uhr
                  </Typography>
                </Box>
                <Box sx={{ mx: "8px", display: "flex" }}>bis</Box>
                <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                  <TextField
                    {...endProps}
                    // inputProps={{
                    //   ...endProps.inputProps,
                    //   sx: {
                    //     pl: "3px", pr: "3px"
                    //   },
                    // }}
                  />
                  <Typography fontSize={"small"} pl={2}>
                    {endTimeHour}:{endTimeMinutes} Uhr
                  </Typography>
                </Box>
              </Box>
            </Fragment>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
});

export default StatisticDateRangePicker;
