import { useEffect, useMemo, useState } from "react";
import GraphHeader from "./GraphHeader";
import GraphFooter from "./GraphFooter";
import RootStore from "stores/RootStore";
import GraphBody from "./GraphBody";
import { Box, Modal, Popper } from "@mui/material";
import { observer } from "mobx-react-lite";

const getPeriods = ({
  standardPeriodsEZG,
  standardNamedPeriodsEZG,
  periodConfig,
  tab,
}) => {
  let periods = standardPeriodsEZG;
  if (standardNamedPeriodsEZG[tab]) {
    periods = standardNamedPeriodsEZG[tab];
  }
  return periods
    .map((p) => {
      const temp = periodConfig[p];
      return temp && { value: p, label: temp["displayName"] };
    })
    .filter((e) => !!e);
};

const GraphContainer = observer(
  ({ catchmentArea, defaultChartConfig, catchmentAreasConfig, nls }) => {
    // console.log(">>>>>> render GraphContainer");
    const { /*feature, app,*/ tab /*,catchmentAreaInfo*/ } = catchmentArea;
    const [anchorElement, setAnchorElement] = useState(null);

    const [legend, setLegend] = useState(null);
    const [fullscreen, setFullscreen] = useState(false);
    const [download, setDownload] = useState(null);
    const [update, setUpdate] = useState(null);

    const [isShowingPeriodTooltip, setIsShowingPeriodTooltip] = useState(false);
    const handleClosePeriodTooltip = (event, reason) => {
      setIsShowingPeriodTooltip(false);
    };

    const {
      standardPeriodsEZG,
      standardNamedPeriodsEZG,
      periods: periodConfig,
    } = defaultChartConfig;

    const options = useMemo(() => {
      return getPeriods({
        standardPeriodsEZG,
        standardNamedPeriodsEZG,
        periodConfig,
        tab,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    useEffect(() => {
      RootStore.mapStore.selectedPeriod = options?.[0] || "";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    const content = (
      <>
        <Popper
          open={anchorElement && legend ? true : false}
          anchorEl={anchorElement}
          placement="bottom-end"
          style={{ zIndex: "9999" }}
        >
          <Box
            sx={{
              border: 1,
              p: 1,
              bgcolor: "background.paper",
              marginTop: "5px",
            }}
          >
            {legend}
          </Box>
        </Popper>
        <div
          style={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            padding: 15,
            display: "flex",
            flexDirection: "column",
            background: "#FFF",
          }}
          onClick={() => {
            setAnchorElement(null);
          }}
        >
          <GraphHeader
            nls={nls}
            legend={legend}
            anchorElement={anchorElement}
            setAnchorElement={setAnchorElement}
          />

          <div
            style={{
              paddingBottom: 25,
              flexGrow: 1,
              overflow: "hidden",
            }}
          >
            <GraphBody
              period={RootStore.mapStore.selectedPeriod?.value}
              periodsOption={options}
              setSelectedPeriod={(value) =>
                (RootStore.mapStore.selectedPeriod = value)
              }
              reduceInterval={
                periodConfig[RootStore.mapStore.selectedPeriod?.value]
                  ?.reduceInterval
              }
              setLegend={setLegend}
              setDownload={setDownload}
              setUpdate={setUpdate}
              nls={nls}
              setIsShowingPeriodTooltip={setIsShowingPeriodTooltip}
              catchmentArea={catchmentArea}
              catchmentAreasConfig={catchmentAreasConfig}
              defaultChartConfig={defaultChartConfig}
            />
          </div>

          <GraphFooter
            options={options}
            nls={nls}
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
            download={download}
            update={update}
            isShowingPeriodTooltip={isShowingPeriodTooltip}
            handleClosePeriodTooltip={handleClosePeriodTooltip}
          />
        </div>
      </>
    );

    if (fullscreen) {
      return (
        <Modal
          open={fullscreen}
          onBackdropClick={() => {
            setFullscreen(false);
          }}
          onClose={() => {
            setFullscreen(false);
          }}
        >
          <div
            style={{
              overflow: "hidden",
              // padding: 25,
              transform: "translate(-50%,-50%)",
              position: "relative",
              top: "50%",
              left: "50%",
              height: "90%",
              width: "90%",
            }}
          >
            {content}
          </div>
        </Modal>
      );
    } else {
      return content;
    }
  }
);

export default GraphContainer;
