import React from "react";
import PrecipitationParameters from "./components/PrecipitationParameters";
import PrecipitationLegend from "./components/PrecipitationLegend";
import PrecipitationSelection from "./components/PrecipitationSelection";
import { makeStyles } from "@mui/styles";
import RootStore from "stores/RootStore";
import { observer } from "mobx-react-lite";
import DataLoadingIndicator from "widgets/DataLoadingIndicator/widget";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: "40px",
  },
  chip: { fontSize: "inherit" },
}));

const Widget = observer((props) => {
  const { selectedTS } = RootStore.regionPrecipitationStore;
  const classes = useStyles({});
  const dividerTextAlign = "left";

  const { loadingWMSLayer: dataLoading } = RootStore.regionPrecipitationStore;

  return (
    <div style={{ margin: "20px", position: "relative" }}>
      <DataLoadingIndicator dataLoading={dataLoading} />
      <PrecipitationSelection />

      {!selectedTS.deactive ? (
        <>
          <PrecipitationParameters
            classes={classes}
            dividerTextAlign={dividerTextAlign}
          />
          <PrecipitationLegend
            classes={classes}
            dividerTextAlign={dividerTextAlign}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
});

export default Widget;
