const { Autocomplete, TextField } = require("@mui/material");

const PeriodSelect = ({
  nls: { timePeriod },
  value,
  setValue,
  options,
  style = {},
}) => {
  return (
    <Autocomplete
      disablePortal
      options={options}
      getOptionLabel={({ label }) => label}
      value={value}
      style={{ ...style }}
      onChange={(e, value) => {
        if (value) setValue(value);
      }}
      // sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label={timePeriod} />}
      size={"small"}
    />
  );
};
export default PeriodSelect;
