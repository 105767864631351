import { Autocomplete, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const PrecipitationSelection = observer((props) => {
  const { timeseries, selectedTS, setSelectedTS } =
    RootStore.regionPrecipitationStore;

  return (
    <Autocomplete
      id="precipitationTimeSelection"
      sx={{
        boxShadow:
          "0px 5px 5px -3px rgb(0 109 151 / 45%), 0px 8px 10px 1px rgb(0 109 151 / 14%), 0px 3px 14px 2px rgb(0 109 151 / 12%)",
        borderRadius: 1,
        marginBottom: "20px",
      }}
      options={timeseries}
      value={selectedTS}
      label={"Wählen Gebietsniederschlag"}
      getOptionLabel={(option) => option.selectlabel || ""}
      renderInput={(params) => (
        <TextField {...params} fullWidth label="Wählen Gebietsniederschlag" />
      )}
      disableClearable
      onChange={(event, newValue) => {
        setSelectedTS(newValue);
      }}
    />
  );
});

export default PrecipitationSelection;
