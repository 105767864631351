import { Box, Chip, Divider, Slider } from "@mui/material";
import React from "react";

const TransparencySlider = ({
  classes,
  dividerTextAlign,
  opacityValue,
  onChangeOpacity,
}) => {
  return (
    <Box key="transparence_slider" id="transparency-slider">
      <Divider className={classes.divider} textAlign={dividerTextAlign}>
        <Chip className={classes.chip} label="Transparenz" />
      </Divider>
      <Slider
        value={opacityValue}
        aria-label="Default"
        valueLabelDisplay="auto"
        onChange={onChangeOpacity}
      />
    </Box>
  );
};

export default TransparencySlider;
