import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

/*
Field={
  name="", // for display in header
  dataPath="", // => data[i].value
  unit="", // for appending as suffix to data
  dataFunction=undefined, // if defined dataFunction(data[i], i, data) will be used to determine data visible => dataPath will be ignored then
  onDataClick
  onHeaderClick
}
*/
function Header({ fields = [] }) {
  return (
    <TableHead>
      <TableRow>
        {fields.map(({ label, name }, i) => {
          return (
            <TableCell
              key={`header-${name}-${i}`}
              // align={i ? "right" : undefined}
            >
              {label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
const nop = () => {}; // just a nop so that it does not have to be created multiple times
export default function SimpleTable({
  fields = [],
  data = [],
  nls = { noData: " - " },
}) {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: "80%" }}
        size="small" /*aria-label="a dense table"*/
      >
        <Header fields={fields} />
        <TableBody>
          {data.map((d, i) => {
            return (
              <TableRow
                key={`row-${i}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover={true}
              >
                {fields.map((field, j) => {
                  const { dataFunction, dataPath, label, name, onDataClick } =
                    field;
                  let unit = null;
                  if (d && d.unit !== undefined) {
                    if (typeof d.unit === "string") {
                      unit = d.unit;
                    } else if (d.unit[name] !== undefined) {
                      unit = d.unit[name];
                    }
                  }
                  if (unit === null) {
                    if (field.unit) {
                      unit = field.unit;
                    } else if (field.units && field.units[i]) {
                      if (field.units[i][j]) {
                        unit = field.units[i][j];
                      } else {
                        unit = field.units[i];
                      }
                    }
                  }

                  // const unit =
                  //   d && d?.unit?.[name] !== undefined
                  //     ? d.unit[name]
                  //     : field.unit;
                  let value = "";
                  if (dataFunction) {
                    value = dataFunction(d, i, field, j, data, fields);
                  } else if (dataPath) {
                    value = dataPath.split(".").reduce((acc, cur) => {
                      return acc?.[cur];
                    }, d);
                  } else {
                    value = d;
                  }

                  if (value === null || value === "") {
                    // nodata
                    value = nls.noData;
                  } else if (unit && value !== null && value !== "") {
                    // otherwise suffix with unit
                    value = `${value} ${unit}`;
                  }

                  const onclick = d?.onClick || onDataClick || nop;

                  if (j === 0) {
                    return (
                      <TableCell
                        key={`cell-${name}-${i}-${j}`}
                        onClick={() => {
                          onclick(d, i, field, j, fields, data);
                        }}
                        style={{
                          cursor: onclick !== nop ? "pointer" : null,
                        }}
                        component="th"
                        scope="row"
                      >
                        {value}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={`cell-${name}-${i}`}
                      onClick={() => {
                        onclick(d, i, field, j, fields, data);
                      }}
                      style={{
                        cursor: onclick !== nop ? "pointer" : null,
                      }}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
