import React, { useEffect, useState } from "react";
import AppBar from "./components/AppBar";
import I18N from "./libs/I18N";
import RootStore from "./stores/RootStore";
import MainArea from "./components/MainArea";
import { BusyIndicator } from "@giscon/ui.busy-indicator";

function App(props) {
  const [isInitialising, setIsInitialising] = useState(true);

  const { widgets, children } = props;

  const init = async () => {
    await RootStore.fetchAppConfig();
    RootStore.initFeatureStores();
    await RootStore.initConfig();
    // await RootStore.mapStore.initMap();
    initApp();
    window.setInterval(() => {
      RootStore.refreshFeatures();
      // window.alert("Daten wurden aktualisiert!");
    }, 600000);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initApp = () => {
    I18N.loadLocale(RootStore.config.locale || "de");
    // RootStore.mapStore.initStore();
    RootStore.mapScopeThemeStore.initStore();
    setIsInitialising(false);
  };

  if (isInitialising) {
    return (
      <BusyIndicator
        label="Lade Anwendung..."
        style={{
          position: "absolute",
          inset: 0,
        }}
      />
    );
    // return <div>Lade Anwendung</div>;
  }

  return (
    <div id="app-container">
      <AppBar />
      <MainArea widgets={widgets}>{children}</MainArea>
    </div>
  );
}

export default App;
