import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    // backgroundColor: theme.palette.action.hover,
    backgroundColor: "#e5f4fc",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DownloadReport = ({ mp, extraMenuItems }) => {
  console.log(mp);
  const primaryKey = mp.groupParam;
  const handleDownload = (e, config) => {
    console.log(e);
    const url =
      config.downloadURL +
      "?site=" +
      encodeURIComponent(mp.station.Datenherr_kurz) +
      "&org=" +
      encodeURIComponent(mp.station.Org_Einheit_kurz) +
      "&nr=" +
      encodeURIComponent(mp.station_no);
    window.open(url);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        overflow: "auto",
        padding: 5,
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: "x-large",
          fontWeight: 500,
          fontFamily: "Roboto,Helvetica,Arial,sans-serif",
          lineHeight: "48px",
        }}
      >
        {primaryKey}
      </Typography>
      {extraMenuItems[primaryKey].groupParameters.map((param, index) => (
        <Box sx={{ pl: 3, pr: 3 }} key={"param-box" + index}>
          <Typography
            key={param.uiName + index}
            sx={{ fontSize: "large", fontWeight: 500, ml: 2 }}
          >
            {param.uiName}
          </Typography>
          <Table
            key={param.uiName}
            size="small"
            aria-label="purchases"
            sx={{ mb: 3 }}
          >
            <TableBody>
              {param.attributes.map((att) => (
                <StyledTableRow key={att.Name + index}>
                  <TableCell
                    key={att.AnzeigeName + index}
                    component="th"
                    scope="row"
                    sx={{ width: "50%", pb: 0, pt: 0 }}
                  >
                    {att.AnzeigeName}
                  </TableCell>
                  <TableCell key={att.Name + "-" + index} sx={{ pb: 0, pt: 0 }}>
                    {mp.station[att.Name] ? mp.station[att.Name] : " - "}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ))}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ lineHeight: "20px" }}
          size={"small"}
          // fullWidth
          onClick={(e) => handleDownload(e, extraMenuItems[primaryKey].config)}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default DownloadReport;
