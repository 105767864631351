import React from "react";
import { useMemo } from "react";
import StationMinMaxAvg from "./StationMinMaxAvg";
import IntensityTable from "./IntensityTable";
import DiagramView from "./DiagramView";

const StationStatistics = ({
  diagram,
  selectedInterval,
  // setSelectedInterval,
}) => {
  // const ref = useRef();

  //  React.forwardRef((props, ref) => {
  // const { diagram, selectedInterval, setSelectedInterval } = props;

  const { min, max, avg, Einheit } = useMemo(() => {
    console.log("----- MinMaxAvg useMemo");
    if (diagram?.stores.length > 0 && diagram.loading === false) {
      const { min, max, avg, Einheit } = diagram?.stores[0];
      return { min, max, avg, Einheit };
    } else {
      return {
        min: undefined,
        max: undefined,
        avg: undefined,
        Einheit: undefined,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInterval, diagram.loading]);

  return (
    <div
      // ref={ref}
      style={{
        //   width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <StationMinMaxAvg min={min?.y} max={max?.y} avg={avg} unit={Einheit} />

      <DiagramView
        diagram={diagram}
        reduceInterval={selectedInterval.reduceInterval}
      />

      {diagram?.stores[0].showIntensity && (
        <IntensityTable
          rows={diagram?.stores[0].topLargestValues}
          intervalValue={selectedInterval.reduceInterval}
        />
      )}
    </div>
  );
};

export default StationStatistics;
