export default class Dataset {
  get eventTypes() {
    return ["click", "mouseover", "mouseout", "change"];
  }
  constructor({
    text = { anchor: "center", offset: { x: 0, y: -5 } },
    fill = "#0000FF",
    outline = "#000000",
    type,
    data,
    tooltipOptions,
    formatY,
    visible = true,
    yAxis = "y",
    xAxis = "x",
    name = "",
  }) {
    this.data = data || [];
    this.xAxis = xAxis;
    this.yAxis = yAxis;
    this.name = name;
    this.drawInfo = {
      fill,
      outline,
      text,
    };
    this.formatY = formatY;
    this.type = type || "not set";
    this.listeners = {};
    this.eventTypes.forEach((type) => {
      this.listeners[type] = [];
    });
    this.visible = visible;
  }

  setVisible(visible) {
    if (visible !== this.visible) {
      this.visible = visible;
      this.handleEvent("change");
    }
  }

  add(item) {
    this.data.push(item);
  }

  sort(func = undefined) {
    this.data.sort(func);
  }

  setData(data) {
    this.data = data || [];
  }

  removeAll() {
    this.data = [];
  }

  on(eventType, listener) {
    if (listener) {
      if (this.listeners[eventType]) {
        this.listeners[eventType].push(listener);
        return {
          remove: () => {
            let index = -1;
            for (let i = 0; i < this.listeners[eventType].length; i++) {
              if (this.listeners[eventType][i] === listener) {
                index = i;
                break;
              }
            }
            if (index !== -1) {
              this.listeners[eventType].splice(index, 1);
            }
          },
        };
      } else {
        console.error(`EventType '${eventType}' unknown`);
      }
    } else {
      console.error("Listener is not defined");
    }
    return { remove: () => {} };
  }

  handleEvent(eventType, options) {
    // const { event, index, axisMap, pos } = options;
    this.listeners[eventType]?.forEach((listener) => {
      try {
        listener({ dataset: this, data: this.data, ...options });
      } catch (e) {
        console.error(
          `An error occurred in Datasets' ${eventType} listener`,
          e
        );
      }
    });
  }
}
