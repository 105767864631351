import React, { useEffect, useState } from "react";
import Content from "./Content";
import Header from "./Header";
import Box from "@mui/material/Box";
import RootStore from "stores/RootStore";

export default function Container({
  catchmentArea,
  setCatchmentArea = () => {
    console.warn("needs to be a valid prop");
  },
  close,
  nls,
  defaultChartConfig,
}) {
  const catchmentAreasConfig = RootStore.envisHydroConfig.Einzugsgebiete;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [catchmentArea]);

  return (
    <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <Header
        open={open}
        close={close}
        catchmentArea={catchmentArea}
        nls={nls}
        setOpen={(val) => {
          setOpen(val);
        }}
      />
      <Content
        catchmentArea={catchmentArea}
        nls={nls}
        style={{ height: "100%", width: "100%" }}
        catchmentAreasConfig={catchmentAreasConfig}
        defaultChartConfig={defaultChartConfig}
        open={open}
        setCatchmentArea={setCatchmentArea}
        setOpen={(val) => {
          setOpen(val);
        }}
      />
    </Box>
  );
}
