import WMSLayer from "@arcgis/core/layers/WMSLayer";
import React from "react";
import RootStore from "stores/RootStore";

export default class TimeWMSrasterVlaues {
  constructor(url, ts_id) {
    this.ts_id = ts_id;
    this.proxyURL = RootStore.config.proxyURL;
    // this.url = proxyURL ? proxyURL + "?" + url : url;
    this.url = url;
    this.urlParams = {
      datasource: 0,
      request: "getRasterTsValues",
      format: "dajson",
      service: "kisters",
      type: "queryServices",
      ts_id: this.ts_id,
    };

    this.serverURL = this.url + this.queryString(this.urlParams);
  }

  queryString = (urlParams, period) => {
    if (!period) {
      console.log("queryString");
      switch (urlParams.ts_id) {
        case 75727042: // 48556042:
          urlParams.period = "P2D";
          break;
        case 48557042:
          urlParams.period = "P7D";
          break;
        case 49489042:
          urlParams.period = "P2M";
          break;
        case 48193042:
          urlParams.period = "P1Y";
          break;
        case -1:
          urlParams.period = "P1Y";
          break;
        default:
          urlParams.period = null;
      }
    }
    return Object.keys(urlParams)
      .map((key) => key + "=" + urlParams[key])
      .join("&");
  };

  load = (tsId, period) => {
    console.log(period);
    this.ts_id = tsId;
    this.urlParams.ts_id = tsId;
    this.serverURL = this.url + this.queryString(this.urlParams, period);
    console.log(this.serverURL);

    return new Promise((resolve, reject) => {
      fetch(this.serverURL)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((json) => {
          console.log(json);
          const timestamps = this.getTimestamps(json[0]);
          resolve({ timestamps, tsId });
        })
        .catch((error) => {
          console.error("Error loading data:", error.message);
          reject(error);
        });
    });
  };

  compare = (a, b) => {
    if (a.timestamp < b.timestamp) return -1;
    if (a.timestamp > b.timestamp) return 1;
    return 0;
  };

  getTimestamps = (json) => {
    // console.log(json);
    const data = json.data;
    const columns = json.columns.split(",");
    const ret = [];
    for (var i = 0; i < data.length; i++) {
      const temp = {
        timestamp: new Date(data[i][0]),
        png_value: data[i][1] ? "data:image/png;base64," + data[i][1] : "",
        value: data[i][1],
        values: {},
      };
      for (var j = 0; j < columns.length; j++) {
        temp.values[columns[j]] = data[i][j];
      }
      ret.push(temp);
    }
    ret.sort(this.compare);
    const res = [];
    for (var i = 0; i < ret.length; i++) {
      // res.push(ret[i].timestamp.getTime());
      res.push(ret[i].timestamp);
    }
    return res;
  };

  setToDate = (pValue) => {
    //debugger;
    // this.toDate = new Date(pValue).toISOString();
    this.toDate = pValue;
    // this.urlParams.to = new Date(pValue).toISOString();
    this.urlParams.to = pValue;
  };

  setFromDate = (pValue) => {
    this.fromDate = pValue;
  };

  setPeriod = (pValue) => {
    this.urlParams.period = pValue;
  };
}
