import { Autocomplete, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";

const StatisticsInterval = observer(({ value, handleChangeValue, options }) => {
  return (
    <Autocomplete
      style={{
        // width: "75%",
        // flexGrow: 0,
        // margin: "9px",
        // backgroundColor: "white",
        // width: "50%",
        width: "150px",
      }}
      disablePortal
      options={options}
      getOptionLabel={(option) => option?.label || ""}
      value={value}
      onChange={(e, value) => {
        if (value) handleChangeValue(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={"Intervall"}
          inputProps={{
            ...params.inputProps,
            sx: {
              fontSize: "small",
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <Typography {...props} fontSize="small">
          {option?.label || ""}
        </Typography>
      )}
      size={"small"}
      isOptionEqualToValue={(option, value) => {
        return option.label === value.label;
      }}
      disableClearable
    />
  );
});

export default StatisticsInterval;
