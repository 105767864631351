import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import RootStore from "stores/RootStore";
import ClimateTopicsSelection from "./components/ClimateTopicsSelection";
import ClimateTopicLayers from "./components/ClimateTopicLayers";
import DataLoadingIndicator from "widgets/DataLoadingIndicator/widget";
import ClimateLayerLegend from "./components/ClimateLayerLegend";
import { makeStyles } from "@mui/styles";
import TransparencySlider from "components/TransparencySlider";
import ExtraClimateLayers from "./components/ExtraClimateLayers";
import LayersIcon from "@mui/icons-material/Layers";
import CompareIcon from "@mui/icons-material/Compare";
import TabWidget, { TabContent } from "widgets/TabWidget";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: "30px",
  },
  chip: { fontSize: "inherit" },
}));

const Widget = observer((props) => {
  const {
    selectedClimateTopic,
    selectedClimateLayerId,
    loading,
    climateLayerOpacity,
    handleTransparency,
  } = RootStore.climateStore;

  // const [tabValue, setTabValue] = useState(0);
  const { setClimateTabValue, climateTabValue } = RootStore.uiStore;

  const classes = useStyles({});
  const dividerTextAlign = "left";

  const tabContents = [
    {
      id: "scenarios-tab",
      tabTitle: "Klimaszenarien",
      icon: <CompareIcon />,
      content: (
        <>
          <ClimateTopicsSelection />
          {selectedClimateTopic && (
            <ClimateTopicLayers
              classes={classes}
              dividerTextAlign={dividerTextAlign}
            />
          )}

          {selectedClimateLayerId && (
            <>
              <TransparencySlider
                classes={classes}
                dividerTextAlign={dividerTextAlign}
                opacityValue={climateLayerOpacity}
                onChangeOpacity={handleTransparency}
              />
              <ClimateLayerLegend
                classes={classes}
                dividerTextAlign={dividerTextAlign}
              />
            </>
          )}
        </>
      ),
    },
    {
      id: "extra-climate-layers-tab",
      tabTitle: "Zusätzliche Layer",
      icon: <LayersIcon />,
      content: (
        <ExtraClimateLayers
          classes={classes}
          dividerTextAlign={dividerTextAlign}
        />
      ),
    },
  ];

  return (
    <TabWidget tabValue={climateTabValue} onTabSelect={setClimateTabValue}>
      {tabContents.map((tabContent) => (
        <TabContent
          key={tabContent.id}
          id={tabContent.id}
          tabTitle={tabContent.tabTitle}
          icon={tabContent.icon}
        >
          <div style={{ margin: "20px", position: "relative" }} id="climate">
            <DataLoadingIndicator dataLoading={loading} />
            {tabContent.content}
          </div>
        </TabContent>
      ))}
    </TabWidget>
  );
});

export default Widget;
