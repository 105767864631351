import { Card } from "@mui/material";
import React from "react";
import ThemesSelection from "./ThemesSelection";

const Themes = (props) => {
  return (
    <Card
      id="themesSelection"
      sx={{
        height: "auto",
        flexGrow: 1,
        maxWidth: "auto",
        overflowY: "auto",
        border: "1px solid lightgray",
        borderRadius: "3px",
        padding: "12px",
      }}
    >
      <ThemesSelection />
    </Card>
  );
};

export default Themes;
