import { createTheme } from "@mui/material/styles";
import { deDE } from "@mui/material/locale";

export function lightTheme() {
  return createTheme(
    {
      palette: {
        primary: {
          main: "#006d97",
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
    },
    deDE
  );
}
