import { observable, action, makeObservable } from "mobx";
import MapScopeThemeWidget from "widgets/MapScopeThemeWidget/Widget";
import Abflussspende from "widgets/Abflussspende/Abflussspende";
// import TravelExploreIcon from "@mui/icons-material/TravelExplore";
// import SendIcon from "@mui/icons-material/Send";
// import OpacityIcon from "@mui/icons-material/Opacity";
import RegionPrecipitationWidget from "widgets/RegionPrecipitationWidget/Widget";
// import PikettSearchWidget from "widgets/PikettSearchWidget/Widget";
// import HydroWebSearchWidget from "widgets/HydroWebSearchWidget/Widget";
import HydroWebPikettWidget from "widgets/HydroWebPikettWidget/Widget";
import ClimateWidget from "widgets/ClimateWidget/Widget";
import StatisticWidget from "widgets/StatisticWidget/Widget";
// import APIHelper from "libs/APIHelper";

// import AuthApp from "../widgets/mu-authorization/WidgetInterface";
// import TestApp from "../widgets/test/Widget";
import ForestIcon from "@mui/icons-material/Forest";
import FloodIcon from "@mui/icons-material/Flood";
import WaterIcon from "@mui/icons-material/Water";
import PublicSharpIcon from "@mui/icons-material/PublicSharp";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import moment from "moment";

class UiStore {
  widgets = [];
  activeWidget = (<MapScopeThemeWidget />);
  activeWidgetId = "envis";
  minWidgetAreaWidth = 350;
  maxWidgetAreaWidth = 600;
  widgetAreaWidth = 350;
  isWidgetOpen = true;
  lastWidgetAreaWidth = this.widgetAreaWidth;
  breakPointMobileWidthSize = 600;
  isMobileView = false;
  searchIcon = (
    <img
      src={"images/i_search.png"}
      alt="Suche"
      height={"36px"}
      width={"36px"}
    />
  );
  introStepsEnabled = false;

  pikettHydrowebIntroSteps = [
    {
      element: "#tab-widget-tabs",
      intro: "Hier gibt es zwei Tabs",
    },
    {
      element: "#full-width-tab-search-tab",
      intro:
        "Der erste Tab ist die Suche: Enthält Legende, Suchaspekte und Filteraspekte.",
    },
    {
      element: "#pikettHydroLegend",
      intro:
        "Dies ist die Legende für die Kartendarstellung der Messstationen. Bei einem Klick auf eine der Farben werden die aktuellen Messwerte des entsprechenden Parameters auf der Karte ein-/ausgeblendet.",
    },
    {
      element: "#pikettHydroSearch",
      intro:
        "Hier kann nach Namen, Nummern von Stationen und Gemeinden gesucht und auf diese gezoomt werden.",
    },
    {
      element: "#pikettHydroFilter",
      intro:
        "Hier können die Kriterien ausgewählt werden, nach denen die Mapansicht gefiltert werden soll. Eine Kombination aus mehreren ist möglich, die Darstellung aktualisiert sich direkt.",
    },
    {
      element: "#full-width-tab-measurements-tab",
      intro: "Der zweite Tab ist die Messwerte.",
    },
    {
      element: ".measurements-list-grid-class",
      intro: "Diese ist die Messwerte Liste.",
    },
    {
      element: ".MuiDataGrid-row",
      intro: "Mit einem Klick auf eine Zeile wird es auf die Station gezoomt.",
    },
    {
      element: "#pikett-hydroweb-refresh",
      intro: "Mit dem Klick hier werden die Messwerte aktualisiert.",
    },
  ];

  envisIntroSteps = [
    {
      element: "#regionLegend",
      intro: "Dies ist die Legende für die Kartendarstellung der Messstationen",
    },
    {
      element: "#envis-serach-steps",
      intro: "Hier gibt es zwei Suchaspekte für die Messstationen",
    },
    {
      element: "#envis-step-0",
      intro: "Erste Suchaspekt ist Gebietsauswahl",
    },
    {
      element: "#regionSelection",
      intro:
        "Hier kann ein Gebiet ausgewählt werden mit die Messstationen in der Karte gefiltert werden können. Wird keines ausgewählt, werden alle Messstellen des Kantons angezeigt.",
    },
    {
      element: "#clearRegionSelection",
      intro:
        "Mit diesem Button kann die Gebietsauswahl wieder aufgehoben werden",
    },
    {
      element: "#envis-step-1",
      intro: "Zweite Suchaspekt ist Themensauswahl",
    },
    {
      element: "#nextToThemesSelection",
      intro:
        "Klicken sie hier um innerhalb des ausgewählten Gebiets nach Attributen oder Themen filtern zu können",
    },
    {
      element: "#backToRegionSelection",
      intro: "Hier kommen sie zurück zu der Gebietsauswahl und Legende",
    },
    {
      element: "#themesSelection",
      intro:
        "Hier können Sie auswählen welche Themen bzw. Attribute angezeigt werden sollen. Mit einem Klick auf den Pfeil werden die Attribute zu den Themen angezeigt, welche auch gefiltert werden können.",
    },
    {
      element: "#tree-view-text-filter",
      intro:
        "In dieser Suchleiste kann innerhalb der Themen/Attribute gesucht werden. (Angehakte Elemente die dadurch nicht angezeigt werden bleiben angehakt.)",
    },
    {
      element: "#tree-view-expand-btn", // ausklappen
      intro:
        "Hier können alle Unterkathegorien angezeigt bzw. verborgen werden",
    },
    {
      element: "#tree-view-select-btn", //auswählen
      intro:
        "Hier können alle aktuell angezeigten Themen/Attribute ausgewählt oder aus der Auswahl entfernt werden",
    },
    {
      element: "#showOnTheMap",
      intro:
        "Mit einem Klick hier werden die Punkte auf der Karte entsprechend der Auswahl gefiltert und die Messstellenliste angezeigt.",
    },
  ];

  precipitationIntroSteps = [
    {
      element: "#precipitationTimeSelection",
      intro:
        "Wählen sie hier die Art der Mittelwerte für die Darstellung des Niederschlags auf der Karte aus.",
    },
    {
      element: "#transparency-slider",
      intro: "Hier können Sie die Transparenz der Kartendarstellung anpassen.",
    },
    {
      element: "#precipitationDateSelection",
      intro:
        "Wählen sie hier das Datum für die anzuzeigenden Daten aus, wenn sie das Feld leer lassen ist Heute ausgewählt.",
    },
    {
      element: "#precipitationDisplayType",
      intro:
        "Wählen sie hier ob die aktuellen Daten statisch oder die letzten Daten animiert angezeigt werden sollen",
    },
    {
      element: "#precipitationLegend",
      intro:
        "Dies ist die interaktive Legende zu der Kartenansicht. Wenn sie mit der Maus über die Ansicht fahren, dann wird die entsprechende Zeile der Legende hervorgehoben.",
    },
    {
      element: "#timeSlider",
      intro: "Dies ist die Steuerung der Animation.",
    },
    {
      element: ".esri-time-slider__animation",
      intro: "Hier kann die Animation gestartet oder pausiert werden.",
    },
    {
      element: ".esri-time-slider__previous",
      intro: "Wechseln sie hier zum vorherigen Bild.",
    },
    {
      element: ".esri-time-slider__next",
      intro: "Wechseln sie hier zum nächsten Bild.",
    },
    {
      element: ".esri-slider__thumb",
      intro: "Mit diesem Slider können Sie auch direkt ein Bild auswählen.",
    },
  ];

  dischargeIntroSteps = [
    {
      element: "#dischargeHierarchyLevel",
      intro:
        "Wählen sie hier die gewünschte Hierarchiestufe für die Abflussspendeberechnung aus. Das Ergebnis kann dann auf der Karte dargestellt.",
    },
    {
      element: "#dischargeLegend",
      intro: "Dies ist die Legende zu der Ergebnisdarstellung auf der Karte.",
    },
    {
      element: "#feature-node",
      intro:
        "Mit der Mausbewegung über eines der gerenderten Einzugsgebiete auf der Karte können dann dessen Details hier angezeigt werden.",
    },
  ];

  climateIntroSteps = [
    {
      element: "#tab-widget-tabs",
      intro: "Hier gibt es zwei Tabs",
    },
    {
      element: "#full-width-tab-scenarios-tab",
      intro:
        "Der erste Tab ist Vergleich der Szenarien: Enthält Klimathemen, Szenarien, Transparenz und Legende.",
    },
    {
      element: "#climate-topic-selection",
      intro:
        "Wählen sie hier das gewünschte Klimathema aus, um dessen Szenarien zu vergleichen.",
    },
    {
      element: "#climate-topic-layers",
      intro:
        "Hier sind die verfügbare Szenarien für das ausgewählte Klimathema. Das Szenario kann dann auf der Karte dargestellt.",
    },
    {
      element: "#transparency-slider",
      intro: "Hier können Sie die Transparenz der Kartendarstellung anpassen.",
    },
    {
      element: "#climate-layer-legend",
      intro: "Dies ist die Legende zu der Kartenansicht.",
    },
    {
      element: "#full-width-tab-extra-climate-layers-tab",
      intro: "Der zweite Tab ist die Zusätzliche Layers.",
    },
    {
      element: "#climate-extra-layers",
      intro:
        "Diese ist die Zusätzliche Layers Liste. Hier können sie ausgewählt werden, um sie auf der Karte darzustellen. Und sobald ein Layer ausgewählt ist, wird seine Legende unten angezeigt.",
    },
    // {
    //   element: "#climate-extra-layer-legend",
    //   intro: "Dies ist die Legende für die ausgewählten Layers.",
    // },
  ];

  statisticIntroSteps = [
    {
      element: "#statistic-topic-selection",
      intro: "Wählen sie hier das Statistik-Thema.",
    },
    {
      element: "#statistic-station-selection",
      intro:
        "Hier kann nach Namen und Nummern von Stationen gesucht und auf diese gezoomt werden.",
    },
    {
      element: "#statistic-interval",
      intro:
        "Wählen Sie hier den Zielzeitraum für die gewünschte Statistik aus.",
    },
    {
      element: "#station-stats",
      intro:
        // eslint-disable-next-line no-multi-str
        "Hier sind die Statistik: Enthält Möglichkeit der Zeitintervallauswahl und entsprechendes Diagramm.<br/><br/>\
         <small>Falls das Thema <b>Niederschlag</b> ausgewählt wird, dann wird eine Niederschlagsintensitättabelle für die Top-Ten-Werte angezeigt.</small><br/><br/>\
         <small>Und falls der Zeitintervall <b>'10-Min'</b> oder <b>'60-Min'</b> ausgewählt wird, dann wird eine Skala für die Niederschlagsintensität in dem Diagramm integriert.</small>",
    },
  ];

  introSteps = this.envisIntroSteps;
  pikettTabValue = 0;
  hydrowebTabValue = 0;

  climateTabValue = 0;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      widgets: observable,
      activeWidget: observable,
      activeWidgetId: observable,
      widgetAreaWidth: observable,
      isWidgetOpen: observable,
      lastWidgetAreaWidth: observable,
      isMobileView: observable,
      introStepsEnabled: observable,
      introSteps: observable,
      pikettTabValue: observable,
      hydrowebTabValue: observable,
      climateTabValue: observable,
      openWidget: action,
      closeWidget: action,
      setWidgetAreaWidth: action,
      setIntroSteps: action,
      exitIntrosteps: action,
      openIntroSteps: action,
      setHydroPikettTabValue: action,
      setActiveWidget: action,
      setClimateTabValue: action,
      // isWidgetOpen: computed,
    });
    this.initEnvisWidget();
    this.initPikettWidget();
    this.initHydroWebWidget();
    this.initClimateWidget();
    this.initStatisticWidget();
    this.initDischargeWidget();
    this.initPrecipitationWidget();

    this.isMobileView = window.innerWidth < this.breakPointMobileWidthSize;
    window.addEventListener("resize", this.handleResizeWindow);
  }

  setActiveWidget = (widgetId) => {
    const widget = this.widgets.find((elem) => elem.id === widgetId);
    this.activeWidget = widget.widget;
    this.activeWidgetId = widgetId;
  };

  setHydroPikettTabValue = (newValue) => {
    console.log("setHydroPikettTabValue newValue", newValue);
    const { currentApp, apps } = this.rootStore;
    const isAppPikett = currentApp === apps[1] ? false : true;
    if (isAppPikett) {
      this.pikettTabValue = newValue;
    } else {
      this.hydrowebTabValue = newValue;
    }
  };

  setClimateTabValue = (newValue) => {
    this.climateTabValue = newValue;
  };

  handleResizeWindow = (event) => {
    // const { isMobileView, breakPointMobileWidthSize } = RootStore.uiStore;
    if (
      event.currentTarget.innerWidth < this.breakPointMobileWidthSize &&
      !this.isMobileView
    ) {
      this.isMobileView = true;
      console.log("jetzt ists mobile");
    } else if (
      window.innerWidth > this.breakPointMobileWidthSize &&
      this.isMobileView
    ) {
      this.isMobileView = false;
      console.log("jetzt ists desktop");
    }
  };

  handleMultiPage = (index) => {
    console.log("handleMultiPage");
    if (this.activeWidgetId === "envis") {
      if (index === 7) {
        this.rootStore.mapScopeThemeStore.activeStep = 1;
      } else if (index === 6) {
        this.rootStore.mapScopeThemeStore.activeStep = 0;
      }
    } else if (
      this.activeWidgetId === "pikett" ||
      this.activeWidgetId === "hydroweb"
    ) {
      if (index === 5) {
        this.setHydroPikettTabValue(1);
      } else if (index === 4) {
        this.setHydroPikettTabValue(0);
      }
    } else if (this.activeWidgetId === "climate") {
      if (index === 6) {
        this.setClimateTabValue(1);
      } else if (index === 5) {
        this.setClimateTabValue(0);
      }
    }
  };

  setIntroSteps = (newSteps) => {
    this.introSteps = newSteps;
  };

  exitIntrosteps = () => {
    this.introStepsEnabled = false;
  };

  openIntroSteps = () => {
    this.handleWidgetIntro(this.activeWidgetId);
    this.introStepsEnabled = true;
  };

  setWidgetAreaWidth = (newWidth) => {
    this.widgetAreaWidth = newWidth;
  };

  initEnvisWidget = () => {
    // const widget = <div />;
    const widget = <MapScopeThemeWidget />;
    this.widgets.push({
      id: "envis",
      title: "EnVIS",
      icon: <ForestIcon fontSize="large" />,
      // icon: this.searchIcon,
      widget: widget,
    });
  };

  initDischargeWidget = () => {
    const widget = <Abflussspende />;
    this.widgets.push({
      id: "discharge",
      title: "Abflussspende",
      // icon: <SendIcon />,
      icon: (
        <img
          src={"images/Abflussspende.png"}
          alt="Abflussspende"
          height={"35px"}
          width={"35px"}
        />
      ),
      widget: widget,
    });
  };

  initPrecipitationWidget = () => {
    // const widget = <div />;
    const widget = <RegionPrecipitationWidget />;
    this.widgets.push({
      id: "precipitation",
      title: "Gebietsniederschlag",
      // icon: <OpacityIcon />,
      icon: (
        <img
          height={"35px"}
          src={"images/Gebietsniederschlag.svg"}
          alt="Gebietsniederschlag"
        />
      ),
      widget: widget,
    });
  };

  initPikettWidget = () => {
    const widget = <HydroWebPikettWidget />;
    this.widgets.push({
      id: "pikett",
      title: "Pikett",
      icon: <FloodIcon fontSize="large" />,
      // icon: this.searchIcon,
      widget: widget,
    });
  };

  initHydroWebWidget = () => {
    const widget = <HydroWebPikettWidget />;
    this.widgets.push({
      id: "hydroweb",
      title: "HydroWeb",
      icon: <WaterIcon fontSize="large" />,
      // icon: this.searchIcon,
      widget: widget,
    });
  };

  initClimateWidget = () => {
    const widget = <ClimateWidget />;
    this.widgets.push({
      id: "climate",
      title: "Klimaanalysekarte",
      // icon: <SendIcon />,
      icon: <PublicSharpIcon fontSize="large" />,
      widget: widget,
    });
  };

  initStatisticWidget = () => {
    const widget = <StatisticWidget />;
    this.widgets.push({
      id: "statistic",
      title: "Statistik",
      icon: <QueryStatsIcon fontSize="large" />,
      widget: widget,
    });
  };

  // get isWidgetOpen() {
  //   return this.activeWidgetId !== "";
  // }

  goToViewPoints = (
    targetViewPoint,
    animationViewPoint,
    mapView,
    sceneView
  ) => {
    if (targetViewPoint) {
      mapView.goTo(targetViewPoint, animationViewPoint);
      sceneView.goTo(targetViewPoint, animationViewPoint);
    }
  };

  prepareStatisticWidgetForIntro = () => {
    const statisticStore = this.rootStore.statisticStore;

    if (
      !statisticStore.selectedStatisticTopic ||
      statisticStore.selectedStatisticTopic.deactive
    ) {
      statisticStore.setSelectedStatisticTopic(
        statisticStore.statisticTopicsConfig[1]
      );
    }

    if (
      !statisticStore.selectedStatStation &&
      statisticStore.selectedStatisticTopic.features &&
      statisticStore.selectedStatisticTopic.features.length > 0
    ) {
      statisticStore.handleStationChange(
        statisticStore.selectedStatisticTopic.features[0]
      );

      const newRange = [
        moment().subtract(8, "days").toDate(),
        moment().subtract(1, "days").toDate(),
      ];
      statisticStore.adjustDateTime(newRange);
      statisticStore.setStatisticDateRange(newRange);
    }
  };

  prepareClimateWidgetForIntro = () => {
    const climateStore = this.rootStore.climateStore;
    if (
      climateStore.selectedClimateTopic === undefined ||
      climateStore.selectedClimateTopic?.deactive
    ) {
      climateStore.handleClimateTopicChange(climateStore.allClimateTopics[1]);
      if (climateStore.selectedClimateTopic?.subLayerIds.length > 1) {
        climateStore.handleClimateLayerChange(
          climateStore.selectedClimateTopic?.subLayerIds[1]
        );
      }
    }
  };

  preparePrecipitationWidgetForIntro = () => {
    const precipitationStore = this.rootStore.regionPrecipitationStore;
    if (this.rootStore.regionPrecipitationStore.selectedTS.deactive) {
      precipitationStore.setSelectedTS(precipitationStore.timeseries[1]);
    }
    precipitationStore.selectedDisplayType = precipitationStore.displayTypes[1];
  };

  handleWidgetIntro = (widgetId) => {
    if (widgetId === "envis") {
      // this.rootStore.mapScopeThemeStore.expandedRegionsSelection = true;
      this.rootStore.mapScopeThemeStore.activeStep = 0;
      this.setIntroSteps(this.envisIntroSteps);
    } else if (widgetId === "pikett" || widgetId === "hydroweb") {
      this.setHydroPikettTabValue(0);
      this.setIntroSteps(this.pikettHydrowebIntroSteps);
    } else if (widgetId === "precipitation") {
      this.preparePrecipitationWidgetForIntro();
      this.setIntroSteps(this.precipitationIntroSteps);
    } else if (widgetId === "discharge") {
      this.setIntroSteps(this.dischargeIntroSteps);
    } else if (widgetId === "climate") {
      this.setClimateTabValue(0);
      this.prepareClimateWidgetForIntro();
      this.setIntroSteps(this.climateIntroSteps);
    } else if (widgetId === "statistic") {
      this.prepareStatisticWidgetForIntro();
      this.setIntroSteps(this.statisticIntroSteps);
    } else {
      this.setIntroSteps([]);
    }
  };

  openWidget = (widgetId) => {
    const {
      map,
      mapView,
      sceneView,
      layer,
      pikettLayer,
      hydroWebLayer,
      // envisTargetViewPoint,
      // pikettTargetViewPoint,
      // hydroTargetViewPoint,
      // animationViewPoint,
    } = this.rootStore.mapStore;
    const { apps, setCurrentApp } = this.rootStore;
    const {
      selectedTS,
      timeWMSLayer,
      lastWMSLayer,
      addWMSLayerToViews,
      removeWMSLayerFromViews,
      stopLastTimeSlider,
    } = this.rootStore.regionPrecipitationStore;
    if (widgetId === "precipitation") {
      if (!selectedTS.deactive) {
        if (lastWMSLayer) {
          addWMSLayerToViews(mapView, sceneView, lastWMSLayer);
        }
      }
    } else {
      stopLastTimeSlider();
      removeWMSLayerFromViews(mapView, sceneView, lastWMSLayer);
      if (timeWMSLayer) {
        timeWMSLayer._legend = null;
      }
    }

    if (widgetId === "hydroweb" || widgetId === "statistic") {
      console.log("%copenWidget hydroweb", "background: lightred");
      setCurrentApp(apps[1]);
      layer?.removeFromMap(map);
      pikettLayer?.removeFromMap(map);
      if (!map.findLayerById("hydroweb-GraphicsLayer")) {
        hydroWebLayer?.addToMap(map);
      }
      // this.goToViewPoints(
      //   hydroTargetViewPoint,
      //   animationViewPoint,
      //   mapView,
      //   sceneView
      // );
    }

    if (widgetId === "pikett") {
      setCurrentApp(apps[0]);
      layer?.removeFromMap(map);
      hydroWebLayer?.removeFromMap(map);
      pikettLayer?.addToMap(map);
      // this.goToViewPoints(
      //   pikettTargetViewPoint,
      //   animationViewPoint,
      //   mapView,
      //   sceneView
      // );
    }

    if (widgetId === "envis") {
      setCurrentApp(apps[2]);
      pikettLayer?.removeFromMap(map);
      hydroWebLayer?.removeFromMap(map);
      layer?.addToMap(map);
      // this.goToViewPoints(
      //   envisTargetViewPoint,
      //   animationViewPoint,
      //   mapView,
      //   sceneView
      // );
    }

    if (widgetId !== "climate") {
      this.rootStore.climateStore.resetClimateWidget();
    }
    // this.handleWidgetIntro(widgetId);
    // if (widgetId === "discharge") {
    // pikettLayer?.removeCatchmentAreaLayerFromMap(sceneView.map);
    // hydroWebLayer?.removeCatchmentAreaLayerFromMap(sceneView.map);
    // }

    this.setActiveWidget(widgetId);
    this.isWidgetOpen = true;
  };

  get widgetTitle() {
    const widget = this.widgets.find((elem) => elem.id === this.activeWidgetId);
    return widget.title;
  }

  closeWidget = () => {
    // this.activeWidgetId = "";
    // this.activeWidget = null;
    this.isWidgetOpen = false;
  };

  handleOpenCloseWidget = () => {
    if (this.isWidgetOpen) {
      this.closeWidget();
    } else {
      this.openWidget(this.activeWidgetId);
    }
  };
}

export default UiStore;
