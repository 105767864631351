import React from "react";
import RegionsLegend from "./RegionsLegend";
import RegionSelection from "./RegionSelection";
import { Card } from "@mui/material";

const Regions = (props) => {
  return (
    <Card
      style={{
        border: "1px solid lightgray",
        borderRadius: "3px",
        padding: "12px",
      }}
      // elevation={16}
    >
      {/* Gebietsauswahl */}
      <RegionSelection />

      {/* Legends */}
      <RegionsLegend />
    </Card>
  );
};

export default Regions;
