import { observer } from "mobx-react";
import React from "react";
import RootStore from "stores/RootStore";
import Container from "./components/Container";

const nls = {
  openMenu: "Menü öffnen",
  closeMenu: "Menü schließen",
  close: "schließen",
  stationInfo: "Einzugsgebiet",
  menu: "Menü",
  // graphViewHeading: "Parameter",
  graphViewHeading: "Parameter Niederschlag",
  overviewHeading: "Einzugsgebiet",
  timePeriod: "Zeitraum",
  hidePoints: "Punkte ausblenden",
  showPoints: "Punkte anzeigen",
  fullscreen: "Vollbild",
  noLegend: "Aktuell keine Legende vorhanden",
  legend: "Legende",
};

const Widget = (props) => {
  const catchmentArea = RootStore.mapStore.clickedCatchmentArea;
  const defaultChartConfig = RootStore.config.defaultChartConfig;
  // console.log(catchmentArea);

  return (
    <Container
      catchmentArea={catchmentArea}
      close={() => {
        // RootStore.mapStore.clickedCatchmentArea = null;
        RootStore.mapStore.setClickedCatchmentArea(null);
      }}
      setCatchmentArea={(catchmentArea) => {
        // RootStore.mapStore.clickedCatchmentArea = catchmentArea;
        RootStore.mapStore.setClickedCatchmentArea(catchmentArea);
      }}
      nls={nls}
      defaultChartConfig={defaultChartConfig}
    />
  );
};

export default observer(Widget);
