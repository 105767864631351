import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import APIHelper from "libs/APIHelper";
import LayerStore from "libs/Layers";

const ServiceLayerSelector = (props) => {
  const {
    layerURL,
    searchAttribute,
    idAttribute,
    inputLabel,
    selectedEntity,
    handleSelectEntity,
  } = props;
  const { map, view, token } = LayerStore;

  const [entities, setEntities] = useState([]);

  useEffect(() => {
    // entities berechnen
    // setEntities([]);
    // setSelectedEntity("");
    // selectedEntity = "";
    let aborted = false;
    APIHelper.queryAllFeaturesOnLayerUrl(
      layerURL,
      token,
      view,
      map,
      /*returnGometry*/ false,
      false,
      [idAttribute, searchAttribute]
    )
      .then((features) => {
        if (!aborted) {
          const searchAttributeMap = {};
          // map: searchAttribute -> ids
          features
            .map((feature) => APIHelper.getAttributes(feature)) // in the following only attributes needed
            .forEach((attributes) => {
              if (!searchAttributeMap[attributes[searchAttribute]]) {
                searchAttributeMap[attributes[searchAttribute]] = [
                  attributes[idAttribute],
                ];
              } else {
                searchAttributeMap[attributes[searchAttribute]].push(
                  attributes[idAttribute]
                );
              }
            });

          //create Options Array with elements of form {[searchAttribute], ids}
          const options = Object.keys(searchAttributeMap)
            .sort() /* keys are the searchAttributes => standard string sort is enough */
            .map((displayValue) => {
              return {
                [searchAttribute]: displayValue,
                ids: searchAttributeMap[displayValue],
                // for later getting the geometry
                searchAttribute,
                layerURL,
              };
            });

          setEntities(options);
        }
      })
      .catch((e) => {
        if (aborted) {
          console.warn(e, "but already aborted");
        } else {
          console.error(e);
        }
      });
    return () => {
      aborted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      size={"small"}
      sx={{ marginBottom: "15px" }}
      map={map}
      view={view}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          // label={selectedEntity ? selectedEntity[searchAttribute] : inputLabel}
          label={inputLabel}
          value={selectedEntity && selectedEntity[searchAttribute]}
        />
      )}
      options={entities}
      getOptionLabel={(option) => option[searchAttribute] || ""}
      value={selectedEntity}
      disableClearable
      fullWidth
      onChange={(e, value) => {
        handleSelectEntity(value);
      }}
    />
  );
};

export default ServiceLayerSelector;
