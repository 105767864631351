import {
  Autocomplete,
  Button,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
} from "@mui/material";

const DownloadList = ({ mp, extraMenuItems }) => {
  // console.log(mp);
  const primaryKey = mp.groupParam;
  const paramsSlectedYear = {};
  const handleYearBookDownload = (e, param, config) => {
    console.log(e);
    const selectedYearbook =
      paramsSlectedYear[param.name]?.jahr || param.selectEntries[0]?.jahr;
    const url =
      config.downloadURL +
      "?site=" +
      encodeURIComponent(mp.station.Datenherr_kurz) +
      "&org=" +
      encodeURIComponent(mp.station.Org_Einheit_kurz) +
      "&nr=" +
      encodeURIComponent(mp.station_no) +
      "&year=" +
      encodeURIComponent(selectedYearbook) +
      "&param=" +
      encodeURIComponent(param.name);
    console.log(url);
    window.open(url);
  };
  console.log(paramsSlectedYear);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        overflow: "auto",
        padding: 5,
      }}
    >
      <List
        sx={{ width: "100%" }}
        subheader={
          <ListSubheader sx={{ textAlign: "center", fontSize: "x-large" }}>
            {primaryKey}
          </ListSubheader>
        }
      >
        {extraMenuItems[primaryKey].downloadParameters.map((param) => {
          return (
            <ListItem key={param.uiName} alignItems={"center"}>
              <ListItemText
                sx={{ width: "100%" }}
                primary={param.uiName + ":"}
              />

              <Autocomplete
                key={param.name}
                sx={{
                  borderRadius: 1,
                  mb: "5px",
                  mt: "5px",
                  mr: "25px",
                }}
                size={"small"}
                fullWidth
                label={"Wählen Jahr"}
                options={param.selectEntries}
                defaultValue={param.selectEntries[0]}
                // value={param.selectedYear}
                getOptionLabel={(option) => option.string || ""}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Wählen Jahr" />
                )}
                disableClearable
                onChange={(event, newValue) => {
                  // param.selectedYear = newValue;
                  paramsSlectedYear[param.name] = newValue;
                }}
                // isOptionEqualToValue={(option, value) => (option, value) =>
                //   option.string === value.string}
              />

              <Button
                variant="outlined"
                sx={{ lineHeight: "2.75" }}
                size={"small"}
                fullWidth
                onClick={(e) =>
                  handleYearBookDownload(
                    e,
                    param,
                    extraMenuItems[primaryKey].config
                  )
                }
                // disabled={selectedRegion === "" ? true : false}
              >
                Download
              </Button>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default DownloadList;
