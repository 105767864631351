import React from "react";
import { observer } from "mobx-react-lite";
import ThemaSelection from "./components/ThemaSelection";
import StationSelection from "./components/StationSelection";
import RootStore from "stores/RootStore";
import StatisticDateRangePicker from "./components/StatisticDateRangePicker";
import { makeStyles } from "@mui/styles";
import StationStatisticsContainer from "./components/StationStatisticsContainer";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  chip: { fontSize: "inherit" },
}));

const Widget = observer((props) => {
  const {
    selectedStatisticTopic,
    selectedStatStation,
    isRangeValid,
    statisticDateRange,
  } = RootStore.statisticStore;
  const classes = useStyles({});
  const dividerTextAlign = "left";

  // const configStore = RootStore.appsTimeSeriesConfig[RootStore.apps[1]];

  return (
    <div style={{ margin: "20px", position: "relative" }} id="statistic">
      <ThemaSelection />

      {selectedStatisticTopic && <StationSelection />}

      {selectedStatStation && (
        <>
          <StatisticDateRangePicker
            classes={classes}
            dividerTextAlign={dividerTextAlign}
          />

          {isRangeValid(statisticDateRange) && (
            <StationStatisticsContainer
              classes={classes}
              dividerTextAlign={dividerTextAlign}
              title={"Station Statistik"}
            />
          )}
        </>
      )}
    </div>
  );
});

export default Widget;
