import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  CardActions,
  CardHeader,
  Collapse,
  IconButton,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import React, { useState } from "react";

const useStyles = makeStyles({
  cardStyle: {
    marginBottom: "4px",
    borderRadius: 3,
    color: "dimgray",
    border: "1px solid lightgray",
  },
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Widget = ({ contentTitle, id, children }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      id={id}
      className={classes.cardStyle}
      elevation={expanded ? 1 : 5}
      sx={{
        "& > .MuiCardActions-root > .MuiCardHeader-root > .MuiCardHeader-content > .MuiTypography-root":
          {
            fontSize: "1.25rem",
            fontWeight: "400",
          },
      }}
    >
      <CardActions
        sx={{
          cursor: "pointer",
          pt: "2px",
          pb: "2px",
        }}
        disableSpacing
        onClick={handleExpand}
      >
        <CardHeader
          title={contentTitle}
          sx={{
            padding: "8px",
            pl: "0",
            pt: "0",
            pb: "0",
          }}
        />
        <ExpandMore
          expand={expanded}
          aria-expanded={expanded}
          aria-label="show more"
          data-testid="expandRegionSelection"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{ padding: "15px", paddingTop: "0px", paddingBottom: "0px" }}
      >
        {children}
      </Collapse>
    </Card>
  );
};

export default Widget;
