import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import { useEffect, useMemo, useState } from "react";
import SimpleTable from "libs/SimpleTable";

function Overview({
  // station, //={items}
  // mp: { station_no, app /*, groupParam = -1*/ },
  catchmentArea,
  catchmentAreasConfig,
  defaultChartConfig,
  // tsconfig,
  nls,
  store,
  // configStore,
}) {
  const [modalOpened, setModalOpened] = useState(false);
  const fields = [
    { name: "parameter", label: "Parameter", dataPath: "parameter" },
    { name: "value", label: "Wert", dataPath: "value" },
  ];
  const { feature, /*app, tab,*/ catchmentAreaInfo } = catchmentArea;
  // const { groupParam, index } = station[0];
  // const feature = store.data[groupParam][index];
  const pictureUrl = catchmentAreasConfig.BildURL + catchmentAreaInfo.Bild;
  const tableData = useMemo(() => {
    const rows = [
      {
        parameter: "Mittlere Höhe",
        value:
          (isNaN(catchmentAreaInfo.mHoehe) ? "-" : catchmentAreaInfo.mHoehe) +
          " m.ü.M.",
      },
      { parameter: "Einzugsgebiet", value: catchmentAreaInfo.name || "-" },
      {
        parameter: "Datenquelle",
        value: defaultChartConfig.EZGdatenquelle || "-",
      },
      {
        parameter: "Grösse EZG",
        value:
          (isNaN(catchmentAreaInfo.groesse)
            ? "-"
            : Math.round(catchmentAreaInfo.groesse / 1000 / 100) / 10) + " km²",
      },
    ];
    return rows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature, catchmentAreaInfo]);

  useEffect(() => {
    setModalOpened(false);
  }, [feature, catchmentAreaInfo]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        overflow: "auto",
        padding: 15,
      }}
    >
      <Typography variant="h5" style={{ marginBottom: 15 }}>
        {nls.overviewHeading}
      </Typography>
      <Divider />
      <Card>
        <CardMedia
          component="img"
          alt="Einzugsgebietsbild"
          image={pictureUrl}
          onClick={() => setModalOpened(true)}
          style={{ cursor: "pointer" }}
        />
        <CardContent>
          <SimpleTable fields={fields} data={tableData} />
        </CardContent>
      </Card>
      <Modal
        open={modalOpened}
        onClose={() => {
          setModalOpened(false);
        }}
        BackdropProps={{
          style: {
            cursor: "pointer",
          },
        }}
      >
        <Card
          style={{
            // assure that the picture is not rendered taller than the window
            maxWidth: "80%",
            maxHeight: "80%",
            // positioning in middle of window
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
          onClick={() => setModalOpened(false)}
        >
          <CardMedia component="img" alt="Stationsbild" image={pictureUrl} />
        </Card>
      </Modal>
    </div>
  );
}

export default Overview;
