import APIHelper from "libs/APIHelper";
import { observable, action, makeObservable, computed, toJS } from "mobx";
class MapScopeThemeStore {
  // steps = ["Gebietsauswahl", "Themenauswahl"];
  steps = { Gebietsauswahl: "0", Themenauswahl: "1" };
  isLoading = false;
  expandedRegionsSelection = false;
  activeStep = 0;
  filterTextThemesTimeSeries = "";
  filterTextMeasuringPoints = "";
  selectedRegion = "";
  expandedThemesTimeSeries = [];
  selectedThemesTimeSeries = [];
  themesNodeIds = [];
  allThemesTimeSeriesNodeIds = [];
  selectedThemesParametrs = [];
  completedSteps = {};
  // totalFilteredFeaturesData = [];
  allMeasuringPointsTreeNodeIds = [];
  expandedMeasuringPoints = [];
  structuredTreeViewData = [];
  filteredTimeSeriesIdsByText = [];
  filteredThemesIdsByText = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      isLoading: observable,
      activeStep: observable,
      completedSteps: observable,
      expandedRegionsSelection: observable,
      selectedRegion: observable,
      expandedThemesTimeSeries: observable,
      selectedThemesTimeSeries: observable,
      allThemesTimeSeriesNodeIds: observable,
      allMeasuringPointsTreeNodeIds: observable,
      themesNodeIds: observable,
      selectedThemesParametrs: observable,
      filterTextThemesTimeSeries: observable,
      filterTextMeasuringPoints: observable,
      expandedMeasuringPoints: observable,
      filteredTimeSeriesIdsByText: observable,
      filteredThemesIdsByText: observable,
      setFilterTextThemesTimeSeries: action,
      setFilterTextMeasuringPoints: action,
      setSelectedThemesTimeSeries: action,
      setExpandedThemesTimeSeries: action,
      // setTotalFilteredFeaturesData: action,
      setExpandedMeasuringPoints: action,
      setStructuredTreeViewData: action,
      setAllMeasuringPointsTreeNodeIds: action,
      setFilteredTimeSeriesIdsByText: action,
      setFilteredThemesIdsByText: action,
      handleNextStep: action,
      treeViewItemsThemesTimeSeries: computed,
      treeViewItemsMeasuringPoints: computed,
    });
  }

  setAllMeasuringPointsTreeNodeIds = (newIds) => {
    this.allMeasuringPointsTreeNodeIds = newIds;
  };

  setFilteredTimeSeriesIdsByText = (newIds) => {
    this.filteredTimeSeriesIdsByText = newIds;
  };

  setFilteredThemesIdsByText = (newIds) => {
    this.filteredThemesIdsByText = newIds;
  };

  setStructuredTreeViewData = (data) => {
    this.structuredTreeViewData = data;
  };

  setExpandedMeasuringPoints = (newIds) => {
    this.expandedMeasuringPoints = newIds;
  };

  // setTotalFilteredFeaturesData = (fitleredData) => {
  //   this.totalFilteredFeaturesData = fitleredData;
  // };

  setSelectedThemesTimeSeries = (newIds) => {
    this.selectedThemesTimeSeries = newIds;
  };

  setExpandedThemesTimeSeries = (newIds) => {
    this.expandedThemesTimeSeries = newIds;
  };

  initStore = () => {
    this.setStructuredTreeViewData(this.structuringTreeViewData);
    const themes_nodeIds = this.treeViewItemsThemesTimeSeries.map(
      (theme) => theme.ID
    );
    // const timeSereies_nodeIds =
    //   this.rootStore.envisHydroConfig.ZeitreihenConfig.map(
    //     (zeitreihe) => zeitreihe.ID
    //   );
    const timeSereies_nodeIds = this.treeViewItemsThemesTimeSeries
      .map((theme) => theme.children.map((child) => child.ID))
      .flat(Infinity);
    const all_ThemesTimeSeriesNodeIds = [
      ...themes_nodeIds,
      ...timeSereies_nodeIds,
    ];
    this.themesNodeIds.replace(themes_nodeIds);
    this.allThemesTimeSeriesNodeIds.replace(all_ThemesTimeSeriesNodeIds);
    this.setSelectedThemesTimeSeries(all_ThemesTimeSeriesNodeIds);
  };

  get finalSelectedTimeSeriesIds() {
    return this.selectedThemesTimeSeries.filter((item) =>
      this.filteredTimeSeriesIdsByText.includes(item)
    );
  }

  get finalSelectedThemesIds() {
    return this.selectedThemesTimeSeries.filter((item) =>
      this.filteredThemesIdsByText.includes(item)
    );
  }

  setThemesParametrs = () => {
    const allTimeSeries = this.rootStore.envisHydroConfig.ZeitreihenConfig;
    const parameters = allTimeSeries
      .filter((zeitreihe) =>
        this.finalSelectedTimeSeriesIds.includes(zeitreihe.ID)
      )
      .map((zeitreihe) => zeitreihe.GruppenID + "," + zeitreihe.TypID);
    this.selectedThemesParametrs.replace(parameters);
  };

  setFilterThemesTimeSeries = (GeoFilter, Themesfilter) => {
    this.setThemesParametrs();
    let filter;
    if (!GeoFilter && Themesfilter) {
      // console.log("onlyThemes");
      filter = {
        parameter: toJS(this.selectedThemesParametrs),
      };
    } else if (GeoFilter && !Themesfilter) {
      // console.log("onlyGeo");
      filter = {
        geometry: toJS(this.selectedRegion?.geometry),
      };
    } else if (GeoFilter && Themesfilter) {
      // console.log("Geo And Themes");
      filter = {
        parameter: toJS(this.selectedThemesParametrs),
        geometry: toJS(this.selectedRegion?.geometry),
      };
    }
    this.rootStore.envisFeatures.setFilter(filter);
  };

  getSelectedParent = (selectedTreeItemID) => {
    return this.treeViewItemsThemesTimeSeries.filter(
      (selectedTreeItem) => selectedTreeItem.ID === selectedTreeItemID
    );
  };

  isTreeItemAlreadySelected = (selectedTreeItemID) => {
    return this.selectedThemesTimeSeries.includes(selectedTreeItemID);
  };

  getParentofSelectedChild = (selectedTreeItemID) => {
    return this.treeViewItemsThemesTimeSeries.filter((item) =>
      item.children.map((child) => child.ID).includes(selectedTreeItemID)
    )[0].ID;
  };

  getChildrenNodeIdsOfTheParent = (parentID) => {
    const selectedParentTreeItem = this.getSelectedParent(parentID);
    let childrenNodeIds = [];
    if (
      selectedParentTreeItem.length > 0 &&
      selectedParentTreeItem[0].children &&
      selectedParentTreeItem[0].children.length > 0
    ) {
      childrenNodeIds = selectedParentTreeItem[0].children.map(
        (child) => child.ID
      );
    }
    return childrenNodeIds;
  };

  deselectTreeItems = (nodeIds) => {
    this.setSelectedThemesTimeSeries(
      this.selectedThemesTimeSeries.filter((id) => !nodeIds.includes(id))
    );
  };

  deselectJustChild = (childId) => {
    this.setSelectedThemesTimeSeries(
      this.selectedThemesTimeSeries.filter((id) => id !== childId)
    );
  };

  selectTreeItems = (nodeIds) => {
    this.setSelectedThemesTimeSeries([
      ...nodeIds,
      ...this.selectedThemesTimeSeries,
    ]);
  };

  selectJustChild = (childId) => {
    this.setSelectedThemesTimeSeries([
      childId,
      ...this.selectedThemesTimeSeries,
    ]);
  };

  isAllSiblingsSelected = (parentID, selectedTreeItemID) => {
    const allChildrenOfchildParent =
      this.getChildrenNodeIdsOfTheParent(parentID);
    return allChildrenOfchildParent
      .filter((child) => child !== selectedTreeItemID)
      .every((element) => {
        return this.selectedThemesTimeSeries.includes(element);
      });
  };

  isAtLeastOneSiblingSelected = (parentID, selectedTreeItemID) => {
    const allChildrenOfchildParent =
      this.getChildrenNodeIdsOfTheParent(parentID);
    return allChildrenOfchildParent
      .filter((child) => child !== selectedTreeItemID)
      .some((element) => {
        return this.selectedThemesTimeSeries.includes(element);
      });
  };

  isAllChildrenSelected = (allChildrenOfParent) => {
    return allChildrenOfParent.every((element) => {
      return this.selectedThemesTimeSeries.includes(element);
    });
  };

  isAllChildrenDeselected = (allChildrenOfParent) => {
    return allChildrenOfParent.every((element) => {
      return !this.selectedThemesTimeSeries.includes(element);
    });
  };

  isAtLeastOneChildSelected = (allChildrenOfParent) => {
    return allChildrenOfParent.some((element) => {
      return this.selectedThemesTimeSeries.includes(element);
    });
  };

  getNodeIdsSelectedParentWithItsChildren = (selectedTreeItemID) => {
    const childrenNodeIds =
      this.getChildrenNodeIdsOfTheParent(selectedTreeItemID);
    const nodeIdsOfParentAndItsChildren = [
      selectedTreeItemID,
      ...childrenNodeIds,
    ];
    return nodeIdsOfParentAndItsChildren;
  };

  setFilterTextThemesTimeSeries = (filterText) => {
    this.filterTextThemesTimeSeries = filterText;
  };

  setFilterTextMeasuringPoints = (filterText) => {
    this.filterTextMeasuringPoints = filterText;
  };

  handleStep = (step) => () => {
    this.activeStep = step;
  };

  handleNextStep = () => {
    const newCompleted = this.completedSteps;
    newCompleted[this.activeStep] =
      this.activeStep === 0
        ? this.selectedRegion !== ""
          ? true
          : false
        : this.activeStep === 1
        ? this.selectedThemesTimeSeries.length > 0
          ? true
          : false
        : false;
    this.completedSteps = newCompleted;
    // this.activeStep = this.activeStep + 1;
    if (this.activeStep === 1) {
      if (Object.values(this.selectedRegion).length > 0) {
        this.setFilterThemesTimeSeries(true, true);
      } else {
        this.setFilterThemesTimeSeries(false, true);
      }
      // this.setTotalFilteredFeaturesData(this.getTotalFilteredFeaturesdata);
      // this.setTotalFilteredFeaturesData(
      //   this.filteredTreeViewDataByGroupParameters
      // );
    }
    this.activeStep = this.activeStep === 2 ? 2 : this.activeStep + 1;
  };

  handleBackStep = () => {
    this.completedSteps = this.selectedRegion !== "" ? this.completedSteps : {};
    this.activeStep = this.activeStep - 1;
  };

  handleResetSteps = () => {
    this.activeStep = 0;
    this.completedSteps = {};
    this.setExpandedThemesTimeSeries([]);
    this.setSelectedThemesTimeSeries(this.allThemesTimeSeriesNodeIds);
    this.setFilterTextThemesTimeSeries("");
    this.selectedRegion = "";
    this.setFilterThemesTimeSeries(false, true);
    this.setFilterTextMeasuringPoints("");
  };

  processedSteps = () => {
    // return Object.values(this.completedSteps);
    return Object.keys(this.completedSteps).filter(
      (key) => this.completedSteps[key]
    );
  };

  isThemeSelectionStepCompleted = () => {
    return this.processedSteps().includes(this.steps.Themenauswahl);
  };

  handleExpandRegionsSelection = () => {
    this.expandedRegionsSelection = !this.expandedRegionsSelection;
  };

  handleSelectRegion = (option, geometry) => {
    this.selectedRegion = { option, geometry };
    this.setFilterThemesTimeSeries(true, false);
    this.completedSteps[this.activeStep] = true;
    if (geometry.extent)
      this.rootStore.mapStore.sceneView.center = geometry.extent.center;
    return APIHelper.zoomToExtent(
      this.rootStore.mapStore.scene,
      geometry.extent,
      this.rootStore.mapStore.sceneView,
      1.2
    );
  };

  handleRemoveAllRegionSelection = () => {
    this.selectedRegion = "";
    this.completedSteps[this.activeStep] = false;
    this.setFilterThemesTimeSeries(false, true);
  };

  doNameIncludeText = (obj, searchPattern) => {
    if (obj.Name) {
      return obj.Name.toLowerCase().includes(searchPattern);
    } else {
      return false;
    }
  };

  filterByText = (data, searchPattern) => {
    return data.reduce((filteredData, parent) => {
      if (this.doNameIncludeText(parent, searchPattern)) {
        filteredData.push({ ...parent });
      } else if (parent.children) {
        const children = this.filterByText(parent.children, searchPattern);
        if (children.length) {
          filteredData.push({ ...parent, children: children });
        }
      }
      return filteredData;
    }, []);
  };

  get treeViewItemsThemesTimeSeries() {
    const searchPattern = this.filterTextThemesTimeSeries.toLowerCase();
    const treeViewData = this.structuredTreeViewData.map((theme) => {
      return {
        ...theme,
        children: theme.children.map((timeSeries) => {
          return {
            ...timeSeries,
            children: [],
          };
        }),
      };
    });
    const finalFilteredData = this.filterByText(treeViewData, searchPattern);
    const filteredTimeSereies_nodeIds = finalFilteredData
      .map((theme) => theme.children.map((child) => child.ID))
      .flat(Infinity);
    this.setFilteredTimeSeriesIdsByText(filteredTimeSereies_nodeIds);
    this.setFilteredThemesIdsByText(finalFilteredData.map((theme) => theme.ID));
    return finalFilteredData;
  }

  get treeViewItemsMeasuringPoints() {
    const searchPattern = this.filterTextMeasuringPoints.toLowerCase();
    const finalFilteredData = this.filterByText(
      this.filteredTreeViewDataByGroupParameters,
      searchPattern
    );
    const measuringPointsNodeIds = finalFilteredData
      .map((theme) =>
        theme.children.map((child) => child.children.map((point) => point.ID))
      )
      .flat(Infinity);
    const all_MeasuringPointsTreeNodeIds = [
      ...this.finalSelectedThemesIds,
      ...this.finalSelectedTimeSeriesIds,
      ...measuringPointsNodeIds,
    ];
    this.setAllMeasuringPointsTreeNodeIds(all_MeasuringPointsTreeNodeIds);
    // const finalFilteredData = filter(this.totalFilteredFeaturesData, searchPattern);
    return finalFilteredData;
  }

  isClickedItemMeasuringPoints = (nodeId) => {
    const finalSelectedThemesTimeSeriesIds = [
      ...this.finalSelectedThemesIds,
      ...this.finalSelectedTimeSeriesIds,
    ];
    return !finalSelectedThemesTimeSeriesIds.includes(nodeId);
  };

  findItemInNestedArrayById = (arr, itemId, nestingKey, idKey) =>
    arr.reduce((a, item) => {
      if (a) {
        return a;
      }
      if (item[idKey] === itemId) {
        return item;
      }
      if (item[nestingKey]) {
        return this.findItemInNestedArrayById(
          item[nestingKey],
          itemId,
          nestingKey,
          idKey
        );
      }
      return a;
    }, null);
  // Example:
  // const measuringPoint = findItemInNestedArray (
  //     treeViewItemsMeasuringPoints,
  //     slectedTreeItem, or parseInt(slectedTreeItem)
  //     "children",
  //     "ID"
  //   );

  handleMeasuringPointMenuOptionsSelection = (
    e,
    treeItemId,
    treeItemMenuOptions
  ) => {
    const targetMeasuringPoint = this.findItemInNestedArrayById(
      this.treeViewItemsMeasuringPoints,
      treeItemId,
      "children",
      "ID"
    );
    if (e.currentTarget.innerText === treeItemMenuOptions[0]) {
      this.rootStore.mapStore.layer.highlightStation(
        targetMeasuringPoint.station_id,
        5000
      );
      this.rootStore.mapStore.layer.zoomToStation(
        targetMeasuringPoint.station_id
      );
    } else if (e.currentTarget.innerText === treeItemMenuOptions[1]) {
      const groupParameter = this.rootStore.noChartStationsGroupParams.includes(
        targetMeasuringPoint.groupParam
      )
        ? -1
        : targetMeasuringPoint.groupParam;
      this.rootStore.mapStore.openMeasuringPointWidget(
        targetMeasuringPoint.station_id,
        "envis",
        groupParameter,
        targetMeasuringPoint
      );
    } else if (e.currentTarget.innerText === treeItemMenuOptions[2]) {
      this.rootStore.mapStore.layer.zoomToStation(
        targetMeasuringPoint.station_id
      );
    }
  };

  get structuringTreeViewData() {
    const { envisHydroConfig } = this.rootStore;
    const { data: featuresData } = toJS(this.rootStore.envisFeatures);
    // const { dataFilteredJS: dataFiltered } = this.rootStore.envisFeatures;
    return envisHydroConfig.Themen.map((theme, index) => {
      const relatedTimeSeries = envisHydroConfig.ZeitreihenConfig.filter(
        (timeSeriesConfig) =>
          theme.Zeitreihen.split(",").includes(timeSeriesConfig.ID)
      );
      return {
        ...theme,
        // ID: theme.Name + "-" + index,
        ID: theme.Name,
        children: relatedTimeSeries.map((child) => {
          const relatedMeasuringPoints =
            featuresData[`${child.GruppenID},${child.TypID}`];
          return {
            ...child,
            Name: child.Anzeigename,
            groupParam: `${child.GruppenID},${child.TypID}`,
            children: relatedMeasuringPoints
              ? relatedMeasuringPoints.map((item) => {
                  return {
                    ...item,
                    ID: String(item.ts_id),
                    Name: item.station_name + " (" + item.station_no + ")",
                    groupParam: `${child.GruppenID},${item.parametertype_id}`,
                  };
                })
              : [],
          };
        }),
      };
    });
  }

  get filteredTreeViewDataByGroupParameters() {
    const { dataFilteredJS: dataFiltered } = this.rootStore.envisFeatures;
    const groupParameters = Object.keys(dataFiltered);
    const themeHasAtLeastOneChildeInFilter = this.structuredTreeViewData.filter(
      (parentTreeItem) =>
        parentTreeItem.children.some((timeSeries) => {
          return groupParameters.includes(timeSeries.groupParam);
        })
    );
    const filteredTreeViewData = themeHasAtLeastOneChildeInFilter.map(
      (parentTreeItem) => {
        const filteredTimeSeries = parentTreeItem.children.filter(
          (timeSeries) => groupParameters.includes(timeSeries.groupParam)
        );
        const filteredChildren = filteredTimeSeries.map((timeSeries) => {
          const filterdMeasuringPointsIDs = dataFiltered[
            timeSeries.groupParam
          ].map((point) => point.ts_id);
          return {
            ...timeSeries,
            children: timeSeries.children.filter((child) =>
              filterdMeasuringPointsIDs.includes(parseInt(child.ID))
            ),
          };
        });
        return {
          ...parentTreeItem,
          children: filteredChildren,
        };
      }
    );
    return filteredTreeViewData;
  }
}

export default MapScopeThemeStore;
