import { Button } from "@mui/material";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DownloadIcon from "@mui/icons-material/Download";
import CachedIcon from "@mui/icons-material/Cached";

const FooterButtons = ({
  nls,
  fullscreen,
  setFullscreen,
  download,
  update,
}) => {
  // console.log(">>>>>> render FooterButtons");

  return (
    <div style={{ display: "inline-flex" }}>
      <Button
        size="small"
        style={{ border: 0 }}
        onClick={() => {
          setFullscreen(!fullscreen);
        }}
        title={nls.fullscreen}
      >
        {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </Button>
      {/* <Button
          size="small"
          style={{ border: 0 }}
          onClick={() => {
            setShowPoints(!showPoints);
          }}
          title={showPoints ? nls.hidePoints : nls.showPoints}
        >
          {showPoints ? <ShowChartIcon /> : <TimelineIcon />}
        </Button> */}
      {download && false && (
        <Button
          size="small"
          style={{ border: 0 }}
          onClick={() => {
            download();
          }}
        >
          <DownloadIcon />
        </Button>
      )}
      {update && (
        <Button
          size="small"
          style={{ border: 0 }}
          onClick={() => {
            update();
          }}
        >
          <CachedIcon />
        </Button>
      )}
    </div>
  );
};

export default FooterButtons;
