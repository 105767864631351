import { CircularProgress } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { toJS } from "mobx";
import { Observer } from "mobx-react";
import { ChartComponent } from "components/chart/Chart";
import RootStore from "stores/RootStore";
import { FeatureStore } from "utils/MeasuringPointFeatureStore";
import { EnVISChart } from "utils/MeasuringPointChart";
import { grpahInfo, createLegend } from "utils/ChartUtils";

const getTimeseriesForChart = ({ tsconfig, configStore, feature, store }) => {
  const timeseries = [];
  tsconfig.Reihen.forEach((tseries) => {
    /*
    "BasisURL": "https://www.ag.ch/app/hydrometrie/kiwis/KiWIS?datasource=0&service=kisters&type=queryServices",
          "GruppenID": "71407",
          "TypID": "542",
     */
    let ts = {
      Einheit: tsconfig.Einheit,
      groupParam: `${tsconfig.GruppenID},${tsconfig.TypID}`,
      baseUrl: tsconfig.BasisURL,
      Nachkommastellen: tsconfig.Nachkommastellen,
      Anzeigename: tsconfig.Anzeigename,
      Grenzwert: tsconfig.Grenzwert,
      Zusatzreihe: tsconfig.Zusatzreihe === true,
      ...tseries,
      feature,
    };
    if (tseries.Zusatzreihe) {
      let newTs = null;
      const keys = Object.keys(configStore);
      for (let i = 0; i < keys.length; i++) {
        const item = configStore[keys[i]];
        // eslint-disable-next-line eqeqeq
        if (item.ID == tseries.Zusatzreihe) {
          newTs = item;
          break;
        }
      }
      if (!newTs) ts = null;
      else {
        ts = null;
        if (newTs.Reihen?.length) {
          ts = { ...newTs.Reihen[0] };
          if (!ts.Farbe) ts.Farbe = newTs.Farbe;
          ts.Anzeigename = newTs.Anzeigename;
          ts.Einheit = newTs.Einheit;
          ts.Nachkommastellen = newTs.Nachkommastellen;
          ts.Grenzwert = newTs.Grenzwert;
          ts.groupParam = `${newTs.GruppenID},${newTs.TypID}`;
          ts.Zusatzreihe = true;
          const station = store.indices.station[feature.station_id];
          for (let i = 0; i < station.length; i++) {
            if (station[i].groupParam === ts.groupParam) {
              ts.feature = toJS(store.data[ts.groupParam][station[i].index]);
              break;
            }
          }
          ts.baseUrl = newTs.BasisURL;
        }
      }
    }
    if (ts) {
      const {
        Farbe,
        Art,
        Feld,
        Strichstaercke,
        Anzeigename,
        Einheit,
        groupParam,
        baseUrl,
        Nachkommastellen,
        Grenzwert,
        feature,
        Zusatzreihe,
      } = ts;
      if (
        Feld !== "ts_value" &&
        Feld !== "ts_value2" &&
        Feld !== "ts_summe" &&
        !feature[Feld]
      ) {
      } else {
        timeseries.push({
          Farbe,
          Art,
          Feld,
          Strichstaercke,
          Anzeigename,
          Einheit,
          groupParam,
          baseUrl,
          Nachkommastellen,
          Grenzwert,
          feature,
          Zusatzreihe,
        });
      }
    }
  });
  return timeseries;
};

const getChartStores = ({ tsconfig, configStore, feature, store }) => {
  const configs = getTimeseriesForChart({
    tsconfig,
    configStore,
    feature,
    store,
  });
  return configs.map(
    ({
      Farbe,
      Art,
      Feld, // ts_value, ts_value2, ts_summe => vielleicht als Reduce Function
      Strichstaercke,
      Anzeigename,
      Einheit,
      groupParam,
      baseUrl,
      Nachkommastellen,
      Grenzwert,
      Zusatzreihe,
      feature,
    }) => {
      return new FeatureStore({
        Farbe,
        Art,
        Feld, // ts_value, ts_value2, ts_summe => vielleicht als Reduce Function
        Strichstaercke,
        Anzeigename,
        Einheit,
        groupParam,
        baseUrl,
        Nachkommastellen,
        Grenzwert,
        Zusatzreihe,
        feature,
      });
      // woher die ts_id bei Zusatzreihen  => in selber {groupParam, index} = store.indices.station[station_no][.] nach groupParam suchen und dann in store.data[groupParam][index]
    }
  );
};

function Graph({
  period,
  periodsOption,
  setSelectedPeriod,
  reduceInterval,
  tsconfig,
  feature,
  store,
  configStore,
  title,
  showPoints,
  nls,
  setLegend,
  setDownload,
  setUpdate,
  setIsShowingPeriodTooltip,
}) {
  // console.log(">>>>>> render Graph");
  const ref = useRef();
  const [chart, setChart] = useState(
    new EnVISChart({
      stores: [],
      showPoints: showPoints,
    })
  );
  const { isMobileView } = RootStore.uiStore;
  useEffect(() => {
    // console.log("useEffect Graph 01");
    chart.setShowPoints(showPoints);
  }, [showPoints, chart]);

  useEffect(() => {
    // console.log("useEffect Graph 02"); ///HIER START
    // loading listener => Chart neu aufbauen wenn alle geladen, wenn nicht BI
    const stores = getChartStores({
      tsconfig,
      configStore,
      feature,
      store,
    });
    const chart = new EnVISChart({
      stores,
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight,
      title,
      updateLegend: (ch) => {
        setLegend(createLegend(stores, nls, ch));
      },
    });
    // console.log("EnVISChart", chart);
    setChart(chart);
    RootStore.mapStore.measuringPointChart = chart;
    setLegend(null);
    // setDownload(() => {
    //   return () => chart.download(); // react is weird -.-
    // });
    setUpdate(() => {
      return () => {
        chart.update();
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMobileView,
    tsconfig?.ID,
    feature?.timestamp,
    feature?.ts_id,
    ref.current,
  ]);

  useEffect(() => {
    // console.log("useEffect Graph 03");
    chart?.setPeriod(
      period,
      reduceInterval,
      false,
      periodsOption,
      setSelectedPeriod,
      setIsShowingPeriodTooltip
    );
    setDownload(() => {
      let downloadURL;
      const downloadURLSpecial =
        tsconfig.downloadURLSpecial &&
        tsconfig.downloadURLSpecial["" + feature.ts_id];
      console.log(downloadURLSpecial);
      if (downloadURLSpecial) {
        downloadURL = downloadURLSpecial + "&period=" + chart.stores[0]?.period;
      } else {
        const tsConfigDownloadURL = tsconfig.downloadURL;
        if (tsConfigDownloadURL) {
          const urlparams = {
            station_id: feature.station_id,
            period: chart.stores[0]?.period,
          };
          const queryString = Object.keys(urlparams)
            .map(
              (key) =>
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(urlparams[key])
            )
            .join("&");
          downloadURL =
            tsConfigDownloadURL +
            (tsConfigDownloadURL.indexOf("?") !== -1 ? "&" : "?") +
            queryString;
        }
      }
      if (downloadURL) {
        return () => chart.download(downloadURL);
      } else {
        return null;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, period, reduceInterval]);
  // useEffect(() => {}, [tsconfig, period]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      ref={ref}
    >
      <Observer>
        {() => {
          return chart.loading ? (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
              thickness={5}
            />
          ) : chart?.chart?.datasets[0].data.length > 0 ? (
            // <div style={{ display: "flex", flexDirection: "column" }}>
            <>
              <ChartComponent
                style={{
                  // width: "100%", height: "100%",
                  flexGrow: 1,
                }}
                chart={chart.chart}
              />
              <div
                style={{
                  marginTop: "-30px",
                  textAlign: "center",
                  flexGrow: 0,
                  fontSize: isMobileView ? "14px" : "16px",
                }}
              >
                {grpahInfo(store?.app, chart, tsconfig?.Nachkommastellen)}
              </div>
            </>
          ) : (
            // </div>
            "Keine Daten für alle Zeitperiode vorhanden."
          );
        }}
      </Observer>
    </div>
  );
}

export default Graph;
