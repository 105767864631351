// eslint-disable-next-line import/prefer-default-export
export const geometryServiceUrl =
  'https://utility.arcgisonline.com/ArcGIS/rest/services/Geometry/GeometryServer/reshape';

export const apiVersion = '3.29';
export const apiUrl = 'https://js.arcgis.com/3.29/';
export const geometryServerUrl =
  'https://utility.arcgisonline.com/ArcGIS/rest/services/Geometry/GeometryServer';
// "http://sampleserver6.arcgisonline.com";
export const allowMultiparts = false;

export const helpUrl = 'https://www.umweltkarten-niedersachsen.de/LaermHilfe/';
export const modifyGeometryHelpUrl =
  'auswahl-und-anzeige-von-daten/edittoolbar/geometrie-von-features-aendern';

export const proxyUrl = '';

export const fields = ['geometryServiceUrl', 'allowMultiparts', 'proxyUrl'];

export const pointExtentExtension = 100;
