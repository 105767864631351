/* eslint-disable no-underscore-dangle */
/* eslint-disable no-else-return */
import GraphicLayer from "@arcgis/core/layers/GraphicsLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import Layer from "@arcgis/core/layers/Layer";
import Graphic from "@arcgis/core/Graphic";
import Query from "@arcgis/core/tasks/support/Query";
import * as GeometryEngine from "@arcgis/core/geometry/geometryEngine";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import * as GeoProject from "@arcgis/core/geometry/projection";
// import { projection as GeoProject } from "@arcgis/core/geometry/projection";
import GeometryService from "@arcgis/core/tasks/GeometryService";
import Polygon from "@arcgis/core/geometry/Polygon";
import Point from "@arcgis/core/geometry/Point";
import Extent from "@arcgis/core/geometry/Extent";
import Geometry from "@arcgis/core/geometry/Geometry";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import ProjectParameters from "@arcgis/core/tasks/support/ProjectParameters";
import Polyline from "@arcgis/core/geometry/Polyline";
import { identify } from "@arcgis/core/rest/identify";
import IdentifyParameters from "@arcgis/core/rest/support/IdentifyParameters";
// import * as SymbolJsonUtils from "@arcgis/core/symbols/support/jsonUtils";
// import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
// import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
// import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import {
  geometryServiceUrl,
  pointExtentExtension,
  apiVersion,
  apiUrl,
  geometryServerUrl,
} from "../configs/config";
import symbols from "../configs/symbols";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { renderPreviewHTML } from "@arcgis/core/symbols/support/symbolUtils";

import { executeForCount } from "@arcgis/core/rest/query";
import Popup from "@arcgis/core/widgets/Popup";
import RootStore from "stores/RootStore";

export default class APIHelper {
  static instance;

  static esri = {};

  constructor() {
    if (APIHelper.instance) {
      return APIHelper; //.instance;
    }
    this.promise = new Promise((resolve) => {
      resolve(true);
    });
  }

  static get version() {
    return 4;
  }

  static get geometryEngine() {
    return GeometryEngine;
  }

  static getLayer(graphic) {
    return graphic.layer;
  }

  static isGraphicOnLayer(graphic, layer) {
    return graphic.layer && layer && graphic.layer.id == layer.id;
  }

  static projectGeometryTo(geometry, spatialReference) {
    return GeoProject.project(geometry, spatialReference);
  }

  static isLayerOnMap(map, layer) {
    return !!map.findLayerById(layer.id);
  }

  // GeometryEngines haben die selben interfaces => einfach austauschen
  static setSymbol(graphic, symbol) {
    // eslint-disable-next-line no-param-reassign
    graphic.symbol = symbol;
    // v3
    // graphic.setSymbol(symbol)
  }

  static getLayerGraphicsArray(layer) {
    return layer.graphics.toArray();
    // v3
    // return layer.graphics.concat([]);
  }

  static removeGraphicFromLayer(graphic, layer) {
    layer.remove(graphic); // same in both i guess
  }

  static addGraphicToLayer(graphic, layer) {
    layer.add(graphic); // same in both i guess
  }

  static removeAllGraphicsFromLayer(layer) {
    layer.removeAll();
  }

  static clearLayer(layer) {
    APIHelper.removeAllGraphicsFromLayer(layer);
  }

  static removeAllGraphics(layer) {
    APIHelper.removeAllGraphicsFromLayer(layer);
  }

  static isSameSpatialReference(sp1, sp2) {
    return sp1 === sp2 || (sp1 && sp2 && sp1.wkid === sp2.wkid);
  }

  static getSpatialReference(geometry) {
    // xxxxxxxxxxxxx;
    return geometry && geometry.spatialReference;
  }

  static constructSpatialReference(wkid) {
    // xxxxxxxxxxxxx;
    return new SpatialReference({ wkid: wkid });
  }

  static hideGraphic(graphic) {
    // eslint-disable-next-line no-param-reassign
    graphic.visible = false;
    // v3
    // graphic.hide();
  }

  static showGraphic(graphic) {
    // eslint-disable-next-line no-param-reassign
    graphic.visible = true;
    // v3
    // graphic.show()
  }

  static hideLayer(layer) {
    layer.visible = false;
  }

  static showLayer(layer) {
    layer.visible = true;
  }

  static cloneAttributes(graphic) {
    //  clone attributes
    const attributes = {};
    const keys = Object.keys(graphic.attributes);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      attributes[k] = graphic.attributes[k];
    }
    return attributes;
  }

  static createGraphic(geometry, symbol, attributes) {
    return new Graphic({ geometry, symbol, attributes }); // sollte so auch in beiden gehen, aber in v3 müssen die symboltypen und die geometrietypen required werden
  }

  static createGraphicWithStandardSymbol(
    geometry,
    attributes = {},
    symbolType = "selected"
  ) {
    const symbol =
      symbolType === "mask"
        ? symbols(APIHelper.version)["mask"]
        : symbols(APIHelper.version)[
            APIHelper.getGeometryType(geometry).toLowerCase()
          ][symbolType];
    return new Graphic({
      geometry,
      symbol,
      attributes,
    }); // sollte so auch in beiden gehen, aber in v3 müssen die symboltypen und die geometrietypen required werden
  }

  static createPolygon(geometry) {
    return new Polygon(geometry);
  }

  static createPoint(geometry) {
    return new Point(geometry);
  }

  static createExtent(minx, miny, maxx, maxy, sr) {
    return new Extent({
      xmin: minx,
      ymin: miny,
      xmax: maxx,
      ymax: maxy,
      spatialReference:
        typeof sr === "object"
          ? new SpatialReference({ wkid: sr.wkid })
          : new SpatialReference({ wkid: sr }),
    });
  }

  static createPolygonFromExtent(extent) {
    return Polygon.fromExtent(extent);
  }

  static isExtent(obj) {
    return obj && obj.type === "extent";
  }

  static cloneGeometry(geo) {
    return geo.clone();
    // in v3 erst .tojson() und dann mit geometry/jsonUtils neu erstellen
  }

  static addToMap(map, layer) {
    map.add(layer); // same in both
  }

  static removeFromMap(map, layer) {
    map.remove(layer); // same in both - not anymore
  }

  static createGraphicsLayer(options) {
    return new GraphicLayer({ ...options, listMode: "hide" }); // same in both
    // set name
  }

  static queryFeatureByIdOnLayer(id, layer) {
    const query = new Query();
    query.returnGeometry = true;
    query.outFields = ["*"];
    query.maxAllowableOffset = 0; // no generalization
    query.objectIds = [id];
    return layer.queryFeatures(query);
  }

  static queryFeaturesByIdsOnLayer(ids, layer) {
    const query = new Query();
    query.returnGeometry = true;
    query.outFields = ["*"];
    query.maxAllowableOffset = 0; // no generalization
    query.objectIds = ids;
    return new Promise(function (resolve, reject) {
      layer
        .queryFeatures(query)
        .then(function (response) {
          response.features = response.features.map((feature) => {
            // eslint-disable-next-line no-param-reassign
            feature.layer = layer;
            return feature;
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  static queryFeaturesByIdsOnLayerUrl(layerurl, ids, token) {
    const layer = this.createFeatureLayer({ url: layerurl }, { token });
    return APIHelper.queryFeaturesByIdsOnLayer(ids, layer).then(
      (response) => response.features
    );
  }

  static queryFeatureByIdOnLayerUrl(layerurl, id, token) {
    const query = new Query();
    query.returnGeometry = true;
    query.outFields = ["*"];
    query.maxAllowableOffset = 0; // no generalization
    query.objectIds = [id];
    const layer = this.createFeatureLayer({ url: layerurl }, { token });
    return layer.queryFeatures(query).then((response) => response.features[0]);
  }

  static calculateStatisticsFromLayer( // min, max, count, avg, median
    layerurl,
    token,
    view,
    map,
    field_name,
    queryGeometry
  ) {
    const layer = this.createFeatureLayer({ url: layerurl }, { token });
    const query = new Query();
    query.outStatistics = [
      {
        statisticType: "min",
        onStatisticField: `${field_name}`,
        outStatisticFieldName: "min",
      },
      {
        statisticType: "max",
        onStatisticField: `${field_name}`,
        outStatisticFieldName: "max",
      },
      {
        statisticType: "avg",
        onStatisticField: `${field_name}`,
        outStatisticFieldName: "avg",
      },
      {
        statisticType: "count",
        onStatisticField: `${field_name}`,
        outStatisticFieldName: "count",
      },
    ];
    if (queryGeometry) {
      query.geometry = queryGeometry.clone();
      query.spatialRelationship = "contains";
    }
    return new Promise((resolve, reject) => {
      layer
        .queryFeatures(query)
        .then((response) => {
          const { min, max, avg, count } = response.features[0].attributes;
          const countMod2 = count % 2;
          if (count == 0) {
            resolve({
              min: parseFloat(min),
              max: parseFloat(max),
              avg: parseFloat(avg),
              count: parseFloat(count),
            });
          } else {
            const query = new Query();
            query.where = "1=1";
            if (countMod2 == 0) {
              query.num = 2;
              query.start = count / 2 - 1;
            } else {
              query.num = 1;
              query.start = Math.floor(count / 2);
            }
            query.outFields = [`${field_name}`];
            query.orderByFields = [`${field_name}`];

            if (queryGeometry) {
              query.geometry = queryGeometry.clone();
              query.spatialRelationship = "contains";
            }

            layer
              .queryFeatures(query)
              .then((response) => {
                const [feature1, feature2] = response.features;
                let med = undefined;
                if (!feature1) {
                  debugger;
                }
                if (countMod2 == 0) {
                  med =
                    (parseFloat(feature1.attributes[`${field_name}`]) +
                      parseFloat(feature2.attributes[`${field_name}`])) /
                    2;
                } else {
                  med = parseFloat(feature1.attributes[`${field_name}`]);
                }
                resolve({ min, max, avg, count, med });
              })
              .catch((e) => {
                reject(e);
              });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  static queryAllFeaturesOnLayerUrl(
    layerurl,
    token,
    view,
    map,
    returnGometry,
    queryGeometry,
    outField = ["*"]
  ) {
    const layer = this.createFeatureLayer({ url: layerurl }, { token });
    const createQuery = () => {
      const query = new Query();
      query.outFields = outField;
      query.where = "1=1";
      if (returnGometry) {
        query.returnGeometry = true;
        query.maxAllowableOffset = 0; // no generalization
        query.outSpatialReference =
          map.basemap.baseLayers.getItemAt(0).spatialReference;
      }
      // query.objectIds = ids;
      if (queryGeometry) {
        query.geometry = queryGeometry;
        query.spatialRelationship = "contains";
      }
      return query;
    };

    const queryLayer = (query) => {
      return layer.queryFeatures(query).then(function (response) {
        response.features = response.features.map((feature) => {
          // eslint-disable-next-line no-param-reassign
          feature.layer = layer;
          // eslint-disable-next-line no-param-reassign
          feature._layer = layer;
          return feature;
        });
        return response.features;
      });
    };

    return new Promise((resolve, reject) => {
      const promises = [];
      const countQuery = createQuery();
      // debugger;
      executeForCount(layerurl, countQuery).then((count) => {
        if (count === 0) {
          resolve([]);
        } else {
          // debugger;
          const queryCount = Math.ceil(count / 1000);
          for (let i = 0; i < queryCount; i++) {
            const query = createQuery();
            query.num = 1000;
            query.start = i * 1000;
            promises.push(queryLayer(query));
          }
          Promise.all(promises)
            .then((values) => {
              // debugger;
              resolve(values.flat(1));
            })
            .catch((e) => reject(e));
        }
      });
    });
  }

  static queryFeaturesOnLayerURL(layerurl, attribute, value, map, geometry) {
    // TODO: NEU !!!!!!
    const layer = this.createFeatureLayer(
      { url: layerurl },
      { token: "DUMMYTOKEN" }
    );
    return APIHelper.queryFeaturesOnLayer(
      attribute,
      value,
      layer,
      map,
      geometry
    );
  }

  static getLayerField(layerFields, fieldName) {
    const layerField = layerFields.find((field) => field.name === fieldName);
    return layerField;
  }

  static queryFeaturesOnLayer(attribute, value, layer, map, geometry) {
    const createQuery = (attribute, value, layerField, map, geometry) => {
      const query = new Query();
      query.returnGeometry = true;
      query.outFields = ["*"];
      query.maxAllowableOffset = 0; // no generalization
      // query.objectIds = ids;
      if (geometry) {
        query.geometry = geometry;
        query.spatialRelationship = "contains";
      }
      if (Array.isArray(value)) {
        query.where = `${attribute} in (${value
          .map((v) => `${v}`.replace(/\\/gi, "\\\\").replace(/'/gi, "\\'"))
          .join(",")})`;
      } else {
        query.where = [
          "esriFieldTypeDouble",
          "esriFieldTypeInteger",
          "esriFieldTypeSmallInteger",
        ].includes(layerField.type)
          ? `${attribute} = ${value}`
          : `${attribute} = '${value}'`;
      }
      // query.outSpatialReference =
      //   map.basemap.baseLayers.getItemAt(0).spatialReference;
      query.outSpatialReference =
        map.ground.layers.getItemAt(0).spatialReference;
      return query;
    };
    return new Promise((resolve, reject) => {
      fetch(layer.layerInfo.url + "?f=json")
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          const layerField = APIHelper.getLayerField(result.fields, attribute);
          return layerField;
        })
        .then((layerField) => {
          const query = createQuery(
            attribute,
            value,
            layerField,
            map,
            geometry
          );
          layer.queryFeatures(query).then(function (response) {
            response.features = response.features.map((feature) => {
              // eslint-disable-next-line no-param-reassign
              feature.layer = layer;
              // eslint-disable-next-line no-param-reassign
              feature._layer = layer;
              return feature;
            });
            resolve(response.features);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getFeatureId(feature, layer) {
    const { attributes } = feature.graphic || feature;
    return attributes[layer.objectIdField];
  }

  static setPointerEventsOnMapLayersExept(map, value, layers, exceptValue) {
    // TODO !!!!!
    return;
    const mapLayers = APIHelper.getAllFeatureLayersOnMap(map, true);
    // eslint-disable-next-line no-shadow
    // function apply(layer, value) {
    /* if (value === 'none') {
        layer.disableMouseEvents();
      } else if (value === 'auto') {
        layer.enableMouseEvents();
      } */
    //  layer.getNode().style.pointerEvents = value;
    // }
    for (let i = 0; i < mapLayers.length; i++) {
      const layer = mapLayers[i];
      if (!layers || layers.indexOf(layer) === -1) {
        APIHelper.setLayersMouseEventsDisabled(
          layer,
          value.toLowerCase() === "none"
        );
        // apply(layer, value);
      } else if (exceptValue) {
        APIHelper.setLayersMouseEventsDisabled(
          layer,
          exceptValue.toLowerCase() === "none",
          exceptValue
        );
        // apply(layer, exceptValue);
      }
    }
  }

  static registerClickHandlerOnLayers(callback, layers, map, view) {
    /*
     * Je nach entscheidung auf Map
     * wenn wie in APIv4 dann muss in v3 einmal durch alle Layer durchiteriert werden und die Grafiken an dem entsprechenden Punkten mit GeometryEngine.touches berechnet werden (oberste Grafik geschenkt, da im click event)
     * wenn im APIv3 Flavor im Hittest von v4 einfach nur die oberste Geometry weitergeben
     *
     * Vorteil vom v4 Flavor ist, dass man auch Grafiken die übereinander sind selektieren kann
     * Man könnte auch dann ein Auswahlfenster einblenden in dem der User dann die gewünschte Grafik auswählt, mit hover effekt, dass die entsprechende Grafik gehighlighted wird, am besten durch ein mehrfaches aufblinken der outline oder so
     */
    // debugger;
    return view.on(
      // TODO: WENN DAS ERSTE GEHITTETE OBJEKT AUF EINEM ANDEREN LAYER SEIN SOLLTE DANN ABBRECHEN?? WEIL Z.B. WENN EINE GRAPHIC EDITIERT WIRD GIBT ES SONST PROBLEME!!!
      "click", // ESRI API DEPENDANT - vielleicht das onClick auf der Map / den Layern Wrappen + NUR only one feature
      (event) => {
        // debugger;
        view.hitTest(event.screenPoint).then((response) => {
          // IDEE: Wenn mehrere übereinander sind Dialog für auswahl mit entsprechendem highlicghting auf der Map um die Möglichkeit zu haben diese auszuwählen, dabei wenn es bereits eine EditGraphic zu einer anderen auswählbaren gibt diese bevorzugen und die andere nicht in der Liste anzeigen
          const hits = [];
          for (let j = 0; j < response.results.length; ++j) {
            const feature = response.results[j].graphic;
            let validLayer = false;
            for (let i = 0; i < layers.length; i++) {
              if (feature.layer === layers[i]) {
                validLayer = layers[i];
                break;
              }
            }
            if (validLayer) {
              if (validLayer instanceof FeatureLayer) {
                hits.push(
                  APIHelper.queryFeatureByIdOnLayer(
                    APIHelper.getFeatureId(feature, validLayer),
                    validLayer
                  ).then(function (res) {
                    // debugger;
                    return res.features.map((graphic) => {
                      if (graphic.geometry) return graphic;
                      return undefined;
                    })[0]; // there can only be one feature with the same ID
                  })
                );
              } else {
                hits.push(feature);
              }
            } else if (
              j === 0 &&
              feature.layer &&
              feature.layer.listMode === "hide"
            ) {
              return; // otherwise there are problems with the sketchviewModel used by sreshape for example
            }
          }
          if (hits.length) {
            Promise.all(hits)
              // eslint-disable-next-line no-shadow
              .then((hits) => {
                // debugger;
                let errors = 0;
                for (let i = hits.length - 1; i >= 0; i--) {
                  if (!hits[i]) {
                    hits.splice(i, 1);
                    // console.error("Some query returned undefined");
                    errors++;
                  }
                }
                callback(
                  true,
                  hits,
                  errors ? [`${errors} queries went wrong`] : undefined
                );
              })
              .catch((error) => {
                // debugger;
                // console.error("Some query returned an error", error);
                callback(false, undefined, [
                  "Some query returned an error",
                  error,
                ]);
              });
          } else {
            callback(true, []); // empty selection
          }
        });
      }
    );
  }

  static drawGeometry(view, map, geotype, callback, mode) {
    // debugger;
    // view needed for v4, map for v3 here both to have same interfaces
    const templayer = new GraphicLayer({ listMode: "hide" });
    APIHelper.addToMap(map, templayer);
    const sketchSymbols = {
      pointSymbol: symbols(APIHelper.version)["point"].selected,
      polylineSymbol: symbols(APIHelper.version)["polyline"].selected,
      polygonSymbol: symbols(APIHelper.version)["polygon"].selected,
    };
    const sketchVM = new SketchViewModel({
      layer: templayer,
      view,
      ...sketchSymbols,
    });
    let type = "";
    switch (geotype.toLowerCase()) {
      case "extent":
        type = "rectangle";
        break;
      case "multipoint":
        type = "point";
        break;
      case "point":
      case "polyline":
      case "polygon":
        type = geotype.toLowerCase();
        break;
      default:
        type = "polygon";
    }

    sketchVM.create(type, { mode: mode || "click" });
    sketchVM.on("create", function (event) {
      if (event.state === "complete") {
        // remove the graphic from the layer associated with the sketch widget
        // instead use the polygon that user created to query features that
        // intersect it.
        map.remove(templayer);
        callback(true, event.graphic.geometry);
      }
    });

    function abort() {
      sketchVM.cancel();
      templayer.removeAll();
      map.remove(templayer);
      // HACK: hacked solution because sketchviewModel is buggy...
      sketchVM._internalGraphicsLayer.removeAll();
      map.remove(sketchVM._internalGraphicsLayer);
    }
    return {
      abort: () => {
        const item = sketchVM.updateGraphics.items[0]; // if you abort without drawing a single point items[0] is not defined
        const geo = item && item.geometry;
        abort();
        callback(false, geo);
      },
      complete: () => {
        sketchVM.complete();
        // const item = sketchVM.updateGraphics.items[0]; // if you abort without drawing a single point items[0] is not defined
        // const geo = item && item.geometry;
        // debugger;
        // abort();
        // debugger;
        // callback(true, geo);
      },
    };
  }

  static reshapeGeometry(view, map, geometry, callback, onChange) {
    const templayer = new GraphicLayer({ listMode: "hide" });
    APIHelper.addToMap(map, templayer);
    const sketchSymbols = {
      //new SimpleMarkerSymbol(
      pointSymbol: symbols(APIHelper.version)["point"].selected, //),
      //new SimpleLineSymbol(
      polylineSymbol: symbols(APIHelper.version)["polyline"].selected, //),
      //new SimpleFillSymbol(
      polygonSymbol: symbols(APIHelper.version)["polygon"].selected, //),
    };

    // console.log(
    //   SymbolJsonUtils.fromJSON(symbols(APIHelper.version)["point"].selected)
    // );
    // console.log(
    //   new SimpleMarkerSymbol(symbols(APIHelper.version)["point"].selected)
    // );
    const sketchVM = new SketchViewModel({
      layer: templayer,
      view,
      defaultUpdateOptions: {
        tool: "reshape",
        toggleToolOnClick: false,
        enableScaling: false,
        multipleSelectionEnabled: false,
      },
      updateOnGraphicClick: false,
      ...sketchSymbols,
    });

    // const completed = false;

    let tgraphic = new Graphic({
      geometry: APIHelper.cloneGeometry(geometry),
      attributes: {},
      symbol: symbols(APIHelper.version)[
        APIHelper.getGeometryType(geometry).toLowerCase()
      ].selected,
    });
    templayer.add(tgraphic);
    // templayer.graphics = [graphic]; // workaround - https://community.esri.com/thread/230264-editing-temporary-graphic-in-sketchviewmodel

    // timeout needed?
    /* const action = */
    sketchVM.update([tgraphic], {});
    const clickhandler = APIHelper.registerClickHandlerOnLayers(
      function () {
        // debugger;
        sketchVM.update([tgraphic], {});
      },
      [templayer],
      map,
      view
    );

    let aborted = false;

    sketchVM.on("update", function onGraphicUpdate(event) {
      // debugger;
      /* if (canceled) {
        //sketchVM.cancel();
        clickhandler.remove();
        sketchVM.complete();
        templayer.removeAll();
        map.remove(templayer);
      } else { */
      // const graphic = event.graphics[0];
      // eslint-disable-next-line prefer-destructuring
      tgraphic = event.graphics[0];
      const { type, state } = event;
      // eslint-disable-next-line no-empty
      // console.log("sketchVM", event.type, event.state, event)
      if (
        state === "active" &&
        type &&
        type in
          {
            create: !0,
            delete: !0,
            redo: !0,
            undo: !0,
            update: !0,
          }
      ) {
        // eslint-disable-next-line no-unused-expressions
        onChange && onChange(event, sketchVM);
      } else if (state === "cancel" || state === "complete") {
        // callback(completed, graphic.geometry); // doesn't work as wanted
      }
      // }
    });
    function abort() {
      aborted = true;
      clickhandler.remove();
      // sketchVM.complete();
      templayer.removeAll();
      map.remove(templayer);
      // HACK: hacked solution because sketchviewModel is buggy...
      sketchVM._viewHandles.removeAll();
      sketchVM._handles.removeAll();
      sketchVM._emitter.target._viewHandles.removeAll();
      // sketchVM._removeDefaultLayer();
      sketchVM._internalGraphicsLayer.removeAll();
      map.remove(sketchVM._internalGraphicsLayer);
      // sketchVM.destroy();
    }
    return {
      abort: () => {
        const geo = sketchVM.updateGraphics.items[0].geometry;
        abort();
        callback(false, geo);
      },
      complete: () => {
        const geo = sketchVM.updateGraphics.items[0].geometry;
        abort();
        callback(true, geo);
        /* completed = true;

        sketchVM.complete();

        view.whenLayerView(templayer).then(function(layerView) {
          layerView.queryGraphics().then(function(results) {
            debugger;
            console.log(results); // prints the array of client-side graphics to the console
            templayer.removeAll();
            map.remove(templayer);
          });
        }); */
      },
      resume: () => {
        if (!aborted) {
          sketchVM.update([tgraphic], {});
        }
      },
    };
  }

  static setCurrentSketchVM(skvm) {
    if (APIHelper.currentSketchVM !== skvm) {
      APIHelper.unsetCurrentSketchVM();
    }
    APIHelper.currentSketchVM = skvm;
  }

  static getCurrentSketchVM() {
    return APIHelper.currentSketchVM;
  }

  static unsetCurrentSketchVM(skvm) {
    if (skvm) {
      if (APIHelper.currentSketchVM === skvm) {
        APIHelper.currentSketchVM.cancel();
        APIHelper.currentSketchVM.destroy();
        APIHelper.currentSketchVM._internalGraphicsLayer.removeAll();
        APIHelper.currentSketchVM = null;
      }
    } else if (APIHelper.currentSketchVM) {
      APIHelper.currentSketchVM.cancel();
      APIHelper.currentSketchVM.destroy();
      APIHelper.currentSketchVM._internalGraphicsLayer.removeAll();
      APIHelper.currentSketchVM = null;
    }
  }

  static setGeometry(graphic, geometry) {
    // eslint-disable-next-line no-param-reassign
    graphic.geometry = geometry;
  }

  static getGeometry(graphic) {
    return graphic.geometry;
  }

  static getNumberPolygonParts(polygon) {
    if (polygon && polygon.rings && polygon.isClockwise) {
      let num = 0;
      let holes = 0;
      for (let i = 0; i < polygon.rings.length; i++) {
        if (polygon.isClockwise(polygon.rings[i])) {
          num++;
        } else {
          // eslint-disable-next-line no-unused-vars
          holes++;
        }
      }
      return num;
    } else return undefined;
  }

  static getNumberPolylineParts(polyline) {
    if (polyline && polyline.paths) {
      let num = 0;
      for (let i = 0; i < polyline.paths.length; i++) {
        num++;
      }
      return num;
    } else return undefined;
  }

  static isMultipart(geometry) {
    return (
      (geometry.rings && APIHelper.getNumberPolygonParts(geometry) > 1) ||
      (geometry.paths && APIHelper.getNumberPolylineParts(geometry) > 1)
    );
  }

  static multipartToSinglepart(polygon) {
    if (polygon.rings) {
      if (APIHelper.getNumberPolygonParts(polygon) === 1) {
        return [polygon];
      } else {
        const { rings } = polygon;
        const ret = [];
        let poly = null;
        for (let i = 0; i < rings.length; i++) {
          if (polygon.isClockwise(rings[i])) {
            poly = new Polygon(polygon.spatialReference);
            ret.push(poly);
          }
          poly = poly.addRing(rings[i]);
        }
        return ret;
      }
    } else if (polygon.paths) {
      if (APIHelper.getNumberPolylineParts(polygon) === 1) {
        return [polygon];
      } else {
        const { paths } = polygon;
        const ret = [];
        let poly = null;
        for (let i = 0; i < paths.length; i++) {
          poly = new Polyline(polygon.spatialReference);
          ret.push(poly);
          poly = poly.addPath(paths[i]);
        }
        return ret;
      }
    } else return [];
  }

  static onLayersChanged(map, callback) {
    map.allLayers.on("change", callback);
  }

  static setLayersMouseEventsDisabled(layer, disabled, enabledValue) {
    // wird nur auf highlightlayer verwendet - hier nicht in dem Sinne benötigt, da rendern in einem Canvas geschieht
  }

  static getDisplayField(layer) {
    return layer && layer.displayField;
  }

  // const handlePossibleMissingSizeForRotationVV = renderer => {
  //   let visualVariables = renderer.visualVariables;
  //   if (visualVariables) {
  //     var t = visualVariables.filter(function(e) {
  //       return "size" === e.type;
  //     });
  //     if (t.length === 0) {
  //       let visualVariable = new SizeVariable({
  //         valueExpression: "$view.scale",
  //         stops: [{ value: 5000, size: "24px" }, { value: 100000, size: "24px" }]
  //       });
  //       renderer.visualVariables.push(visualVariable);
  //     }
  //   }
  // };
  static parseType(type) {
    switch (type) {
      case "esriFieldTypeString":
      case "string":
        return "string";
      case "esriFieldTypeDate":
      case "date":
        return "date";
      case "long":
      case "integer":
      case "small-integer":
      case "esriFieldTypeInteger":
      case "esriFieldTypeSmallInteger":
        return "int";

      case "single":
      case "double":
      case "esriFieldTypeDouble":
        return "double";
      case "esriFieldTypeOID":
      case "oid":
        return "oid";
      case "esriFieldTypeGeometry":
      case "geometry":
        return "geometry";
      // TODO: handle esriFieldTypeOID
      default:
        // blob, raster, guid, global-id, xml
        console.warning(`Undefined field type ${type}`);
        return "unknown";
    }
  }

  static getLayersDimension(layer) {
    const dimensions = {
      point: 1,
      multipoint: 1,
      polyline: 2,
      polygon: 3,
      multipatch: 4, // ??
      mesh: 5, // ??
    };
    return dimensions[layer.geometryType];
  }

  static getFields(layer) {
    return layer ? [].concat(layer.fields) : [];
  }

  static getObjectIdField(layer) {
    return layer && layer.objectIdField;
  }

  static hasRenderer(layer) {
    return layer && !!layer.renderer;
  }

  static getMapScale(map, view) {
    return view.scale;
  }

  static setMapScale(map, view, scale) {
    view.scale = scale;
  }

  static getMapExtent(map, view) {
    return view.extent;
  }

  static setExtent(extent, map, view) {
    view.extent = extent;
    RootStore.mapStore.mapView.extent = view.extent;
    return new Promise((resolve) => {
      resolve();
    });
  }

  static showMapTip(map, view, mapPoint, node, title = "Info") {
    view.popup = new Popup({
      location: mapPoint,
      content: node,
      title,
      view,
      visible: true,
      autoOpenEnabled: false,
    });
    view.popup.when((e) => {
      view.popup.reposition();
    });
  }

  static zoomToExtent(map, pextent, view, expandFactor) {
    // let mapSR;
    // if (view.type === "2d") {
    //   mapSR = map.basemap.baseLayers.getItemAt(0).spatialReference;
    // }
    // if (view.type === "3d") {
    //   mapSR = map.ground.layers.getItemAt(0).spatialReference;
    // }
    const mapSR = map.ground.layers.getItemAt(0).spatialReference;
    const extent = expandFactor
      ? pextent.clone().expand(expandFactor)
      : pextent.clone();
    if (mapSR.wkid != extent.spatialReference.wkid) {
      return new Promise((resolve, reject) => {
        APIHelper.projectGeometries({ geometries: [extent], outSR: mapSR }, map)
          .then(([projectedExtent]) => {
            APIHelper.setExtent(projectedExtent, map, view);
            resolve();
          })
          .catch((e) => reject(e));
      });
    } else {
      APIHelper.setExtent(extent, map, view);
      return new Promise((resolve) => {
        resolve();
      });
    }
  }

  static getAttributes(feature) {
    const graphic = APIHelper.getGraphic(feature);
    return graphic.attributes;
  }

  static setAttributes(feature, attributes) {
    const graphic = APIHelper.getGraphic(feature);
    graphic.attributes = attributes;
  }

  static getSymbol(feature) {
    const graphic = APIHelper.getGraphic(feature);
    return graphic.symbol;
  }

  static isLayerVisibleAtMapScale(layer, map, view) {
    // TODO: view übergeben
    const scale = APIHelper.getMapScale(map, view);
    // APIHelper.debug &&
    //   console.log(
    //     "isLayerVisibleAtMapScale",
    //     { min: layer.minScale, max: layer.maxScale },
    //     scale,
    //     (!layer.minScale || scale >= layer.minScale) && // minScale 0 or scale >= minScale
    //       (!layer.maxScale || scale <= layer.maxScale)
    //   );
    return (
      !layer ||
      ((!layer.minScale || scale >= layer.minScale) && // minScale 0 or scale >= minScale
        (!layer.maxScale || scale <= layer.maxScale)) // analog
    );
  }

  static isLayerVisible(layer) {
    return layer.visible;
  }

  static getLayerID(layer) {
    return layer.id;
  }

  static getUniqueIdentifierNumberOfLayer(layer) {
    return layer.id; // TODO ??
  }

  static getLayerURL(layer) {
    if (layer.layerInfo && layer.layerInfo.url) {
      return layer.layerInfo.url;
    }
    return layer.url;
  }

  static getLayerVersion(layer) {
    return layer.version;
  }

  static getLayerName(layer) {
    return layer.title;
  }

  static getGraphic(feature) {
    return (feature && feature.graphic) || feature;
  }

  static getGeometryType(geo) {
    return geo && geo.type; // TODO: schauen, dass dieselben typen wie bei v3
  }

  static getRenderer(layer) {
    return layer && layer.renderer;
  }

  static refreshLayer(layer) {
    layer && layer.refresh && layer.refresh();
  }

  static disableKeyboardNavigation(map, view) {
    // not implemented in v4, but the bug with autocomplete should be solved by design of v4 api
    //map.disableKeyboardNavigation();
    if (!APIHelper.keyDisableListener) {
      APIHelper.keyDisableListener = view.on("key-down", (e) => {
        // const { key } = e;
        // if (key.slice(0, 5) === "Arrow")
        e.stopPropagation();
      });
    }
  }

  static enableKeyboardNavigation(map, view) {
    // not implemented in v4, but the bug with autocomplete should be solved by design of v4 api
    //map.enableKeyboardNavigation();

    if (APIHelper.keyDisableListener) {
      APIHelper.keyDisableListener.remove();
      APIHelper.keyDisableListener = null;
    }
  }

  static setVisibility(layer) {
    // TODO
    // should reset to default visibility
    // debugger;
    return;
  }

  static getLayerOpacity(layer) {
    return layer && layer.opacity;
  }

  static setLayerOpacity(layer, opacity) {
    layer.opacity = opacity;
  }

  static isLayer(layer) {
    return layer instanceof Layer;
  }

  static isGraphicsLayer(layer) {
    return layer && layer.type === "graphics";
  }

  static isFeatureLayer(layer) {
    return layer && layer.type === "feature";
  }

  static isMapLayer(layer) {
    return layer && layer.type === "map-image";
  }

  static isWMSLayer(layer) {
    return layer && layer.type === "wms";
  }

  static reorderLayer(map, layer, index) {
    map.reorder(layer, index);
  }

  static getvisibleLayersOfWMS(layer) {
    if (!APIHelper.isWMSLayer(layer)) {
      return [];
    }
    return layer.allSublayers.filter((i) => i.visible).map((j) => j.name);
  }

  static setLayerIndexOnMap(layer, index, map) {
    APIHelper.reorderLayer(map, layer, index);
  }

  static putLayerOnTop(map, layer) {
    const numLayers = map.layers.length; //map.layerIds.length + map.graphicsLayerIds.length;
    APIHelper.reorderLayer(map, layer, numLayers);
  }

  static convexHull(geometries) {
    return GeometryEngine.convexHull(geometries, true); // cause of the true a single part geometry is returned
  }

  static getLayerIndex(map, layer) {
    let layerIndex = 0;
    for (let index = 0; index < map.layers.length; index++) {
      if (map.layers[index].id === layer.id) {
        layerIndex = index;
      }
    }
    return layerIndex;
  }

  static getAllLayersOfMap(map) {
    return map.allLayers;
  }

  static getAllFeatureLayersOnMap(map, alsoHidden) {
    const ids = map.graphicsLayerIds;
    const layers = [];
    for (let i = 0; i < map.layers.length; i++) {
      const layer = map.layers[i];
      if (APIHelper.isFeatureLayer(layer) && (!alsoHidden || layer.visible)) {
        layers.push(layer);
      }
    }
    return layers;
  }

  static onSameLayer(graphics) {
    const array = (Array.isArray(graphics) ? graphics : [graphics]).map((g) =>
      APIHelper.getGraphic(g)
    );
    if (array.length > 0) {
      let layer = APIHelper.getLayer(array[0]);
      layer = layer && layer.id;
      for (let i = 1; i < array.length; i++) {
        const tmplayer = APIHelper.getLayer(array[i]);
        if (tmplayer ? tmplayer.id !== layer : tmplayer === layer) return false;
      }
      return true;
    } else {
      return true;
    }
  }

  static getLayersFullExtent(layer, expandFactor) {
    const extent = layer && layer.fullExtent;
    if (!expandFactor || !extent) {
      return extent;
    } else {
      return extent.expand(2);
    }
  }

  static isEmptyGeometry(geometry) {
    // eval('debugger;');
    if (geometry.type) {
      switch (geometry.type) {
        case "polygon":
          return !!geometry.rings && geometry.rings.length === 0;
        case "polyline":
          return !!geometry.paths && geometry.paths.length === 0;
        case "multipoint":
          return !!geometry.points && geometry.points.length === 0;
        case "point":
          return (
            // for whatever reason null is a Number
            (isNaN(geometry.x) && geometry.x !== null) ||
            (isNaN(geometry.y) && geometry.y !== null)
          );
        default:
      }
    }
    return true;
  }

  static createHighlightGraphic(feature) {
    const graphic = APIHelper.getGraphic(feature);
    return APIHelper.createGraphic(
      APIHelper.cloneGeometry(APIHelper.getGeometry(graphic)),
      JSON.parse(
        JSON.stringify(
          symbols(APIHelper.version)[graphic.geometry.type.toLowerCase()]
            .highlight
        )
      ),
      {}
    );
  }

  static planarArea(geometry, unit = "square-meters") {
    return GeometryEngine.planarArea(geometry, unit);
  }
  static planarLength(geometry, unit = "meters") {
    return GeometryEngine.planarLength(geometry, unit);
  }

  static intersects(geometries, geometry) {
    if (Array.isArray(geometries)) {
      for (let i = 0; i < geometries.length; i++) {
        if (GeometryEngine.intersects(geometries[i], geometry)) return true;
      }
    } else {
      return GeometryEngine.intersects(geometries, geometry);
    }
    return false;
  }

  static insideGeometries(geometry, geometries) {
    // let ret = false;
    if (geometry.type === "point") {
      for (let i = 0; i < geometries.length; i++) {
        if (GeometryEngine.intersects(geometries[i], geometry)) {
          return true;
        }
      }
    } else {
      for (let i = 0; i < geometries.length; i++) {
        if (GeometryEngine.contains(geometries[i], geometry)) {
          return true;
        }
      }
    }
    return false;
  }

  static differenceOfGeometries(geo1, geo2) {
    return GeometryEngine.difference(geo1, geo2);
  }

  static cutGeometries(geometry, cutter) {
    return GeometryEngine.cut(geometry, cutter);
  }

  static unionOfGeomnetries(geos) {
    return GeometryEngine.union(geos);
  }

  static intersectionOfGeometries(geo1, geo2) {
    return GeometryEngine.intersect(geo1, geo2);
  }

  static getRendererType(renderer) {
    const type = renderer && renderer.type;
    if (type === "unique-value") {
      return "uniqueValue";
    }
    return "static";
  }

  static getTemplateAttributes(renderer, layerSymbol) {
    const attributes = {};
    if (layerSymbol.value) {
      const values = layerSymbol.value /* layer.renderer.values[0] */
        .split(renderer.fieldDelimiter);
      const fields = []; // Object.values(template.attributeFields);
      if (renderer.field) fields.push(renderer.field);
      if (renderer.field2) fields.push(renderer.field2);
      if (renderer.field3) fields.push(renderer.field3);
      if (values.length === fields.length) {
        fields.map((field, i) => {
          attributes[field] = values[i];
        });
      } else {
        // eslint-disable-next-line no-console
        console.error("Picker Fehler...");
      }
    }
    return attributes;
  }

  static getRendererSymbol(renderer) {
    return renderer && renderer.symbol;
  }

  static getUniqueValueRendererSymbols(renderer) {
    const ret = [];
    if (renderer) {
      const symbolinfos = renderer?.uniqueValueInfos?.map((info) => info); // TODO: checken, da{ symbol, label, value } und somit label nicht im symbol
      //aber das wird ja benötigt für die anzeige beim Templatepicker, oder?
      if (symbolinfos) {
        for (let i = 0; i < symbolinfos.length; i++) {
          const { symbol, label, value } = symbolinfos[i];
          //const tsym = symbols[i];
          if (!label || label.toLowerCase().trim().indexOf("<changed>") === -1)
            ret.push(symbolinfos[i]); // oder doch nur symbol ?!?
        }
      }
    }
    return ret;
  }

  static getClassBreaksRendererSymbols(renderer) {
    const ret = [];
    if (renderer) {
      const symbolinfos = renderer?.classBreakInfos
        ?.map(({ minValue, maxValue, symbol, label }) => {
          return { minValue, maxValue, symbol, label };
        })
        .filter(({ label, symbol }) => {
          return (
            symbol &&
            (!label || label.toLowerCase().trim().indexOf("<changed>") === -1)
          );
        });
      return symbolinfos;
    }
    return ret;
  }

  static getRendererSymbols(renderer) {
    if (renderer) {
      const { type } = renderer;
      switch (type) {
        case "unique-value":
          return APIHelper.getUniqueValueRendererSymbols(renderer);
          break;
        case "class-breaks":
          return APIHelper.getClassBreaksRendererSymbols(renderer);
          break;
        default:
          console.warn(`Renderer type ${type} not yet supported by LayerStore`);
      }
    }

    return [];
  }

  static getLayerGrapicsType(layer) {
    if (APIHelper.isFeatureLayer(layer)) {
      let type = "polygon";
      switch (layer.geometryType) {
        case "point":
        case "multipoint":
          type = "point";
          break;
        case "polyline":
          type = "polyline";
          break;
        default:
        case "polygon":
          type = "polygon";
          break;
      }
      return type;
    }
    return null;
  }

  static reshape(geo1, geo2) {
    return APIHelper.geometryService.reshape(geo1, geo2);
  }

  static renderLayerSymbolInElement(symbol, div) {
    return new Promise((resolve, reject) => {
      renderPreviewHTML(symbol?.symbol || symbol, {
        node: div,
        size: 14, // in pt
        maxSize: 20,
      })
        .then(() => {
          resolve(div);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  }

  static getSurroundingExtent(pGraphics) {
    const graphics = Array.isArray(pGraphics)
      ? pGraphics
      : pGraphics
      ? [pGraphics]
      : [];
    let first = true;
    let xmin;
    let xmax;
    let ymin;
    let ymax;
    let spatialReference;
    for (let i = 0; i < graphics.length; i++) {
      const { geometry } = graphics[i];
      if (geometry) {
        const extent = geometry.extent;
        if (extent) {
          if (first || extent.xmin < xmin) xmin = extent.xmin;
          if (first || extent.xmax > xmax) xmax = extent.xmax;
          if (first || extent.ymin < ymin) ymin = extent.ymin;
          if (first || extent.ymax > ymax) ymax = extent.ymax;
          if (first) {
            spatialReference = extent.spatialReference;
          }
          first = false;
        } else if (
          geometry.type.indexOf("point" !== -1) &&
          geometry.x !== undefined &&
          geometry.y !== undefined
        ) {
          const { x, y } = geometry;
          if (first || xmin > x) xmin = x;
          if (first || xmax < x) xmax = x;
          if (first || ymin > y) ymin = y;
          if (first || ymax < y) ymax = y;
          if (first) {
            spatialReference = geometry.spatialReference;
          }
          first = false;
        }
      }
    }

    if (
      graphics.length >= 1 &&
      xmin === xmax &&
      ymin === ymax &&
      graphics[0].geometry &&
      `${graphics[0].geometry.type}`.indexOf("point") !== -1
    ) {
      const { x, y } = graphics[0].geometry;
      xmin = x - pointExtentExtension;
      xmax = x + pointExtentExtension;
      ymin = y - pointExtentExtension;
      ymax = y + pointExtentExtension;
      spatialReference = graphics[0].geometry.spatialReference;
      first = false;
    }

    return first
      ? undefined
      : APIHelper.createExtent(xmin, ymin, xmax, ymax, spatialReference);
  }

  static registerSelectionClearEvent(layer, handler) {
    // v3 only
    // layer.watch()
    return { remove: () => {} };
  }

  static registerSelectionComplete(layer, handler) {
    // v3 only
    // layer.watch()
    return { remove: () => {} };
  }

  static registerSelectionChange(layer, handler) {
    // v3 only
    // layer.watch()
    return { remove: () => {} };
  }

  static registerVisibilityChangeEvent(layer, handler) {
    return layer.watch("visible", handler); // calls handler(newvalue, oldvalue)
  }

  static onLayerLoadedChange(layer, handler, fireAtBegin) {
    if (fireAtBegin) {
      handler(layer.loaded);
    }
    return layer.watch("loaded", handler); // calls handler(newvalue, oldvalue)
  }

  static registerOpacityChangeEvent(layer, handler) {
    return layer.watch("opacity", handler); // ruft handler(newvalue, oldvalue auf)
  }

  static addOnZoomEndListener(map, listener, view) {
    return view.watch("zoom", listener.bind(undefined, map));
  }

  static addOnPanEndListener(map, listener, view) {
    return view.watch("extent", listener); // on pan the extent of the map changes
  }

  static applyEdits(featureLayer, adds, updates, deletes) {
    const edits = {};
    if (adds.length) {
      edits.addFeatures = adds;
    }

    if (updates.length) {
      edits.updateFeatures = updates;
    }

    if (deletes.length) {
      edits.deleteFeatures = deletes;
    }

    return new Promise((resolve, reject) => {
      featureLayer
        .applyEdits(edits)
        .then(function (result) {
          const {
            addFeatureResults: addResult,
            updateFeatureResults: updateResult,
            deleteFeatureResults: deleteResult,
          } = result;
          const errors = [];
          if (addResult && addResult.length) {
            for (let i = 0; i < addResult.length; i++) {
              const result = addResult[i];
              if (result.error) {
                errors.push(result.error);
              }
            }
          }
          if (updateResult && updateResult.length) {
            for (let i = 0; i < updateResult.length; i++) {
              const result = updateResult[i];
              if (result.error) {
                errors.push(result.error);
              }
            }
          }
          if (deleteResult && deleteResult.length) {
            for (let i = 0; i < deleteResult.length; i++) {
              const result = deleteResult[i];
              if (result.error) {
                errors.push(result.error);
              }
            }
          }
          if (
            errors.length
            /* (updateResult.length > 0 && updateResult[0].success === false) ||
              (addResult.length > 0 && addResult[0].success === false) */
          ) {
            reject(errors.join("\n"));
          } else {
            // (() => {
            //   controller.resetState();
            //   APIHelper.refreshLayer(featureLayer);
            // })();
            resolve();
          }
          // this.setState({ pendingSave: false });
        })
        .catch(function (error) {
          // this.setState({ pendingSave: false });
          console.error(error);
          // this.showSnackbar('error', error.message);
          reject(error.message);
        });
    });
  }

  static createFeatureLayer(layerInfo, { token } /* { id, name } */) {
    // debugger;
    const options = { id: layerInfo.name, label: layerInfo.name };
    let fl = new FeatureLayer({
      url: layerInfo.url,
      customParameters: { gisconToken: token },
      ...options,
      outFields: ["*"],
      //mode: APIHelper.esri.MODE_AUTO,
    });
    fl.layerInfo = layerInfo;
    return fl;
  }
  static registerLoadErrorListener(layer, listener) {
    try {
      listener(layer.loadStatus === "failed");
    } catch (e) {
      console.error("Error in LoadErrorListener", e);
    }

    return layer.watch("loadStatus", (loadStatus) => {
      listener(loadStatus === "failed");
    });
  }

  static registerBasemapUpdatingEvent(layer, name, map, view, listener) {
    let signals = [];
    let basemap = map.basemap;
    let basemapLayers = [];
    let currentListener = null;
    let layerChangeSignal = null;
    const basemapLayersChanged = () => {
      if (currentListener) {
        currentListener.abort();
      }

      const listenerCollection = {
        listeners: [],
        listener: () => {
          if (!listenerCollection.aborted) {
            listener(
              listenerCollection.layerViews.reduce((acc, lview) => {
                return acc || lview.updating || lview.loadStatus === "loading";
              }, false)
            );
          }
        },
        aborted: false,
        layerViews: [],
        remove: () => {
          if (!listenerCollection.aborted) {
            listenerCollection.aborted = true;
            listenerCollection.listeners.forEach((s) => {
              s.remove();
            });
            listenerCollection.listener.remove();
          }
        },
      };

      basemap.baseLayers.forEach((layer) => {
        view
          .whenLayerView(layer)
          .then((layerView) => {
            if (!listenerCollection.aborted) {
              listenerCollection.layerViews.push(layerView);
              listenerCollection.listener();
              listenerCollection.listeners.push(
                layerView.watch("updating", () => {
                  listenerCollection.listener();
                })
              );
              listenerCollection.listeners.push(
                layerView.watch("loadStatus", () => {
                  listenerCollection.listener();
                })
              );
            }
          })
          .catch((e) => {
            console.error({
              msg: `Could not get Layerview for BasemapLayer ${layer.title}`,
              error: e,
              layer,
            });
          });
      });
    };

    const basemapChange = () => {
      signals.forEach((s) => {
        s.remove();
      });
      signals = [];
      basemapLayersChanged;
      signals.push(
        map.watch("basemap", (base) => {
          basemap = base;
          basemapChange();
        })
      );
      if (layerChangeSignal) layerChangeSignal.remove();
      layerChangeSignal = map.basemap.baseLayers.on("change", () => {
        basemapLayersChanged();
      });
      basemapLayersChanged();
    };

    basemapChange();
    return {
      remove: () => {
        signals.forEach((s) => {
          s.remove();
        });
        listenerCollection.remove();
      },
    };
    // return new Promise((resolve, reject) => {});
    // (updating) => {
    //   basemapUpdating = updating;
    //   //console.log(this.name + "is " + (updating ? "" : "not ") + "updating");
    // }
  }

  static registerUpdatingEvent(layer, name, map, view, listener) {
    // view.whenLayerView();
    return new Promise((resolve, reject) => {
      view
        .whenLayerView(layer)
        .then((layerView) => {
          try {
            listener(layerView.updating);
          } catch (e) {
            reject({ msg: "Error in listener", error: e });
          }
          const signal = layerView.watch("updating", (updating) => {
            listener(updating);
          });
          resolve(signal);
        })
        .catch((e) => {
          reject({
            msg: `Could not get Layerview for Layer ${name}`,
            error: e,
            layer,
          });
        });
    });
  }

  static getLegendInfo(url) {
    if (!APIHelper.legendCache) APIHelper.legendCache = {}; // maybe auslagern und url + id rausschmeißen
    const match = /\/([0-9]+)(\/|\/\/)?$/gim.exec(url);
    let effectiveURL = url;
    let id = 0;
    if (match) {
      id = match[1];
      effectiveURL = url.replace(match[0], "");
    }
    const getLayerInfo = () => {
      const infos = APIHelper.legendCache[effectiveURL.toLowerCase()];
      for (let i = 0; i < infos.length; i++) {
        if (infos[i].layerId == id) {
          return infos[i];
        }
      }
      return null;
    };
    if (APIHelper.legendCache[effectiveURL.toLowerCase()]) {
      return new Promise((resolve) => {
        resolve(getLayerInfo());
      });
    } else {
      return new Promise((resolve, reject) => {
        fetch(effectiveURL + "/legend?f=pjson")
          .then((response) => {
            return response.json();
          })
          .then((info) => {
            if (info && info.layers) {
              APIHelper.legendCache[effectiveURL.toLowerCase()] = info.layers;
              resolve(getLayerInfo());
            } else {
              resolve(null);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
  }

  static registerCustomClickHandlerOnMap(callback, map, view) {
    return view.on("click", (event) => {
      const { mapPoint, screenPoint } = event;
      try {
        callback({ mapPoint, screenPoint });
      } catch (e) {
        console.error("An error occured in a custom map click handler ", e);
      }
    });
  }

  static identifyPointOnLayer(url, point, map, view) {
    // identify
    const match = /\/([0-9]+)(\/|\/\/)?$/gim.exec(url);
    let effectiveURL = url;
    let id = -1;
    if (match) {
      id = match[1];
      effectiveURL = url.replace(match[0], "");
    }
    return new Promise((resolve, reject) => {
      if (id === -1) {
        console.error("URL cannot be used for an identify task ", url);
        reject();
      } else {
        const identifyParameters = new IdentifyParameters({
          geometry: point,
          layerIds: [id],
          layerOption: "all",
          tolerance: 1, // tolerance is mandatory
          mapExtent: view.extent,
          returnGeometry: true,
        });
        identify(effectiveURL, identifyParameters /* , requestOptions */)
          .then(({ results }) => {
            resolve(results.map((result) => result.feature));
          })
          .catch((e) => {
            console.error("An error occured during identify task ", e);
            reject(e);
          });
      }
    });
  }

  static createImageLayer(layerInfo /*{name, url}*/, { token }, options) {
    // debugger;
    const { url } = layerInfo;
    const opts = { ...options, id: layerInfo.name, label: layerInfo.name };
    const match = /\/([0-9]+)(\/|\/\/)?$/gim.exec(url);
    let effectiveURL = url;
    if (match) {
      const id = match[1];
      opts.sublayers = [
        {
          id,
          visible: true,
        },
      ];
      effectiveURL = url.replace(match[0], "");
    }
    let fl = new MapImageLayer({
      url: effectiveURL, // layerInfo.url,
      customParameters: { gisconToken: token },
      ...opts,
      outFields: ["*"],
      //mode: APIHelper.esri.MODE_AUTO,
    });
    fl.layerInfo = layerInfo;
    return fl;
  }

  static projectGeometries({ geometries, outSR, callback }, map) {
    // eval('debugger;');
    const groups = [];
    let group = [];
    if (geometries.length) {
      group.push(geometries[0]);
    }
    for (let i = 1; i < geometries.length; i++) {
      const geometry = geometries[i];
      if (
        !APIHelper.isSameSpatialReference(
          group[0].spatialReference,
          geometry.spatialReference
        )
      ) {
        groups.push(group);
        group = [geometry];
      } else {
        group.push(geometry);
      }
    }
    if (group.length) {
      groups.push(group);
    }
    function internal(pgeometries) {
      return new Promise((resolve, reject) => {
        const params = new ProjectParameters();
        params.geometries = pgeometries.map((geo) => {
          return geo; // Geometry.fromJSON(geo);
        });
        params.outSpatialReference =
          outSR ||
          // (map.basemap && map.basemap.baseLayers.getItemAt(0).spatialReference);
          (map.ground && map.ground.layers.getItemAt(0).spatialReference);
        const cachedValues = APIHelper.projectCache.get(
          JSON.stringify({
            geoms: params.geometries,
            outSR: params.outSR,
          })
        );
        if (cachedValues) {
          if (callback) {
            callback(true, cachedValues);
          }
          resolve(cachedValues);
        } else {
          APIHelper.geometryService
            .project(params)
            .then((projectedGeometries) => {
              // eslint-disable-next-line no-unused-expressions
              APIHelper.projectCache.set(
                JSON.stringify({
                  geoms: params.geometries,
                  outSR: params.outSR,
                }),
                projectedGeometries
              );
              if (callback) {
                callback(true, projectedGeometries);
              }
              resolve(projectedGeometries);
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error("projectionError", error);
              // eslint-disable-next-line no-unused-expressions
              callback && callback(false);
              reject();
            });
        }
      });
    }
    const promises = [];
    for (let i = 0; i < groups.length; i++) {
      promises.push(internal(groups[i]));
    }

    return new Promise((resolve, reject) => {
      Promise.all(promises)
        .then((projected) => {
          resolve(projected.reduce((acc, val) => acc.concat(val), []));
        })
        .catch((e) => reject(e));
    });
  }

  static getMapPoint(x, y, map, view) {
    const screenPoint = { x, y }; // screenpoint?
    return view.toMap(screenPoint);
  }

  static disableZoom(map, view) {
    // https://swaggypyang.github.io/arcgisapi/sdk/latest/sample-code/view-disable-zoom/index.html
    if (!APIHelper.disableZoomListener) {
      const listeners = [];
      listeners.push(
        view.on("key-down", function (event) {
          var prohibitedKeys = ["+", "-", "Shift", "_", "="];
          var keyPressed = event.key;
          if (prohibitedKeys.indexOf(keyPressed) !== -1) {
            event.stopPropagation();
          }
        })
      );
      listeners.push(
        view.on("mouse-wheel", function (event) {
          event.stopPropagation();
        })
      );
      listeners.push(
        view.on("double-click", function (event) {
          event.stopPropagation();
        })
      );
      listeners.push(
        view.on("double-click", ["Control"], function (event) {
          event.stopPropagation();
        })
      );
      listeners.push(
        view.on("drag", function (event) {
          event.stopPropagation();
        })
      );
      listeners.push(
        view.on("drag", ["Shift"], function (event) {
          event.stopPropagation();
        })
      );
      listeners.push(
        view.on("drag", ["Shift", "Control"], function (event) {
          event.stopPropagation();
        })
      );
      APIHelper.disableZoomListener = listeners;
    }
  }

  static enableZoom(map, view) {
    if (APIHelper.disableZoomListener) {
      const listeners = APIHelper.disableZoomListener;
      APIHelper.disableZoomListener = null;
      listeners.map((l) => {
        l.remove();
      });
    }
  }

  static disablePan(map, view) {
    // https://swaggypyang.github.io/arcgisapi/sdk/latest/sample-code/view-disable-panning/index.html
    if (!APIHelper.disablePanListener) {
      const listeners = [];
      listeners.push(
        view.on("drag", function (event) {
          // prevents panning with the mouse drag event
          event.stopPropagation();
        })
      );
      listeners.push(
        view.on("key-down", function (event) {
          // prevents panning with the arrow keys
          var keyPressed = event.key;
          if (keyPressed.slice(0, 5) === "Arrow") {
            event.stopPropagation();
          }
        })
      );
      APIHelper.disablePanListener = listeners;
    }
  }

  static enablePan(map, view) {
    if (APIHelper.disablePanListener) {
      const listeners = APIHelper.disablePanListener;
      APIHelper.disablePanListener = null;
      listeners.map((l) => {
        l.remove();
      });
    }
  }

  static disablePanZoom(map, view) {
    APIHelper.disablePan(map, view);
    APIHelper.disableZoom(map, view);
  }

  static enablePanZoom(map, view) {
    APIHelper.enablePan(map, view);
    APIHelper.enableZoom(map, view);
  }

  static addAndLoadLayer(layer, map, waitLoaded) {
    return new Promise((resolve, reject) => {
      // if (waitLoaded) {
      //   let pending = 0;
      //   const removesignals = () => {
      //     signal0.remove();
      //     signal1.remove();
      //     signal2.remove();
      //     signal3.remove();
      //   };
      //   const signal0 = layer.on("load", () => {
      //     if (!layer.visibleAtMapScale) {
      //       removesignals();
      //       resolve(layer);
      //     }
      //   });
      //   const signal1 = layer.on("update-start", () => {
      //     // console.log(`update-start for ${layer.url}`);
      //     pending++;
      //   });
      //   const signal2 = layer.on("update-end", () => {
      //     // console.log(`update-end for ${layer.url}`);
      //     pending--;
      //     if (pending === 0 && layer.hasAllFeatures) {
      //       // console.info(`update done ${layer.url}`);
      //       removesignals();
      //       resolve(layer);
      //     }
      //   });
      //   const signal3 = layer.on("error", (a, b) => {
      //     // console.error(`update error ${layer.url}`);
      //     removesignals();
      //     reject(a, b);
      //   });
      // } else {
      //   // const signal = layer.on('load', () => {
      //   //   // layer;
      //   //   // layer._map;
      //   //   // eval('debugger;');
      //   //   signal.remove();
      //   //   resolve(layer);
      //   // });
      // }
      // const x =
      map.add(layer);
      if (!waitLoaded) {
        resolve(layer);
      } else {
        const signal = layer.watch("loaded", (loaded) => {
          if (loaded) {
            signal.remove();
            resolve(layer);
          }
        });
      }
      // if (!waitLoaded) {
      //   resolve(layer);
      // }
      // eval('debugger;');
      // x;
      // if (returnImmediately) resolve();
      // console.info(`layer added ${layer.url}`);
    });
  }

  static editRuleToPolygons(
    { coordinateSystem, parts },
    /* map */ { spatialReference }
  ) {
    // layerStore.setPermissionsLoading(index, true);
    return new Promise((resolve, reject) => {
      const partsRings = parts.map((part) => part.rings);

      const polygons = [];
      partsRings.map((rings) => {
        const geometry = {
          rings: rings.map((ring) =>
            ring.points.map((point) => [point.x, point.y])
          ),
          spatialReference: { wkid: coordinateSystem /* , latestWkid: 4647 */ },
        };

        polygons.push(new Polygon(geometry));
      });
      const promises = [];
      polygons.map((polygon) => {
        promises.push(
          new Promise((resolveInner, rejectInner) => {
            APIHelper.projectGeometries({
              geometries: [polygon],
              outSR: spatialReference,
              callback: (success, projectedGeometries) => {
                if (success) {
                  const graphics = [];
                  projectedGeometries.map((geometry) => {
                    graphics.push(APIHelper.createGraphic(geometry, null, {}));
                  });
                  resolveInner(graphics[0]);
                } else {
                  rejectInner();
                }
              },
            });
          })
        );
      });
      Promise.all(promises)
        .then((graphics) => {
          resolve(graphics);
        })
        .catch(() => {
          reject();
        });
    });
  }

  static getNonGeneralized(features) {
    const promises = [];
    const layerRequests = {};
    const layers = {};
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      const layer = APIHelper.getLayer(feature);
      if (
        APIHelper.isFeatureLayer(layer)
        // && layer.getMaxAllowableOffset() > 0 // disabled to get fresh copies for the case someone edited it after the layer was loaded here
      ) {
        const { attributes } = feature.graphic || feature;
        const id = attributes[layer.objectIdField];
        if (id === undefined || id === null) {
          promises.push(feature);
        } else {
          if (layerRequests[layer.id] === undefined) {
            layerRequests[layer.id] = [];
            layers[layer.id] = layer;
          }
          layerRequests[layer.id].push(id);
        }
      } else {
        promises.push(feature);
      }
    }
    Object.keys(layerRequests).forEach(function (key) {
      promises.push(
        APIHelper.queryFeaturesByIdsOnLayer(
          layerRequests[key], // APIHelper.getFeatureId(feature, layer),
          layers[key]
        ).then((result) => {
          return result.features;
        })
      );
    });
    return Promise.all(promises);
  }

  static getNonGeneralizedOneLayer(features) {
    const layer =
      features &&
      features[0] &&
      features[0].attributes &&
      APIHelper.getLayer(features[0]);
    if (
      APIHelper.isFeatureLayer(layer)
      // && layer.getMaxAllowableOffset() > 0 // disabled to get fresh copies for the case someone edited it after the layer was loaded here
    ) {
      // just test first feature if it has an objectid
      const { attributes } = features[0].graphic || features[0];
      const id = attributes[layer.objectIdField];
      if (id === undefined || id === null) {
        return Promise.all(features);
      } else {
        const ids = features.map((feature) => {
          return APIHelper.getFeatureId(feature, layer);
        });
        return new Promise((resolve, reject) => {
          APIHelper.queryFeaturesByIdsOnLayer(ids, layer)
            .then((queriedFeatures) => {
              resolve(queriedFeatures);
              // TODO morgen features aus antwort ausgeben.
            })
            .catch((e) => reject(e));
        });
      }
    } else {
      return Promise.all(features);
    }
  }

  static chunkArray(array, size) {
    const chunks = [];
    let i = 0;
    while (i < array.length) {
      chunks.push(array.slice(i, size + i));
      i += size;
    }
    return chunks;
  }

  static getGraphicsTouchedByExtent(layer, extent, nongeneralized) {
    return new Promise(function (resolve, reject) {
      const graphics = [];
      layer.graphics.map((g) => {
        // TODO => get graphics with query
        if (g.visible) {
          let intersects = false;
          switch (g.geometry.type) {
            case "point":
            case "multipoint":
            case "extent":
              intersects = !!extent.intersects(g.geometry);
              break;
            case "polyline":
            case "polygon":
            default:
              intersects =
                !!extent.intersects(g.geometry.getExtent()) &&
                !GeometryEngine.disjoint(extent, g.geometry);
              break;
          }
          if (intersects) {
            graphics.push(g);
          }
        }
      });
      if (nongeneralized && APIHelper.isFeatureLayer(layer)) {
        const promises = [];
        APIHelper.chunkArray(
          graphics.map((g) => {
            return { id: APIHelper.getFeatureId(g, layer), g };
          }),
          1000
        ).map((chunk) => {
          if (chunk.length && chunk[0]) {
            const { attributes } = chunk[0].g.graphic || chunk[0].g;
            const idOfFirst = attributes[layer.objectIdField]; //
            if (idOfFirst === undefined || idOfFirst === null) {
              promises.push(Promise.all(chunk));
            } else {
              promises.push(
                APIHelper.queryFeaturesByIdsOnLayer(
                  chunk.map(({ id }) => {
                    return id;
                  }),
                  layer
                )
              );
            }
          } else if (chunk.length) {
            promises.push(
              APIHelper.queryFeaturesByIdsOnLayer(
                chunk.map(({ id }) => {
                  return id;
                }),
                layer
              )
            );
          } else {
            promises.push(Promise.all([]));
          }
        });
        Promise.all(promises)
          .then((graphicchunks) => {
            resolve(graphicchunks.reduce((acc, val) => acc.concat(val), []));
          })
          .catch(() => {
            reject();
          });

        /* promises.push(
          APIHelper.queryFeatureByIdOnLayer(
            APIHelper.getFeatureId(g, layer),
            layer,
          ).then((result) => {
            return result.features[0];
          }),
        ); */
      } else {
        resolve(graphics);
      }
      // APIHelper.queryFeaturesByIdsOnLayer;
    });
  }

  /* Snapping */

  static prepareSnapping(
    map,
    customSymbol,
    tolerance = 20,
    force = false,
    layerInfos = null
  ) {
    console.error("Snapping not yet implemented for this api Version");
    return;
    const symbol =
      customSymbol ||
      APIHelper.esri.SymbolJsonUtils.fromJson(
        symbols(APIHelper.version).point.snap
      );

    const oldManager = APIHelper.getSnappingManager(map);

    if (oldManager) {
      APIHelper.cleanUpSnapping(map, oldManager);
    }

    const snappingManager = map.enableSnapping({
      snapPointSymbol: symbol,
      tolerance,
      alwaysSnap: force,
      layerInfos,
    });
    /*
    if (layerInfos) {
      snappingManager.setLayerInfos(layerInfos);
    }
    */
    return snappingManager;
  }

  static enableSnapping(map, snappingManager = map.snappingManager) {
    console.error("Snapping not yet implemented for this api Version");
    return;
    snappingManager._activateSnapping();
  }

  static disableSnapping(map, snappingManager = map.snappingManager) {
    console.error("Snapping not yet implemented for this api Version");
    return;
    snappingManager._deactivateSnapping();
  }

  static cleanUpSnapping(map, snappingManager = map.snappingManager) {
    console.error("Snapping not yet implemented for this api Version");
    return;
    // snappingManager._deactivateSnapping();
    map.disableSnapping();
  }

  static getSnappingManager(map) {
    console.error("Snapping not yet implemented for this api Version");
    return;
    return map.snappingManager;
  }

  static hasSnappingManager(map) {
    console.error("Snapping not yet implemented for this api Version");
    return;
    return !!map.snappingManager;
  }

  static getGeometriesExtent(geometry) {
    return geometry.extent;
  }

  static setDefinitionExpression(layer, defExp) {
    layer.definitionExpression = defExp;
  }

  static getDistinctValuesForField(layer, fieldName) {
    const query = new Query();
    query.returnGeometry = false;
    query.outFields = [fieldName];
    query.returnDistinctValues = true;
    query.where = "1=1";
    return layer.queryFeatures(query).then((featrueSet) => {
      return new Promise((resolve) => {
        resolve(
          featrueSet.features.map((feature) => {
            return APIHelper.getAttributes(APIHelper.getGraphic(feature))[
              fieldName
            ];
          })
        );
      });
    });
    // const valmap = {};
    /*const values = [];
    for (let i = 0; i < attributes.length; i++) {
      const value = attributes[i][fieldName];
      if (values.indexOf(value) === -1 && value !== undefined) {
        // if value is undefined it is not set or not provided by the map/feature service
        // valmap[value] = true;
        values.push(value);
      }
    }*/
  }

  static createSimpleFillSym(
    lineColor,
    fillColor,
    style = "solid",
    lineWidth = 1
  ) {
    return {
      type: "simple-fill", // autocasts as new SimpleFillSymbol()
      color: fillColor,
      style,
      outline: {
        // autocasts as new SimpleLineSymbol()
        color: lineColor,
        width: lineWidth,
      },
    };
  }
}

APIHelper.instance = APIHelper;
APIHelper.loading = false;
APIHelper.promise = new Promise((resolve) => resolve(true));

APIHelper.projectCache = new (function () {
  const values = {};
  this.set = (key, value) => {
    values[key] = value;
  };
  this.get = (key) => {
    return values[key];
  };
})();

APIHelper.geometryService = new GeometryService({ url: geometryServerUrl });
//link für disable pan
//https://swaggypyang.github.io/arcgisapi/sdk/latest/sample-code/view-disable-panning/index.html

//snapping -.-
//https://developers.arcgis.com/javascript/latest/api-reference/esri-views-interactive-snapping-FeatureSnappingLayerSource.html

APIHelper.debug = true;
