import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";
import DataLoadingIndicator from "../DataLoadingIndicator/widget";
import DatePickerWrapper from "./DatePickerWrapper";
import MeasurementsButton from "./MeasurementsButton";
import MeasurementsList from "./MeasurementsList";
import MeasurementsTimeSelection from "./MeasurementsTimeSelection";
import ErrorMessageTooltip from "./ErrorMessageTooltip";

const MeasurementsContent = observer((props) => {
  const { isAppPikett } = props;
  const { isDatePicker, dataLoading } = RootStore.hydroWebStore;

  return (
    <div
      style={{
        margin: "15px",
        position: "relative",
      }}
      id="measurementsContent-id"
    >
      <DataLoadingIndicator dataLoading={dataLoading} />
      <ErrorMessageTooltip />
      {!isAppPikett && <MeasurementsTimeSelection />}
      {!isAppPikett && isDatePicker && <DatePickerWrapper />}
      <MeasurementsButton isAppPikett={isAppPikett} />
      <MeasurementsList />
    </div>
  );
});

export default MeasurementsContent;
