import { Button, Tooltip } from "@mui/material";
import React from "react";
import SendIcon from "@mui/icons-material/Send";

const WidgetFoldButton = ({
  isWidgetOpen,
  handleOpenCloseWidget,
  className,
}) => {
  return (
    <Tooltip title={isWidgetOpen ? "Bereich ausblenden" : "Bereich einblenden"}>
      <Button
        className={className}
        onClick={() => handleOpenCloseWidget()}
        variant="contained"
      >
        <SendIcon
          sx={{
            transform: isWidgetOpen
              ? "matrix(1, 0, 0, 1, 0, 0)"
              : "matrix(-1, 0, 0, 1, 0, 0)",
            transition: "transform 0.8s ease",
          }}
        />
      </Button>
    </Tooltip>
  );
};

export default WidgetFoldButton;
