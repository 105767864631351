import { observer } from "mobx-react-lite";
import React from "react";
// import { CircularProgress, Modal, Typography } from "@mui/material";
import { BusyIndicator } from "@giscon/ui.busy-indicator";

const DataLoadingIndicator = observer((props) => {
  const { dataLoading } = props;

  if (!dataLoading) {
    return null;
  } else {
    return (
      <BusyIndicator
        label="lädt Daten..."
        elevation={0}
        style={{
          backgroundColor: "#ffffffaa",
          position: "absolute",
          zIndex: 100,
          inset: 0,
        }}
      />
    );
  }
  // return (
  // <Modal
  //   sx={{ backgroundColor: "rgb(0, 0, 0, 5%)", margin: "-12px" }}
  //   open={dataLoading}
  //   disablePortal
  //   style={{ position: "absolute" }}
  //   BackdropProps={{ style: { position: "absolute" } }}
  //   // container={() => document.getElementById("measurementsContent-id")}
  // >
  //   <Box
  //     sx={{
  //       position: "absolute",
  //       top: "50%",
  //       left: "50%",
  //       transform: "translate(-50%, -50%)",
  //       width: "auto",
  //       backgroundColor: "rgb(255 255 255 / 90%)",
  //       borderRadius: "5px",
  //       textAlign: "center",
  //       boxShadow: 24,
  //       pt: 2,
  //       px: 4,
  //       pb: 3,
  //       zIndex: "99",
  //     }}
  //   >
  //     <CircularProgress />
  //     <Typography>Laden der Daten</Typography>
  //   </Box>
  // </Modal>
  // );
});

export default DataLoadingIndicator;
