// import { ThemeProvider } from "@mui/private-theming";
import { observer } from "mobx-react-lite";
import React from "react";
import RegionsThemesSelectionSteps from "./components/RegionsThemesSelectionsSteps";
// import ownTheme from "./ownTheme";

const Widget = observer((props) => {
  return (
    <div style={{ margin: "15px" }}>
      {/* <ThemeProvider theme={ownTheme}> */}
      <RegionsThemesSelectionSteps />
      {/* </ThemeProvider> */}
    </div>
  );
});

export default Widget;
