import Dataset from "../Dataset";
import { addEvent } from "../utils";
import { drawSingleHandlers } from "../constants";
import * as d3 from "d3";
export class BarDataset extends Dataset {
  constructor(props) {
    // const { fill, outline, data = [] } = props;
    super({ ...props, type: "bar" });
    this.drawInfo.width = props.width || 5;
    this.drawInfo.stroke = this.drawInfo.stroke || "#333";
  }
}

function drawBarDatasetItem({
  item,
  drawInfo,
  index,
  dataset,
  // outline: outlineColor,
  // fill: fillColor,
  xAxis,
  yAxis,
  axisMap,
  svg,
}) {
  const { x: xRaw, y: yRaw } = item; // when x is not a range => setup a width and center it
  const {
    strokeWidth = 1,
    fill,
    stroke,
    // text,
    width = 5,
  } = drawInfo;
  const halfWidth = width / 2;
  if (axisMap[yAxis]) {
    const x = axisMap[xAxis].scale(xRaw);
    const y = axisMap[yAxis].scale(yRaw);
    const y0 = axisMap[yAxis].scale(0);
    const bar = svg
      .append("path")
      .attr(
        "d",
        `M${x - halfWidth} ${y0}L${x + halfWidth} ${y0}L${
          x + halfWidth
        } ${y} L${x - halfWidth} ${y}Z`
      )
      .attr(
        "style",
        `stroke-width:${strokeWidth}; stroke: ${stroke}; fill: ${fill}`
      )
      .node();

    bar.chartdataset = dataset;
    bar.chartdatasetIndex = index;
    bar.axisMap = axisMap;
    bar.data = { x: xRaw, y: yRaw };
    bar.pos = { x, y };

    ["click", "mouseover", "mouseout"].forEach((type) => {
      addEvent(d3.select(bar), type);
    });
    // drawLabel({
    //   x: [x1, x2],
    //   y: [y1, y2],
    //   text: y,
    //   svg,
    //   drawInfo: text,
    //   fill,
    // });
  }
}

// const getXY = (data) => {
//   if (!data) {
//     throw "Dataformat not supported";
//   } else if (Array.isArray(data)) {
//     const [x, y] = data;
//     return { x, y };
//   } else if (typeof data === "object") {
//     const { x, y } = data;
//     return { x, y };
//   } else {
//     throw "Dataformat not supported";
//   }
// };

drawSingleHandlers["bar"] = drawBarDatasetItem;
