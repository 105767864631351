export default function symbols(version) {
  const isVersion3 = version >= 3 && version < 4;

  const highlightColor = [18, 246, 41, isVersion3 ? 127 : 0.5]; // deepskyblue
  const selectedColor = [0, 191, 255, isVersion3 ? 127 : 0.5]; // deepskyblue
  const violatedColor = [255, 13, 37, isVersion3 ? 127 : 0.5]; // deepskyblue
  const defaultColor = [105, 105, 105, isVersion3 ? 127 : 0.5];
  const deletedColor = [255, 0, 0, isVersion3 ? 127 : 0.5];

  const maskColor = [0, 0, 0, isVersion3 ? 64 : 0.25];

  /* DO NOT CHANGE - BEGIN */

  const pointSymbolType = isVersion3 ? "esriSMS" : "simple-marker";
  const pointSymbolCross = isVersion3 ? "esriSMSCross" : "x";
  const pointSymbolTriangle = isVersion3 ? "esriSMSCircle" : "circle";
  const lineSymbolType = isVersion3 ? "esriSLS" : "simple-line";
  const lineSolid = isVersion3 ? "esriSLSSolid" : "solid";
  const polygonSymbolType = isVersion3 ? "esriSFS" : "simple-fill";
  const polygonSymbolSolid = isVersion3 ? "esriSFSSolid" : "solid";
  const polygonSymbolBackwardDiagonal = isVersion3
    ? "esriSFSBackwardDiagonal"
    : "backward-diagonal";
  const pointSize = 50;

  /* DO NOT CHANGE - END */

  const outline = {
    // autocasts as new SimpleLineSymbol()
    color: [128, 128, 128, isVersion3 ? 127 : 0.5],
    width: 1,
    style: lineSolid,
  };
  const highlightOutline = {
    // autocasts as new SimpleLineSymbol()
    color: [255, 0, 0, isVersion3 ? 127 : 0.5],
    width: 1,
    style: lineSolid,
  };
  return {
    mask: {
      color: maskColor,
      outline,
      type: polygonSymbolType,
      style: polygonSymbolSolid,
    },
    point: {
      default: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: defaultColor,
        style: pointSymbolTriangle, // so that rotation is visible
        outline,
        size: pointSize,
      },
      selected: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: selectedColor,
        style: pointSymbolTriangle, // so that rotation is visible
        outline,
        size: pointSize,
      },
      highlight: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: highlightColor,
        style: pointSymbolTriangle, // so that rotation is visible
        outline: highlightOutline,
        size: pointSize,
      },
      highlightdeselect: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: highlightColor,
        style: pointSymbolCross, // so that rotation is visible
        outline,
        size: pointSize,
      },
      violated: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: violatedColor,
        style: pointSymbolTriangle, // so that rotation is visible
        outline,
        size: pointSize,
      },
      snap: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: violatedColor,
        style: pointSymbolCross, // so that rotation is visible
        outline,
        size: pointSize,
      },
      deleted: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: deletedColor,
        style: pointSymbolCross, // so that rotation is visible
        outline,
        size: pointSize,
      },
    },
    multipoint: {
      default: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: defaultColor,
        style: pointSymbolTriangle, // so that rotation is visible
        outline,
        size: pointSize,
      },
      selected: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: selectedColor,
        style: pointSymbolTriangle, // so that rotation is visible
        outline,
      },
      highlight: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: highlightColor,
        style: pointSymbolTriangle, // so that rotation is visible
        outline: highlightOutline,
      },
      highlightdeselect: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: highlightColor,
        style: pointSymbolCross, // so that rotation is visible
        outline,
        size: pointSize,
      },
      violated: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: violatedColor,
        style: pointSymbolTriangle, // so that rotation is visible
        outline,
        size: pointSize,
      },
      deleted: {
        type: pointSymbolType, // autocasts as new SimpleMarkerSymbol()
        color: deletedColor,
        style: pointSymbolCross, // so that rotation is visible
        outline,
        size: pointSize,
      },
    },
    polyline: {
      default: {
        color: defaultColor,
        style: lineSolid,
        join: "round",
        type: lineSymbolType,
        cap: "butt",
        width: 3,
      },
      selected: {
        color: selectedColor,
        style: lineSolid,
        join: "round",
        type: lineSymbolType,
        cap: "butt",
        width: 5,
      },
      highlight: {
        color: highlightColor,
        style: lineSolid,
        join: "round",
        type: lineSymbolType,
        cap: "butt",
        width: 5,
      },
      highlightdeselect: {
        color: highlightColor,
        style: lineSolid,
        join: "dash",
        type: lineSymbolType,
        cap: "butt",
        width: 3,
      },
      violated: {
        color: violatedColor,
        style: lineSolid,
        join: "round",
        type: lineSymbolType,
        cap: "butt",
        width: 5,
      },
      deleted: {
        color: deletedColor,
        style: lineSolid,
        join: "dash",
        type: lineSymbolType,
        cap: "butt",
        width: 3,
      },
    },
    linestring: {
      default: {
        color: defaultColor,
        style: lineSolid,
        join: "round",
        type: lineSymbolType,
        cap: "butt",
        width: 3,
      },
      selected: {
        color: selectedColor,
        style: lineSolid,
        join: "round",
        type: lineSymbolType,
        cap: "butt",
        width: 5,
      },
      highlight: {
        color: highlightColor,
        style: lineSolid,
        join: "round",
        type: lineSymbolType,
        cap: "butt",
        width: 5,
      },
      highlightdeselect: {
        color: highlightColor,
        style: lineSolid,
        join: "dash",
        type: lineSymbolType,
        cap: "butt",
        width: 3,
      },
      violated: {
        color: violatedColor,
        style: lineSolid,
        join: "round",
        type: lineSymbolType,
        cap: "butt",
        width: 5,
      },
      deleted: {
        color: deletedColor,
        style: lineSolid,
        join: "dash",
        type: lineSymbolType,
        cap: "butt",
        width: 3,
      },
    },
    polygon: {
      default: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolSolid,
        color: defaultColor,
        outline,
      },
      selected: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolSolid,
        color: selectedColor,
        outline,
      },
      highlight: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolSolid,
        color: highlightColor,
        outline: highlightOutline,
      },
      highlightdeselect: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolBackwardDiagonal,
        color: highlightColor,
        outline,
      },
      violated: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolSolid,
        color: violatedColor,
        outline,
      },
      deleted: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolBackwardDiagonal,
        color: deletedColor,
        outline,
      },
    },
    extent: {
      default: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolSolid,
        color: defaultColor,
        outline,
      },
      selected: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolSolid,
        color: selectedColor,
        outline,
      },
      highlight: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolSolid,
        color: highlightColor,
        outline: highlightOutline,
      },
      highlightdeselect: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolBackwardDiagonal,
        color: highlightColor,
        outline,
      },
      violated: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolSolid,
        color: violatedColor,
        outline,
      },
      deleted: {
        type: polygonSymbolType, // autocasts as new SimpleMarkerSymbol()
        style: polygonSymbolBackwardDiagonal,
        color: deletedColor,
        outline,
      },
    },
  };
}
