import GraphView from "./GraphView";
import Overview from "./Overview";
import { toJS } from "mobx";
import { useMemo } from "react";
import ExtraMenuItemContent from "./ExtraMenuItemContent";

const ViewMap = {
  "-1": (props) => <Overview {...props} />,
  Jahrbuch: (props) => <ExtraMenuItemContent {...props} />,
  Download: (props) => <ExtraMenuItemContent {...props} />,
  Statistik: (props) => <ExtraMenuItemContent {...props} />,
};

const Content = ({
  mp,
  nls,
  style,
  store,
  configStore,
  defaultChartConfig,
  extraMenuItems,
}) => {
  // const { station_no, /*app,*/ groupParam } = mp;
  const { /*station_no, app,*/ groupParam, station } = mp;
  const [feature, item, items] = useMemo(() => {
    const items = toJS(store.indices.station[station.station_id]);
    let item = null;
    let feature = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i]?.groupParam === groupParam) {
        item = items[i];
        feature = toJS(store.data[groupParam][item.index]);
        // return [feature, item, items];
      }
    }
    return [feature, item, items];
  }, [groupParam, station.station_id, store.data, store.indices.station]);

  const props = {
    station: items,
    mp,
    defaultChartConfig,
    tsconfig: configStore?.[items?.[0]?.groupParam],
    nls,
    store,
    configStore,
    extraMenuItems,
  };

  // RootStore.zeitreihenConfig[groupParam]["Anzeigename"]

  return (
    <div style={style}>
      {item && (
        <GraphView
          station={items}
          mp={mp}
          nls={nls}
          item={item}
          feature={feature}
          defaultChartConfig={defaultChartConfig}
          tsconfig={configStore[groupParam]}
          store={store}
          configStore={configStore}
        />
      )}
      {ViewMap[`${groupParam}`] && ViewMap[`${groupParam}`](props)}
    </div>
  );
};

export default Content;
