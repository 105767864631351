import { LocalizationProvider } from "@mui/lab";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Chip, Divider, TextField, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";
import { de } from "date-fns/locale";

const DatePickerWrapper = observer(({ classes, dividerTextAlign }) => {
  const {
    precipitationDate,
    handlePrecipitationDate,
    todayDate,
    selectedTS,
    monthPrecipitationDate,
  } = RootStore.regionPrecipitationStore;
  // const yesterday = todayDate.setHours('00') - 86400000;
  // console.log(yesterday);

  const isDatePickerMonthly = selectedTS.dateformat === "MMMM y" ? true : false;
  return (
    <Box
      id="precipitationDateSelection"
      key="Date_picker_wrapper"
      sx={{
        "& > .MuiFormControl-root": {
          width: "100%",
          mt: "10px",
        },
        "& > .MuiFormControl-root > .MuiOutlinedInput-root > .MuiOutlinedInput-input":
          {
            padding: "10px 14px",
          },
        "& > .MuiFormControl-root > .MuiInputLabel-root": {
          // top: "-3px",
        },
      }}
    >
      <Divider className={classes.divider} textAlign={dividerTextAlign}>
        <Chip className={classes.chip} label="Datum" />
      </Divider>
      <LocalizationProvider locale={de} dateAdapter={AdapterDateFns}>
        <MobileDateTimePicker
          label="Datum auswählen"
          inputFormat={isDatePickerMonthly ? "MM.yyyy" : "dd.MM.yyyy HH:mm"}
          mask={isDatePickerMonthly ? "__.____" : "__.__.____ __:__"}
          value={
            isDatePickerMonthly ? monthPrecipitationDate : precipitationDate
          }
          onChange={(newValue) => {
            console.log("For displaying changes on the Fly");
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                // helperText={params?.inputProps?.placeholder}
                // onClick={(e) => console.log(e)}
              />
              {/* <Button>OK</Button> */}
            </>
          )}
          ampm={false}
          minutesStep={60}
          views={
            isDatePickerMonthly
              ? ["year", "month"]
              : ["year", "month", "day", "hours"]
          }
          maxDateTime={todayDate}
          // maxDateTime={new Date().setHours(new Date().getHours() - 1)}
          onAccept={(newValue) => {
            console.log("Accepted date");
            handlePrecipitationDate(newValue);
          }}
          cancelText="Abbrechen"
          hideTabs={true}
          showToolbar={false}
          // ToolbarComponent={TimePickerToolbar}
          showTodayButton={isDatePickerMonthly ? false : true}
          disableCloseOnSelect={true}
          todayText="Heute"
        />
      </LocalizationProvider>
    </Box>
  );
});

export default DatePickerWrapper;
