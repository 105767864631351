import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
// import SearchIcon from "@mui/icons-material/Search";
import AppBarMainMenu from "./AppBarMainMenu";
import I18N from "../libs/I18N";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import { Fab, Stack, Tooltip, tooltipClasses } from "@mui/material";
import RootStore from "stores/RootStore";
import { observer } from "mobx-react";

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(1),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("sm")]: {
//       width: "12ch",
//       "&:focus": {
//         width: "20ch",
//       },
//     },
//   },
// }));

const StyledViewModeButton = styled(Fab)((props) => ({
  background: props.is3d_mode
    ? "linear-gradient(to right, #00d0ff, yellow)"
    : "linear-gradient(to right, #bef3ff, #67caf9fa)",
  zIndex: "9",
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    // placement={"left"}
    // TransitionComponent={Zoom}
    // followCursor
  />
))((props) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "gray",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "gray", //"#d97d4b"
  },
}));

export default observer(function SearchAppBar() {
  const { isThreeDMode, isTwoViews, handleMode, handleSynchronizeMode } =
    RootStore.mapStore;
  const { currentApp } = RootStore;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <AppBarMainMenu />
          <Typography
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            variant="h6"
            noWrap
            component="div"
          >
            {currentApp.toUpperCase()} {I18N.labels.appTitle}
          </Typography>

          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <StyledTooltip
              title={isThreeDMode ? "Auf 2D umschalten" : "Auf 3D umschalten"}
            >
              <span>
                <StyledViewModeButton
                  is3d_mode={isThreeDMode ? isThreeDMode.toString() : undefined}
                  size="small"
                  color="default"
                  aria-label="3D"
                  onClick={handleMode}
                >
                  {isThreeDMode ? (
                    <>2D</>
                  ) : (
                    <ThreeDRotationIcon fontSize="large" />
                  )}
                </StyledViewModeButton>
              </span>
            </StyledTooltip>

            <StyledTooltip
              title={`Synchronisation ${
                isTwoViews ? "deaktivieren" : "aktivieren"
              }`}
            >
              <span>
                <Fab
                  sx={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    // ml: "10px",
                    // title: "Synchronisierte zwei Views",
                    zIndex: "9",
                  }}
                  size="small"
                  variant="extended"
                  aria-label="SynchronizeMode"
                  onClick={handleSynchronizeMode}
                >
                  Sync 2 Views
                </Fab>
              </span>
            </StyledTooltip>
          </Stack>

          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
});
