import { Box, Chip, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import StationStatisticContent from "./StationStatisticContent";

const StationStatisticsContainer = observer(
  ({ classes, dividerTextAlign, title }) => {
    return (
      <Box id="station-stats">
        <Divider className={classes.divider} textAlign={dividerTextAlign}>
          <Chip className={classes.chip} label={title} />
        </Divider>

        <StationStatisticContent title={title} />
      </Box>
    );
  }
);

export default StationStatisticsContainer;
