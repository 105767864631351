import { LocalizationProvider } from "@mui/lab";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";
import { de } from "date-fns/locale";

const DatePickerWrapper = observer(() => {
  const { maxMeasurementsDate, handleMeasurementsDate } =
    RootStore.hydroWebStore;
  // const yesterday = new Date().setHours("00") - 86400000;
  const { hydroWebDate } = RootStore.hydroWebFeatures;

  return (
    <Box
      key="Date_picker_wrapper"
      sx={{
        mb: "15px",
        "& > .MuiFormControl-root": {
          width: "100%",
          // mt: "0px",
        },
        "& > .MuiFormControl-root > .MuiOutlinedInput-root > .MuiOutlinedInput-input":
          {
            padding: "10px 14px",
          },
      }}
    >
      <LocalizationProvider locale={de} dateAdapter={AdapterDateFns}>
        <MobileDateTimePicker
          label="Datum auswählen"
          inputFormat={"dd.MM.yyyy"}
          mask={"__.__.____ "}
          value={hydroWebDate}
          onChange={(newValue) => {
            console.log("For displaying changes on the Fly");
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                // helperText={params?.inputProps?.placeholder}
                // onClick={(e) => console.log(e)}
              />
            </>
          )}
          ampm={false}
          minutesStep={60}
          views={["year", "month", "day"]}
          maxDateTime={maxMeasurementsDate}
          // maxDateTime={new Date().setHours(new Date().getHours() - 1)}
          onAccept={(newValue) => {
            console.log("Accepted date");
            handleMeasurementsDate(newValue);
          }}
          cancelText="Abbrechen"
          hideTabs={true}
          showToolbar={false}
          // ToolbarComponent={TimePickerToolbar}
          // showTodayButton={true}
          disableCloseOnSelect={true}
          todayText="Heute"
        />
      </LocalizationProvider>
    </Box>
  );
});

export default DatePickerWrapper;
