import { observer } from "mobx-react-lite";
import RootStore from "stores/RootStore";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import ExtraClimateLayersLegend from "./ExtraClimateLayersLegend";
// import DataLoadingIndicator from "widgets/DataLoadingIndicator/widget";

const ExtraClimateLayers = observer(({ classes, dividerTextAlign }) => {
  const {
    windSpeedSceneLayer,
    coldAirProductionRateLayer,
    windSpeedFeatureLayer,
    hideWindSpeedLayer,
    showWindSpeedLayer,
    showColdAirProductionRateLayer,
    hideColdAirProductionRateLayer,
  } = RootStore.climateStore;

  const [checkboxState, setCheckboxState] = useState({
    windSpeed: windSpeedSceneLayer.visible,
    coldAirProductionRate: coldAirProductionRateLayer.visible,
  });

  const handleChange = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };

  const someLayersVisible = Object.values(checkboxState).some(
    (val) => val === true
  );

  const extraClimateLayersList = (
    <Box id="climate-extra-layers-list">
      <Divider className={classes.divider} textAlign={dividerTextAlign}>
        <Chip className={classes.chip} label="Layers" />
      </Divider>

      <FormControlLabel
        label={
          <ListItemText
            primary="Windrichtung und -Geschwindigkeit"
            secondary={"m/s, 4 Uhr, 2m über Grund - aggregierte 50 m Auflösung"}
          />
        }
        sx={{ paddingTop: 1 }}
        control={
          <>
            <Checkbox
              checked={checkboxState.windSpeed}
              onChange={(event) => {
                handleChange(event);
                if (event.target.checked) {
                  showWindSpeedLayer();
                } else {
                  hideWindSpeedLayer();
                }
              }}
              color="primary"
              name="windSpeed"
            />
          </>
        }
      />

      <FormControlLabel
        label="Kaltluftproduktionsrate"
        control={
          <Checkbox
            checked={checkboxState.coldAirProductionRate}
            onChange={(event) => {
              handleChange(event);
              if (event.target.checked) {
                showColdAirProductionRateLayer();
              } else {
                hideColdAirProductionRateLayer();
              }
            }}
            color="primary"
            name="coldAirProductionRate"
          />
        }
      />
    </Box>
  );

  return (
    <Box id="climate-extra-layers">
      {extraClimateLayersList}

      {someLayersVisible && (
        <ExtraClimateLayersLegend
          classes={classes}
          dividerTextAlign={dividerTextAlign}
          showLegend={checkboxState}
        />
      )}
    </Box>
  );
});

export default ExtraClimateLayers;
