import { Autocomplete, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const StationSelection = observer((props) => {
  const { selectedStatisticTopic, selectedStatStation, handleStationChange } =
    RootStore.statisticStore;

  // const zofingen = hydroWebStationsNamesSorted.filter(
  //   (station) => station.station_name === "Zofingen"
  // );
  // console.log("zofingen", zofingen);

  if (selectedStatisticTopic.deactive) {
    return null;
  }

  return (
    <Autocomplete
      id="statistic-station-selection"
      // sx={{ marginBottom: "15px" }}
      // size={"small"}
      sx={{
        boxShadow:
          "0px 5px 5px -3px rgb(0 109 151 / 45%), 0px 8px 10px 1px rgb(0 109 151 / 14%), 0px 3px 14px 2px rgb(0 109 151 / 12%)",
        borderRadius: 1,
        marginBottom: "20px",
      }}
      options={selectedStatisticTopic.features}
      value={selectedStatStation}
      getOptionLabel={(option) =>
        option.station_name + " (" + option.station_no + ")" || ""
      }
      renderInput={(params) => (
        <TextField {...params} fullWidth label={"Station Auswahl"} />
      )}
      disableClearable
      fullWidth
      onChange={(e, value, reason) => {
        if (value.station_id) {
          handleStationChange(value, reason);
        }
      }}
      isOptionEqualToValue={(option, value) => option === selectedStatStation}
    />
  );
});

export default StationSelection;
