import APIHelper from "./APIHelper";
import ReactDOM from "react-dom";
import RootStore from "stores/RootStore";
import SimpleTable from "./SimpleTable";
import { Chip } from "@mui/material";

export function round(number, fixpoint) {
  let actualFixpoint;
  if (!fixpoint) {
    // 3er regel
    const abs = Math.abs(number);
    if (abs >= 100) {
      actualFixpoint = 0;
    } else if (abs >= 10) {
      actualFixpoint = 1;
    } else {
      actualFixpoint = 2;
    }
  } else {
    actualFixpoint = fixpoint;
  }
  const tenthpow = Math.pow(10, actualFixpoint);
  return Math.round(number * tenthpow) / tenthpow;
}

export const changeCursor = (graphic, view) => {
  if (graphic) {
    view.container.style.cursor = "pointer";
  } else {
    view.container.style.cursor = "default";
  }
};

const openViewPopup = ({ title, view, location, content }) => {
  view.popup.open({
    location,
    content,
    title,
  });
  view.popup.when((e) => {
    view.popup.reposition();
    // view.popup.visible = true;
  });
};

const featureClicked = (feature, station_no, groupParam, app) => {
  RootStore.mapStore.openMeasuringPointWidget(
    station_no,
    app,
    groupParam,
    feature
  );
  // RootStore.mapStore.clickedCatchmentArea = null;
  RootStore.mapStore.setClickedCatchmentArea(null);
};

const createTableForStation = (station_id, store, app) => {
  const fields = [
    { name: "key", label: "Parameter", dataPath: "key" },
    { name: "value", label: "Wert", dataPath: "value" },
  ];
  const data = store.indicesFiltered.station[station_id]
    .map(({ groupParam, index }, stationIndex) => {
      // const tsconfig =
      //   app === "envis"
      //     ? RootStore.zeitreihenConfig[groupParam]
      //     : app === "pikett"
      //     ? RootStore.pikettZeitreihenConfig[groupParam]
      //     : RootStore.hydroZeitreihenConfig[groupParam];
      const tsconfig = store.timeSeriesconfig[groupParam];
      if (!tsconfig.zusatzReihe || !tsconfig.hideTab) {
        const name = tsconfig.Anzeigename;
        const feature = store.dataFiltered[groupParam][index];

        const tableRow = {
          value: feature.ts_value
            ? round(feature.ts_value, tsconfig.Nachkommastellen)
            : feature.ts_value,
          feature,
          key: name,
          groupParam,
          index,
          station_id,
          onClick: (row) => {
            const { groupParam, index, station_id, feature } = row;
            const groupParameter =
              RootStore.noChartStationsGroupParams.includes(groupParam)
                ? -1
                : groupParam;
            featureClicked(feature, feature.station_no, groupParameter, app);
          },
        };
        // if (tsconfig.Einheit) {
        //   tableRow.unit = { value: tsconfig.Einheit };
        // }
        if (feature.ts_unitsymbol) {
          tableRow.unit = { value: feature.ts_unitsymbol };
        }
        return tableRow;
      } else return null;
    })
    .filter((e) => e !== null);

  return <SimpleTable fields={fields} data={data} />;
};

const showTooltip = (graphic, store, app) => {
  if (graphic) {
    const attributes = APIHelper.getAttributes(graphic);
    const geometry = APIHelper.cloneGeometry(APIHelper.getGeometry(graphic));
    const node = document.createElement("div");
    node.style.overflow = "hidden";
    // const multipleStations = attributes.station_id.indexOf(",") !== -1;
    const multipleStations = attributes.stations;

    ReactDOM.render(
      <div>
        {attributes.station_id.split(",").map((station_id) => {
          let stationLabel = "";
          if (multipleStations) {
            const station = multipleStations.find(
              (station) => station.station_id === station_id
            );
            stationLabel = `Station: ${station.station_name} (${station.station_no})`;
          }
          return (
            <div key={station_id} style={{ marginBottom: "16px" }}>
              {multipleStations && (
                // <Typography>{`Station ${station_id}`}</Typography>
                <Chip sx={{ fontSize: "inherit" }} label={stationLabel} />
              )}
              {createTableForStation(station_id, store, app)}
            </div>
          );
        })}
      </div>,
      node
    );
    // APIHelper.showMapTip(
    //   map,
    //   // view,
    //   RootStore.mapStore.activeView,
    //   geometry,
    //   node,
    //   `${attributes.station_name} (${attributes.station_no})`
    // );
    openViewPopup({
      title: multipleStations
        ? attributes.stations.length + " Stationen"
        : `${attributes.station_name} (${attributes.station_no})`,
      view: RootStore.mapStore.activeView,
      location: geometry,
      content: node,
    });
  } else {
    console.log("showTooltip no graphic");
    RootStore.mapStore.activeView.popup.close();
  }
  // view.popup = {
  //   // Set the popup's title to the coordinates of the location
  //   title: "asdasd",
  //   location: geometry,
  //   content: element,
  // };
};

// export const onClick = (graphic, store, app) => {
//   if (graphic) {
//     const view = RootStore.mapStore.activeView;
//     const attributes = APIHelper.getAttributes(graphic);
//     const point = APIHelper.cloneGeometry(APIHelper.getGeometry(graphic));
//     if (attributes.showTooltip) {
//       showTooltip(graphic, store, app);
//       // showCursor(graphic);
//     } else {
//       const { mapStore } = RootStore;
//       showTooltip(null);
//       const scale = view.scale;
//       const zoomLevel = mapStore.getZoomLevel(scale);
//       if (zoomLevel !== -1) {
//         // zoomlevel found
//         const zoomScale = mapStore.getZoomScale(zoomLevel + 1) || scale; // if level does not exist stay on same scale
//         view.goTo({ center: point, scale: zoomScale });
//       }
//       // if (zoomLevel < view.lods.length - 1) { // not working in 3d ...
//       view.zoom = zoomLevel + 1;
//       view.center = point;
//       // }
//     }
//   } else {
//     // showTooltip(null);
//   }
//   // }
// };

export const onClick = (graphic, store, app) => {
  if (graphic) {
    const view = RootStore.mapStore.activeView;
    const attributes = APIHelper.getAttributes(graphic);
    const point = APIHelper.cloneGeometry(APIHelper.getGeometry(graphic));
    if (attributes.showTooltip) {
      showTooltip(graphic, store, app);
    } else {
      showTooltip(null);
      let viewZoom = view.zoom;
      if (viewZoom === -1) {
        // view has no LODs
        const { mapStore } = RootStore;
        const zoomLevel = mapStore.getZoomLevel(view.scale);
        if (zoomLevel !== -1) {
          const zoomScale = mapStore.getZoomScale(zoomLevel + 1);
          if (zoomScale) {
            view.goTo({ center: point, scale: zoomScale });
          }
        }
      } else {
        view.zoom = viewZoom + 1;
        view.center = point;
      }
    }
  }
};
