import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import Station from "./components/Station";
import RootStore from "stores/RootStore";

const Widget = (props) => {
  const mp = toJS(RootStore.mapStore.selectedMeasuringPoint);

  return (
    <Station
      measuringPoint={mp}
      close={() => {
        RootStore.mapStore.selectedMeasuringPoint = null;
      }}
      setMP={(mp) => {
        RootStore.mapStore.selectedMeasuringPoint = mp;
      }}
    />
  );
};
export default observer(Widget);
