import { round } from "libs/EnVISUtils";
import { action, makeObservable, observable } from "mobx";
import { LineDataset } from "components/chart/types/line";
import { BarDataset } from "components/chart/types/bar";
import { updatePeriodToNextOne } from "utils/ChartUtils";
import RootStore from "stores/RootStore";

export class FeatureStore {
  // proxy = "https://test.giscon.de/Proxy/Proxy.ashx?";
  constructor(props) {
    const {
      Farbe,
      Art,
      Feld,
      Strichstaercke,
      Anzeigename,
      Einheit,
      groupParam,
      baseUrl,
      Nachkommastellen,
      Grenzwert,
      Zusatzreihe,
      feature,
      showPoints = false,
    } = props;
    this.Farbe = Farbe;
    this.Art = Art;
    this.Feld = Feld;
    this.Strichstaercke = parseFloat(Strichstaercke);
    this.Anzeigename = Anzeigename;
    this.Einheit = Einheit;
    this.groupParam = groupParam;
    this.baseUrl = baseUrl;
    this.Nachkommastellen = Nachkommastellen;
    this.feature = feature;
    this.Grenzwert = !!Grenzwert;
    this.Zusatzreihe = !!Zusatzreihe;
    this.visible = true;
    this.loading = false;
    //
    this.loadingListener = [];
    this.showPoints = showPoints;
    makeObservable(this, {
      loading: observable.ref,
      setLoading: action,
      visible: observable.ref,
      setVisible: action,
    });
    // TODO: for chart itself => hover Tooltips => template for that and
    // TODO: interactive Legend for Chart => in svg and/or js functions to control which to show (Wrapper around chart) and then show it in HTML => new Class envisChart
    const name = `${this.Feld === "ts_summe" ? "Summe " : ""}${
      this.Anzeigename
    }`;
    if (this.Feld === "ts_summe") {
      this.hasy2 = true;
    }
    switch (Art) {
      default:
      case "line":
        // TODO: display the Points (optional) ; points for hovering (transparent) as svg circle
        this.chartData = new LineDataset({
          formatY: (y) => {
            return round(y, this.Nachkommastellen);
          },
          fill: this.Farbe,
          data: [],
          yAxis: this.hasy2 ? "y2" : "y",
          strokeWidth: this.Strichstaercke + 1,
          name,
        });
        this.setShowPoints(this.showPoints);
        break;
      case "column":
        // TODO: hover => use the displayed bars
        this.chartData = new BarDataset({
          formatY: (y) => {
            return round(y, this.Nachkommastellen);
          },
          // TODO: add possibility to hide label
          fill: this.Farbe,
          outline: "transparent",
          data: [],
          yAxis: this.hasy2 ? "y2" : "y",
          strokeWidth: this.Strichstaercke + 1,
          name,
        });
        break;
    }
  }

  setVisible(visible) {
    this.visible = visible !== undefined ? visible : this.visible;
    this.chartData?.setVisible(visible !== undefined ? visible : this.visible);
  }

  setChartData(data) {
    if (data.length === 0) {
      updatePeriodToNextOne(
        this.period,
        this.periodsOption,
        this.setSelectedPeriod,
        this.setIsShowingPeriodTooltip
      );
    }
    this.chartData?.setData(data !== undefined ? data : this.data);
  }

  setShowPoints(visible) {
    this.chartData.drawInfo.pointRadius = visible ? 5 : 0;
    this.showPoints = visible;
  }

  updateChartData() {
    const {
      data,
      visible,
      // min: { x: minx, y: miny },
      // max: { x: maxx, y: maxy },
    } = this;
    // console.log(`ChartData ${this.Anzeigename}:`, data, {
    //   min: this.min,
    //   max: this.max,
    // });
    data !== undefined && this.setChartData(data);
    visible !== undefined && this.setVisible(visible);
  }

  handlePeriod(period) {
    this.period = period;
    const date = new Date();
    if (period === "p12m") {
      if (date.getDay() !== 31 || date.getMonth() !== 11) {
        date.setFullYear(date.getFullYear() - 1);
      }
      date.setMonth(11); //31.12 23:59:59
      date.setDate(31);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      this.toDate = date.toISOString();
      // this.period = "p1y";
    } else {
      // this.period = period;
      if (this.lastFeatureTsId !== this.feature.ts_id) {
        this.toDate = null;
      }
    }
    this.lastFeatureTsId = this.feature.ts_id;
  }

  setPeriod(
    period,
    reduceInterval,
    noFetch = false,
    periodsOption,
    setSelectedPeriod,
    setIsShowingPeriodTooltip
  ) {
    console.log("setPeriod");
    // this.period = period;
    this.handlePeriod(period);
    this.periodsOption = periodsOption;
    this.setSelectedPeriod = setSelectedPeriod;
    this.setIsShowingPeriodTooltip = setIsShowingPeriodTooltip;
    this.reduceInterval = reduceInterval;
    noFetch || this.fetch();
  }

  get url() {
    const period = this.period === "p12m" ? "p1y" : this.period;
    return (
      RootStore.config.proxyURL +
      this.baseUrl +
      `&request=getTimeseriesValues&format=dajson&dateformat=UNIX&ts_id=${this.feature.ts_id}` +
      `&metadata=true&md_returnfields=ts_id%2Cparametertype_id%2Cts_unitsymbol` +
      `${this.toDate ? "&to=" + encodeURIComponent(this.toDate) : ""}` +
      `${this.period ? "&period=" + period : ""}`
    );
  }

  setLoading(loading) {
    this.loading = loading;
    this.loadingListener.forEach((listener) => {
      try {
        listener(this.loading, this);
      } catch (e) {
        console.error("Error in loading listener", e);
      }
    });
  }

  applyData(raw = []) {
    // console.log(this);
    // console.log("this.Nachkommastellen", this.Nachkommastellen);
    // console.log("raw", raw);
    let data = [];
    for (let i = 0; i < raw.length; i++) {
      let [x, y] = raw[i];
      if (y !== null) {
        if (
          this.Feld === "ts_value" ||
          this.Feld === "ts_value2" ||
          this.Feld === "ts_summe"
        ) {
          y = round(y, this.Nachkommastellen);
        } else {
          y = round(this.feature[this.Feld], this.Nachkommastellen);
        }
        // if (y !== null) {
        data.push({ x, y });
        // }
      } else {
        console.log(`Value "${i}" is "null"`);
      }
    }

    switch (this.Feld) {
      case "ts_value":
      case "ts_value2":
      case "ts_summe":
        break;
      default:
        console.warn(`Unrecognized Feld "${this.Feld}"`);
    }

    const reduced = this.reduceValues(data); // does handle ts_value, ts_value2, ts_summe
    this.data = reduced.data;
    this.min = reduced.min;
    this.max = reduced.max;
    this.lastValue = reduced.lastValue;
    this.isDataReduced = reduced.isDataReduced;
    this.updateChartData();
    this.onChange && this.onChange();
  }

  getReducedData(raw, reduceInterval, min, max, lastValue) {
    const intervalls = {};
    for (let i = 0; i < raw.length; i++) {
      const value = raw[i];
      const index = `${value.x - (value.x % reduceInterval)}`;
      if (!intervalls[index]) intervalls[index] = [];
      intervalls[index].push(value);
    }

    const data = Object.keys(intervalls).map((key) => {
      const interval = intervalls[key];
      let x = 0; // arit avg
      let y = 0; // median
      interval.forEach(({ y: ycurr, x: xcurr }) => {
        y += Number(ycurr);
        x = xcurr;
      });

      if (this.Feld !== "ts_value2" && this.Feld !== "ts_summe") {
        x = Math.round(interval[Math.floor(interval.length / 2)].x);
        y /= interval.length;
      }
      // otherwise just use the sum
      if (x < min.x) {
        min.x = x;
      }
      if (x > max.x) {
        max.x = x;
        lastValue.x = x;
        lastValue.y = y;
      }
      if (y < min.y) {
        min.y = y;
      }
      if (y > max.y) {
        max.y = y;
      }
      return { x, y };
    });
    return data;
  }

  getDataWithoutReducing(raw, min, max, lastValue) {
    const data = raw.map((value) => {
      const x = Number(value.x);
      const y = Number(value.y);
      if (x < min.x) {
        min.x = x;
      }
      if (x > max.x) {
        max.x = x;
        lastValue.x = x;
        lastValue.y = y;
      }
      if (y < min.y) {
        min.y = y;
      }
      if (y > max.y) {
        max.y = y;
      }
      return { x, y };
    });
    return data;
  }

  reduceValues(raw) {
    console.log("Data length before reducing: ", raw.length);
    // console.log(raw);
    const reduceInterval = this.reduceInterval;
    let min = { x: Infinity, y: Infinity };
    let max = { x: -Infinity, y: -Infinity };
    let lastValue = { x: -Infinity, y: -Infinity };
    let data = [];
    if (reduceInterval) {
      if (
        this.period === "p1y" ||
        this.period === "p5y" ||
        this.period === "p10y"
      ) {
        if (raw.length > 1800) {
          data = this.getReducedData(raw, reduceInterval, min, max, lastValue);
        } else {
          data = this.getDataWithoutReducing(raw, min, max, lastValue);
        }
      } else {
        data = this.getReducedData(raw, reduceInterval, min, max, lastValue);
      }
    } else {
      data = this.getDataWithoutReducing(raw, min, max, lastValue);
    }

    if (this.Feld === "ts_summe") {
      let sum = 0;
      data = data.map(({ x, y }) => {
        sum += y;
        max.y = sum;
        return { x, y: sum };
      });
    }
    console.log("Data length after reducing: ", data.length);
    const isDataReduced = data.length < raw.length ? true : false;
    // console.log(data);
    return { data, min, max, lastValue, isDataReduced };
  }

  // reduceValues(raw) {
  //   console.log("Data length before reducing: ", raw.length);
  //   // console.log(raw);
  //   const reduceInterval = this.reduceInterval;
  //   const intervalls = {};
  //   let min = { x: Infinity, y: Infinity };
  //   let max = { x: -Infinity, y: -Infinity };
  //   let lastValue = { x: -Infinity, y: -Infinity };
  //   let data = [];
  //   if (reduceInterval) {
  //     for (let i = 0; i < raw.length; i++) {
  //       const value = raw[i];
  //       const index = `${value.x - (value.x % reduceInterval)}`;
  //       if (!intervalls[index]) intervalls[index] = [];
  //       intervalls[index].push(value);
  //     }

  //     data = Object.keys(intervalls).map((key) => {
  //       const interval = intervalls[key];
  //       let x = 0; // arit avg
  //       let y = 0; // median
  //       interval.forEach(({ y: ycurr }) => {
  //         y += Number(ycurr);
  //       });
  //       x = Math.round(interval[Math.floor(interval.length / 2)].x);
  //       if (this.Feld !== "ts_value2" && this.Feld !== "ts_summe") {
  //         y /= interval.length;
  //       }

  //       // otherwise just use the sum
  //       if (x < min.x) {
  //         min.x = x;
  //       }
  //       if (x > max.x) {
  //         max.x = x;
  //         lastValue.x = x;
  //         lastValue.y = y;
  //       }
  //       if (y < min.y) {
  //         min.y = y;
  //       }
  //       if (y > max.y) {
  //         max.y = y;
  //       }
  //       return { x, y };
  //     });
  //   } else {
  //     data = raw.map((value) => {
  //       const x = Number(value.x);
  //       const y = Number(value.y);
  //       if (x < min.x) {
  //         min.x = x;
  //       }
  //       if (x > max.x) {
  //         max.x = x;
  //         lastValue.x = x;
  //         lastValue.y = y;
  //       }
  //       if (y < min.y) {
  //         min.y = y;
  //       }
  //       if (y > max.y) {
  //         max.y = y;
  //       }
  //       return { x, y };
  //     });
  //   }

  //   if (this.Feld === "ts_summe") {
  //     let sum = 0;
  //     data = data.map(({ x, y }) => {
  //       sum += y;
  //       max.y = sum;
  //       return { x, y: sum };
  //     });
  //   }
  //   console.log("Data length after reducing: ", data.length);
  //   const isDataReduced = data.length < raw.length ? true : false;
  //   // console.log(data);
  //   return { data, min, max, lastValue, isDataReduced };
  // }

  fetch() {
    let aborted = false;
    const abort = () => {
      aborted = true;
    };

    if (this.currentFetch) {
      this.currentFetch.abort();
    }

    this.setLoading(true);
    fetch(this.url)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (!aborted) {
          this.currentFetch = null;
          const [raw] = response;
          if (!this.Einheit) this.Einheit = raw.ts_unitsymbol;
          this.applyData(raw.data);
          /*
            data:[[x,y]]
            parametertype_id: "542"
            ts_id: "40926042"
            rows: "49"
            ts_unitsymbol: "mg/l"
            columns: "Timestamp,Value"
           */
          this.setLoading(false);
        } else {
          console.log("aborted", aborted);
        }
      })
      .catch((e) => {
        if (!aborted) {
          this.currentFetch = null;
          this.onError && this.onError(e);
          this.setLoading(false);
          console.error("Error while loading timeseries", e);
        }
      });
    this.currentFetch = { abort };
  }
  abort() {
    if (this.currentFetch) {
      this.currentFetch.abort();
      this.setLoading(false);
      this.currentFetch = null;
    }
  }
}
