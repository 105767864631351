import { Card, CardHeader } from "@mui/material";
import React from "react";
import MeasuringPointsSelection from "./MeasuringPointsSelection";

const MeasuringPointsList = (props) => {
  return (
    <Card
      sx={{
        height: "auto",
        flexGrow: 1,
        maxWidth: "auto",
        // overflowY: "auto",
        border: "1px solid lightgray",
        borderRadius: "3px",
        padding: "12px",
      }}
    >
      <CardHeader title="Messstellen-Liste" sx={{ p: "0px", pb: "8px" }} />

      <MeasuringPointsSelection />
    </Card>
  );
};

export default MeasuringPointsList;
