import { Divider, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import BookIcon from "@mui/icons-material/Book";
import DownloadIcon from "@mui/icons-material/Download";
import TimelineIcon from "@mui/icons-material/Timeline";

const ExtraMenu = ({ extraMenuItems, openTab }) => {
  // const extraMenuItems = {
  //   Yearbook: { config: "test", downloadParameters: "test" },
  //   DownLoad: { config: "test", downloadParameters: "test" },
  //   Statistics: { config: "test", downloadParameters: "test" },
  // };

  return (
    <>
      <Divider />
      {extraMenuItems &&
        Object.keys(extraMenuItems).map((name) => (
          <ListItem
            button
            key={name}
            onClick={() => {
              openTab(name);
            }}
          >
            <ListItemIcon>
              {name === "Statistik" ? (
                <QueryStatsIcon />
              ) : name === "Jahrbuch" ? (
                <BookIcon />
              ) : name === "Download" ? (
                <DownloadIcon />
              ) : (
                <TimelineIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        ))}
    </>
  );
};

export default ExtraMenu;
