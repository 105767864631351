import React, { useMemo } from "react";
import { renderTreeItemsFromData } from "./TreeViewItems";
import RootStore from "stores/RootStore";
import { treeItemLabelKeys, treeItemMenuOptions } from "utils/TreeViewUtils";
import { observer } from "mobx-react";

import { TreeView as GGITreeView } from "@giscon/ui.tree-view";
import { Typography } from "@mui/material";

const MeasuringPointsSelection = observer((props) => {
  const {
    treeViewItemsMeasuringPoints,
    expandedMeasuringPoints,
    isClickedItemMeasuringPoints,
    findItemInNestedArrayById,
    setExpandedMeasuringPoints,
    handleMeasuringPointMenuOptionsSelection,
    setFilterTextMeasuringPoints,
    filterTextMeasuringPoints,
  } = RootStore.mapScopeThemeStore;
  // const isCheckBox = false;

  const treeItems = useMemo(
    () =>
      renderTreeItemsFromData(
        treeViewItemsMeasuringPoints,
        0,
        treeItemLabelKeys,
        treeItemMenuOptions,
        handleMeasuringPointMenuOptionsSelection
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [treeViewItemsMeasuringPoints, treeItemLabelKeys, treeItemMenuOptions]
  );

  const handleSelectMeasuringPoints = (event, clickedNodeId) => {
    if (isClickedItemMeasuringPoints(clickedNodeId)) {
      const clickedMeasuringPoint = findItemInNestedArrayById(
        treeViewItemsMeasuringPoints,
        clickedNodeId,
        "children",
        "ID"
      );
      const groupParameter = RootStore.noChartStationsGroupParams.includes(
        clickedMeasuringPoint.groupParam
      )
        ? -1
        : clickedMeasuringPoint.groupParam;
      RootStore.mapStore.openMeasuringPointWidget(
        clickedMeasuringPoint.station_no,
        "envis",
        groupParameter,
        clickedMeasuringPoint
      );
    }
  };

  return (
    <>
      <GGITreeView
        treeContainerStyle={{
          height: treeItems.length === 0 ? 0 : "calc(100vh - 475px)",
          overflow: "hidden auto",
        }}
        showToggleExpandAll
        isFilterable
        onNodeClick={(event, nodeId) =>
          handleSelectMeasuringPoints(null, nodeId)
        }
        expandOnContentClick
        onFilterTextChange={(event, filterText) =>
          setFilterTextMeasuringPoints(filterText)
        }
        defaultFilterText={filterTextMeasuringPoints}
        defaultExpandedNodeIds={expandedMeasuringPoints}
        onNodeExpand={(event, nodeIds) => {
          setExpandedMeasuringPoints(nodeIds);
        }}
      >
        {treeItems}
      </GGITreeView>
      {treeItems.length === 0 && (
        <Typography>Keine Daten vorhanden.</Typography>
      )}
    </>
  );
});

export default MeasuringPointsSelection;
