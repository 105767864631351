import React from "react";
import { Alert, Snackbar } from "@mui/material";

const TooltipWidget = ({ open, onCloseHandle, textMessage }) => {
  return (
    <Snackbar
      sx={{ bottom: "15%" }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={2000}
      onClose={onCloseHandle}
      // message={textMessage}
    >
      <Alert severity="warning" onClose={onCloseHandle} sx={{ width: "100%" }}>
        {textMessage}
      </Alert>
    </Snackbar>
  );
};

export default TooltipWidget;
