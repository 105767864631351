import UiStore from "./UiStore";
import MapStore from "./MapStore";
import MapScopeThemeStore from "./MapScopeThemeStore";
import WiskiStore from "./WiskiStore";
import RegionPrecipitationStore from "./RegionPrecipitationStore";
import HydroWebStore from "./HydroWebStore";
import { action, makeObservable, observable } from "mobx";
import ClimateStore from "./ClimateStore";
import StatisticStore from "./StatisticStore";

class RootStore {
  apps = ["pikett", "hydroweb", "envis"];
  // proxyURL =  "https://test.giscon.de/Proxy/Proxy.ashx?";
  currentApp = this.apps[2];
  config = {};
  featuresData = {};
  envisFeatures = {};
  envisHydroConfig = {};
  pikettFeatures = {};
  hydroWebFeatures = {};
  appsTimeSeriesConfig = {};
  TimeSeriesIdsWithoutChart = [230, 272]; // Wasserqualität Biologie
  windDirectionGroupParam = "69162,507";
  climateRasterLayersConfig = [];
  hydroFeaturesFilters = [
    // By Timeseries ShortName
    {
      parametertype_name: "Niederschlag",
      parametertype_id: "523",
      ts_shortname: "CmdTotal.P",
    },
  ];

  constructor() {
    this.mapStore = new MapStore(this);
    this.uiStore = new UiStore(this);
    this.mapScopeThemeStore = new MapScopeThemeStore(this);

    makeObservable(this, {
      currentApp: observable,
      setCurrentApp: action,
    });
  }

  setCurrentApp = (newApp) => {
    this.currentApp = newApp;
  };

  initConfig() {
    const promises = [
      this.fetchEnvisHydroConfig(),
      this.fetchEnvisFeaturesConfig(),
      this.fetchClimateRasterLayersConfig(),
    ];

    return Promise.all(promises)
      .then(() => {
        return this.envisFeatures.load(); // needs to be loaded after Zeitreihenconfig is loaded
      })
      .then(() => {
        return this.pikettFeatures.load();
      })
      .then(() => {
        return this.hydroWebFeatures.load();
      })
      .then(() => {
        this.regionPrecipitationStore = new RegionPrecipitationStore(this);
      })
      .then(() => {
        this.hydroWebStore = new HydroWebStore(this);
      })
      .then(() => {
        this.climateStore = new ClimateStore(this);
      })
      .then(() => {
        this.statisticStore = new StatisticStore(this);
      });
  }

  initFeatureStores() {
    // envisFeatures local url: "data/envis.objson",
    // envisFeatures url: "https://test.giscon.de/Proxy/Proxy.ashx?https://www.ag.ch/app/hydrometrie/data/envis/envis.objson",
    this.envisFeatures = new WiskiStore({
      app: this.apps[2], // app: "envis"
      rootStore: this,
      url: this.config.proxyURL + this.config.envisURL,
    });

    // pikettFeatures local url: "data/envis.objson",
    // pikettFeatures url: "https://test.giscon.de/Proxy/Proxy.ashx?https://www.ag.ch/app/hydrometrie/data/envis/envis.objson",
    this.pikettFeatures = new WiskiStore({
      app: this.apps[0], // app: "pikett"
      rootStore: this,
      url: this.config.proxyURL + this.config.envisURL,
    });

    // hydroWebFeatures url: "https://www.ag.ch/app/hydrometrie/kiwis/KiWIS?datasource=0&service=kisters&type=queryServices&request=getTimeseriesValueLayer&format=json&crs=local&timeseriesgroup_id=39016&metadata=true&md_returnfields=custom_attributes%2Cstation_id%2Cstation_no%2Cstation_name%2Cparametertype_id%2Cparametertype_name%2Cts_unitsymbol",
    //hydroWebFeatures  url: "https://test.giscon.de/Proxy/Proxy.ashx?https://www.ag.ch/app/hydrometrie/data/kiwis.objson",
    this.hydroWebFeatures = new WiskiStore({
      app: this.apps[1], // app: "hydroweb"
      rootStore: this,
      url:
        this.config.proxyURL +
        "https://www.ag.ch/app/hydrometrie/data/kiwis.objson",
    });

    // console.log("hydroWebFeatures", this.hydroWebFeatures);
  }

  fetchAppConfig() {
    return new Promise((resolve, reject) => {
      fetch(`config/app.json?t=${Date.now()}`)
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              this.config = json;
              resolve(json);
            });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          throw e;
        });
    });
  }

  fetchEnvisHydroConfig() {
    // fetch(`data/envisHydro.json?t=${Date.now()}`)
    // fetch(
    //   `https://test.giscon.de/Proxy/Proxy.ashx?https://hydroftp.ag.ch/hydrometrie/config/envisHydro.json?t=${Date.now()}`
    // )
    return new Promise((resolve, reject) => {
      fetch(
        this.config.proxyURL + this.config.envisHydroURL + `?t=${Date.now()}`
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              this.envisHydroConfig = json;
              // this.createZeitreihenIndex(json["ZeitreihenConfig"]);
              this.zeitreihenConfig = this.createZeitreihenIndex(
                json["ZeitreihenConfig"]
              );
              this.noChartStationsGroupParams =
                this.setNoChartStaionsGroupParams(this.zeitreihenConfig);
              this.appsTimeSeriesConfig[this.apps[2]] = this.zeitreihenConfig;
              // this.createPikettZeitreihenIndex(json["PikettZeitreihenConfig"]);
              this.pikettZeitreihenConfig = this.createZeitreihenIndex(
                json["PikettZeitreihenConfig"]
              );
              this.appsTimeSeriesConfig[this.apps[0]] =
                this.pikettZeitreihenConfig;
              this.hydroZeitreihenConfig = this.createZeitreihenIndex(
                json["HydroZeitreihenConfig"]
              );
              this.appsTimeSeriesConfig[this.apps[1]] =
                this.hydroZeitreihenConfig;
              // console.log("appsTimeSeriesConfig", this.appsTimeSeriesConfig);
              resolve(false);
            });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          throw e;
        });
    });
  }

  setNoChartStaionsGroupParams(config) {
    return Object.keys(config).filter((key) => {
      return this.TimeSeriesIdsWithoutChart.includes(parseInt(config[key].ID));
    });
  }

  createZeitreihenIndex(config) {
    const index = {};
    for (let i = 0; i < config.length; i++) {
      const item = config[i];
      const { GruppenID, TypID /* ,Farbe */ } = item;
      index[`${GruppenID},${TypID}`] = item;
    }
    // this.zeitreihenConfig = index;
    return index;
  }

  fetchEnvisFeaturesConfig() {
    // fetch(`data/envis.objson?t=${Date.now()}`)
    return new Promise((resolve, reject) => {
      fetch(
        this.config.proxyURL +
          `https://www.ag.ch/app/hydrometrie/data/envis/envis.objson?t=${Date.now()}`
      )
        .then((response) => {
          if (response.ok) {
            // response.json().then((json) => {
            //   this.featuresData = json;
            //   resolve(false);
            // });
            response
              .json()
              .then((json) => {
                this.featuresData = json;
                resolve(false);
              })
              .catch((e) => {
                console.error(">>> fetchEnvisFeaturesConfig ", e);
                this.featuresData = {};
                resolve(false);
              });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          throw e;
        });
    });
  }

  fetchClimateRasterLayersConfig() {
    return new Promise((resolve, reject) => {
      this.fetchClimateRastersLegend()
        .then((rastersLegend) => {
          this.fetchClimateRasterGroups(rastersLegend)
            .then((config) => {
              this.climateRasterLayersConfig = config;
              resolve(false);
            })
            .catch((e) => reject(e));
        })
        .catch((e) => resolve(false));
    });
  }

  fetchClimateRastersLegend() {
    return new Promise((resolve, reject) => {
      fetch(
        this.config.proxyURL +
          this.config.climateScenariosConfigURL +
          "/legend?f=json"
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              const climateRastersLegend = json?.layers?.filter(
                (layer) => layer.layerType === "Raster Layer"
              );
              resolve(climateRastersLegend);
            });
          } else {
            // throw response;
            reject(new Error("Failed to fetch legend information"));
          }
        })
        .catch((e) => {
          throw e;
        });
    });
  }

  getRasterLegendConfigById(rastersLegend, rasterLayerId) {
    const rasterLegendConfig = rastersLegend.find(
      (item) => item.layerId === rasterLayerId
    );
    if (rasterLegendConfig) {
      const { legend, legendGroups } = rasterLegendConfig;
      return { legend, legendGroups };
    } else {
      return undefined;
    }
  }

  adjustRasterLayeName(rasterLayer) {
    if (rasterLayer.name.includes("Δ")) {
      rasterLayer.name += " 'Differenz Zukunftsszenario zu heute'";
    }
  }

  fetchClimateRasterGroups(rastersLegend) {
    return new Promise((resolve, reject) => {
      fetch(
        this.config.proxyURL + this.config.climateScenariosConfigURL + "?f=json"
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              const allServerLayers = json?.layers;
              const rasterLayers = allServerLayers?.filter(
                (layer) => layer.type === "Raster Layer"
              );

              const climateRasterGroups = [];

              rasterLayers?.forEach((rasterLayer) => {
                const rasterLegendConfig = this.getRasterLegendConfigById(
                  rastersLegend,
                  rasterLayer.id
                );

                this.adjustRasterLayeName(rasterLayer);

                const parentLayer = allServerLayers?.find(
                  (layer) => layer.id === rasterLayer.parentLayerId
                );
                if (parentLayer) {
                  const groupLayer = climateRasterGroups.find(
                    (layer) => layer.id === parentLayer.id
                  );

                  const rasterLayerConfig = rasterLegendConfig
                    ? { ...rasterLayer, ...rasterLegendConfig, visible: false }
                    : rasterLayer;
                  if (groupLayer) {
                    groupLayer.subLayers.push(rasterLayerConfig);
                  } else {
                    parentLayer.subLayers = [rasterLayerConfig];
                    climateRasterGroups.push(parentLayer);
                  }
                }
              });
              resolve(climateRasterGroups);
            });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          throw e;
        });
    });
  }

  async refreshFeatures() {
    await this.envisFeatures.refresh();
    await this.pikettFeatures.refresh();
    this.hydroWebFeatures.refresh();
  }
}

export default new RootStore();
