import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const getTitle = (mp) => {
  const { station, station_no } = mp;
  return `${station.station_name} (${station_no})`;
};

const Header = ({ nls, open, setOpen, mp, close }) => {
  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <IconButton
        color="inherit"
        aria-label={open ? nls.closeMenu : nls.openMenu}
        onClick={() => {
          setOpen(!open);
        }}
        style={{ marginRight: 15, flexGrow: 0 }}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        component="div"
        style={{
          fontWeight: "bold",
          fontSize: 18,
          flexGrow: 1,
          cursor: "move",
        }}
        className="dragHandleClass"
      >
        {getTitle(mp)}
      </Typography>
      <IconButton
        color="inherit"
        aria-label={nls.close}
        onClick={() => {
          close();
        }}
        style={{ marginLeft: 15, flexGrow: 0 }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default Header;
