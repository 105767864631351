import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import RootStore from "stores/RootStore";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  datGridContent: {
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 320px)",
    },
    [theme.breakpoints.down("md")]: {
      height: "calc(50vh - 125px)",
    },
    overflow: "hidden auto",
  },
}));

const MeasurementsList = observer((props) => {
  const { measurementsColumns, measurementsRows, zoomToStation } =
    RootStore.hydroWebStore;
  const [pageSize, setPageSize] = useState(10);
  const classes = useStyles();
  // console.log("measurementsRows", measurementsRows);

  return (
    <div
      style={{ marginTop: 18, width: "100%" }}
      className={classes.datGridContent}
    >
      <DataGrid
        className="measurements-list-grid-class"
        sx={{
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "center",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "rgb(138 188 207)", // rgb(95 193 238)
          },
          ".MuiDataGrid-cell": {
            maxHeight: "none !important",
            lineHeight: "16px !important",
            display: "flex !important",
            justifyContent: "center",
            padding: 0,
            paddingRight: 1,
            paddingLeft: 1,
            paddingTop: 0.25,
            cursor: "pointer",
          },
          "& .MuiDataGrid-cellContent": {
            wordBreak: "break-word !important",
            whiteSpace: "break-spaces",
            textAlign: "center",
          },
          "& .MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone":
            {
              maxHeight: "none !important",
            },
          "& .MuiTablePagination-selectLabel": {
            display: "none ! important",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "whitesmoke",
          },
          "& .MuiTablePagination-toolbar": {
            // display: "flex",
            pl: 0,
            pr: 0,
          },
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
          "& .MuiTablePagination-actions": {
            ml: 0,
            textAlign: "center",
          },
          "& .MuiTablePagination-displayedRows": {
            pl: "8px",
            pr: "8px",
          },
          "& .MuiInputBase-root": {
            mr: 1,
          },
          // "& .MuiDataGrid-paper": {
          //   minWidth: "none !important",
          //   width: "auto !important",
          // },
          // "& .MuiDataGrid-filterForm": {
          //   display: "flex !important",
          //   flexDirection: "column !important",
          // },
        }}
        rows={measurementsRows}
        columns={measurementsColumns}
        pageSize={pageSize}
        density={"compact"}
        disableColumnFilter={true}
        onRowClick={(params, event, details) => zoomToStation(params.row)}
        disableSelectionOnClick={true}
        // editMode={"row"}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageSizeChange={(number, detail) => setPageSize(number)}
        disableColumnMenu={false}
        // components={{ Toolbar: GridToolbar }}
        disableDensitySelector={true}
        localeText={{
          columnMenuLabel: "Menü",
          columnMenuShowColumns: "Spalten anzeigen",
          columnMenuFilter: "Filter",
          columnMenuHideColumn: "Ausblenden",
          columnMenuUnsort: "Unsortieren",
          columnMenuSortAsc: "Aufsteigend sortieren",
          columnMenuSortDesc: "Absteigend sortieren",
          columnsPanelTextFieldLabel: "Spalte finden",
          columnsPanelTextFieldPlaceholder: "Spaltentitel",
          columnsPanelDragIconLabel: "Spalte neu anordnen",
          columnsPanelShowAllButton: "Alle zeigen",
          columnsPanelHideAllButton: "Alle ausblenden",
          columnHeaderFiltersTooltipActive: (count) =>
            count !== 1 ? `${count} aktive Filter` : `${count} aktive Filter`,
          columnHeaderFiltersLabel: "Filter anzeigen",
          columnHeaderSortIconLabel: "Sortieren",
          toolbarColumns: "Spalten",
          toolbarColumnsLabel: "Spalten auswählen",
          toolbarDensity: "Dichte",
          toolbarDensityLabel: "Dichte",
          toolbarDensityCompact: "Kompakt",
          toolbarDensityStandard: "Standard",
          toolbarDensityComfortable: "Gemütlich",
          noRowsLabel: "Keine Ergebinsse",
          noResultsOverlayLabel: "Keine Ergebinsse gefunden.",
          errorOverlayDefaultLabel: "Ein Fehler ist aufgetreten.",
          toolbarExport: "Export",
          toolbarExportLabel: "Export",
          toolbarExportCSV: "Als csv herunterladen",
          toolbarExportPrint: "Drucken",
          toolbarFilters: "Filter",
          toolbarFiltersLabel: "Filter zeigen",
          toolbarFiltersTooltipHide: "Filter ausblenden",
          toolbarFiltersTooltipShow: "Filter anzeigen",
          toolbarFiltersTooltipActive: (count) =>
            count !== 1 ? `${count} aktive Filter` : `${count} aktive Filter`,
        }}
        // componentsProps={{ toolbar: { GridCsvExportOptions } }}
      />
    </div>
  );
});

export default MeasurementsList;
