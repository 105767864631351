import {
  Chip,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const ClimateTopicLayers = observer(({ classes, dividerTextAlign }) => {
  const {
    selectedClimateTopic,
    selectedClimateLayerId,
    handleClimateLayerChange,
  } = RootStore.climateStore;

  if (selectedClimateTopic.deactive) {
    return null;
  }

  return (
    <Box id="climate-topic-layers">
      <Divider className={classes.divider} textAlign={dividerTextAlign}>
        {/* <Chip label={`${selectedClimateTopic.name} Szenerien`} /> */}
        <Chip className={classes.chip} label={"Szenerien"} />
      </Divider>
      <RadioGroup
        sx={{ mt: "10px" }}
        // row
        aria-label="climateTopicLayers"
        name="controlled-radio-buttons-group"
        // value={selectedClimateLayerId}
        onChange={(event) => {
          handleClimateLayerChange(Number(event.target.value));

          // const climateImageLayer = new MapImageLayer({
          //   url: RootStore.config.climateScenariosConfigURL, // "https://www.ag.ch/geoportal/rest/services/alg_klimaanalyse_zukunftszenario/MapServer",
          //   title: selectedClimateTopic.subLayers[0].name,
          //   visible: true,
          //   sublayers: [selectedClimateTopic.subLayers[0]],
          // });
          // RootStore.mapStore.map.add(climateImageLayer);
        }}
      >
        {selectedClimateTopic.subLayers.map((layer) => (
          <FormControlLabel
            key={layer.id}
            value={layer.id}
            control={<Radio checked={selectedClimateLayerId === layer.id} />}
            label={layer.name}
          />
        ))}
      </RadioGroup>
    </Box>
  );
});

export default ClimateTopicLayers;
