import { BarDataset } from "components/chart/types/bar";
import { round } from "libs/EnVISUtils";
import { action, makeObservable, observable } from "mobx";
import RootStore from "stores/RootStore";
import { updatePeriodToNextOne } from "utils/ChartUtils";
export class CatchmentAreaFeatureStore {
  // proxy = "https://test.giscon.de/Proxy/Proxy.ashx?";
  constructor(props) {
    const {
      Art = "column",
      Farbe,
      baseUrl,
      catchmentArea,
      catchmentAreasConfig,
      showPoints = false,
    } = props;
    this.Art = Art;
    this.Farbe = Farbe;
    this.baseUrl = baseUrl;
    this.catchmentArea = catchmentArea;
    this.catchmentAreasConfig = catchmentAreasConfig;
    this.Anzeigename = catchmentArea.tab;
    this.Einheit = catchmentArea.catchmentAreaInfo.unit || "mm";
    this.Nachkommastellen = catchmentArea.catchmentAreaInfo.Nachkommastellen
      ? catchmentArea.catchmentAreaInfo.Nachkommastellen
      : 2;
    this.visible = true;
    this.loading = false;
    this.loadingListener = [];
    this.showPoints = showPoints;
    makeObservable(this, {
      loading: observable.ref,
      setLoading: action,
      visible: observable.ref,
      setVisible: action,
    });
    const name = `${this.Anzeigename}`;

    switch (Art) {
      default:
      case "column":
        this.chartData = new BarDataset({
          formatY: (y) => {
            return round(y, this.Nachkommastellen);
          },
          fill: this.Farbe,
          outline: "transparent",
          data: [],
          yAxis: "y",
          strokeWidth: this.Strichstaercke + 1,
          name,
        });
    }
  }

  setVisible(visible) {
    this.visible = visible !== undefined ? visible : this.visible;
    this.chartData?.setVisible(visible !== undefined ? visible : this.visible);
  }

  setChartData(data) {
    if (data.length === 0) {
      updatePeriodToNextOne(
        this.period,
        this.periodsOption,
        this.setSelectedPeriod,
        this.setIsShowingPeriodTooltip
      );
    }
    this.chartData?.setData(data !== undefined ? data : this.data);
  }

  setShowPoints(visible) {
    this.chartData.drawInfo.pointRadius = visible ? 5 : 0;
    this.showPoints = visible;
  }

  updateChartData() {
    const {
      data,
      visible,
      // min: { x: minx, y: miny },
      // max: { x: maxx, y: maxy },
    } = this;
    // console.log(`ChartData ${this.Anzeigename}:`, data, {
    //   min: this.min,
    //   max: this.max,
    // });
    data !== undefined && this.setChartData(data);
    visible !== undefined && this.setVisible(visible);
  }

  setPeriod(
    period,
    reduceInterval,
    noFetch = false,
    periodsOption,
    setSelectedPeriod,
    setIsShowingPeriodTooltip
  ) {
    // HIER ENDE
    console.log("setPeriod");
    this.period = period;
    this.periodsOption = periodsOption;
    this.setSelectedPeriod = setSelectedPeriod;
    this.setIsShowingPeriodTooltip = setIsShowingPeriodTooltip;
    this.reduceInterval = reduceInterval;
    noFetch || this.fetch();
  }

  get url() {
    const { /*feature, app, */ tab, catchmentAreaInfo } = this.catchmentArea;
    const ts_id = catchmentAreaInfo[this.catchmentAreasConfig.Tabs[tab]];
    return (
      RootStore.config.proxyURL +
      this.baseUrl +
      `&request=getTimeseriesValues&format=dajson&dateformat=UNIX&ts_id=${ts_id}` +
      `&metadata=true&md_returnfields=ts_id%2Cparametertype_id%2Cts_unitsymbol` +
      `${this.period ? "&period=" + this.period : ""}`
    );
  }

  setLoading(loading) {
    this.loading = loading;
    this.loadingListener.forEach((listener) => {
      try {
        listener(this.loading, this);
      } catch (e) {
        console.error("Error in loading listener", e);
      }
    });
  }

  applyData(raw = []) {
    let data = [];
    for (let i = 0; i < raw.length; i++) {
      let [x, y] = raw[i];
      if (y !== null) {
        y = round(y, this.Nachkommastellen);
        data.push({ x, y });
      } else {
        console.log(`Value "${i}" is "null"`);
      }
    }

    const reduced = this.reduceValues(data); // does handle ts_value, ts_value2, ts_summe
    this.data = reduced.data;
    this.min = reduced.min;
    this.max = reduced.max;
    this.lastValue = reduced.lastValue;
    this.updateChartData();
    this.onChange && this.onChange();
  }

  reduceValues(raw) {
    const reduceInterval = this.reduceInterval;
    const intervalls = {};
    let min = { x: Infinity, y: Infinity };
    let max = { x: -Infinity, y: -Infinity };
    let lastValue = { x: -Infinity, y: -Infinity };
    let data = [];
    if (reduceInterval) {
      for (let i = 0; i < raw.length; i++) {
        const value = raw[i];
        const index = `${value.x - (value.x % reduceInterval)}`;
        if (!intervalls[index]) intervalls[index] = [];
        intervalls[index].push(value);
      }

      data = Object.keys(intervalls).map((key) => {
        const interval = intervalls[key];
        let x = 0; // arit avg
        let y = 0; // median
        interval.forEach(({ y: ycurr }) => {
          y += Number(ycurr);
        });
        x = Math.round(interval[Math.floor(interval.length / 2)].x);
        if (this.Feld !== "ts_value2" && this.Feld !== "ts_summe") {
          y /= interval.length;
        }

        // otherwise just use the sum
        if (x < min.x) {
          min.x = x;
        }
        if (x > max.x) {
          max.x = x;
          lastValue.x = x;
          lastValue.y = y;
        }
        if (y < min.y) {
          min.y = y;
        }
        if (y > max.y) {
          max.y = y;
        }
        return { x, y };
      });
    } else {
      data = raw.map((value) => {
        const x = Number(value.x);
        const y = Number(value.y);
        if (x < min.x) {
          min.x = x;
        }
        if (x > max.x) {
          max.x = x;
          lastValue.x = x;
          lastValue.y = y;
        }
        if (y < min.y) {
          min.y = y;
        }
        if (y > max.y) {
          max.y = y;
        }
        return { x, y };
      });
    }
    return { data, min, max, lastValue };
  }

  fetch() {
    let aborted = false;
    const abort = () => {
      aborted = true;
    };

    if (this.currentFetch) {
      this.currentFetch.abort();
    }

    this.setLoading(true);
    fetch(this.url)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (!aborted) {
          this.currentFetch = null;
          const [raw] = response;
          if (!this.Einheit) this.Einheit = raw.ts_unitsymbol;
          this.applyData(raw.data);
          /*
            data:[[x,y]]
            parametertype_id: "542"
            ts_id: "40926042"
            rows: "49"
            ts_unitsymbol: "mg/l"
            columns: "Timestamp,Value"
           */
          this.setLoading(false);
        }
      })
      .catch((e) => {
        if (!aborted) {
          this.currentFetch = null;
          this.onError && this.onError(e);
          this.setLoading(false);
          console.error("Error while loading timeseries", e);
        }
      });
    this.currentFetch = { abort };
  }
  abort() {
    if (this.currentFetch) {
      this.currentFetch.abort();
      this.setLoading(false);
      this.currentFetch = null;
    }
  }
}
