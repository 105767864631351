import { Box, Chip, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";
import LayerLegend from "./LayerLegend";

const ClimateLayerLegend = observer(({ classes, dividerTextAlign }) => {
  const { selectedClimateTopic, selectedClimateLayerId } =
    RootStore.climateStore;

  if (!selectedClimateLayerId) {
    return null;
  }

  const selectedClimateLayer = selectedClimateTopic?.subLayers?.find(
    (layer) => layer.id === selectedClimateLayerId
  );

  return (
    <Box id="climate-layer-legend">
      <Divider className={classes.divider} textAlign={dividerTextAlign}>
        <Chip className={classes.chip} label="Legende" />
      </Divider>
      <LayerLegend layer={selectedClimateLayer} />
    </Box>
  );
});

export default ClimateLayerLegend;
