import ReactDOM from "react-dom";
// import { round } from "./utils";
import { round, onClick, changeCursor } from "./EnVISUtils";
import { connectLayerWithStore } from "./StoreLayer";
import {
  extendObservable,
  action,
  makeObservable,
  observable,
  when,
  reaction,
  toJS,
} from "mobx";
import { clusterSym } from "../configs/envisSymbols";
import StoreLayer from "./StoreLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import APIHelper from "./APIHelper";
import RootStore from "stores/RootStore";
import { Chip, Typography } from "@mui/material";
import SimpleTable from "./SimpleTable";
import symbols from "../configs/symbols";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import Polygon from "@arcgis/core/geometry/Polygon";
import Extent from "@arcgis/core/geometry/Extent";
import * as GeometryEngine from "@arcgis/core/geometry/geometryEngine";

// const screenToMapDistance = function (distance) {
//   const { mapStore } = RootStore;
//   const view = mapStore.isThreeDMode ? mapStore.sceneView : mapStore.mapView;
//   const { x, y } = view.center;
//   const screenGeometry = view.toScreen(view.center);

//   screenGeometry.x = screenGeometry.x + distance;
//   screenGeometry.y = screenGeometry.y + distance;

//   const { x: x2, y: y2 } = view.toMap(screenGeometry);

//   const length = Math.sqrt(Math.pow(x2 - x, 2) + Math.pow(y2 - y, 2));
//   return length;
// };
const screenToMapDistance = function (distance, view) {
  if (!view) {
    return 1;
  }
  // console.log(view.resolution);
  // debugger;
  return distance * view.resolution;
};

const isInBuffer = (points, tolerance) => {
  const { x, y } = points[0];
  for (let i = 1; i < points.length; i++) {
    const point = points[i];
    if (
      Math.sqrt(Math.pow(x - point.x, 2) + Math.pow(y - point.y, 2)) > tolerance
    )
      return false;
  }
  return true;
};

export default class EnvisLayer {
  app = "envis";
  constructor({
    tolerance = 5, // 5 meter (map coordinates) tolerance to consider as one point (stations @ same point)
    clusterDistance = 100, // distance for clusterer in px (Screen Coordiantes)
    store,
    highlightSymbol = {
      type: "simple-fill",
      style: "solid",
      color: [0, 0, 255, 0.1],
      outline: {
        type: "simple-line",
        style: "solid",
        color: [0, 0, 255, 0.6],
        width: 3,
      },
    },
  }) {
    const layer = new GraphicsLayer();
    const highlightLayer = new GraphicsLayer();
    const geoRestrictionLayer = new GraphicsLayer();
    this.highlightSymbol = highlightSymbol;
    this.graphics = [];
    connectLayerWithStore(layer, store, (graphics, fields) => {
      this.setFeatures(graphics);
    });

    this.layer = layer;
    this.highlightLayer = highlightLayer;
    this.geoRestrictionLayer = geoRestrictionLayer;
    this.store = store;
    this.tolerance = tolerance;
    this.clusterDistance = clusterDistance;
    this.watcher = [];
    if (store.graphics) {
      this.setFeatures(store.graphics);
    }

    reaction(
      () => {
        return this.store.filters?.geometry;
      },
      () => {
        const geometry = toJS(this.store.filters?.geometry);
        this.showGeoRestriction(geometry);
      }
    );
  }

  showGeoRestriction(geometry) {
    const { map } = this;
    const symbol = {
      color: [0, 0, 0, 0.25],
      outline: {
        color: [57, 248, 248, 1],
        width: 2,
        type: "simple-line",
        style: "solid",
      },
      type: "simple-fill",
      style: "solid",
    };
    this.geoRestrictionLayer.removeAll();
    if (geometry) {
      // const geo = APIHelper.differenceOfGeometries(view.extent, geometry);
      const viewExtent = Polygon.fromExtent(
        new Extent({
          spatialReference: { wkid: 2056 },
          xmin: 2500000,
          ymin: 1100000,
          xmax: 2800000,
          ymax: 1400000,
        })
      );
      const geo = GeometryEngine.difference(viewExtent, geometry);
      const graphic = APIHelper.createGraphic(geo, symbol, {});
      this.geoRestrictionLayer.add(graphic);
      if (this.geoRestriction !== geometry) {
        this.geoRestriction = geometry;
        const extent = APIHelper.getSurroundingExtent(
          APIHelper.createGraphic(geometry, null, {})
        );
        RootStore.mapStore.activeView.center = extent.center;

        // APIHelper.zoomToExtent(map, extent, view, 1.2);
      }
    } else if (this.geoRestriction) {
      // no restriction
      this.geoRestriction = null;
    }
  }

  setFeatures(graphics) {
    // debugger;
    this.layer.removeAll();
    this.graphics = graphics.concat([]);
    this.cluster();
  }

  zoomToStation(station_id) {
    // const station = this.store.indicesFiltered.station[station_no];
    const station = this.store.indicesFiltered.station[station_id];
    const { map } = this;
    const extent = APIHelper.getSurroundingExtent(station.graphic);
    // if (extent) view.center = extent.center;
    // return APIHelper.zoomToExtent(map, extent, view, 1.2);
    if (extent) {
      // for (const view of RootStore.mapStore.views) {
      //   view.goTo(extent, RootStore.mapStore.animationViewPoint);
      // }
      // // RootStore.mapStore.setAppViewPoint(extent);
      RootStore.mapStore.mapView.goTo(
        extent,
        RootStore.mapStore.animationViewPoint
      );
      RootStore.mapStore.sceneView.goTo(
        extent,
        RootStore.mapStore.animationViewPoint
      );
    }
  }

  highlightStation(station_id, highlightTimeout) {
    const station = this.store.indicesFiltered.station[station_id];
    const geometry = APIHelper.getGeometry(station.graphic);
    const highlightGraphic = APIHelper.createGraphic(
      geometry,
      JSON.parse(
        JSON.stringify(
          symbols(APIHelper.version)[geometry.type.toLowerCase()].highlight
        )
      ),
      {}
    );
    this.highlightLayer.add(highlightGraphic);
    const me = this;
    if (highlightTimeout && !isNaN(highlightTimeout)) {
      setTimeout(function () {
        me.highlightLayer.remove(highlightGraphic);
      }, highlightTimeout);
    }
  }

  cluster() {
    const { layer, store, graphics, clusterDistance, tolerance } = this;
    layer.removeAll();
    const clusterer = {};
    // const distance =
    //   clusterDistance == 0 ? 1 : screenToMapDistance(clusterDistance);
    const distance =
      clusterDistance == 0
        ? 1
        : screenToMapDistance(
            clusterDistance,
            RootStore.mapStore.activeView
            // ? RootStore.mapStore.activeView
            // : this.view
          );
    graphics.forEach((graphic) => {
      // cluster graphics => save corresponding arrays from cluster cells in clusterer[.]
      const { num, colors, station_id } = APIHelper.getAttributes(graphic);
      const { x, y } = APIHelper.getGeometry(graphic);
      const index = Math.floor(x / distance) + "_" + Math.floor(y / distance);
      if (!clusterer[index]) clusterer[index] = [];
      clusterer[index].push(graphic);
    });
    Object.keys(clusterer).forEach((key) => {
      // createClusterSymbols
      const clusterCell = clusterer[key];
      const points = clusterCell.map((graphic) =>
        APIHelper.getGeometry(graphic)
      );
      const attributes = clusterCell.map((graphic) =>
        APIHelper.getAttributes(graphic)
      );
      const stations = attributes.map(({ station_id }) => station_id);
      const onlySamePoint = isInBuffer(points, tolerance);

      //calculate center
      const sum = points.reduce(
        ({ x, y }, point) => {
          return { x: x + point.x, y: y + point.y };
        },
        { x: 0, y: 0 }
      );
      const geometry = {
        //Create a point
        type: "point",
        x: sum.x / points.length,
        y: sum.y / points.length,
        spatialReference: {
          wkid: 2056,
        },
      };

      let symbol = null;
      let graphicAttributes = {};

      // decide which symbol and which attributes to use considering the count of the clusterCell and if all points in there are on the same point
      if (clusterCell.length === 1) {
        //when there is only 1 graphic in the clusterCell => just display this one
        const {
          colors,
          num,
          station_id,
          station,
          station_no,
          station_name,
          Datenherr_kurz,
          stations,
        } = attributes[0];
        graphicAttributes = {
          colors,
          num,
          station_id,
          station,
          showTooltip: 1,
          station_no,
          station_name,
          Datenherr_kurz,
          stations,
        };
        symbol = APIHelper.getSymbol(clusterCell[0]);
      } else {
        symbol = {
          url: clusterSym(clusterCell.length),
          type: "picture-marker", // autocasts as new PictureMarkerSymbol()
          width: 40,
          height: 40,
        };

        graphicAttributes = {
          colors: "",
          num: -1,
          station_id: stations.join(","),
          // showTooltip: onlySamePoint ? 1 : 0,
          showTooltip: 0,
          station: "",
        };
      }
      // debugger;
      this.layer.add({ geometry, attributes: graphicAttributes, symbol });
    });
    this.lastClusterer = clusterer;
  }

  onHover(graphic) {
    for (const view of RootStore.mapStore.views) {
      this.highlightLayer.removeAll();
      if (graphic) {
        const attributes = APIHelper.getAttributes(graphic);
        if (attributes.showTooltip) {
          // this.showCursor(graphic);
        } else {
          const geometries = attributes.station_id
            .split(",")
            .map((station_id) => {
              return APIHelper.getGeometry(
                this.store.indicesFiltered.station[station_id].graphic
              );
            });
          const geo = APIHelper.convexHull(geometries)?.[0];
          const graphic = {
            attributes: {},
            geometry: geo,
            symbol: this.highlightSymbol,
          };
          this.highlightLayer.add(graphic);
          // this.showTooltip(null);
        }
      } else {
        // this.showTooltip(null);
      }
    }
  }

  // watchViewExtent = (view) => {
  //   return view.watch("extent", () => {
  //     this.showGeoRestriction(this.geoRestriction);
  //   });
  // };

  watchViewScale = (view) => {
    let timeout = null;
    return view.watch("scale", (scale) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(() => {
        this.cluster();
        timeout = null;
      }, 300);
    });
  };

  pointerMoveEvent = (view) => {
    return view.on("pointer-move", (event) => {
      view.hitTest(event).then((response) => {
        if (response.results.length) {
          const graphic = response.results.filter((result) => {
            // check if the graphic belongs to the layer of interest
            return result.graphic.layer === this.layer;
          })[0]?.graphic;
          changeCursor(graphic, view);
          if (graphic) this.onHover(graphic);
          else {
            this.onHover(null);
          }
        } else {
          this.onHover(null);
        }
      });
    });
  };

  clickEvent = (view) => {
    return view.on("click", (event) => {
      view.hitTest(event).then((response) => {
        if (response.results.length) {
          const graphic = response.results.filter((result) => {
            // check if the graphic belongs to the layer of interest
            return result.graphic.layer === this.layer;
          })[0]?.graphic;
          if (graphic) {
            onClick(graphic, this.store, this.app);
          } else {
            onClick(null);
          }
        } else {
          onClick(null);
        }
      });
    });
  };

  addToMap(map) {
    this.map = map;
    this.watcher?.forEach((watcher) => watcher.remove());
    this.watcher = [];
    RootStore.mapStore.mapView.popup.autoOpenEnabled = false;
    RootStore.mapStore.sceneView.popup.autoOpenEnabled = false;
    // this.watcher.push(this.watchViewExtent(RootStore.mapStore.mapView));
    // this.watcher.push(this.watchViewExtent(RootStore.mapStore.sceneView));
    this.watcher.push(this.watchViewScale(RootStore.mapStore.mapView));
    this.watcher.push(this.watchViewScale(RootStore.mapStore.sceneView));
    this.watcher.push(this.pointerMoveEvent(RootStore.mapStore.mapView));
    this.watcher.push(this.pointerMoveEvent(RootStore.mapStore.sceneView));
    this.watcher.push(this.clickEvent(RootStore.mapStore.mapView));
    this.watcher.push(this.clickEvent(RootStore.mapStore.sceneView));

    APIHelper.addToMap(map, this.geoRestrictionLayer);
    APIHelper.addToMap(map, this.highlightLayer);
    APIHelper.addToMap(map, this.layer);
    this.cluster();
  }

  putLayerOnTop(map) {
    APIHelper.putLayerOnTop(map, this.geoRestrictionLayer);
    APIHelper.putLayerOnTop(map, this.highlightLayer);
    APIHelper.putLayerOnTop(map, this.layer);
  }

  removeFromMap(map) {
    this.watcher?.forEach((watcher) => watcher.remove());
    this.watcher = [];
    APIHelper.removeFromMap(map, this.geoRestrictionLayer);
    APIHelper.removeFromMap(map, this.highlightLayer);
    APIHelper.removeFromMap(map, this.layer);
  }
}

// export default class WiskiLayer extends StoreLayer{
//   constructor(layeroptions, store){
//     const options = {...layeroptions};
//     super(options, store, ClusterLayer);
//   }
//   StoreLayer(layeroptions, store, layerClazz)
// }
