import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import SimpleTable from "libs/SimpleTable";

function Overview({
  station, //={items}
  mp: { station_no, app /*, groupParam = -1*/ },
  tsconfig,
  nls,
  store,
  configStore,
}) {
  const [modalOpened, setModalOpened] = useState(false);
  const fields = [
    { name: "parameter", label: "Parameter", dataPath: "parameter" },
    { name: "value", label: "Wert", dataPath: "value" },
  ];
  const { groupParam, index } = station[0];
  const feature = store.data[groupParam][index];
  const pictureUrl =
    store.pictureUrl +
    feature["station_no"] +
    "_" +
    feature["Datenherr_kurz"] +
    ".jpg";
  const tableData = useMemo(() => {
    const rows = [];
    tsconfig.Spalten.forEach((column) => {
      const {
        anzeigeName,
        type,
        name,
        dateFormat,
        suffix,
        prefix,
        Faktor,
        showInAttributeWindow,
      } = column;
      if (showInAttributeWindow) {
        const row = { parameter: anzeigeName, column, value: null };
        switch (`${type}`.toLocaleLowerCase()) {
          case "string":
            row.value = feature[name];
            break;
          case "number":
            if (Faktor) row.value = `${Number(feature[name]) * Faktor}`;
            else row.value = `${Number(feature[name])}`;
            break;
          case "float":
            row.value = `${parseFloat("" + feature[name])}`;
            break;
          case "date":
            // 1969-01-01 01:00:00.0
            let format = "dd.mm.yyyy";
            switch (dateFormat) {
              case "Y":
                format = "yyyy";
                break;
              default:
            }
            row.value = DateTime.fromFormat(
              feature[name],
              "yyyy-MM-dd HH:mm:ss.S"
            ).toFormat(format);
            break;
          default:
            row.value = " - ";
        }
        if (row.value !== " - " && suffix) {
          row.value = `${row.value}${suffix}`;
        }
        if (row.value !== " - " && prefix) {
          row.value = `${prefix}${row.value}`;
        }
        rows.push(row);
      }
    });
    rows.push({
      parameter: "Koordinaten",
      value: `${Math.round(feature["station_carteasting"])} / ${Math.round(
        feature["station_cartnorthing"]
      )}`,
    });
    rows.push({
      parameter: "Stationsblatt",
      value: "Download",
      url: store.downloadUrl
        .replace("{id}", feature["station_id"])
        .replace("{site}", feature["Datenherr_kurz"])
        .replace("{org}", feature["Org_Einheit_kurz"])
        .replace("{nr}", feature["station_no"]),
      onClick: (row) => {
        window.open(row.url);
      },
    });
    return rows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station_no, tsconfig]);

  useEffect(() => {
    setModalOpened(false);
  }, [station_no, tsconfig]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        overflow: "auto",
        padding: 15,
      }}
    >
      <Typography variant="h5" style={{ marginBottom: 15 }}>
        {nls.overviewHeading}
      </Typography>
      <Divider />
      <Card>
        <CardMedia
          component="img"
          alt="Stationsbild"
          image={pictureUrl}
          onClick={() => setModalOpened(true)}
          style={{ cursor: "pointer" }}
        />
        <CardContent>
          <SimpleTable fields={fields} data={tableData} />
        </CardContent>
      </Card>
      <Modal
        open={modalOpened}
        onClose={() => {
          setModalOpened(false);
        }}
        BackdropProps={{
          style: {
            cursor: "pointer",
          },
        }}
      >
        <Card
          style={{
            // assure that the picture is not rendered taller than the window
            maxWidth: "80%",
            maxHeight: "80%",
            // positioning in middle of window
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
          onClick={() => setModalOpened(false)}
        >
          <CardMedia component="img" alt="Stationsbild" image={pictureUrl} />
        </Card>
      </Modal>
    </div>
  );
}

export default Overview;
