import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useMemo } from "react";
import Graph from "./Graph";
import IconButton from "@mui/material/IconButton";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TimelineIcon from "@mui/icons-material/Timeline";

import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Modal from "@mui/material/Modal";
import DownloadIcon from "@mui/icons-material/Download";
import CachedIcon from "@mui/icons-material/Cached";
import { Popper, Box, Button } from "@mui/material";
import PeriodTooltip from "./PeriodTooltip";
import RootStore from "stores/RootStore";
import { observer } from "mobx-react-lite";

const getPeriods = ({ tsconfig, standardPeriods, feature, periodConfig }) => {
  const tsperiods = tsconfig.periods;
  let periods = standardPeriods;
  if (tsperiods) {
    periods = tsperiods[feature["Datenherr_kurz"]];
    if (!periods) periods = tsperiods["all"];
    if (!periods) periods = tsperiods;
    for (var i = 0; i < periods.length; i++) {
      // TODO: change it in config, like this its not a good practise
      periods[i] = periods[i].replace("j", "y");
    }
  }

  return periods
    .map((p) => {
      //TODO: behandlung fuer pall?? - fehlt auch im alten envis => wenn nicht genutzt raus aus config (bei den 14er chemie dingern steht das drin als 3te option)
      const temp = periodConfig[p];
      return temp && { value: p, label: temp["displayName"] };
    })
    .filter((e) => !!e); // wenn pall behandelt => nicht mehr nötig
};

const PeriodSelect = ({
  nls: { timePeriod },
  value,
  setValue,
  options,
  style = {},
}) => {
  return (
    <Autocomplete
      disablePortal
      options={options}
      getOptionLabel={({ label }) => label}
      value={value}
      style={{ ...style }}
      onChange={(e, value) => {
        if (value) setValue(value);
      }}
      // sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label={timePeriod} />}
      size={"small"}
    />
  );
};

const GraphView = observer(
  ({
    station, //={items} (all items of station)
    mp: { station_no, app /* , groupParam */ },
    item: { groupParam, index }, // item from indices.staton[station_no]
    tsconfig, // zeitreihenconfig
    feature, // wiski feature
    nls,
    store, // RootStore.envisFeatures
    configStore, // RootStore.zeitreihenConfig
    defaultChartConfig: { standardPeriods, periods: periodConfig },
  }) => {
    // console.log(">>>>>>> render GraphView");
    const { isMobileView } = RootStore.uiStore;
    const options = useMemo(() => {
      return getPeriods({ tsconfig, standardPeriods, feature, periodConfig });
    }, [tsconfig, feature, standardPeriods, periodConfig]);

    // const [period, setPeriod] = useState(options?.[0] || "");
    const [fullscreen, setFullscreen] = useState(false);
    const [showPoints, setShowPoints] = useState(false);
    const [legend, setLegend] = useState(null);
    const [anchorElement, setAnchorElement] = useState(null);
    const [download, setDownload] = useState(null);
    const [update, setUpdate] = useState(null);
    const [isShowingPeriodTooltip, setIsShowingPeriodTooltip] = useState(false);
    const handleClosePeriodTooltip = (event, reason) => {
      // if (reason === "clickaway") {
      //   return;
      // }
      setIsShowingPeriodTooltip(false);
    };

    // useEffect(() => {
    //   setPeriod(options?.[0] || "");
    // }, [options]); // only triggered, when other timeseries is selected

    useEffect(() => {
      // console.log({ tsconfig, feature, standardPeriods, periodConfig });
      const options = getPeriods({
        tsconfig,
        standardPeriods,
        feature,
        periodConfig,
      });
      // console.log("useEffect GraphView 01");
      RootStore.mapStore.selectedPeriod = options?.[0] || "";
    }, [tsconfig, feature, standardPeriods, periodConfig]); // only triggered, when other timeseries is selected

    useEffect(() => {
      // console.log("useEffect GraphView 02");
      // otherwise a parameter change with opened Legend is buggy and the Legend will be displayed top left of the window
      setAnchorElement(null);
    }, [tsconfig, feature]);
    const content = (
      <>
        <Popper
          open={anchorElement && legend ? true : false}
          anchorEl={anchorElement}
          placement="bottom-end"
          style={{ zIndex: "9999" }}
        >
          <Box
            sx={{
              border: 1,
              p: 1,
              bgcolor: "background.paper",
              marginTop: "5px",
            }}
          >
            {legend}
          </Box>
        </Popper>
        <div
          style={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            padding: 15,
            display: "flex",
            flexDirection: "column",
            background: "#FFF",
          }}
          onClick={() => {
            setAnchorElement(null);
          }}
        >
          <div
            style={{
              marginBottom: 15,
              flexGrow: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              // variant="h5"
              style={{
                flexGrow: 0,
                fontSize: isMobileView ? "1rem" : "1.5rem",
              }}
            >
              {`${nls.graphViewHeading} ${tsconfig["Anzeigename"]}`}
            </Typography>
            {legend && (
              <IconButton
                title={nls.legend}
                onClick={(e) => {
                  e.stopPropagation();
                  if (anchorElement) {
                    setAnchorElement(null);
                  } else {
                    setAnchorElement(e.target);
                  }
                }}
              >
                <LegendToggleIcon />
              </IconButton>
            )}
          </div>
          <div
            style={{
              paddingBottom: 25,
              flexGrow: 1,
              overflow: "hidden",
            }}
          >
            <Graph
              period={RootStore.mapStore.selectedPeriod?.value}
              periodsOption={options}
              setSelectedPeriod={(value) =>
                (RootStore.mapStore.selectedPeriod = value)
              }
              reduceInterval={
                periodConfig[RootStore.mapStore.selectedPeriod?.value]
                  ?.reduceInterval
              }
              tsconfig={tsconfig}
              feature={feature}
              store={store}
              configStore={configStore}
              title={tsconfig["Anzeigename"]}
              showPoints={showPoints}
              setLegend={setLegend}
              setDownload={setDownload}
              setUpdate={setUpdate}
              nls={nls}
              setIsShowingPeriodTooltip={setIsShowingPeriodTooltip}
            />
          </div>
          <div
            style={{
              flexGrow: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: "#efefef",
              // marginTop: "-20px",
            }}
          >
            <PeriodSelect
              style={{
                width: "75%",
                flexGrow: 0,
                margin: "9px",
                backgroundColor: "white",
              }}
              value={RootStore.mapStore.selectedPeriod}
              setValue={(value) => {
                RootStore.mapStore.selectedPeriod = value;
              }}
              options={options}
              nls={nls}
            />
            <div style={{ display: "inline-flex" }}>
              <Button
                size="small"
                style={{ border: 0, minWidth: isMobileView ? "35px" : "64px" }}
                onClick={() => {
                  setFullscreen(!fullscreen);
                }}
                title={nls.fullscreen}
              >
                {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </Button>
              <Button
                size="small"
                style={{ border: 0, minWidth: isMobileView ? "35px" : "64px" }}
                onClick={() => {
                  setShowPoints(!showPoints);
                }}
                title={showPoints ? nls.hidePoints : nls.showPoints}
              >
                {showPoints ? <ShowChartIcon /> : <TimelineIcon />}
              </Button>
              {download && (
                <Button
                  size="small"
                  style={{
                    border: 0,
                    minWidth: isMobileView ? "35px" : "64px",
                  }}
                  onClick={() => {
                    download();
                  }}
                >
                  <DownloadIcon />
                </Button>
              )}
              {update && (
                <Button
                  size="small"
                  style={{
                    border: 0,
                    minWidth: isMobileView ? "35px" : "64px",
                  }}
                  onClick={() => {
                    update();
                  }}
                >
                  <CachedIcon />
                </Button>
              )}
            </div>
            <PeriodTooltip
              open={isShowingPeriodTooltip}
              onCloseHandle={handleClosePeriodTooltip}
            />
          </div>
        </div>
      </>
    );
    if (fullscreen) {
      return (
        <Modal
          open={fullscreen}
          onBackdropClick={() => {
            setFullscreen(false);
          }}
          onClose={() => {
            setFullscreen(false);
          }}
        >
          <div
            style={{
              overflow: "hidden",
              // padding: 25,
              transform: "translate(-50%,-50%)",
              position: "relative",
              top: "50%",
              left: "50%",
              height: "90%",
              width: "90%",
            }}
          >
            {content}
          </div>
        </Modal>
      );
    } else {
      return content;
    }
  }
);
export default GraphView;
