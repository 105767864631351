import {
  Card,
  CardHeader,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Box,
} from "@mui/material";
import React from "react";
import RootStore from "stores/RootStore";
import { observer } from "mobx-react-lite";

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

const FilterAspects = observer((props) => {
  const { CardContentNoPadding, isAppPikett } = props;

  const { parameterTypes, parameterType, handleChangeParameterType } =
    RootStore.hydroWebStore;

  const { filterAspects, handleSelectedfilters } = RootStore.hydroWebStore;

  return (
    <Card
      id="pikettHydroFilter"
      sx={{
        marginTop: "15px",
        border: "1px solid lightgray",
        color: "dimgray",
      }}
    >
      <CardHeader
        title="Filter"
        sx={{
          padding: "8px",
        }}
      />
      <CardContentNoPadding>
        {filterAspects.map((filterAspect) => {
          return (
            <FormControl
              key={filterAspect.id}
              sx={{ marginBottom: "15px" }}
              fullWidth
              size={"small"}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                {filterAspect.title}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filterAspect.value}
                onChange={(event) =>
                  handleSelectedfilters(event, filterAspect.id)
                }
                input={<OutlinedInput label="Datenherr" />}
                // renderValue={(selected) => selected.join(", ")}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {filterAspect.options.map((name) => (
                  <MenuItem key={name} value={name} sx={{ padding: 0.3 }}>
                    <Checkbox
                      checked={filterAspect.value.indexOf(name) > -1}
                      sx={{ padding: 0 }}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        })}

        {!isAppPikett && (
          <FormControl sx={{ marginBottom: "15px" }} fullWidth size={"small"}>
            <InputLabel id="demo-multiple-checkbox-label">
              Parameter Typ
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={parameterType}
              onChange={handleChangeParameterType}
              input={<OutlinedInput label="Parameter Typ" />}
              // renderValue={(selected) => selected.join(", ")}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {parameterTypes.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.value}
                  sx={{ padding: 0.3 }}
                >
                  <Checkbox
                    checked={parameterType.indexOf(item.value) > -1}
                    sx={{ padding: 0 }}
                  />
                  <ListItemText primary={item.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </CardContentNoPadding>
    </Card>
  );
});

export default FilterAspects;
