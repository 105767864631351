import {
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const pikettAbflussSvg = (isPikettFlagVisible, handlePikettLegend) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="175"
      height="115"
      viewBox="0 0 60 30"
      version="1.1"
      className="pikett-abflusssvg"
      id="svg28-pikett-abfluss"
      style={{
        left: "50%",
        position: "relative",
        marginLeft: "-90px",
      }}
    >
      <defs id="defs10-pikett-abfluss">
        <linearGradient
          id="lgrad-pikett-abfluss"
          x1="0%"
          y1="100%"
          x2="0%"
          y2="0%"
        >
          <stop
            offset="0%"
            style={{ stopColor: "rgb(0,0,0)", stopOpacity: ".5" }}
          />
          <stop
            offset="100%"
            style={{ stopColor: "rgb(200,200,200)", stopOpacity: ".5" }}
          />
        </linearGradient>
        <clipPath id="outercircle-pikett-abfluss">
          <circle cx="15" cy="15" r="14" id="circle2-pikett-abfluss" />
        </clipPath>
        <mask id="innercycle-pikett-abfluss">
          <rect
            x="0"
            y="0"
            width="30"
            height="30"
            id="rect5-pikett-abfluss"
            style={{ fill: "#ffffff" }}
          />
          <circle
            cx="15"
            cy="15"
            r="5"
            id="circle7-pikett-abfluss"
            style={{ fill: "#000000" }}
          />
        </mask>
      </defs>
      <g
        transform="matrix(0.87618916,0,0,0.87618916,17.893105,2.0976769)"
        id="g20-pikett-abfluss"
      >
        <g clipPath="url(#outercircle-pikett-abfluss)" id="g16-pikett-abfluss">
          <rect
            x="0"
            y="0"
            width="30"
            height="30"
            className="blue"
            id="rect14-pikett-abfluss"
            style={{
              fill: "#0000ff",
              cursor: "pointer",
              pointerEvents: "auto",
              opacity: isPikettFlagVisible ? 1 : 0.65,
            }}
            onClick={(e) => {
              handlePikettLegend(e);
            }}
          />
        </g>
        <circle
          cx="15"
          cy="15"
          r="13"
          style={{
            fill: "none",
            stroke: "url(#lgrad-pikett-abfluss)",
            strokeWidth: "3",
          }}
          id="circle18-pikett-abfluss"
        />
      </g>
      <g
        id="g26-pikett-abfluss"
        transform="matrix(1.5005499,0,0,1.5005499,0.55959917,-6.3336171)"
      >
        <text
          x="12"
          y="4"
          id="text-pikett-abfluss"
          style={{ fontSize: "3px", fontFamily: "Calibri, Arial" }}
        >
          Abfluss [m³/s]
        </text>
      </g>
    </svg>
  );
};

const PikettLegend = observer((props) => {
  const { isPikettFlagVisible, handlePikettLegend } = RootStore.hydroWebStore;

  return (
    <Card
      id="pikettHydroLegend"
      sx={{
        border: "1px solid lightgray",
      }}
    >
      <CardHeader
        title="Legende"
        sx={{
          paddingBottom: "0px",
          paddingTop: "5px",
          color: "dimgray",
        }}
      />
      <List
        sx={{
          width: "100%",
          maxWidth: "auto",
          bgcolor: "background.paper",
          paddingTop: "0",
        }}
      >
        <ListItem
          alignItems="center"
          sx={{
            paddingBottom: "0",
            paddingTop: "0",
            justifyContent: "space-around",
          }}
        >
          <ListItemAvatar>
            {pikettAbflussSvg(isPikettFlagVisible, handlePikettLegend)}
          </ListItemAvatar>
        </ListItem>
      </List>
    </Card>
  );
});

export default PikettLegend;
