import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// const options = ["Hervorheben", "Widget öffnen", "Zoomen auf"];
const ITEM_HEIGHT = 48;

const TreeViewItemMenu = ({
  treeItemData,
  treeItemMenuOptions,
  handleMenuOptionsSelection,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const pauseEvent = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e) => {
    pauseEvent(e);
    setAnchorEl(null);
  };

  const handleTreeItemMenuOptionsSelection = (
    e,
    treeItemId,
    treeItemMenuOptions
  ) => {
    pauseEvent(e);
    handleMenuOptionsSelection(e, treeItemId, treeItemMenuOptions);
    setAnchorEl(null);
  };

  if (treeItemMenuOptions.length > 0) {
    return (
      <>
        <IconButton
          sx={{
            float: "right",
          }}
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e) => handleMenuClick(e)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={(e) => handleMenuClose(e)}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {treeItemMenuOptions.map((option) => (
            <MenuItem
              key={option}
              // selected={option === "Hervorheben"}
              onClick={(e) =>
                handleTreeItemMenuOptionsSelection(
                  e,
                  treeItemData.ID,
                  treeItemMenuOptions
                )
              }
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  } else {
    return "";
  }
};

export default TreeViewItemMenu;
