import { Store } from "../libs/StoreLayer";
import {
  action,
  extendObservable,
  makeObservable,
  observable,
  toJS,
  // action,
  // makeObservable,
  // when,
} from "mobx";
// import zeitreihenconfig from "../configs/Zeitreihen"; // TODO später in einen anderen Store und nochmal überdenken ob wir die config genauso behalten wollen
import { stationSym } from "../configs/envisSymbols";
import APIHelper from "libs/APIHelper";
import RootStore from "./RootStore";

const createPointFromFeature = (feature) => {
  return {
    //Create a point
    type: "point",
    x: feature.station_carteasting,
    y: feature.station_cartnorthing,
    spatialReference: {
      wkid: 2056,
    },
  };
};
export default class WiskiStore extends Store {
  constructor({
    app,
    url = "data/envis.objson",
    downloadUrl = "https://hydroftp.ag.ch/hydrometrie/station/station.php?site={site}&org={org}&nr={nr}",
    pictureUrl = "https://hydroftp.ag.ch/hydrometrie/img/",
    rootStore,
  }) {
    super(arguments);
    // window.store = this;
    this.app = app;
    this.dataUrl = url;
    this.downloadUrl = downloadUrl;
    this.pictureUrl = pictureUrl;
    this.rootStore = rootStore;
    this.filters = {
      parameter: null,
      geometry: null,
    };
    this.hydrometrieTSGruppe = 39016;
    this.hydroWebDate = null;
    this.loadingErrorMessage = "";

    makeObservable(this, {
      hydroWebDate: observable,
      loadingErrorMessage: observable,
    });

    extendObservable(this, {
      //loading:false,
      dataFiltered: {},
      dataFilteredJS: {},
      data: {},
      filters: {
        parameter: null,
        geometry: null,
      },
      indices: {},
      indicesFiltered: {},
      measurementsFeatures: [],
    });
    // this.setLoading = action((value)=>{this.loading = !!value;})
    this.indicesToCreate = { station: "station_id" };

    this.filterMap = {
      parameter: this.parameterFilterFunction.bind(this),
      geometry: this.geometryFilterFunction.bind(this),
      default: this.badFilterFunction.bind(this),
    };

    this.fields = [
      // {
      //   name: "ObjectID",
      //   alias: "ObjectID",
      //   type: "oid",
      // },
      {
        name: "colors",
        alias: "colors",
        type: "string",
      },
      // {
      //   name: "x",
      //   alias: "x",
      //   type: "long",
      // },
      // {
      //   name: "y",
      //   alias: "y",
      //   type: "long",
      // },
      {
        // JSON String of station index
        name: "station",
        alias: "station",
        type: "string",
      },
      // },
      {
        // JSON String of station index
        name: "station_no",
        alias: "station_no",
        type: "string",
      },
      {
        // JSON String of station index
        name: "station_id",
        alias: "station_id",
        type: "int",
      },
      {
        // JSON String of station index
        name: "station_name",
        alias: "station_name",
        type: "string",
      },
      {
        // JSON String of station index
        name: "Datenherr_kurz",
        alias: "Datenherr_kurz",
        type: "string",
      },
      // {
      //   // JSON String of station index
      //   name: "timestamp",
      //   alias: "timestamp",
      //   type: "string",
      // },
      // {
      //   // JSON String of station index
      //   name: "ts_value",
      //   alias: "ts_value",
      //   type: "float",
      // },
      {
        // JSON String of station index
        name: "num",
        alias: "num",
        type: "long",
      },
      {
        // JSON String of station index
        name: "showTooltip",
        alias: "showTooltip",
        type: "int",
      },
    ];
    // // this.setGraphics();
    // when(()=>configStore.loaded,()=>{
    //   this.loadWISKIData();
    // });
  }

  parameterFilterFunction(
    feature,
    parameter /*group,parameter*/,
    index /* index in this.data[parameter] */,
    filter
  ) {
    if (this.app === "envis") {
      return toJS(this.filters.parameter).indexOf(parameter) !== -1;
    } else {
      const isFeatureMatchFilters = parameter.every((para) => {
        if (toJS(this.filters.parameter[para]).length > 0) {
          return toJS(this.filters.parameter[para]).includes(feature[para]);
        } else {
          return true;
        }
      });
      return isFeatureMatchFilters;
    }
  }

  geometryFilterFunction(
    feature,
    parameter /*group,parameter*/,
    index /* index in this.data[parameter] */,
    filter
  ) {
    const geometry = toJS(this.filters.geometry);
    const geometries = Array.isArray(geometry) ? geometry : [geometry];

    return APIHelper.insideGeometries(
      createPointFromFeature(feature),
      geometries
    );
  }

  badFilterFunction() {
    // eslint-disable-next-line no-throw-literal
    throw `No Filter declared for ${arguments[3]} in ${this.constructor.name}`;
  }

  setDataLoading = (isLoading) => {
    if (this.rootStore.hydroWebStore && this.app !== "envis") {
      this.rootStore.hydroWebStore.dataLoading = isLoading;
    }
  };

  refresh() {
    return this.load();
  }

  applyHydroFeaturesData(features) {
    let hydroFeatures = {};
    const filteredFeatures = this.filterFeaturesByTSShortName(features);
    hydroFeatures[this.hydrometrieTSGruppe] = filteredFeatures;
    this.applyData(hydroFeatures);
  }

  filterFeaturesByTSShortName(features) {
    const { hydroFeaturesFilters } = this.rootStore;
    const filteredFeatures = [];
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      const featureFilter = hydroFeaturesFilters?.find(
        (item) => item.parametertype_id === feature.parametertype_id
      );
      if (featureFilter) {
        if (feature.ts_shortname === featureFilter.ts_shortname) {
          filteredFeatures.push(feature);
        }
      } else {
        filteredFeatures.push(feature);
      }
    }
    return filteredFeatures;
  }

  load() {
    console.log("%c >>>>> load app", "background:lightblue", this.app);
    this.loadingErrorMessage = "";
    this.setDataLoading(true);
    return new Promise((resolve, reject) => {
      // this.setLoading(true);
      let url = `${this.dataUrl}?t=${Date.now()}`;
      if (this.app === "hydroweb" && this.hydroWebDate) {
        console.log(
          "WiskiStore load: app is hydroweb and the date has been changed"
        );
        const oDate = new Date(this.hydroWebDate);
        const pDate = oDate.toISOString();
        url =
          RootStore.config.proxyURL +
          "https://www.ag.ch/app/hydrometrie/kiwis/KiWIS?datasource=0&service=kisters&type=queryServices&request=getTimeseriesValueLayer&format=objson&crs=local&dateformat=UNIX&metadata=true&timeseriesgroup_id=" +
          this.hydrometrieTSGruppe +
          "&md_returnfields=ca_sta%2Cstation_id%2Cstation_no%2Cstation_name%2Cparametertype_id%2Cparametertype_name%2Cts_unitsymbol" +
          (pDate ? "&date=" + pDate : "") +
          "&nochache=" +
          new Date().getTime() +
          "_" +
          Math.random();
      }
      fetch(url)
        // fetch(`${this.dataUrl}`)
        .then((response) => {
          if (
            response.ok
            //  &&
            // response.headers.get("content-type").includes("json")
          ) {
            // return response.json();
            return response
              .json()
              .then((json) => {
                return json;
              })
              .catch((e) => {
                console.error(e);
                return {};
              });
          } else {
            throw response;
          }
        })
        .then((features) => {
          //this.featuresData = json;
          if (this.app === "hydroweb") {
            this.applyHydroFeaturesData(features);
          } else {
            this.applyData(features);
          }
          this.loadingErrorMessage = "";
          resolve(false);
        })
        .catch((e) => {
          this.loadingErrorMessage = "Fehler beim Abrufen von Daten";
          console.error(this.loadingErrorMessage, e);
          reject(e);
        })
        .finally(() => {
          // this.setLoading(false);
          this.setDataLoading(false);
        });
    });
  }

  // appendFeatureToGroup(group, feature){
  //   if(!group[feature["parametertype_id"]]){
  //     group[feature["parametertype_id"]] = {};
  //   }
  //   if(!group[feature["parametertype_id"]][feature["station_no"]]){
  //     group[feature["parametertype_id"]][feature["station_no"]] = {};
  //   }

  // if(!conf[obj[i]["parametertype_id"]][obj[i]["station_no"]])
  //   conf[obj[i]["parametertype_id"]][obj[i]["station_no"]] = {}
  // conf[obj[i]["parametertype_id"]][obj[i]["station_no"]][obj[i]["Datenherr_kurz"]] = obj[i];
  // }

  // appendFeature(data, indices, group, feature) {
  //   const { parametertype_id, station_no } = feature;
  //   const index = group + "," + parametertype_id;
  //   if (!data[index]) data[index] = [];
  //   data[index].push(feature);
  //   // const dataIndex = data[index].length - 1;
  //   if (!indices["station"][station_no]) indices["station"][station_no] = [];
  //   indices["station"][station_no].push(feature);
  // }

  appendFeature(data, indices, group, feature) {
    if (this.app !== "envis" && feature) {
      this.measurementsFeatures.push(feature);
    }
    const { parametertype_id, station_id } = feature;
    const index = group + "," + parametertype_id;
    if (!data[index]) data[index] = [];
    data[index].push(feature);
    // const dataIndex = data[index].length - 1;

    if (!indices["station"][station_id]) indices["station"][station_id] = [];
    indices["station"][station_id].push(feature);
  }

  createIndex(data, attribute, index) {
    Object.keys(data).forEach((groupParam) => {
      // const [group, param] = groupParam.split(",");
      const groupData = data[groupParam];
      for (let i = 0; i < groupData.length; i++) {
        const feature = groupData[i];
        const attributeValue = feature[attribute];
        if (!index[attributeValue]) index[attributeValue] = [];
        index[attributeValue].push({ groupParam, index: i });
      }
    });
  }

  createIndices(data) {
    const indices = {};
    Object.keys(this.indicesToCreate).forEach((name) => {
      const index = {};
      this.createIndex(data, this.indicesToCreate[name], index);
      indices[name] = index;
    });
    return indices;
  }

  createStationList(stations) {
    let allStationsList = [];
    Object.keys(stations).forEach((station_id) => {
      allStationsList.push(stations[station_id][0]);
    });
    // if (this.app === "hydroweb") console.log(allStationsList);
    return allStationsList;
  }

  applyData(rawData) {
    const data = {};
    const indices = { station: {} };
    // const tsrelation = {};
    const notFound = [];
    this.measurementsFeatures = [];
    this.timeSeriesconfig = this.rootStore.appsTimeSeriesConfig[this.app];
    Object.keys(rawData).forEach(
      action((group) => {
        // data[group] = {};
        for (let i = 0; i < rawData[group].length; i++) {
          const feature = rawData[group][i];
          const groupParam = `${group},${feature["parametertype_id"]}`;
          // const parametertype_id = feature["parametertype_id"];
          if (this.timeSeriesconfig[groupParam]) {
            this.appendFeature(data, indices, group, feature);
          } else {
            if (notFound.indexOf(groupParam) === -1) {
              notFound.push(groupParam);
            }
          }
          // const groupEntry = data[group];
          // if(!groupEntry[feature][feature["parametertype_id"]])
        }
        // console.warn(
        //   "Some WiskiData cannot be displayed, cause there is not corresponding ZeitreihenConfig",
        //   notFound
        // );
        // if(Object.keys(data[group]).length) // no data for group => group not needed - but should not happen
        //   delete data[group];
      })
    );
    console.warn(
      "Some WiskiData cannot be displayed, cause there is not corresponding ZeitreihenConfig",
      notFound
    );
    // this.createIndex(data, "station_no", indices.station);
    this.data = data;
    this.indices = this.createIndices(data);
    this.stationsList = this.createStationList(indices.station);
    // if (this.app === "hydroweb") {
    //   console.log("this.data", toJS(this.data));
    //   console.log("indices", indices);
    //   console.log("this.indices", toJS(this.indices));
    //   console.log("this.measurementsFeatures", this.measurementsFeatures);
    // }
    this.filterData();
    this.createExtraMenuItems();
  }

  getUIText = (name) => {
    const OberflaechenTexte =
      this.rootStore.envisHydroConfig?.OberflaechenTexte;
    if (!OberflaechenTexte) {
      throw new Error("OberflaechenTexte Config noch nicht geladen");
    }
    for (var i = 0; i < OberflaechenTexte.length; i++) {
      if (OberflaechenTexte[i].element === name)
        return OberflaechenTexte[i].beschriftung;
    }
    return name;
  };

  getJahreVonBis = (von, bis, provisorisch) => {
    var jahre = [];
    for (var index = bis; index >= von; index--) {
      var prov = false;
      if (provisorisch) {
        for (var i = 0; i < provisorisch.length; i++) {
          if (provisorisch[i] === index) prov = true;
        }
      }
      if (prov) {
        jahre.push({
          jahr: index,
          string: index + " (provisorisch)",
        });
      } else {
        jahre.push({
          jahr: index,
          string: index + "",
        });
      }
    }
    return jahre;
  };

  getJahreByEintraege = (eintraege, werte, provisorisch) => {
    var jahre = [];
    for (var index = 0; index < eintraege.length; index++) {
      var provString = "";
      if (provisorisch) {
        for (var i = 0; i < provisorisch.length; i++) {
          if (provisorisch[i] === eintraege[index])
            provString = " (provisorisch)";
        }
      }

      jahre.push({
        jahr: werte && werte[index] ? werte[index] : eintraege[index],
        string: eintraege[index] + provString,
      });
    }
    return jahre;
  };

  setSelectEntries = (config, parameterName, station) => {
    // if (station.station_no === "WQ_SP15") {
    //   debugger;
    // }
    let selectEntries;
    if (config["Von_" + parameterName] && config["Bis_" + parameterName]) {
      selectEntries = this.getJahreVonBis(
        config["Von_" + parameterName],
        config["Bis_" + parameterName],
        config["provisorisch_" + parameterName]
      );
    } else if (config["Eintraege_" + parameterName]) {
      selectEntries = this.getJahreByEintraege(
        config["Eintraege_" + parameterName],
        config["Werte_" + parameterName],
        config["provisorisch_" + parameterName]
      );
    } else if (config.Von && config.Bis) {
      selectEntries = this.getJahreVonBis(
        config.Von,
        config.Bis,
        config.provisorisch
      );
    } else if (config.Eintraege) {
      selectEntries = this.getJahreByEintraege(
        config.Eintraege,
        config.Werte,
        config.provisorisch
      );
    }
    return selectEntries;
  };

  setStationStatisticsParams = (station, menuItemData) => {
    if (!menuItemData[station.station_id].downloadParameters) {
      menuItemData[station.station_id].downloadParameters = [];
    }
    if (!menuItemData[station.station_id].groupParameters) {
      menuItemData[station.station_id].groupParameters = [];
    }
    const config = menuItemData[station.station_id].config;
    if (config) {
      if (config.Statistikgruppen) {
        for (let k = 0; k < config["Statistikgruppen"].length; k++) {
          const params = {};
          params.uiName = config["Statistikgruppen"][k].Ueberschrift;
          params.attributes = config["Statistikgruppen"][k].Attribute;
          menuItemData[station.station_id].groupParameters.push(params);
        }
      } else if (config.downloadURL) {
        for (let k = 0; k < config["Parameter"].length; k++) {
          const params = {};
          params.name = config["Parameter"][k];
          params.uiName = this.getUIText(config["Parameter"][k]);
          const selectEntries = this.setSelectEntries(
            config,
            config["Parameter"][k],
            station
          );
          params.selectEntries = selectEntries;
          // params.selectedYear = selectEntries[0]?.string;
          menuItemData[station.station_id].downloadParameters.push(params);
        }
      }
    }
  };

  setStationExtraMenuItemParams = (station, menuItemData) => {
    if (!menuItemData[station.station_id].downloadParameters) {
      menuItemData[station.station_id].downloadParameters = [];
    }
    const config = menuItemData[station.station_id].config;
    if (config) {
      for (let k = 0; k < config["Parameter"].length; k++) {
        const params = {};
        params.name = config["Parameter"][k];
        params.uiName = this.getUIText(config["Parameter"][k]);
        const selectEntries = this.setSelectEntries(
          config,
          config["Parameter"][k],
          station
        );
        params.selectEntries = selectEntries;
        // params.selectedYear = selectEntries[0]?.string;
        menuItemData[station.station_id].downloadParameters.push(params);
      }
    }
  };

  setStationExtraMenuItemConfig = (station, menuItemData, tConf) => {
    for (let k = 0; k < tConf["Stationstypen"].length; k++) {
      const stationType = tConf["Stationstypen"][k];
      if (station.station_no.indexOf(stationType["Prefix"]) === 0) {
        if (stationType["Sites"]) {
          let selected_site = null;
          for (let l = 0; l < stationType["Sites"].length; l++) {
            if (stationType["Sites"][l] === station.Datenherr_kurz) {
              selected_site = stationType["Sites"][l];
              break;
            }
          }
          if (selected_site) {
            menuItemData[station.station_id].config = tConf;
          }
        } else {
          menuItemData[station.station_id].config = tConf;
        }
      }
    }
  };

  createYearbookItems = () => {
    const yearbookConfig = this.rootStore.envisHydroConfig.Jahrbuch;
    const stationYearbook = {};
    this.stationsList.forEach((station) => {
      for (let j = 0; j < yearbookConfig.length; j++) {
        const tConf = yearbookConfig[j];
        if (!stationYearbook[station.station_id]) {
          stationYearbook[station.station_id] = [];
        }
        this.setStationExtraMenuItemConfig(station, stationYearbook, tConf);
      }
      this.setStationExtraMenuItemParams(station, stationYearbook);
    });
    return stationYearbook;
  };

  createDownloadItems = () => {
    const downloadConfig = this.rootStore.envisHydroConfig.Download;
    const stationDownload = {};
    this.stationsList.forEach((station) => {
      for (let j = 0; j < downloadConfig.length; j++) {
        const tConf = downloadConfig[j];
        if (!stationDownload[station.station_id]) {
          stationDownload[station.station_id] = [];
        }
        this.setStationExtraMenuItemConfig(station, stationDownload, tConf);
      }
      this.setStationExtraMenuItemParams(station, stationDownload);
    });
    return stationDownload;
  };

  createStatisticsItems = () => {
    const statisticsConfig = this.rootStore.envisHydroConfig.Statistik;
    const stationStatistics = {};
    this.stationsList.forEach((station) => {
      for (let j = 0; j < statisticsConfig.length; j++) {
        const tConf = statisticsConfig[j];
        if (!stationStatistics[station.station_id]) {
          stationStatistics[station.station_id] = [];
        }
        this.setStationExtraMenuItemConfig(station, stationStatistics, tConf);
      }
      this.setStationStatisticsParams(station, stationStatistics);
    });
    return stationStatistics;
  };

  createExtraMenuItems = () => {
    this.extraMenuItems = {};
    const stationYearbook = this.createYearbookItems();
    const stationDownload = this.createDownloadItems();
    const stationStatistics = this.createStatisticsItems();
    this.stationsList.forEach((station) => {
      if (stationYearbook[station.station_id]?.downloadParameters?.length > 0) {
        if (!this.extraMenuItems[station.station_id]) {
          this.extraMenuItems[station.station_id] = {};
        }
        this.extraMenuItems[station.station_id].Jahrbuch =
          stationYearbook[station.station_id];
      }
      if (stationDownload[station.station_id]?.downloadParameters?.length > 0) {
        if (!this.extraMenuItems[station.station_id]) {
          this.extraMenuItems[station.station_id] = {};
        }
        this.extraMenuItems[station.station_id].Download =
          stationDownload[station.station_id];
      }
      if (
        stationStatistics[station.station_id]?.downloadParameters?.length > 0 ||
        stationStatistics[station.station_id]?.groupParameters?.length > 0
      ) {
        if (!this.extraMenuItems[station.station_id]) {
          this.extraMenuItems[station.station_id] = {};
        }
        this.extraMenuItems[station.station_id].Statistik =
          stationStatistics[station.station_id];
      }
    });
  };

  filterData() {
    const filteredData = {};

    const filters = Object.keys(toJS(this.filters));
    if (
      filters.reduce((prev, current) => {
        return !!toJS(this.filters[prev]) || toJS(this.filters[current]);
      }, false)
    ) {
      // if any filter is active
      Object.keys(this.data).forEach((groupParam) => {
        // if (groupParam === "75673,501") {
        //   debugger;
        // }
        const paramData = this.data[groupParam]; // all Data associated with given Parameter
        for (let i = 0; i < paramData.length; i++) {
          const data = paramData[i];
          //iterate over all data
          let keep = true;
          for (let j = 0; j < filters.length; j++) {
            const filter = filters[j];
            if (toJS(this.filters[filter])) {
              // filter not null
              keep =
                keep &&
                (this.filterMap[filter] || this.filterMap["default"])(
                  data,
                  this.app === "envis"
                    ? groupParam
                    : Object.keys(toJS(this.filters[filter])),
                  i,
                  filter
                );
              if (!keep) break; // once 1 filter filters it out => the others do not have to be checked
            }
          }
          if (keep) {
            if (!filteredData[groupParam]) filteredData[groupParam] = [];
            filteredData[groupParam].push(data);
          }
        }
      });
      this.indicesFiltered = this.createIndices(filteredData);
      this.dataFiltered = filteredData;
      this.dataFilteredJS = toJS(filteredData);
    } else {
      // no filter active
      this.indicesFiltered = this.indices;
      this.dataFiltered = this.data;
      this.dataFilteredJS = toJS(this.data);
    }
    this.calculateGraphics();
    // debugger;
  }

  calculateGraphics() {
    const data = this.dataFiltered;
    const index = this.indicesFiltered.station;
    const graphics = [];
    Object.keys(index).forEach((station_id) => {
      const attributes = { station: JSON.stringify(toJS(index[station_id])) };
      let geometry = null;
      let colors = [];
      const station = index[station_id].map(({ groupParam, index }) => {
        const feature = data[groupParam][index];
        if (!geometry) {
          geometry = createPointFromFeature(feature);
        }
        const tsconfig = this.timeSeriesconfig[groupParam];
        if (!tsconfig.Zusatzreihe || !tsconfig.hideTab)
          colors.push(tsconfig.Farbe);
        return feature;
      });
      if (colors.length) {
        // dont add graphic, if there are only Zusatzreihe or hideTab
        attributes.colors = colors.join(",");
        attributes.num = colors.length;
        attributes.station_id = station_id;
        attributes.station_no = station[0].station_no;
        attributes.station_name = station[0].station_name;
        attributes.Datenherr_kurz = station[0].Datenherr_kurz;
        // attributes.timestamp = station[0].timestamp;
        // attributes.ts_value = station[0].ts_value;
        attributes.showTooltip = 1;
        const graphic = {
          attributes,
          geometry,
          symbol: this.createSymbol(attributes),
        };
        graphics.push(graphic);
        index[station_id].graphic = graphic;
      }
    });
    const recalculatedGraphics = this.recalculateGraphics(graphics);
    if (recalculatedGraphics?.length) {
      this.setGraphics(recalculatedGraphics);
    } else {
      this.setGraphics(graphics);
    }
  }

  findDuplicateCoordinates(graphics) {
    let duplicatesCoordinates = {};
    graphics.forEach((graphic) => {
      const x = graphic.geometry.x;
      const y = graphic.geometry.y;
      const station_id = graphic.attributes.station_id;
      const key = String(x) + "," + String(y);
      const stationsWithSameCoordinates = graphics.filter(
        (graph) =>
          graph.geometry.x === x &&
          graph.geometry.y === y &&
          graph.attributes.station_id !== station_id
      );
      if (stationsWithSameCoordinates.length) {
        if (!duplicatesCoordinates[key]) {
          duplicatesCoordinates[key] = [];
        }
        duplicatesCoordinates[key].push(stationsWithSameCoordinates[0]);
      }
    });
    return duplicatesCoordinates;
  }

  combineGraphics(duplicatesCoordinates) {
    let newGraphics = [];
    let stationsIdOfDuplicatesCoordinates = [];
    Object.keys(duplicatesCoordinates).forEach((key) => {
      const newGraphic = { geometry: duplicatesCoordinates[key][0].geometry };
      const items = duplicatesCoordinates[key];
      let attributes = {
        // station_name: duplicatesCoordinates[key][0].attributes.station_name,
        num: 0,
      };
      let colors = [];
      let station_ids = [];
      let stations = [];
      let station;
      items.forEach((item) => {
        if (!station) {
          station = JSON.parse(item.attributes.station);
        } else {
          JSON.parse(item.attributes.station).forEach((sta) => {
            station.push(sta);
          });
        }
        colors.push(item.attributes.colors.split(","));
        attributes.num += item.attributes.num;
        station_ids.push(item.attributes.station_id);
        stations.push({
          station_name: item.attributes.station_name,
          station_id: item.attributes.station_id,
          station_no: item.attributes.station_no,
          Datenherr_kurz: item.attributes.Datenherr_kurz,
        });
        stationsIdOfDuplicatesCoordinates.push(item.attributes.station_id);
      });
      attributes.colors = colors.join(",");
      attributes.stations = stations;
      attributes.station = JSON.stringify(toJS(station));
      attributes.station_id = station_ids.join(",");
      newGraphic.attributes = attributes;
      newGraphic.symbol =
        this.app === "hydroweb"
          ? this.createSymbolSpecial(attributes)
          : this.createSymbol(attributes);
      newGraphics.push(newGraphic);
    });
    return { newGraphics, stationsIdOfDuplicatesCoordinates };
  }

  recalculateGraphics(graphics) {
    // if (this.app === "hydroweb") {
    const duplicatesCoordinates = this.findDuplicateCoordinates(graphics);
    console.log("duplicatesCoordinates", duplicatesCoordinates);
    const { newGraphics, stationsIdOfDuplicatesCoordinates } =
      this.combineGraphics(duplicatesCoordinates);
    console.log("newGraphics", newGraphics);
    const recalculatedGraphics = graphics.filter(
      (graphic) =>
        !stationsIdOfDuplicatesCoordinates.includes(
          graphic.attributes.station_id
        )
    );
    newGraphics.forEach((graphic) => {
      recalculatedGraphics.push(graphic);
    });
    return recalculatedGraphics;
    // } else {
    //   return null;
    // }
  }

  createSymbol({ station, colors }) {
    const colorArray = colors.split(",");
    // if (this.app === "hydroweb") console.log(colorArray);
    const circle1 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><rect x="0" y="0" width="30" height="30" fill="color1"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';
    const circle2 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><rect x="0" y="0" width="30" height="15" fill="color1"/><rect x="0" y="15" width="30" height="15" fill="color2"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';
    const circle3 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><path d="M 2 7.5 A 15 15 0 0 0 15 30 L 15 15 Z" fill="color1"/><path d="M 28 7.5 A 15 15 0 0 0 2 7.7 L 15 15 Z" fill="color2"/><path d="M 28 7.5 A 15 15 0 0 1 15 30 L 15 15 Z" fill="color3"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';
    const circle4 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><rect x="0" y="0" width="15" height="15" fill="color1"/><rect x="15" y="0" width="15" height="15" fill="color2"/><rect x="15" y="15" width="15" height="15" fill="color3"/><rect x="0" y="15" width="15" height="15" fill="color4"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';

    // const num = colorArray.length;
    /*
      type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
  url: "https://static.arcgis.com/images/Symbols/Shapes/BlackStarLargeB.png",
  width: "64px",
  height: "64px"

     */
    if (this.app === "envis") {
      return {
        url: stationSym(colorArray),
        type: "picture-marker", // autocasts as new PictureMarkerSymbol()
        width: 25,
        height: 40,
        /*
      width="25" height="40" // height, width from svg code
       */
      };
    }

    if (this.app === "pikett") {
      return {
        url:
          "data:image/svg+xml;base64," +
          window.btoa(circle1.replace("color1", colorArray[0])),
        type: "picture-marker", // autocasts as new PictureMarkerSymbol()
        width: 11,
        height: 11,
      };
    }
    if (this.app === "hydroweb") {
      let symbolUrl;
      if (colorArray.length === 1) {
        symbolUrl =
          "data:image/svg+xml;base64," +
          window.btoa(circle1.replace("color1", colorArray[0]));
      } else if (colorArray.length === 2) {
        symbolUrl =
          "data:image/svg+xml;base64," +
          window.btoa(
            circle2
              .replace("color1", colorArray[0])
              .replace("color2", colorArray[1])
          );
      } else if (colorArray.length === 3) {
        symbolUrl =
          "data:image/svg+xml;base64," +
          window.btoa(
            circle3
              .replace("color1", colorArray[0])
              .replace("color2", colorArray[1])
              .replace("color3", colorArray[2])
          );
      } else if (colorArray.length === 4) {
        symbolUrl =
          "data:image/svg+xml;base64," +
          window.btoa(
            circle4
              .replace("color1", colorArray[0])
              .replace("color2", colorArray[1])
              .replace("color3", colorArray[2])
              .replace("color4", colorArray[3])
          );
      }
      return {
        url: symbolUrl,
        type: "picture-marker", // autocasts as new PictureMarkerSymbol()
        width: 14,
        height: 14,
        /*
      width="25" height="40" // height, width from svg code
       */
      };
    }
  }

  createSymbolSpecial({ station, colors }) {
    const colorArray = colors.split(",");
    // if (this.app === "hydroweb") console.log(colorArray);
    const circle1 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><rect x="0" y="0" width="30" height="30" fill="color1"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';
    const circle2 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><rect x="0" y="0" width="30" height="15" fill="color1"/><rect x="0" y="15" width="30" height="15" fill="color2"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';
    const circle3 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><path d="M 2 7.5 A 15 15 0 0 0 15 30 L 15 15 Z" fill="color1"/><path d="M 28 7.5 A 15 15 0 0 0 2 7.7 L 15 15 Z" fill="color2"/><path d="M 28 7.5 A 15 15 0 0 1 15 30 L 15 15 Z" fill="color3"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';
    const circle4 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><rect x="0" y="0" width="15" height="15" fill="color1"/><rect x="15" y="0" width="15" height="15" fill="color2"/><rect x="15" y="15" width="15" height="15" fill="color3"/><rect x="0" y="15" width="15" height="15" fill="color4"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';

    if (this.app === "hydroweb") {
      let symbolUrl;
      if (colorArray.length === 1) {
        symbolUrl =
          "data:image/svg+xml;base64," +
          window.btoa(circle1.replace("color1", colorArray[0]));
      } else if (colorArray.length === 2) {
        symbolUrl =
          "data:image/svg+xml;base64," +
          window.btoa(
            circle2
              .replace("color1", colorArray[0])
              .replace("color2", colorArray[1])
          );
      } else if (colorArray.length === 3) {
        symbolUrl =
          "data:image/svg+xml;base64," +
          window.btoa(
            circle3
              .replace("color1", colorArray[0])
              .replace("color2", colorArray[2])
              .replace("color3", colorArray[1])
          );
      } else if (colorArray.length === 4) {
        symbolUrl =
          "data:image/svg+xml;base64," +
          window.btoa(
            circle4
              .replace("color1", colorArray[0])
              .replace("color2", colorArray[1])
              .replace("color3", colorArray[2])
              .replace("color4", colorArray[3])
          );
      }
      return {
        url: symbolUrl,
        type: "picture-marker", // autocasts as new PictureMarkerSymbol()
        width: 14,
        height: 14,
        /*
      width="25" height="40" // height, width from svg code
       */
      };
    }
  }

  setFilter(filters) {
    this.filters = filters;
    this.filterData();
  }

  canBeFiltered(feature) {
    return !feature.hideTab && !feature.Zusatzreihe;
  }
}
