import { CircularProgress } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { Observer } from "mobx-react";
import { ChartComponent } from "components/chart/Chart";
import RootStore from "stores/RootStore";
import { createLegend } from "utils/ChartUtils";
import { CatchmentAreaChart } from "utils/CatchmentAreaChart";
import { CatchmentAreaFeatureStore } from "utils/CatchmentAreaFeatureStore";
import { observer } from "mobx-react-lite";
import { grpahInfo } from "utils/ChartUtils";

const GraphBody = observer(
  ({
    period,
    periodsOption,
    setSelectedPeriod,
    reduceInterval,
    nls,
    setLegend,
    setDownload,
    setUpdate,
    setIsShowingPeriodTooltip,
    catchmentArea,
    catchmentAreasConfig,
    defaultChartConfig,
  }) => {
    const { /*feature, app,*/ tab, catchmentAreaInfo } = catchmentArea;

    console.log(">>>>>> render GraphBody");
    const ref = useRef();
    const [chart, setChart] = useState(
      new CatchmentAreaChart({
        stores: [],
      })
    );
    const { isMobileView } = RootStore.uiStore;

    useEffect(() => {
      console.log("useEffect GraphBody 01");
      const stores = [
        new CatchmentAreaFeatureStore({
          catchmentArea,
          catchmentAreasConfig,
          baseUrl: defaultChartConfig.kistersDatenUrl,
          Farbe: defaultChartConfig.ezgColor,
        }),
      ];

      const chart = new CatchmentAreaChart({
        stores,
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
        title: tab,
        updateLegend: (ch) => {
          setLegend(createLegend(stores, nls, ch));
        },
      });
      // console.log("CatchmentAreaChart", chart);
      setChart(chart);
      RootStore.mapStore.catchmentAreaChart = chart;
      setLegend(null);
      setDownload(() => {
        return () => chart.download();
      });
      setUpdate(() => {
        return () => {
          chart.update();
        };
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobileView, catchmentAreaInfo.Nr, tab, ref.current]);

    useEffect(() => {
      console.log("useEffect GraphBody 02");
      chart?.setPeriod(
        period,
        reduceInterval,
        false,
        periodsOption,
        setSelectedPeriod,
        setIsShowingPeriodTooltip
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chart, period, reduceInterval]);

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        ref={ref}
      >
        <Observer>
          {() => {
            return chart.loading ? (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
                thickness={5}
              />
            ) : chart?.chart?.datasets[0].data.length > 0 ? (
              <>
                <ChartComponent
                  style={{
                    flexGrow: 1,
                  }}
                  chart={chart.chart}
                />
                <div
                  style={{
                    marginTop: "-30px",
                    textAlign: "center",
                    flexGrow: 0,
                    fontSize: isMobileView ? "14px" : "16px",
                  }}
                >
                  {grpahInfo("enz", chart, catchmentAreaInfo?.Nachkommastellen)}
                </div>
              </>
            ) : (
              "Keine Daten für alle Zeitperiode vorhanden."
            );
          }}
        </Observer>
      </div>
    );
  }
);

export default GraphBody;
