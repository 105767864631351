import {
  Chip,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const PrecipitationDisplayType = observer(({ classes, dividerTextAlign }) => {
  const { displayTypes, selectedDisplayType, handleDisplayTypeChange } =
    RootStore.regionPrecipitationStore;

  return (
    <Box key="precipitation_display_type" id="precipitationDisplayType">
      <Divider className={classes.divider} textAlign={dividerTextAlign}>
        <Chip className={classes.chip} label="Darstellung" />
      </Divider>
      <RadioGroup
        sx={{ mt: "-2px" }}
        row
        aria-label="precipitationDisplayType"
        name="controlled-radio-buttons-group"
        value={selectedDisplayType}
        onChange={handleDisplayTypeChange}
      >
        {displayTypes.map((type) => (
          <FormControlLabel
            key={type}
            value={type}
            control={<Radio />}
            label={type}
          />
        ))}
      </RadioGroup>
    </Box>
  );
});

export default PrecipitationDisplayType;
