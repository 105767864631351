/* eslint-disable no-template-curly-in-string */
import ReactDOM from "react-dom";
import { observable, action, makeObservable } from "mobx";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
// import WMTSLayer from "@arcgis/core/layers/WMTSLayer";
// import WebTileLayer from "@arcgis/core/layers/WebTileLayer";
// import TileInfo from "@arcgis/core/layers/support/TileInfo.js";
// import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer.js";
import TileLayer from "@arcgis/core/layers/TileLayer.js";
import Basemap from "@arcgis/core/Basemap";
import EnvisLayer from "../libs/EnVISLayer";
import MapView from "@arcgis/core/views/MapView";
import SceneView from "@arcgis/core/views/SceneView";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Expand from "@arcgis/core/widgets/Expand";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import LayerList from "@arcgis/core/widgets/LayerList";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import ElevationLayer from "@arcgis/core/layers/ElevationLayer";
import Ground from "@arcgis/core/Ground";
import SceneLayer from "@arcgis/core/layers/SceneLayer";
import WebScene from "@arcgis/core/WebScene";
import esriConfig from "@arcgis/core/config";
import HydroLayer from "libs/HydroLayer";
import Home from "@arcgis/core/widgets/Home";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";
// import CoordinateConversion from "@arcgis/core/widgets/CoordinateConversion";
// import Format from "@arcgis/core/widgets/CoordinateConversion/support/Format";
// import SpatialReference from "@arcgis/core/geometry/SpatialReference";
// import Point from "@arcgis/core/geometry/Point";
// import Conversion from "@arcgis/core/widgets/CoordinateConversion/support/Conversion";
// import APIHelper from "libs/APIHelper";
import {
  formatLV95Coord,
  MapPointCoordinates,
} from "@giscon/map-widgets.map-point-coordinates";

class MapStore {
  // apps = ["pikett", "hydroweb", "envis"];
  // currentApp = this.apps[2];
  mapReady = false;
  container = "map-container";
  map = {};
  mapView = {};
  scene = {};
  sceneView = {};
  mapWidgets = {};
  selectedMeasuringPoint = null;
  isThreeDMode = false;
  isTwoViews = false;
  views = [];
  viewWatchers = [];
  activeView = null;
  // activeView = this.views.length ? this.views[0] : null;
  measuringPointChart = null;
  removedYAxis = null;
  sysnctimeout = 0;

  selectedPeriod = null;
  envisTargetViewPoint = null;
  pikettTargetViewPoint = null;
  hydroTargetViewPoint = null;
  animationViewPoint = {
    animate: true,
    duration: 1750,
    easing: "ease-in-out",
  };
  clickedCatchmentArea = null;

  selectedBasemap = undefined;

  tileBasemapTitles = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      mapReady: observable,
      map: observable,
      mapView: observable,
      scene: observable,
      sceneView: observable,
      isThreeDMode: observable,
      // activeView: observable,
      // currentApp: observable,
      isTwoViews: observable,
      // views: observable,
      selectedPeriod: observable,
      selectedMeasuringPoint: observable.ref,
      clickedCatchmentArea: observable.ref,
      selectedBasemap: observable,
      setMap: action,
      setMapReady: action,
      openMeasuringPointWidget: action,
      setIsThreeDMode: action,
      setIsTwoViews: action,
      setActiveView: action,
      setClickedCatchmentArea: action,
      setSelectedBasemap: action,
      changeActiveBasemap: action,
    });
  }

  setMap = (map) => {
    this.map = map;
  };

  setSelectedBasemap = (basemap) => {
    this.selectedBasemap = basemap;
  };

  setClickedCatchmentArea = (area) => {
    this.clickedCatchmentArea = area;
  };

  putAllLayersOnTop = () => {
    const { currentApp, apps } = this.rootStore;
    if (currentApp === apps[0]) {
      // pikett
      this.pikettLayer.putLayerOnTop(this.map);
    } else if (currentApp === apps[1]) {
      //hydroweb
      this.hydroWebLayer.putLayerOnTop(this.map);
    } else if (currentApp === apps[2]) {
      // envis
      this.layer.putLayerOnTop(this.map);
    }
  };

  createPikettLayer = () => {
    const store = this.rootStore.pikettFeatures;
    // console.log(store);
    this.pikettLayer = new HydroLayer({
      // app: "pikett",
      app: this.rootStore.apps[0],
      store,
    });
  };

  createHydroWebLayer = () => {
    const store = this.rootStore.hydroWebFeatures;
    // console.log(store);
    this.hydroWebLayer = new HydroLayer({
      // app: "hydroweb",
      app: this.rootStore.apps[1],
      store,
    });
  };

  initHomeButton = (view) => {
    let homeWidget = new Home({
      view: view,
    });
    view.ui.add(homeWidget, "top-left");
  };

  initScaleBar = (view) => {
    let scaleBar = new ScaleBar({
      view: view,
      unit: "metric",
    });
    view.ui.add(scaleBar, {
      position: "bottom-left",
    });
  };

  initCoordinatesWidget = (view) => {
    const node = document.createElement("div");
    view.ui.add(node, {
      position: "bottom-left",
    });
    ReactDOM.render(
      <MapPointCoordinates mapView={view} onUserFormat={formatLV95Coord} />,
      node
    );
  };

  // initCoordinateConvertion = (view) => {
  //   const ccWidget = new CoordinateConversion({
  //     view: view,
  //     visibleElements: {
  //       settingsButton: false,
  //       editButton: false,
  //       // expandButton: false,
  //       captureButton: true,
  //     },
  //   });
  //   view.ui.add(ccWidget, "bottom-left");
  //   // Regular expression to find a number
  //   const numberSearchPattern = /-?\d+[\.]?\d*/;
  //   /**
  //    * Create a new Format 'Swiss CH1903+ / LV95'
  //    *
  //    * For this Format, we only need to provide a spatialReference with the correct
  //    * wkid. The geometry service can take care of the rest.
  //    */
  //   const stateplaneCA = new Format({
  //     name: "Swiss CH1903+ / LV95",
  //     conversionInfo: {
  //       spatialReference: new SpatialReference({ wkid: 2056 }),
  //       reverseConvert: function (string, format) {
  //         const parts = string.split(",");
  //         return new Point({
  //           x: parseFloat(parts[0]),
  //           y: parseFloat(parts[1]),
  //           spatialReference: { wkid: 2056 },
  //         });
  //       },
  //     },
  //     coordinateSegments: [
  //       {
  //         alias: "X",
  //         description: "easting",
  //         searchPattern: numberSearchPattern,
  //       },
  //       {
  //         alias: "Y",
  //         description: "northing",
  //         searchPattern: numberSearchPattern,
  //       },
  //     ],
  //     defaultPattern: "X, Y",
  //   });

  //   // Add our new format to the widget's dropdown
  //   ccWidget.formats.add(stateplaneCA);

  //   // Add the custom format to the top of the widget's display
  //   ccWidget.conversions.splice(
  //     0,
  //     0,
  //     new Conversion({
  //       format: stateplaneCA,
  //     })
  //   );
  // };

  setAppViewPoint = (targetViewPoint) => {
    const { currentApp, apps } = this.rootStore;
    if (currentApp === apps[1]) {
      this.hydroTargetViewPoint = targetViewPoint;
    } else if (currentApp === apps[0]) {
      this.pikettTargetViewPoint = targetViewPoint;
    } else if (currentApp === apps[2]) {
      this.envisTargetViewPoint = targetViewPoint;
    }
  };

  initMap = () => {
    return new Promise((resolve) => {
      esriConfig.request.interceptors.push({
        urls: /alg_envis_view\/MapServer\/\d+/,
        after: function (response) {
          response.data.supportedQueryFormats = "JSON";
        },
      });
      const {
        // map: mapConfig,
        scene: sceneConfig,
        // layers: viewLayers,
      } = this.rootStore.config;
      const mapConfig = sceneConfig;
      const viewLayers = {};
      const initialMapViewParams = {
        zoom: mapConfig.zoom - 8,
        center: mapConfig.center,
        container: "map-container",
        constraints: {
          // Disable zoom snapping to get the best synchronization
          snapToZoom: false,
          maxZoom: 10,
        },
      };
      const initialSceneViewParams = {
        container: "scene-container",
      };
      // const viewCenter = new Point({
      //   x: 2647879.1950146137,
      //   y: 1248814.602393067,
      //   spatialReference: 2056
      // });

      // create 2D view
      this.mapView = this.createView(initialMapViewParams, "2d");

      // create 3D view
      this.sceneView = this.createView(initialSceneViewParams, "3d");
      // this.sceneView.center = viewCenter;
      // this.sceneView.zoom = 10;

      this.createMapInstance(mapConfig, sceneConfig, viewLayers);

      this.assignMapToBothViews(this.scene);
      // assignMapToBothViews(this.map);

      // Set Home button
      this.initHomeButton(this.mapView);
      this.initHomeButton(this.sceneView);

      //  Set the coord widget
      this.initCoordinatesWidget(this.mapView);
      this.initCoordinatesWidget(this.sceneView);
      // // Set Coordinate Convertion
      // this.initCoordinateConvertion(this.mapView);
      // this.initCoordinateConvertion(this.sceneView);

      // Set Scale bar (Only works with MapView)
      this.initScaleBar(this.mapView);

      // Set all Views
      this.views = [this.sceneView, this.mapView];

      const basemapsList = this.creatBasemapsList();

      this.mapView.when(() => {
        this.setActiveView(this.mapView);
        this.createEnVISLayerAndAddToMapViewMap();
        this.map.basemap.when(() => {
          this.setLods(this.map);
        });

        this.mapLayerListExpand = this.addLayerListWidget(this.mapView);
        this.mapBasemapGalleryExpand = this.addBasemapWidget(
          this.mapView,
          basemapsList
        );
        this.createPikettLayer();
        this.createHydroWebLayer();
        // this.addClimateGroupLayers(this.map);

        // const swissTopo = new WMTSLayer({
        //   listMode: "hide",
        //   title: "SwissTopo",
        //   url: "https://wmts.geo.admin.ch/EPSG/2056", //"https://wmts100.geo.admin.ch/EPSG/2056",
        //   activeLayer: {
        //     id: "ch.swisstopo.pixelkarte-grau", //"ch.swisstopo.swisstlm3d-karte-farbe.3d", //"ch.swisstopo.pixelkarte-grau",
        //     title: "SwissTopo",
        //   },
        // });

        // const swissTopo = new TileLayer({
        //   url: "https://tiles.arcgis.com/tiles/oPre3pOfRfefL8y0/arcgis/rest/services/Topographic_Map_Switzerland/MapServer",
        //   // fullExtent: {
        //   //   xmin: 2620000,
        //   //   ymin: 1220000,
        //   //   xmax: 2680000,
        //   //   ymax: 1276000,
        //   //   spatialReference: {
        //   //     wkid: 2056,
        //   //     latestWkid: 2056,
        //   //   },
        //   // },
        //   // tileInfo: {
        //   //   // rows: 256,
        //   //   // cols: 256,
        //   //   // width: 256,
        //   //   // height: 256,
        //   //   size: 256,
        //   //   dpi: 96,
        //   //   format: "JPEG",
        //   //   compressionQuality: 75,
        //   //   storageFormat: "esriMapCacheStorageModeCompact",
        //   //   origin: {
        //   //     x: 2620000,
        //   //     y: 1276000,
        //   //   },
        //   //   spatialReference: {
        //   //     wkid: 2056,
        //   //     latestWkid: 2056,
        //   //   },
        //   //   lods: [
        //   //     {
        //   //       level: 0,
        //   //       resolution: 400,
        //   //       scale: 1511808,
        //   //     },
        //   //     {
        //   //       level: 1,
        //   //       resolution: 200,
        //   //       scale: 755904,
        //   //     },
        //   //     {
        //   //       level: 2,
        //   //       resolution: 100,
        //   //       scale: 377952,
        //   //     },
        //   //     {
        //   //       level: 3,
        //   //       resolution: 50,
        //   //       scale: 188976,
        //   //     },
        //   //     {
        //   //       level: 4,
        //   //       resolution: 25,
        //   //       scale: 94488,
        //   //     },
        //   //     {
        //   //       level: 5,
        //   //       resolution: 12.5,
        //   //       scale: 47244,
        //   //     },
        //   //     {
        //   //       level: 6,
        //   //       resolution: 6.25,
        //   //       scale: 23622,
        //   //     },
        //   //     {
        //   //       level: 7,
        //   //       resolution: 3.125,
        //   //       scale: 11811,
        //   //     },
        //   //     {
        //   //       level: 8,
        //   //       resolution: 1.5625,
        //   //       scale: 5905.5,
        //   //     },
        //   //     {
        //   //       level: 9,
        //   //       resolution: 0.78125,
        //   //       scale: 2952.75,
        //   //     },
        //   //   ],
        //   // },
        // });
        // watchUtils.whenTrueOnce(swissTopo, "loaded", () => {
        // });
        // this.map.add(swissTopo);
        // this.map.reorder(swissTopo, 0);
        resolve();
      });

      this.sceneView.when(() => {
        // this.createEnVISLayerAndAddToSceneViewMap();
        this.sceneView.viewpoint = this.mapView.viewpoint;
        this.scene.ground.when(() => {
          this.setLods(this.scene);
        });
        this.sceneLayerListExpand = this.addLayerListWidget(this.sceneView);
        // this.sceneBasemapGalleryExpand = this.addBasemapWidget(
        //   this.sceneView,
        //   basemapsList
        // );
        resolve();
      });

      // this.sceneView.when(() => {
      //   this.viewScale = this.sceneView.scale;
      //   this.createEnVISLayerAndAddToSceneViewMap();
      //   this.mapView.viewpoint = this.sceneView.viewpoint;
      //   // this.sceneView.viewpoint =
      //   //   this.mapView.viewpoint;
      //   this.scene.ground.when(() => {
      //     this.setLods(this.scene);
      //   });
      //   this.sceneLayerListExpand = this.addLayerListWidget(this.sceneView);
      //   this.mapLayerListExpand = this.addLayerListWidget(this.mapView);

      //   this.sceneBasemapGalleryExpand = this.addBasemapWidget(this.sceneView);
      //   this.mapBasemapGalleryExpand = this.addBasemapWidget(this.mapView);
      //   this.createPikettLayer();
      //   this.createHydroWebLayer();

      //   resolve();
      // });
    });
  };

  changeActiveBasemap = () => {
    // Change the active base map to 'empty', only if the basemap is not supported by 3D
    const basemapsList =
      this.mapBasemapGalleryExpand.content.source.basemaps.items;
    // const topMapSwiBasemap = basemapsList.find(
    //   (item) => item.id === "Topographic_Map_Switzerland"
    // );
    const isBasemapSupportedBy3D = this.selectedBasemap?.baseLayers.items.some(
      (layer) => {
        return layer.type !== "tile";
      }
    );

    if (!isBasemapSupportedBy3D) {
      const newBasemap = basemapsList.find((item) => item.id === "empty");
      for (const view of this.views) {
        view.map.basemap = newBasemap;
      }
      this.setSelectedBasemap(newBasemap);
      if (this.sceneBasemapGalleryExpand) {
        this.sceneBasemapGalleryExpand.activeBasemap = newBasemap;
      }
      if (this.mapBasemapGalleryExpand) {
        this.mapBasemapGalleryExpand.activeBasemap = newBasemap;
      }
    }
  };

  createMapInstance = (mapConfig, sceneConfig, viewLayers) => {
    // Create a WebMap instance to be handled as a map for Viewer
    // this.map = new WebMap({
    //   portalItem: {
    //     id: mapConfig.portalItemId,
    //     portal: {
    //       url: mapConfig.portalUrl,
    //     },
    //   },
    // });

    // Create a WebScene instance to be handled as a map for Viewer
    this.scene = new WebScene({
      portalItem: {
        id: sceneConfig.portalItemId,
        portal: {
          url: sceneConfig.portalUrl,
        },
      },
    });
    // this.map = this.scene;
    this.setMap(this.scene);

    // Create a Map instance to be handled as a map for Viewer
    // this.map = new Map({
    //   ground: this.createEnVISGround(viewLayers.ElevationLayers[0]),
    //   layers: [this.createGroupLayer("Klima", viewLayers.ClimateLayers),
    //   this.createGroupLayer("Vegetation Indizes", viewLayers.VegetationIndicesLayers),
    //   this.createSceneLayer(viewLayers.Buildings),
    //   this.createGroupLayer("Bäume", viewLayers.TreeLayers, true),
    //   ]
    // });
  };

  addClimateGroupLayers = (map) => {
    const { climateRasterLayersConfig } = this.rootStore;
    // console.log(">>>>>> climateRasterLayersConfig", climateRasterLayersConfig);

    // const climateLayersGroup = [];
    climateRasterLayersConfig.forEach((climateConfig) => {
      const groupLayer = new GroupLayer({
        title: climateConfig.name,
        visibilityMode: "exclusive",
        visible: false,
        layers: climateConfig.subLayers?.map(
          (layer) =>
            new MapImageLayer({
              url: this.rootStore.config.climateScenariosConfigURL, // "https://www.ag.ch/geoportal/rest/services/alg_klimaanalyse_zukunftszenario/MapServer",
              title: layer.name,
              visible: false,
              sublayers: [layer],
            })
        ),
        opacity: 1,
      });
      // climateLayersGroup.push(groupLayer);
      map.add(groupLayer);
    });

    // console.log("%c climateLayersGroup", "color:blue", climateLayersGroup);
    // const climateGroup = new GroupLayer({
    //   title: "klimaanalyse",
    //   visible: false,
    //   layers: [climateLayersGroup[0]],
    //   opacity: 1,
    // });
    // map.add(climateGroup);
  };

  assignMapToBothViews = (map) => {
    this.mapView.map = map;
    this.sceneView.map = map;
  };

  createEnVISLayerAndAddToSceneViewMap = () => {
    // setTimeout(() => {
    this.createLayer(this.rootStore.envisFeatures);
    this.addLayerToScene();
    this.setMapReady(true);
    // }, 2000);
  };

  createEnVISLayerAndAddToMapViewMap = () => {
    this.createLayer(this.rootStore.envisFeatures);
    this.addLayerToMap();
    this.setMapReady(true);
  };

  getGroupImageLayers = (config) => {
    const layers = [];
    config.forEach((service) => {
      const layer = new MapImageLayer(service);
      layers.push(layer);
    });
    return layers;
  };

  createSceneLayer = (conf) => {
    return new SceneLayer(conf);
  };

  // getGroupSceneLayers = (config) => {
  //   const layers = [];
  //   config.map((service) => {
  //     const layer = this.createSceneLayer(service);
  //     layers.push(layer);
  //   });
  //   return layers;
  // };

  // createGroupLayer = (groupTitle, config, sceneLayer) => {
  //   const groupLayer = new GroupLayer({
  //     title: groupTitle,
  //     visible: true,
  //     visibilityMode: "exclusive",
  //     layers: sceneLayer
  //       ? this.getGroupSceneLayers(config)
  //       : this.getGroupImageLayers(config),
  //     opacity: 1,
  //   });
  //   return groupLayer;
  // };

  createEnVISGround = (config) => {
    const enVISElevationLayer = new ElevationLayer(config);
    const enVISGround = new Ground({
      layers: [enVISElevationLayer],
    });
    return enVISGround;
  };

  setActiveView = (view) => {
    console.log("setActiveView");
    this.activeView = view;
  };

  createView = (params, type) => {
    let view;
    if (type === "2d") {
      view = new MapView(params);
      return view;
    } else {
      view = new SceneView(params);
    }
    return view;
  };

  creatBasemapsList() {
    const { basemapConfig } = this.rootStore.config;
    let basemapsList = [];
    basemapConfig.forEach((config) => {
      let baseLayerList = [];
      config.layers.forEach((lay) => {
        let layer;
        if (lay.type === "WebTiledLayer" || lay?.status === "forbidden") {
          // layer = new WMTSLayer({
          //   url: lay.url,
          //   activeLayer: { id: lay.id, title: config.title },
          // });
        } else if (lay.type === "TiledLayer") {
          layer = new TileLayer({
            url: lay.url,
            title: config.title,
            // lods: customLODs,
          });
          this.tileBasemapTitles.push(config.title);
        } else {
          layer = new MapImageLayer({
            title: config.title,
            url: lay.url,
            opacity: lay.opacity ? lay.opacity : 1,
          });
        }
        if (layer) {
          baseLayerList.push(layer);
        }
      });
      if (baseLayerList.length > 0) {
        const basemap = new Basemap({
          title: config.title,
          thumbnailUrl: config.thumbnailUrl,
          id: config.id,
          baseLayers: baseLayerList,
          spatialReference: {
            wkid: 2056,
          },
        });
        basemapsList.push(basemap);
      }
    });
    return basemapsList;
  }

  addBasemapWidget(view, basemapsList) {
    let activeBasemap = this.selectedBasemap;
    // activeBasemap = basemapsList[basemapsList.length - 1];
    if (view.type === "2d") {
      activeBasemap = basemapsList.find(
        (item) => item.id === "Topographic_Map_Switzerland"
      );
      view.map.basemap = activeBasemap;
      this.setSelectedBasemap(activeBasemap);
    }

    const basemapGallery = new BasemapGallery({
      source: basemapsList,
      activeBasemap: activeBasemap,
      view: view,
    });

    const basemapGalleryExpand = new Expand({
      expandIconClass: "esri-icon-basemap",
      view: view,
      content: basemapGallery,
      mode: "floating",
      autoCollapse: true,
      label: "Grundkarten",
      expandTooltip: "Grundkarten öffnen",
      collapseTooltip: "Grundkarten Liste Ausblenden",
    });
    view.ui.add(basemapGalleryExpand, "top-left");

    watchUtils.watch(basemapGallery, "activeBasemap", () => {
      console.log("-------> activeBasemapcChanged");
      this.setSelectedBasemap(basemapGallery.activeBasemap);
      this.collapseMapBasemapGallery();
      this.collapseSceneBasemapGallery();
      // if (basemapGallery.activeBasemap.id === "Topographic_Map_Switzerland") {
      //   const orthophotoLayer = this.map.allLayers.items.find(
      //     (layer) => layer.title === "ORTHOFOTO" && layer.type === "group"
      //   );
      //   if (orthophotoLayer) {
      //     orthophotoLayer.visible = false;
      //   }
      // }
    });

    // watchUtils.watch(basemapGalleryExpand, "expanded", (isExpanded) => {
    //   this.tileBasemapTitles.forEach((title) => {
    //     const node = document.querySelectorAll(`[title="${title}"]`);
    //     if (view.type === "3d" && isExpanded) {
    //       this.collapseSceneLayersList();
    //       if (node.length > 0) {
    //         // node.style.pointerEvents = "none";
    //         node[0].style.cssText = "pointer-events:none;";
    //       }
    //     }
    //     if (view.type === "2d" && isExpanded) {
    //       this.collapseMapLayersList();
    //       if (node.length > 0) {
    //         if (this.isTwoViews) {
    //           node.forEach((element) => {
    //             element.style.cssText = "pointer-events:none;";
    //           });
    //         } else {
    //           node.forEach((element) => {
    //             element.style.cssText = "pointer-events:auto;";
    //           });
    //         }
    //       }
    //     }
    //   });
    //   // console.log(basemapGalleryExpand);
    // });
    return basemapGalleryExpand;
  }

  addLayerListWidget = (view) => {
    console.log("%c >>> addLayerListWidget", "color:green");
    const layerList = new LayerList({
      view: view,
      listItemCreatedFunction: (event) => {
        const item = event.item;
        this.showLayerLegend(item);
        // view.type === "3d"
        //   ? this.showAll3DLayers(item)
        //   : this.showOnly2DLayers(item);
        this.hideUnnecessaryElements(item);
      },
    });

    const layerListExpand = new Expand({
      id: `LayerListExpand-${view.type}`,
      expandIconClass: "esri-icon-layers",
      view: view,
      content: layerList,
      mode: "floating",
      expandTooltip: "Layers Liste öffnen",
      collapseTooltip: "Layers Liste Ausblenden",
    });

    view.ui.add(layerListExpand, {
      position: "top-left",
    });

    watchUtils.watch(layerListExpand, "expanded", (isExpanded) => {
      if (isExpanded) {
        this.collapseMapBasemapGallery();
        this.collapseSceneBasemapGallery();
      }
      console.log("layerList Expanded");
      watchUtils.whenTrueOnce(layerList, "constructed", () => {
        if (view.type === "2d") {
          this.showOnly2DLayers();
        }
      });
    });
    return layerListExpand;
  };

  showOnly2DLayers = () => {
    console.log("showOnly2DLayers");
    document
      .querySelectorAll(
        "#LayerListExpand-2d_controls_content .esri-layer-list__item-label"
      )
      .forEach((item) => {
        // if (
        //   item["data-item"]?.layer.type === "map-image" ||
        //   item["data-item"]?.layer.type === "tile" ||
        //   this.someLayersTypeMapImage(item["data-item"]?.layer)
        // ) {
        //   if (item.parentNode) {
        //     item.parentNode.parentNode.style.display = "block";
        //   }
        // } else {
        //   item.parentNode.parentNode.style.display = "none";
        // }
        if (
          item["data-item"]?.layer.type === "scene" ||
          item["data-item"]?.layer.type === "integrated-mesh" ||
          this.someLayersTypeFor3D(item["data-item"]?.layer)
        ) {
          if (item.parentNode) {
            item.parentNode.parentNode.style.display = "none";
          }
        } else {
          item.parentNode.parentNode.style.display = "block";
        }
      });
  };

  showLayerLegend = (item) => {
    if (item.layer.type !== "group") {
      item.panel = {
        content: "legend",
        open: false,
      };
    }
  };

  hideUnnecessaryElements = (item) => {
    if (!item.layer.title || !item.layer.type) {
      item.layer.listMode = "hide";
    }
  };
  // addLayerListWidget = (view) => {
  //   const layerList = new LayerList({
  //     view: view,
  //     listItemCreatedFunction: (event) => {
  //       const item = event.item;
  //       this.showLayerLegend(item);
  //       view.type === "3d"
  //         ? this.showAll3DLayers(item)
  //         : this.showOnly2DLayers(item);
  //     },
  //   });
  //   const layerListExpand = new Expand({
  //     expandIconClass: "esri-icon-layers",
  //     view: view,
  //     content: layerList,
  //   });
  //   view.ui.add(layerListExpand, {
  //     position: "top-left",
  //   });
  // };

  // showAll3DLayers = (item) => {
  //   // if (item.layer.type === "graphics") {
  //   if (!item.layer.title || !item.layer.type) {
  //     item.layer.listMode = "hide";
  //   }
  // };
  // showOnly2DLayers = (item) => {
  //   if (
  //     item.layer.type === "map-image" ||
  //     this.someLayersTypeMapImage(item.layer)
  //   ) {
  //     item.layer.listMode = "show";
  //   } else {
  //     item.layer.listMode = "hide";
  //   }
  // };

  someLayersTypeMapImage = (item) => {
    if (item?.type === "group") {
      return item?.layers.items.some((layer) => {
        return layer.type === "map-image";
      });
    } else {
      return false;
    }
  };

  someLayersTypeFor3D = (item) => {
    if (item?.type === "group") {
      return item?.layers.items.some((layer) => {
        return layer.type === "scene" || layer.type === "integrated-mesh";
      });
    } else {
      return false;
    }
  };

  sync = (source) => {
    if (
      !this.activeView ||
      !this.activeView.viewpoint ||
      this.activeView !== source
    ) {
      return;
    }
    if (this.sysnctimeout) {
      clearTimeout(this.sysnctimeout);
      this.sysnctimeout = 0;
    }
    for (const view of this.views) {
      if (view !== this.activeView) {
        this.sysnctimeout = setTimeout(() => {
          view.viewpoint = this.activeView.viewpoint;
        }, 50);
      }
    }
  };

  syncViewPoints = (is3DMode: Boolean) => {
    if (is3DMode) {
      this.sceneView.viewpoint = this.mapView.viewpoint;
      this.setActiveView(this.sceneView);
      // this.handleExpandedWidgets(this.sceneView);
      // this.setAppViewPoint(this.sceneView.viewpoint);
    } else {
      this.mapView.viewpoint = this.sceneView.viewpoint;
      this.setActiveView(this.mapView);
      // this.handleExpandedWidgets(this.mapView);
      // this.setAppViewPoint(this.mapView.viewpoint);
    }
  };

  setIsThreeDMode = (is3DMode) => {
    this.isThreeDMode = is3DMode;
    this.syncViewPoints(is3DMode);
  };

  syncTwoViewsParallel = (isTwoViews: Boolean) => {
    if (isTwoViews) {
      this.viewWatchers?.forEach(action((watcher) => watcher.remove()));
      this.viewWatchers = [];
      this.viewWatchers.push(this.watchViewInteracting(this.mapView));
      this.viewWatchers.push(this.watchViewInteracting(this.sceneView));
      this.viewWatchers.push(this.watchViewViewpoint(this.mapView));
      this.viewWatchers.push(this.watchViewViewpoint(this.sceneView));
    } else {
      this.viewWatchers?.forEach(action((watcher) => watcher.remove()));
      this.viewWatchers = [];
    }
  };

  setIsTwoViews = (isTwoViews) => {
    this.isTwoViews = isTwoViews;
    this.syncTwoViewsParallel(isTwoViews);

    this.handleExpandedWidgets(this.mapView);
    this.handleExpandedWidgets(this.sceneView);
  };

  handleMode = () => {
    this.setIsTwoViews(false);
    this.setIsThreeDMode(!this.isThreeDMode);
    // this.changeActiveBasemap();
  };

  handleSynchronizeMode = () => {
    this.setIsTwoViews(!this.isTwoViews);
    // this.changeActiveBasemap();
  };

  watchViewInteracting = (view) => {
    return view.watch(["interacting", "animation"], () => {
      this.setActiveView(view);
      this.sync(this.activeView);
      // this.setAppViewPoint(this.activeView.viewpoint);
      this.handleExpandedWidgets(view);
    });
  };

  watchViewViewpoint = (view) => {
    return view.watch("viewpoint", () => this.sync(view));
  };

  setLods = (map) => {
    console.log("%c >>>>> setLods ", "background:lightblue");
    // if (view.type === "2d")
    // layers = map.basemap.baseLayers.getItemAt(0);
    // if (view.type === "3d")
    // layer = map.ground.layers.getItemAt(0)
    const layer = map.ground.layers.getItemAt(0);
    watchUtils.whenTrueOnce(layer, "loaded", () => {
      const layersTileInfo = layer.tileInfo;
      // console.log(layersTileInfo);
      this.lods = layersTileInfo.lods.map(({ level, scale, resolution }) => {
        return { level, scale, resolution };
      });
      console.log("%c >>>>> lods ", "background:lightblue", this.lods);
      const lodmap = { scale: {}, level: {} };
      this.lods.forEach((lod) => {
        const { level, scale } = lod;
        lodmap.scale[scale] = lod;
        lodmap.level[level] = lod;
      });
      this.lodmap = lodmap;
      // this.zoomLevel = this.getZoomLevel(this.mapView.scale);
    });
  };

  getZoomLevel(pScale) {
    const lod = this.lodmap.scale[pScale];
    if (lod) {
      return lod.level;
    } else {
      let zoomscale = -1;
      this.lods.forEach(({ scale, level }, i) => {
        if (scale < pScale && scale > zoomscale) {
          zoomscale = scale;
        }
      });

      if (zoomscale === -1) {
        console.warn(`No LOD found for scale ${pScale}`);
        return -1;
      }
      return this.lodmap.scale[zoomscale].level;
      // return Number.isFinite(zoomscale) ? this.lodmap.scale[zoomscale] : 0; // wenn nicht gefunden einfach größter scale
    }
  }

  getZoomScale(level) {
    const lod = this.lodmap.level[level];
    if (lod) {
      return lod.scale;
    } else {
      console.warn(`No LOD found for level ${level}`);
      return undefined;
    }
  }

  collapseSceneLayersList = () => {
    if (this.sceneLayerListExpand?.expanded) {
      this.sceneLayerListExpand.expanded = false;
    }
  };

  collapseMapLayersList = () => {
    if (this.mapLayerListExpand?.expanded) {
      this.mapLayerListExpand.expanded = false;
    }
  };

  collapseMapBasemapGallery = () => {
    if (this.mapBasemapGalleryExpand?.expanded) {
      this.mapBasemapGalleryExpand.expanded = false;
    }
  };

  collapseSceneBasemapGallery = () => {
    if (this.sceneBasemapGalleryExpand?.expanded) {
      this.sceneBasemapGalleryExpand.expanded = false;
    }
  };

  handleExpandedWidgets = (view) => {
    if (view.type === "3d") {
      this.collapseSceneLayersList();
      this.collapseSceneBasemapGallery();
    }

    if (view.type === "2d") {
      this.collapseMapLayersList();
      this.collapseMapBasemapGallery();
    }
  };

  // syncViews = () => {
  //   // let active;
  //   const sync = (source) => {
  //     if (
  //       !this.activeView ||
  //       !this.activeView.viewpoint ||
  //       this.activeView !== source
  //     ) {
  //       return;
  //     }
  //     for (const view of this.views) {
  //       if (view !== this.activeView) {
  //         view.viewpoint = this.activeView.viewpoint;
  //       }
  //     }
  //   };
  //   // let first = false;
  //   for (const view of this.views) {
  //     view.watch(["interacting", "animation"], () => {
  //       this.setActiveView(view);
  //       sync(this.activeView);
  //       this.setAppViewPoint(this.activeView.viewpoint);
  //       this.handleExpandedWidgets(view);
  //     });
  //     // if (!first) {
  //     //   first = view;
  //     // } else {
  //     //   view.viewpoint = first.viewpoint;
  //     // }
  //     view.watch("viewpoint", () => sync(view));
  //     // view.watch("viewpoint", (newValue, oldValue, propertyName, target) => {
  //     // });
  //     view.on("click", (event) => {
  //       this.setActiveView(view);
  //       this.handleExpandedWidgets(view);
  //       // view.hitTest(event).then((response) => {
  //       //   if (response.results.length) {
  //       //     console.log(response.results);
  //       //     const graphic = response.results.filter((result) => {
  //       //       // check if the graphic belongs to the layer of interest
  //       //       console.log(result.graphic.layer);
  //       //       return result.graphic.layer === this.pikettLayer.layer;
  //       //     })[0]?.graphic;
  //       //     if (graphic) this.pikettLayer.onClick(graphic);
  //       //     else {
  //       //       this.pikettLayer.onClick(null);
  //       //     }
  //       //   } else {
  //       //     console.log(
  //       //       "hitTest.response.results.length is ",
  //       //       response.results.length
  //       //     );
  //       //     this.pikettLayer.onClick(null);
  //       //   }
  //       // });
  //     });
  //   }
  // };

  openMeasuringPointWidget(station_no, app, groupParam, station) {
    //example: { station_no: "WQ_CT01", index: -1 }
    this.selectedMeasuringPoint = { station_no, app, groupParam, station };
  }

  setMapReady = (ready) => {
    this.mapReady = ready;
  };

  createLayer(store) {
    this.layer = new EnvisLayer({
      tolerance: 15, // 5 meter (map coordinates) tolerance to consider as one point (stations @ same point)
      clusterDistance: 75, // distance for clusterer in px (Screen Coordiantes)
      store,
    });
  }

  createSceneLayer(store) {
    this.scenelayer = new EnvisLayer({
      tolerance: 15, // 5 meter (map coordinates) tolerance to consider as one point (stations @ same point)
      clusterDistance: 75, // distance for clusterer in px (Screen Coordiantes)
      store,
    });
  }

  addLayerToMap() {
    // const { map, mapView } = this;
    // this.layer.addToMap(map, mapView);
    // // this.sceneView.map.add(this.layer.layer);
    this.layer.addToMap(this.map);
  }

  addLayerToScene() {
    const { sceneView } = this;
    // this.scenelayer.addToMap(sceneView.map, sceneView);
    this.layer.addToMap(sceneView.map, sceneView);
  }

  addFeaturesToMap = () => {
    const graphicsLayer = new GraphicsLayer();
    Object.keys(this.rootStore.featuresData).forEach((key) => {
      const features = this.rootStore.featuresData[key];
      const graphics = features.map((feature) => {
        return new Graphic({
          geometry: this.getPointGeometry(feature),
          symbol: this.getSimpleMarkerSymbol(),
        });
      });
      graphicsLayer.addMany(graphics);
      this.map.add(graphicsLayer);
    });
  };

  getPointGeometry = (feature) => {
    return {
      //Create a point
      type: "point",
      x: feature.station_carteasting,
      y: feature.station_cartnorthing,
      spatialReference: {
        wkid: 2056,
      },
    };
  };

  getSimpleMarkerSymbol = () => {
    return {
      type: "simple-marker",
      color: [226, 119, 40], // Orange
      outline: {
        color: [255, 255, 255], // White
        width: 1,
      },
    };
  };
}

export default MapStore;
