import React, { useEffect } from "react";
import RootStore from "../stores/RootStore";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";
import { updateAppUsingUrlParameter } from "utils/UrlParameterUtils";

const useStyles = makeStyles((theme) => ({
  sceneViewsStyle: {
    float: "left",
    width: (props) =>
      props.isTwoViews ? "50%" : props.is3DMode ? "100%" : "0",
    height: "100%",
    // borderRight: (props) => (props.isTwoViews ? "3px solid" : "0px"),
  },
  mapViewsStyle: {
    float: "left",
    width: (props) =>
      props.isTwoViews ? "50%" : props.is3DMode ? "0" : "100%",
    height: "100%",
  },
  twoViewsdivider: {
    borderColor: "black",
    display: (props) => (props.isTwoViews ? "block" : "none"),
  },
}));

function EsriMap({ className }) {
  const {
    isThreeDMode,
    isTwoViews,
    // syncViews
  } = RootStore.mapStore;
  const classes = useStyles({
    isTwoViews: isTwoViews,
    is3DMode: isThreeDMode,
  });

  useEffect(() => {
    RootStore.mapStore.initMap().then(() => {
      updateAppUsingUrlParameter();
      RootStore.climateStore.addClimateLayersToMap(RootStore.mapStore.map);
    });
    // syncViews();
    for (const view of RootStore.mapStore.views) {
      view.on("click", (event) => {
        RootStore.mapStore.setActiveView(view);
        RootStore.mapStore.handleExpandedWidgets(view);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <div id="scene-container" className={classes.sceneViewsStyle}></div>
      <Divider
        orientation="vertical"
        className={classes.twoViewsdivider}
        variant="middle"
        flexItem
      />
      <div id="map-container" className={classes.mapViewsStyle}></div>
    </div>
  );
}

export default observer(EsriMap);
