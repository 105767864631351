import Dataset from "../Dataset";
import { drawSingleHandlers, drawHandlers } from "../constants";
import { drawLabel } from "../utils";

import * as d3 from "d3";
import { addEvent, getXY } from "../utils";

export class LineDataset extends Dataset {
  constructor(props) {
    const {
      // fill,
      // outline,
      // data = [],
      strokeWidth = 2,
      touchRadius = 10,
      pointRadius = 5,
    } = props;
    super({ ...props, type: "line" });
    this.drawInfo["strokeWidth"] = strokeWidth;
    this.drawInfo["touchRadius"] = touchRadius;
    this.drawInfo["pointRadius"] = pointRadius;
  }
}

export class LineFragmentDataset extends LineDataset {
  constructor({ fill, outline, data = [] } = {}) {
    super({ fill, outline, data, type: "line-fragment" });
  }
}

function drawLineFragmentData({
  item,
  drawInfo,
  // outline: outlineColor,
  // fill: fillColor,
  axisMap,
  svg,
}) {
  const { x, y } = item; // when x is not a range => setup a width and center it
  const { strokeWidth, fill, text } = drawInfo;
  const x1 = axisMap["x"].scale(Array.isArray(x) ? x[0] : x);
  const x2 = axisMap["x"].scale(Array.isArray(x) ? x[1] : x);
  const y1 = axisMap["y"].scale(Array.isArray(y) ? y[0] : y);
  const y2 = axisMap["y"].scale(Array.isArray(y) ? y[1] : y);
  svg
    .append("line")
    .attr("x1", x1)
    .attr("x2", x2)
    .attr("y1", y1)
    .attr("y2", y2)
    .attr("style", `stroke-width:${strokeWidth}; stroke: ${fill}`);

  drawLabel({
    x: [x1, x2],
    y: [y1, y2],
    text: y,
    svg,
    drawInfo: text,
    fill,
  });
}

function drawLineData({ dataset, axisMap, svg }) {
  const { drawInfo, data, xAxis, yAxis } = dataset;
  if (axisMap[yAxis]) {
    const lineGenerator = d3
      .line()
      .x((data) => {
        const { x } = getXY(data);
        return axisMap[xAxis].scale(x);
      })
      .y((data) => {
        const { y } = getXY(data);
        return axisMap[yAxis].scale(y);
      })
      .curve(d3.curveMonotoneX); // interpolation

    // const { x, y } = item; // when x is not a range => setup a width and center it
    const {
      strokeWidth,
      fill,
      // text,
      touchRadius,
      pointRadius,
    } = drawInfo; // TODO: use stroke color => maybe fill for coloring area under the line

    svg
      .append("path")
      .datum(data) // bind data
      .attr(
        "style",
        `stroke-width:${strokeWidth}; stroke: ${fill}; fill: transparent`
      )
      .attr("d", lineGenerator); // createLine

    const g = svg.append("g");
    const touchg = svg.append("g");

    for (let i = 0; i < data.length; i++) {
      const { x, y } = getXY(data[i]);
      const posx = axisMap[xAxis].scale(x);
      const posy = axisMap[yAxis].scale(y);

      const fillElement = (ele) => {
        ele?.setAttribute("style", `stroke:${fill};fill:#bfbfbf7d;`);
      };

      const transparentElement = (ele) => {
        ele?.setAttribute("style", `stroke:transparent;fill:transparent;`);
      };

      let circlePoint;
      if (pointRadius) {
        circlePoint = g
          .append("circle")
          .attr("cx", posx)
          .attr("cy", posy)
          .attr("r", pointRadius)
          .attr(
            "style",
            `stroke-width:1;stroke:${fill};fill:#bfbfbf7d;pointer-events:none`
          );
      }

      if (touchRadius) {
        const circle = touchg
          .append("circle")
          .attr("cx", posx)
          .attr("cy", posy)
          .attr("r", touchRadius)
          .attr("style", `stroke-width:1;stroke:transparent;fill:transparent;`)
          .on("mouseover", function (e) {
            fillElement(e.target);
            transparentElement(circlePoint?._groups[0][0]);
          })
          .on("mouseout", function (e) {
            transparentElement(e.target);
            fillElement(circlePoint?._groups[0][0]);
          })
          .node();
        circle.chartdataset = dataset;
        circle.chartdatasetIndex = i;
        circle.axisMap = axisMap;
        circle.data = { x, y };
        circle.pos = { x: posx, y: posy };
      }
    }

    ["click", "mouseover", "mouseout"].forEach((type) => {
      addEvent(touchg, type);
    });

    // drawLabel({
    //   x: [x1, x2],
    //   y: [y1, y2],
    //   text: y,
    //   svg,
    //   drawInfo: text,
    //   fill,
    // });
  }
}

drawSingleHandlers["line-fragment"] = drawLineFragmentData;
drawHandlers["line"] = drawLineData;
