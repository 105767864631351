import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { round } from "libs/EnVISUtils";
import React from "react";
import RootStore from "stores/RootStore";
import ExpandWidget from "widgets/ExpandWidget/Widget";

const intensityContainerStyle = { marginTop: 1, marginBottom: 1 };
const tableCellStyle = { paddingLeft: 0 };

const calculateIntensity = (value, intervalValue) => {
  const intensityValue = (value / (intervalValue / 1000)) * 10000;
  return round(intensityValue, 2);
};

const IntensityTable = ({ rows, intervalValue }) => {
  const { formulateDateTime } = RootStore.hydroWebStore;
  if (!rows || intervalValue === 0) {
    return null;
  }

  return (
    <Box sx={intensityContainerStyle}>
      <ExpandWidget contentTitle="N-Intensität Tabelle" id="p-intensicty-table">
        <Table size="small" aria-label="a percipitaion intensity table">
          <TableHead>
            <TableRow>
              <TableCell sx={tableCellStyle}>Rang</TableCell>
              <TableCell sx={tableCellStyle}>N (mm)</TableCell>
              <TableCell sx={tableCellStyle}>Datum Zeit</TableCell>
              <TableCell sx={tableCellStyle}>Intensität [(l/s*ha)]</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(
              (row, index) =>
                row.y !== 0 && (
                  <TableRow key={row.x}>
                    <TableCell sx={tableCellStyle}>{index + 1}</TableCell>
                    <TableCell sx={tableCellStyle}>{row.y}</TableCell>
                    <TableCell sx={tableCellStyle}>
                      {formulateDateTime(row.x)}
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      {calculateIntensity(row.y, intervalValue)}
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </ExpandWidget>
    </Box>
  );
};

export default IntensityTable;
