import React from "react";
import { observer } from "mobx-react";
import WidgetHeader from "./WidgetHeader";
import WidgetDragger from "./WidgetDragger";
import WidgetFoldButton from "./WidgetFoldButton";
// import { Drawer } from "@mui/material";

const WidgetContainer = observer(
  ({
    classes,
    widget,
    widgetTitle,
    isWidgetOpen,
    handleOpenCloseWidget,
    widgetAreaWidth,
    minWidgetAreaWidth,
    maxWidgetAreaWidth,
    setWidgetAreaWidth,
  }) => {
    return (
      <div className={classes.widgetContainer}>
        <WidgetFoldButton
          isWidgetOpen={isWidgetOpen}
          handleOpenCloseWidget={handleOpenCloseWidget}
          className={classes.foldButton}
        />

        {/* <Drawer
          className={classes.drawer}
          PaperProps={{
            style: {
              top: "50px",
              width: widgetAreaWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          transitionDuration={0}
          open={isWidgetOpen ? true : false}
        > */}

        <WidgetDragger
          className={classes.dragger}
          minWidgetAreaWidth={minWidgetAreaWidth}
          maxWidgetAreaWidth={maxWidgetAreaWidth}
          setWidgetAreaWidth={setWidgetAreaWidth}
        />
        <div
          style={{
            width: "100%",
            // overflowX: "hidden",
            // overflowY: "auto",
          }}
        >
          <WidgetHeader classes={classes} widgetTitel={widgetTitle} />
          <div className={classes.widgetContent}>{widget}</div>
        </div>
        {/* </Drawer> */}
      </div>
    );
  }
);

export default WidgetContainer;
