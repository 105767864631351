import { Box, Typography } from "@mui/material";
import React from "react";
import TreeViewItemMenu from "./TreeViewItemMenu";

const circle = (colorKey) => (
  <Box
    sx={{
      background: colorKey,
      width: "20px",
      height: "20px",
      borderRadius: "10px",
      display: "inline-block",
      margin: "0px 7px;",
    }}
  >
    &nbsp;
  </Box>
);

export const GGITreeViewItemLabel = ({
  treeItemData,
  children,
  selectedData,
  isParentIndeterminateCallback,
  label,
  colorKey,
  treeItemMenuOptions,
  handleMenuOptionsSelection,
}) => (
  <div style={{ display: "flex", alignItems: "center", minHeight: 35 }}>
    {colorKey && treeItemData[colorKey] && circle(treeItemData[colorKey])}
    <Typography sx={{ flex: 1 }}>{label}</Typography>
    <TreeViewItemMenu
      treeItemData={treeItemData}
      treeItemMenuOptions={treeItemMenuOptions}
      handleMenuOptionsSelection={handleMenuOptionsSelection}
    />
  </div>
);
