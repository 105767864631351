import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import RootStore from "stores/RootStore";

const GraphHeader = ({ nls, legend, anchorElement, setAnchorElement }) => {
  // console.log(">>>>>> render GraphHeader");
  const { isMobileView } = RootStore.uiStore;

  return (
    <div
      style={{
        marginBottom: 15,
        flexGrow: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography
        // variant="h5"
        style={{ flexGrow: 0, fontSize: isMobileView ? "1rem" : "1.5rem" }}
      >
        {`${nls.graphViewHeading}`}
      </Typography>
      {legend && (
        <IconButton
          title={nls.legend}
          onClick={(e) => {
            e.stopPropagation();
            if (anchorElement) {
              setAnchorElement(null);
            } else {
              setAnchorElement(e.target);
            }
          }}
        >
          <LegendToggleIcon />
        </IconButton>
      )}
    </div>
  );
};

export default GraphHeader;
