import APIHelper from "libs/APIHelper";
import ClassBreaksRenderer from "@arcgis/core/renderers/ClassBreaksRenderer";
import ReactDOM from "react-dom";
import Table from "libs/SimpleTable";
import RootStore from "stores/RootStore";
import Feature from "@arcgis/core/widgets/Feature";

export function createRenderer(breaks) {
  let renderer = new ClassBreaksRenderer({
    type: "class-breaks",
    field: "abflussspende",
  });
  breaks.forEach(({ min, max, fill, outline, label }) => {
    renderer.addClassBreakInfo({
      minValue: min,
      maxValue: max,
      symbol: APIHelper.createSimpleFillSym(outline, fill),
      label,
    });
  });

  return renderer;
}

export function formatTime(ptime) {
  var time = new Date(ptime ? ptime : new Date());
  var timespan = 1000 * 60 * 60;
  var month_names = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Okotber",
    "November",
    "Dezember",
  ];
  time = new Date(
    time.getFullYear(),
    time.getMonth(),
    time.getDate(),
    time.getHours()
  );
  var month1 = month_names[time.getMonth()];
  var month1a = time.getMonth() + 1;
  var day1 = time.getDate();
  var year1 = time.getFullYear();
  var hour1 = time.getHours();

  time = new Date(time.getTime() - timespan);
  var month2 = month_names[time.getMonth()];
  var month2a = time.getMonth() + 1;
  var day2 = time.getDate();
  var year2 = time.getFullYear();
  var hour2 = time.getHours();
  if (ptime) {
    if (day1 < 10) day1 = "0" + day1;
    if (month1a < 10) month1a = "0" + month1a;
    return day1 + "." + month1a + "." + year1 + " " + hour1 + ":00";
  }
  var timestring = day2 + "." + month2a + "." + year2 + ", " + hour2 + ":00 - ";

  if (day2 !== day1 || month2 !== month1 || year2 !== year1) {
    timestring += day1 + "." + month1a + "." + year1 + " " + hour1 + ":00";
  } else {
    timestring += hour1 + ":00";
  }

  return timestring;
}

const formatValue = (value) => {
  let formatedValue = null;
  if (value) {
    formatedValue = Math.round(value * 100) / 100;
  }
  return formatedValue;
};

export function createPopupTemplate() {
  return {
    title: "Abflussspende des Gebietes {EZG_NAME}",
    content: function (feature) {
      // debugger;
      const attributes = APIHelper.getAttributes(feature);
      const element = document.createElement("div");
      element.innerHTML = "asdasdasdasd";
      const fields = [
        { dataPath: "key" },
        { label: "Wert", name: "value", dataPath: "value" },
      ];
      const data = [
        {
          key: "Abflussspende",
          value: formatValue(attributes?.abflussspende),
          unit: { value: "l/(s*km²)" },
        },
        { key: "Messzeitpunkt", value: formatTime(attributes.timestamp) },
      ];
      ReactDOM.render(<Table data={data} fields={fields} />, element);
      return (
        element ||
        "Abflussspende: " +
          attributes?.abflussspende +
          " l/(s*km²)<br>Messzeitpunkt: " +
          formatTime(attributes.timestamp)
      );
      // return `Die Abflussspende ist ${attributes?.abflussspende}`;
    },
    outFields: ["abflussspende", "timestamp", "EZG_NAME"],
  };
}
const defaultGraphicTemplate = {
  popupTemplate: {
    content:
      "Bewegen Sie die Maus über eines der dargestellten Einzugsgebiete auf der Karte, um Details anzuzeigen...",
  },
};
export const handleFeatureInfoOnHover = (layer) => {
  //Feature widget
  const feature = new Feature({
    container: "feature-node",
    graphic: defaultGraphicTemplate,
    map: RootStore.mapStore.map,
    spatialReference: RootStore.mapStore.sceneView.spatialReference,
  });
  onHover(RootStore.mapStore.sceneView, layer, feature);
  onHover(RootStore.mapStore.mapView, layer, feature);
};

const onHover = (view, layer, feature) => {
  view.whenLayerView(layer).then((layerView) => {
    // let highlight;
    let prevObjectId;
    view.on("pointer-move", (event) => {
      view.hitTest(event).then((hitTestResult) => {
        const results = hitTestResult.results.filter((result) => {
          //  check if the graphic belongs to the layer of interest and Make sure graphic has a popupTemplate
          return (
            result.graphic.layer === layer && result.graphic.layer.popupTemplate
          );
        });
        const result = results[0];
        if (prevObjectId !== result?.graphic.attributes.ObjectID) {
          // highlight && highlight.remove();
          handleHoverHighlight(result, view);
          // Update the graphic of the Feature widget on pointer-move with the result
          feature.graphic = result?.graphic
            ? result.graphic
            : defaultGraphicTemplate;
          // if (result) {
          //   highlight = layerView.highlight(result.graphic);
          // }
        }
        prevObjectId = result?.graphic.attributes.ObjectID;
      });
    });
  });
};

const handleHoverHighlight = (result, view) => {
  view.graphics.removeAll();
  if (result) {
    const hoverGraphic = result.graphic.clone();
    hoverGraphic.symbol = {
      type: "simple-fill",
      style: "solid",
      color: [255, 165, 0, 0],
      outline: {
        color: "orange",
        width: 2,
      },
    };
    view.graphics.add(hoverGraphic);
  }
};

const showPopup = (result, view) => {
  view.popup.close();
  view.popup.open({
    location: result.mapPoint, //result.graphic.geometry.centroid,
    features: [result.graphic],
  });
};

export const clickEvent = (view, layer) => {
  view.on("click", (event) => {
    view.hitTest(event).then((hitTestResult) => {
      const results = hitTestResult.results.filter((result) => {
        //  check if the graphic belongs to the layer of interest and Make sure graphic has a popupTemplate
        return (
          result.graphic.layer === layer && result.graphic.layer.popupTemplate
        );
      });
      const result = results[0];
      if (result) {
        showPopup(result, view);
      }
    });
  });
};
