import React, { useState, useEffect, useMemo } from "react";

import Content from "./Content";
import Header from "./Header";
import Menu from "./Menu";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import RootStore from "stores/RootStore";
import { toJS } from "mobx";

const sideMenuWidth = 250;

const getStore = (app) => {
  switch (app) {
    default:
    case "envis":
      return RootStore.envisFeatures;
    case "pikett":
      return RootStore.pikettFeatures;
    case "hydroweb":
      return RootStore.hydroWebFeatures;
  }
};
const getConfigStore = (app) => {
  switch (app) {
    default:
    case "envis":
      return RootStore.zeitreihenConfig;
    case "pikett":
      return RootStore.pikettZeitreihenConfig;
    case "hydroweb":
      return RootStore.hydroZeitreihenConfig;
  }
};

export default function Station({
  measuringPoint: mp,
  setMP = () => {
    console.warn("needs to be a valid prop");
  },
  close,
  nls = {
    openMenu: "Menü öffnen",
    closeMenu: "Menü schließen",
    close: "schließen",
    stationInfo: "Stationsinfo",
    menu: "Menü",
    graphViewHeading: "Parameter",
    overviewHeading: "Stationsinfos",
    timePeriod: "Zeitraum",
    hidePoints: "Punkte ausblenden",
    showPoints: "Punkte anzeigen",
    fullscreen: "Vollbild",
    noLegend: "Aktuell keine Legende vorhanden",
    legend: "Legende",
  },
  defaultChartConfig = RootStore.config.defaultChartConfig,
}) {
  const theme = useTheme();
  const { main: mainColor, contrastText } = theme.palette.primary;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [mp]);

  // const { station_no, app /*, groupParam*/ } = mp;
  const { station_no, app, /*, groupParam*/ station } = mp;
  const store = useMemo(() => {
    return getStore(app);
  }, [app]);

  const configStore = useMemo(() => {
    return getConfigStore(app);
  }, [app]);

  const items = toJS(store.indices.station[station.station_id]).filter(
    ({ groupParam }) => {
      return !configStore[groupParam].hideTab;
    }
  );

  const extraMenuItems = toJS(store.extraMenuItems[station.station_id]);

  const openTab = (groupParam) => {
    setMP({ station_no, app, groupParam, station });
  };

  return (
    <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      {/* <CssBaseline /> */}
      <div
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "row",
          flexGrow: 0,
          flexShrink: 0,
          height: 35,
          width: "100%",
          padding: 5,
          boxSizing: "border-box",
          background: mainColor,
          color: contrastText,
          // cursor: "move",
        }}
        // className="dragHandleClass"
      >
        <Header
          nls={nls}
          setOpen={(val) => {
            setOpen(val);
          }}
          open={open}
          close={close}
          mp={mp}
        />
      </div>
      <div
        onClick={(e) => setOpen(false)}
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          width: "100%",
          padding: 5,
          boxSizing: "border-box",
        }}
      >
        <Menu
          openTab={openTab}
          open={open}
          items={items}
          nls={nls}
          width={sideMenuWidth}
          configStore={configStore}
          mp={mp}
          extraMenuItems={extraMenuItems}
        />
        <Content
          mp={mp}
          nls={nls}
          style={{ height: "100%", width: "100%" }}
          store={store}
          configStore={configStore}
          defaultChartConfig={defaultChartConfig}
          extraMenuItems={extraMenuItems}
        />
      </div>
    </Box>
  );
}
