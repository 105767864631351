import React from "react";
import StatisticsInterval from "./StatisticsInterval";
import { intervalOptions } from "../config";
import UpdateDiagramSizeButton from "./UpdateDiagramSizeButton";

const actionsContainerStyle = {
  justifyContent: "center",
  display: "flex",
  marginTop: "10px",
};

const updateDiagramSize = ({ newWidth, diagram, redraw }) => {
  if (newWidth && diagram.chart && newWidth !== diagram.width) {
    diagram.width = newWidth;
    diagram.chart.width = newWidth;
    redraw && diagram.chart?.draw();
  }
};

const StationStatisticActionsContainer = React.forwardRef((props, ref) => {
  const { selectedInterval, setSelectedInterval, diagram } = props;

  return (
    <div style={actionsContainerStyle} id="statistic-actions-container">
      <UpdateDiagramSizeButton
        onClick={() =>
          updateDiagramSize({
            newWidth: ref.current?.offsetWidth,
            diagram,
            redraw: true,
          })
        }
      />

      <StatisticsInterval
        value={selectedInterval}
        handleChangeValue={(value) => {
          setSelectedInterval(value);
          console.log(value);
          updateDiagramSize({
            newWidth: ref.current?.offsetWidth,
            diagram,
            redraw: false,
          });
          diagram.updateByInterval(value.reduceInterval);
        }}
        options={intervalOptions}
      />
    </div>
  );
});

export default StationStatisticActionsContainer;
