import Point from "@arcgis/core/geometry/Point";
import { observable, action, makeObservable } from "mobx";

class StatisticStore {
  noTopic = {
    ID: 9999,
    Anzeigename: "Kein Thema",
    deactive: true,
  };

  statisticTopicsConfig = [];

  selectedStatisticTopic = null;

  selectedStatStation = null;

  statisticDateRange = [null, null];

  startTimeHour = "00";
  startTimeMinutes = "01";
  endTimeHour = "23";
  endTimeMinutes = "59";

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      selectedStatisticTopic: observable,
      selectedStatStation: observable,
      statisticDateRange: observable,
      setSelectedStatisticTopic: action,
      setSelectedStatStation: action,
      handleStatisticTopicChange: action,
      setStatisticDateRange: action,
    });
    this.initStore();
  }

  adjustStartDateTime = (startDate) => {
    startDate.setHours(this.startTimeHour);
    startDate.setMinutes(this.startTimeMinutes);
  };

  adjustEndDateTime = (endDate) => {
    endDate.setHours(this.endTimeHour);
    endDate.setMinutes(this.endTimeMinutes);
  };

  adjustDateTime = (newRange) => {
    newRange[0] && this.adjustStartDateTime(newRange[0]);
    newRange[1] && this.adjustEndDateTime(newRange[1]);
  };

  compareNames = (a, b) => {
    if (a.station_name < b.station_name) return -1;
    if (a.station_name > b.station_name) return 1;
    return 0;
  };

  initStore = () => {
    const { hydroWebFeatures } = this.rootStore;
    const hydroWebTimeSeriesconfig = hydroWebFeatures?.timeSeriesconfig;
    const hydrowebTopics = Object.keys(hydroWebTimeSeriesconfig).map(
      (key) => hydroWebTimeSeriesconfig[key]
    );
    // console.log("%c  hydrowebTopics ", "background: lightblue", hydrowebTopics);

    this.statisticTopicsConfig = [this.noTopic];
    hydroWebFeatures?.measurementsFeatures
      .sort(this.compareNames)
      .forEach((feature) => {
        const featureTopic = hydrowebTopics.find(
          (topic) => topic.TypID === feature.parametertype_id
        );

        if (featureTopic) {
          const statisticTopic = this.statisticTopicsConfig.find(
            (config) => config.ID === featureTopic.ID
          );

          if (statisticTopic) {
            statisticTopic.features.push(feature);
          } else {
            featureTopic.features = [feature];
            this.statisticTopicsConfig.push(featureTopic);
          }
        }
      });
    // console.log(
    //   "%c statisticTopicConfig ",
    //   "background: lightgreen",
    //   this.statisticTopicsConfig
    // );
  };

  setStatisticDateRange = (newRange) => {
    this.statisticDateRange = newRange;
  };

  setSelectedStatisticTopic = (newTopic) => {
    this.selectedStatisticTopic = newTopic;
  };

  setSelectedStatStation = (station) => {
    this.selectedStatStation = station;
  };

  handleStatisticTopicChange = (newTopic) => {
    if (
      !this.selectedStatisticTopic ||
      newTopic.ID !== this.selectedStatisticTopic.ID
    ) {
      this.setSelectedStatisticTopic(newTopic);
      this.setSelectedStatStation(null);
    }
  };

  handleStationChange = (newStation) => {
    this.setSelectedStatStation(newStation);
    this.zoomToStation(newStation);
  };

  zoomToStation = (selectedStation) => {
    const centerPoint = new Point(
      selectedStation?.station_carteasting,
      selectedStation?.station_cartnorthing,
      this.rootStore.mapStore.map.spatialReference
    );
    const targetViewPoint = { center: centerPoint, scale: 4000 };
    this.rootStore.mapStore.mapView.goTo(
      targetViewPoint,
      this.rootStore.mapStore.animationViewPoint
    );
    this.rootStore.mapStore.sceneView.goTo(
      targetViewPoint,
      this.rootStore.mapStore.animationViewPoint
    );
  };

  maxMinAvg = (dataArray) => {
    let max = dataArray[0][1];
    let min = dataArray[0][1];
    let sum = 0;
    for (let i = 1; i < dataArray.length; i++) {
      if (dataArray[i][1] > max) {
        max = dataArray[i][1];
      }
      if (dataArray[i][1] < min) {
        min = dataArray[i][1];
      }
      sum = sum + dataArray[i][1];
    }
    return [max, min, sum / dataArray.length];
  };

  isRangeValid = (dateRange) => {
    return (
      !dateRange.some((item) => item === null) && dateRange[0] < dateRange[1]
    );
  };
}

export default StatisticStore;
