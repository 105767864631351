import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tabPanelContent: {
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 175px)",
    },
    [theme.breakpoints.down("md")]: {
      height: "calc(50vh - 100px)",
    },
    overflow: "hidden auto",
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      className={classes.tabPanelContent}
    >
      {value === index && <Box sx={{ p: "2px" }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const TabWidget = ({ children, tabValue, onTabSelect }) => {
  const handleChange = (event, newValue) => {
    onTabSelect(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          id="tab-widget-tabs"
          value={tabValue}
          onChange={handleChange}
          aria-label="Widget tabs"
          variant="fullWidth"
        >
          {children.map((child, index) => (
            <Tab
              key={child.props.tabTitle}
              label={child.props.tabTitle}
              icon={child.props.icon}
              {...a11yProps(child.props.id)}
            />
          ))}
        </Tabs>
      </Box>
      {children.map((child, index) => (
        <TabPanel key={child.props.tabTitle} value={tabValue} index={index}>
          {child.props.children}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabWidget;
