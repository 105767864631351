import Chart from "components/chart/Chart";
import { DateTime } from "luxon";
import { action, makeObservable, observable, reaction } from "mobx";

export class StatisticFeatureDiagram {
  constructor({ stores = [], width, height, title }) {
    this.width = width;
    this.height = height;
    this.chart = null;
    this.title = title;
    this.stores = stores;
    this.stores.forEach((store) => {
      store.onChange = () => {
        this.createChart();
      };
    });
    this.loading = stores.reduce((acc, curr) => {
      return acc || curr.loading;
    }, false);
    makeObservable(this, {
      chart: observable.ref,
      loading: observable.ref,
      // update: action,
      createChart: action,
    });
    reaction(
      () => stores.map((store) => !!store.loading),
      () => {
        console.log("reaction start");
        const loading = stores.reduce((acc, curr) => {
          return acc || curr.loading;
        }, false);
        if (loading === false) {
          console.log("reaction end");
          // this.initialize();
          // this.createChart();
        }
        this.loading = loading;
        // if (loading === false) {
        //   this.update();
        // }
      }
    );
  }

  // initialize() {
  //   for (let i = 1; i < this.stores.length; i++) {
  //     const store = this.stores[i];
  //     store.setVisible(true);
  //   }
  // }

  loadDiagramData(dateRange, noFetch = false, reduceInterval) {
    this.stores.forEach((store) => {
      store.setDateRange(dateRange, noFetch, reduceInterval); // stores will be loaded here first time
    });
  }

  createChart() {
    console.log("createChart");
    let minx = Infinity,
      maxx = -Infinity,
      miny = Infinity,
      maxy = -Infinity;
    const chart = new Chart({
      height: this.height,
      width: this.width,
      padding: { left: 69, bottom: 90, right: 20, top: 10 },
      bgcolor: null, //"#F5F5F5",
      tooltipOptions: {
        formatX: (x) => {
          return DateTime.fromMillis(x).toFormat("dd.MM.yyyy HH:mm");
        },
        content: `<h2 style="margin-top:0;">\${name}</h2>
        Datum: \${x} <br>
        Wert: \${y} \${unit-y}`,
      },
    });
    const datasets = [];
    // let min2 = null;
    // let max2 = null;
    this.stores.forEach((store) => {
      const { min, max, chartData, data } = store;
      if (!min || !max || !data) {
        return; // not all stores loaded yet => do not append to chart
      }

      if (min.y < miny) {
        miny = min.y;
      }
      if (min.x < minx) {
        minx = min.x;
      }
      if (max.y > maxy) {
        maxy = max.y;
      }
      if (max.x > maxx) {
        maxx = max.x;
      }

      datasets.push(chartData);
    });

    chart.addAxis({
      positioning: "bottom",
      id: "x",
      ticks: 5,
      // tickFormat: (x) => DateTime.fromMillis(x).toFormat("dd.MM.yy"), //"dd.MM.yyyy HH:mm"), // format for date
      domain: [minx, maxx],
      unit: null,
      visible: true,
      scaleType: "time",
      timeOpts: {
        round: false,
        isoWeekday: false,
        displayFormats: {
          month: "M.YYYY",
          day: "D.M.YY",
          week: "D.M.YY",
          quarter: "[Q]Q - YYYY",
          year: "YYYY",
          hour: "D.M H:00",
          minute: "H:mm:ss",
        },
      },
    });
    const computeMinY = (min, max) => {
      return min - (max - min) * 0.075;
    };
    const computeMaxY = (min, max) => {
      return max + (max - min) * 0.075;
    };
    // const testDomain = [
    //   miny === 0 ? 0 : computeMinY(miny, maxy),
    //   computeMaxY(miny, maxy),
    // ];
    // console.log(testDomain);

    chart.addAxis({
      positioning: "left",
      id: "y",
      ticks: 5,
      // domain: [miny > 0 ? 0 : miny, maxy],
      domain: [
        miny === 0 ? 0 : computeMinY(miny, maxy),
        computeMaxY(miny, maxy),
      ],
      unit: this.stores[0].Einheit,
      visible: true,
    });

    datasets.forEach((dataset) => chart.addDataset(dataset));
    this.chart = chart;
  }

  // update() {
  //   this.stores.forEach((store) => {
  //     store.fetch();
  //   });
  // }

  updateByInterval(reduceInterval) {
    this.stores.forEach((store) => {
      store.updateChartDataByInterval(reduceInterval);
    });
  }

  // updateData(rawData) {
  //   this.stores.forEach((store) => {
  //     store.applyData(rawData);
  //   });
  // }
}
