import { CircularProgress } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import RootStore from "stores/RootStore";
import { StatisticFeatureDiagram } from "utils/StatisticFeatureDiagram";
import { intervalOptions } from "../config";
import { getDiagramStores } from "../utils";
import StationStatisticActionsContainer from "./StationStatisticActionsContainer";
import StationStatistics from "./StationStatistics";

const loadingStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const contentStyle = {
  // height: "350px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  // resize: "horizontal",
};

const StationStatisticContent = observer(({ title }) => {
  const ref = useRef();

  const [diagram, setDiagram] = useState(
    new StatisticFeatureDiagram({
      stores: [],
    })
  );
  const [selectedInterval, setSelectedInterval] = useState(intervalOptions[0]);

  const { isMobileView } = RootStore.uiStore;
  const { selectedStatisticTopic, selectedStatStation, statisticDateRange } =
    RootStore.statisticStore;

  // const topicConfig = useMemo(() => {
  //   const configStore = RootStore.appsTimeSeriesConfig[RootStore.apps[1]];
  //   return configStore[
  //     `${selectedStatisticTopic.GruppenID},${selectedStatisticTopic.TypID}`
  //   ];
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedStatisticTopic.GruppenID, selectedStatisticTopic.TypID]);

  const topicConfig = useMemo(() => {
    console.log("----- topicConfig useMemo");
    const {
      ID,
      Anzeigename,
      ts_unitsymbol,
      GruppenID,
      TypID,
      BasisURL,
      Nachkommastellen,
      Reihen,
    } = selectedStatisticTopic;

    return {
      ID,
      Anzeigename,
      ts_unitsymbol,
      GruppenID,
      TypID,
      BasisURL,
      Nachkommastellen,
      Reihen,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatisticTopic.GruppenID, selectedStatisticTopic.TypID]);

  const feature = toJS(selectedStatStation);

  useEffect(() => {
    console.log("----- 01");
    const stores = getDiagramStores({
      topicConfig,
      feature,
    });
    const diagram = new StatisticFeatureDiagram({
      stores,
      width: ref.current.offsetWidth,
      height: 300, // ref.current.offsetHeight,
      title,
    });
    setDiagram(diagram);
    // RootStore.statisticStore.statisticDiagram = diagram;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ref.current,
    feature.station_id,
    feature.station_no,
    // feature.ts_id,
    topicConfig.ID,
    isMobileView,
  ]);

  useEffect(() => {
    console.log("----- 02");
    if (selectedInterval.reduceInterval !== 0) {
      setSelectedInterval(intervalOptions[0]);
    }
    const reduceInterval =
      intervalOptions[0].reduceInterval > 0
        ? intervalOptions[0].reduceInterval
        : undefined;
    diagram?.loadDiagramData(statisticDateRange, false, reduceInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagram, statisticDateRange]);

  const stationStatisticsContent =
    diagram?.chart?.datasets[0].data.length > 0 ? (
      <div style={contentStyle}>
        <StationStatisticActionsContainer
          ref={ref}
          diagram={diagram}
          selectedInterval={selectedInterval}
          setSelectedInterval={setSelectedInterval}
        />
        <StationStatistics
          diagram={diagram}
          selectedInterval={selectedInterval}
        />
      </div>
    ) : (
      "Keine Daten vorhanden."
    );

  return (
    <div style={{ width: "100%" }} ref={ref}>
      {diagram.loading ? (
        <CircularProgress style={loadingStyle} thickness={5} />
      ) : (
        stationStatisticsContent
      )}
    </div>
  );
});

export default StationStatisticContent;
