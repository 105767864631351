import MenuContainer from "./MenuContainer";
import { observer } from "mobx-react-lite";
import GraphContainer from "./GraphContainer";
import Overview from "./Overview";
import { useMemo } from "react";

const ViewMap = {
  "-1": (props) => <Overview {...props} />,
};

const Content = observer(
  ({
    catchmentArea,
    nls,
    style,
    catchmentAreasConfig,
    defaultChartConfig,
    open,
    setCatchmentArea,
    setOpen,
  }) => {
    // console.log(">>>> render Content");
    const { /*feature, app,*/ tab /*, catchmentAreaInfo*/ } = catchmentArea;

    // const items = Object.keys(catchmentAreasConfig.Tabs).map(
    //   (key) => catchmentAreasConfig.Tabs[key]
    // );
    const item = useMemo(() => {
      const items = Object.keys(catchmentAreasConfig.Tabs);
      let item = null;
      if (items.includes(tab)) {
        item = tab;
      }
      return item;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    const props = {
      catchmentArea,
      defaultChartConfig,
      catchmentAreasConfig,
      nls,
    };

    return (
      <div
        onClick={(e) => setOpen(false)}
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          width: "100%",
          padding: 5,
          boxSizing: "border-box",
        }}
      >
        <MenuContainer
          open={open}
          catchmentArea={catchmentArea}
          setCatchmentArea={setCatchmentArea}
          catchmentAreasConfig={catchmentAreasConfig}
          nls={nls}
        />
        {item && (
          <div style={style}>
            <GraphContainer
              catchmentArea={catchmentArea}
              defaultChartConfig={defaultChartConfig}
              catchmentAreasConfig={catchmentAreasConfig}
              nls={nls}
            />
          </div>
        )}
        {ViewMap[`${catchmentArea.tab}`] &&
          ViewMap[`${catchmentArea.tab}`](props)}
      </div>
    );
  }
);

export default Content;
