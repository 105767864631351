import WMSLayer from "@arcgis/core/layers/WMSLayer";
import React from "react";
import RootStore from "stores/RootStore";
export default class TimeWMSLayer extends WMSLayer {
  constructor(url, ts_id, date) {
    super(url);
    this.proxyURL = RootStore.config.proxyURL;
    // this.url = proxyURL ? proxyURL + "?" + url : url;
    this.url = url;
    this.params = {
      datasource: 0,
      rasterwms: true,
      ts_id: ts_id,
      date: date,
      layers: "MS",
    };

    // const wmsLayer = new WMSLayer(this.url);

    // const wmsLayer = new WMSLayer(
    //   "https://www.ag.ch/app/hydrometrie/kiwis/KiWIS?SERVICE=kisters&REQUEST=getTimeseriesValueLayer&datasource=0&rasterwms=true&dateformat=UNIX&timeseriesgroup_id=39016&date=2021-12-01T22%3A59%3A59.000Z&metadata=true&md_returnfields=ca_sta%2Cstation_id%2Cstation_no%2Cstation_name%2Cparametertype_id%2Cparametertype_name%2Cts_unitsymbol"
    // );
    this.customParameters = this.params;
    // this.wmsLayer = wmsLayer;
    this._legend = null;
    this.wmsColors = null;
  }

  setOpacity = (opacity) => {
    this.opacity = opacity;
  };

  setWMSLayerColors = (colors) => {
    this.wmsColors = colors;
  };

  setWMSLayerLegend = (legend) => {
    this._legend = legend;
  };

  queryString = (params) => {
    return Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
  };

  updateTSURL = (pTsId, date) => {
    // this.destroy();
    this.params.ts_id = pTsId;
    this.params.date = date;
    this.customParameters = this.params;
    try {
      // this.load();
      this.refresh();
      // console.log(this.isResolved());
      // console.log(this.isRejected());
    } catch (err) {
      console.log("error", err);
    }
    // console.log("isResolved", this.wmsLayer.isResolved());
  };
}
