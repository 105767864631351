export const treeItemLabelKeys = [
  (treeItemData) => treeItemData.Name,
  (treeItemData) => treeItemData.Anzeigename,
  (treeItemData) =>
    treeItemData.station_name + " (" + treeItemData.station_no + ")",
];

export const treeItemNodeId = [
  (treeItemData) => treeItemData.ID,
  (treeItemData) => treeItemData.ID,
  (treeItemData) => String(treeItemData.ts_id),
];

// export const hasPopupMenuButton = [false, false, true];

export const treeItemMenuOptions = [
  [],
  [],
  ["Hervorheben", "Widget öffnen", "Zoomen auf"],
];
