import { toJS } from "mobx";
import RootStore from "stores/RootStore";

export const removeHashFromUrl = () => {
  var uri = window.location.toString();
  if (uri.indexOf("#") > 0) {
    var clean_uri = uri.substring(0, uri.indexOf("#"));
    window.history.replaceState({}, document.title, clean_uri);
  }
};

export const updateAppUsingUrlParameter = () => {
  if (window.location.hash) {
    const urlHash = window.location.hash; // decodeURI(window.location.hash); // .replace(/\+/g, "");
    console.log("%c urlHash", "background: yellow", urlHash);

    const widgetId = handleWidgetFromUrl(urlHash);

    const params = Object.fromEntries(
      new URLSearchParams(urlHash.slice(urlHash.indexOf("&") + 1))
    );
    console.log("urlHash params", params);
    handleTopicsFromUrl(params.topics);
    handleStationFromUrl(
      widgetId,
      params.station,
      params.hasOwnProperty("chart")
    );

    if (params.hasOwnProperty("nosearch")) {
      RootStore.uiStore.closeWidget();
    }
  }
};

const handleWidgetFromUrl = (urlHash) => {
  let widgetId = urlHash.slice(1);
  if (urlHash.indexOf("&") !== -1) {
    widgetId = urlHash.slice(1, urlHash.indexOf("&"));
  }
  if (RootStore.apps.includes(widgetId)) {
    RootStore.uiStore.openWidget(widgetId);
  }
  return widgetId;
};

const handleTopicsFromUrl = (topics) => {
  if (topics && topics.length > 0) {
    const targetTopicsArray = topics.replace(/\\+/g, "").split("^");
    console.log("%c Topics", "background: lightblue", targetTopicsArray);
    const selectedTopicsAndItsChildren = [];
    targetTopicsArray.forEach((parentTopic) => {
      const nodeIdsOfParentAndItsChildren =
        RootStore.mapScopeThemeStore.getNodeIdsSelectedParentWithItsChildren(
          parentTopic
        );
      selectedTopicsAndItsChildren.push(...nodeIdsOfParentAndItsChildren);
    });
    RootStore.mapScopeThemeStore.setSelectedThemesTimeSeries(
      selectedTopicsAndItsChildren
    );
    RootStore.mapScopeThemeStore.activeStep = 1;
    RootStore.mapScopeThemeStore.setFilterThemesTimeSeries(false, true);
  }
};

const getStationByNumber = (stationNumber, isPikett, isHydroweb, isEnvis) => {
  let featuresWiskiStore;
  let targetStation;
  // let stationGroupParam;

  if (isPikett) {
    featuresWiskiStore = toJS(RootStore.pikettFeatures);
  } else if (isHydroweb) {
    featuresWiskiStore = toJS(RootStore.hydroWebFeatures);
  } else if (isEnvis) {
    featuresWiskiStore = toJS(RootStore.envisFeatures);
  }

  if (featuresWiskiStore) {
    targetStation = featuresWiskiStore.stationsList.find(
      (elem) => elem.station_no === stationNumber
    );
  }

  // if (targetStation && isPikett) {
  //   stationGroupParam = RootStore.hydroWebStore.pikettGroupParam;
  // }
  // // if (targetStation && isHydroweb) {
  // //   stationGroupParam = `${featuresWiskiStore.hydrometrieTSGruppe},${targetStation["parametertype_id"]}`
  // // }
  // if (targetStation && (isEnvis || isHydroweb)) {
  //   stationGroupParam =
  //     featuresWiskiStore.indicesFiltered.station[targetStation.station_id][0]
  //       .groupParam;
  // }
  // return { targetStation, stationGroupParam };
  return targetStation;
};

const handleStationFromUrl = (widgetId, stationNumber, isOpenChart) => {
  if (stationNumber && stationNumber.length > 0) {
    const isPikett = widgetId === RootStore.apps[0];
    const isHydroweb = widgetId === RootStore.apps[1];
    const isEnvis = widgetId === RootStore.apps[2];

    const targetStation = getStationByNumber(
      stationNumber,
      isPikett,
      isHydroweb,
      isEnvis
    );

    // zoom to station
    if (targetStation && isEnvis) {
      RootStore.mapStore.layer.highlightStation(targetStation.station_id, 5000);
      RootStore.mapStore.layer.zoomToStation(targetStation.station_id);
    }
    if (targetStation && (isHydroweb || isPikett)) {
      RootStore.hydroWebStore.zoomToStation(targetStation);
    }

    //  open station widget
    if (isOpenChart && targetStation) {
      // const groupParameter = RootStore.noChartStationsGroupParams.includes(
      //   stationGroupParam
      // )
      //   ? -1
      //   : stationGroupParam;
      RootStore.mapStore.openMeasuringPointWidget(
        targetStation.station_no,
        widgetId,
        -1, // groupParameter
        targetStation
      );
    }
  }
};

// const updateAppUsingUrlParameter = () => {
//   console.log("%cwindow.location.hash", "background: green");
//   if (window.location.hash) {
//     const urlHash = window.location.hash; //  decodeURI(window.location.hash); // .replace(/\+/g, "");
//     console.log("%curlHash", "background: yellow");
//     console.log(urlHash);

//     let widgetId = urlHash.slice(1);
//     if (urlHash.indexOf("&") !== -1) {
//       widgetId = urlHash.slice(1, urlHash.indexOf("&"));
//     }
//     console.log(widgetId);
//     RootStore.uiStore.openWidget(widgetId);

//     const selectedTopicsArray = urlHash.split("topics=")[1]?.split("^");
//     console.log(selectedTopicsArray);

//     if (selectedTopicsArray.length > 0) {
//       const selectedTopicsAndItsChildren = [];
//       selectedTopicsArray.forEach((parentTopic) => {
//         console.log(parentTopic);
//         console.log(parentTopic.replace(/\+/g, ""));
//         const nodeIdsOfParentAndItsChildren =
//           RootStore.mapScopeThemeStore.getNodeIdsSelectedParentWithItsChildren(
//             parentTopic
//           );
//         console.log("%c nodeIdsOfParentAndItsChildren", "background: blue");
//         console.log(nodeIdsOfParentAndItsChildren);
//         selectedTopicsAndItsChildren.push(...nodeIdsOfParentAndItsChildren);
//       });
//       RootStore.mapScopeThemeStore.setSelectedThemesTimeSeries(
//         selectedTopicsAndItsChildren
//       );
//       RootStore.mapScopeThemeStore.activeStep = 1;
//       RootStore.mapScopeThemeStore.setFilterThemesTimeSeries(false, true);
//     }
//   }
// };
