import { Store } from "libs/StoreLayer";
import rootStore from "stores/RootStore";
import APIHelper from "libs/APIHelper";
import Graphic from "@arcgis/core/Graphic";
import * as geometryJsonUtils from "@arcgis/core/geometry/support/jsonUtils";

const getMap = () => rootStore.mapStore.map;

export default class AbflussStore extends Store {
  constructor(layerurl, wiskiUrl, attribute) {
    super(arguments);
    this.hasFields = true;
    this.fields = [
      {
        name: "ObjectID",
        alias: "ObjectID",
        type: "oid",
      },
      {
        name: "abflussspende",
        alias: "Abflussspende [l/(s * km²)]",
        type: "double",
      },
      {
        name: "timestamp",
        alias: "Zeitpunkt",
        type: "long",
      },
      {
        name: "EZG_NAME",
        alias: "Einzugsgebiet",
        type: "string",
      },
    ];
    this.layerurl = layerurl;
    this.wiskiUrl = wiskiUrl;
    this.attribute = attribute;
  }

  applyData(result, features) {
    // result is from wiski service, features from map service
    const map = {}; // maybe cache => only fetch features all the time => refresh, when new data needed
    for (let i = 0; i < result.length; i++) {
      const item = result[i];
      map[item["station_no"]] = item;
    }

    const graphics = [];
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      const attributes = APIHelper.getAttributes(feature);
      const record = map[attributes["NR_WISKI"]];
      if (record) {
        attributes["timestamp"] = record["timestamp"];
        let abfluss = record["ts_value"];
        attributes["abflussspende"] =
          ((abfluss * 1000) /
            (attributes["SHAPE.STArea()"] || attributes["Shape.STArea()"])) *
          1000000;
      } else {
        continue;
        // no data found
      }
      APIHelper.setAttributes(feature, attributes);
      graphics.push(feature);
    }
    this.setGraphics(graphics);
  }

  load(value) {
    let aborted = false;
    if (!value) {
      this.setGraphics([]);
      return new Promise((resolve) => {
        resolve();
      });
    }
    return {
      promise: new Promise((resolve, reject) => {
        fetch(this.wiskiUrl)
          .then((response) => !aborted && response.json())
          .then((result) => {
            if (!aborted) {
              APIHelper.queryFeaturesOnLayerURL(
                this.layerurl,
                this.attribute,
                value,
                getMap()
              )
                .then((features) => {
                  if (!aborted) {
                    this.applyData(
                      result,
                      features?.map(
                        (f) =>
                          new Graphic({
                            geometry: geometryJsonUtils.fromJSON(
                              f.geometry.toJSON()
                            ),
                            attributes: f.attributes,
                          })
                      )
                    );
                  }
                  resolve();
                })
                .catch((e) => {
                  console.error("Error while fetching Features from Layer", e);
                  reject(e);
                });
            }
          })
          .catch((e) => {
            console.error("Error while fetching WISKI Data", e);
            reject(e);
          });
      }),
      abort: () => {
        aborted = true;
      },
    };
  }
}
