import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { makeObservable, observable, autorun, action } from "mobx";

const nop = () => {};
export class Store {
  hasFields = false;
  fields = [];
  graphics = [];
  loading = false;
  changeListener = [];
  layer = null;

  constructor() {
    makeObservable(this, {
      fields: observable,
      graphics: observable.ref,
      setGraphics: action,
      loading: observable.ref,
      setLoading: action,
    });
    autorun(() => {
      nop(this.graphics, this.fields); // needed for reactivity if no changeListener is present atm
      this.changeListener.forEach((listener) => {
        try {
          listener(this.graphics, this.fields);
        } catch (e) {
          console.error(
            "Some error occurred in a onChange Listener of an StoreLayerStore",
            e
          );
        }
      });
    });
  }

  setLoading(value) {
    this.loading = value;
  }

  setGraphics(graphics) {
    this.graphics = graphics;
  }

  addChangeListener(fkt) {
    this.changeListener.push(fkt);
  }
}

const setLayersFeatures = (layer, store, graphics, fields) => {
  // I guess fields cannot be changed after layer is created
  // once source is set during constructor graphics can only be changed with applyEdits
  layer
    .queryFeatures(layer.createQuery())
    .then((result) => {
      return layer.applyEdits({
        addFeatures: graphics,
        deleteFeatures: result.features,
      });
    })
    .then((e) => {
      // e;
      // graphics;
      // layer;
      // TODO: jedes result könnte einen Fehler ausgeben, aber nur bei falschen Daten => eventuell in der Konsole ausgeben (result.addResults[i].error)
    })
    .catch((e) => {
      // e;
      // graphics;
      // layer;
      console.error(e);
    });
};
export function connectLayerWithStore(
  layer,
  store,
  updateFeatures = setLayersFeatures.bind(null, layer, store)
) {
  store.layer = layer;
  layer.store = store;
  store.addChangeListener((graphics, fields) => {
    updateFeatures(graphics, fields);
  });
  // return {
  //   layer: layer,
  //   store: layerStore,
  // };
}

export default function StoreLayer(layeroptions, store, layerClazz) {
  const {
    renderer,
    idField = "OBJECTID",
    fields,
    graphics,
    popupTemplate = undefined,
    geometryType = "point",
    layerOptions = {},
  } = layeroptions;

  const layerStore = store || new Store(); // create new store if store is not existent
  if (fields) {
    store.fields = fields;
    store.hasFields = true;
  }
  if (graphics) {
    store.graphics = graphics;
  }
  const storeFields = !!store.hasFields;

  const options = {
    source: store.graphics || [],
    renderer,
    popupTemplate,
    popupEnabled: !!popupTemplate,
    fields: storeFields ? store.fields : [],
    objectIdField: idField,
    geometryType,
    ...layerOptions,
  };

  const layer = new (layerClazz ? layerClazz : FeatureLayer)(options); // if layerClazz is defined, the layeroptions will be used on it

  connectLayerWithStore(layer, store);

  return {
    layer: layer,
    store: layerStore,
  };
}
