import Menu from "./Menu";
import { observer } from "mobx-react-lite";
import { Divider, Drawer, Typography } from "@mui/material";

const sideMenuWidth = 250;

const MenuContainer = observer(
  ({ open, catchmentArea, setCatchmentArea, catchmentAreasConfig, nls }) => {
    // console.log(">>>> render MenuContainer");

    const { feature, app, /*tab,*/ catchmentAreaInfo } = catchmentArea;
    const openTab = (clickedTab) => {
      console.log("openTab");
      console.log(clickedTab);
      setCatchmentArea({ feature, app, tab: clickedTab, catchmentAreaInfo });
    };
    const menuItems = Object.keys(catchmentAreasConfig.Tabs);

    return (
      <Drawer
        sx={{
          width: open ? sideMenuWidth : 0,
          left: 0,
          top: 50,
          bottom: 0,
          position: "absolute",
          "& .MuiDrawer-paper": {
            width: open ? sideMenuWidth : 0,
            boxSizing: "border-box",
            position: "absolute",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography variant="h5" style={{ padding: "8px 16px" }}>
          {nls.menu}
        </Typography>
        <Divider />
        <Menu
          openTab={openTab}
          open={open}
          menuItems={menuItems}
          nls={nls}
          width={sideMenuWidth}
          catchmentArea={catchmentArea}
        />
      </Drawer>
    );
  }
);

export default MenuContainer;
