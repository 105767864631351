import React, { useState, useEffect, useMemo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import StoreLayer from "../../libs/StoreLayer";
import AbflussStore from "./Abflussstore";
import {
  createRenderer,
  createPopupTemplate,
  handleFeatureInfoOnHover,
  clickEvent,
} from "./utils";
import { observer } from "mobx-react";
import { Layer } from "../../libs/Layers";
import rootStore from "../../stores/RootStore";
import APIHelper from "libs/APIHelper";
import Legend from "../../libs/Legend";
import Divider from "@mui/material/Divider";
import RootStore from "../../stores/RootStore";
import DataLoadingIndicator from "widgets/DataLoadingIndicator/widget";
// import AbflussLegend from "./AbflussLegend";

const breaks = [
  {
    min: 19,
    max: Infinity,
    fill: [8, 81, 156, 0.6],
    outline: [255, 255, 255, 0.75],
    label: "> 19",
  },
  {
    min: 16,
    max: 19,
    fill: [49, 130, 189, 0.6],
    outline: [255, 255, 255, 0.75],
    label: "16 - 19",
  },
  {
    min: 14,
    max: 16,
    fill: [107, 174, 214, 0.6],
    outline: [255, 255, 255, 0.75],
    label: "14 - 16",
  },
  {
    min: 10,
    max: 14,
    fill: [158, 202, 225, 0.6],
    outline: [255, 255, 255, 0.75],
    label: "10 - 14",
  },
  {
    min: 0,
    max: 10,
    fill: [198, 219, 239, 0.6],
    outline: [255, 255, 255, 0.75],
    label: "0 - 10",
  },
];

const renderer = createRenderer(breaks);
const symbols = APIHelper.getClassBreaksRendererSymbols(renderer);

function Abflussspende({
  attribute = "HIERARCHIE",
  hierarchies = [1, 2, 3],
  layerurl = "https://www.ag.ch/geoportal/rest/services/alg_envis_view/MapServer/9/",
  serviceurl = "https://www.ag.ch/app/hydrometrie/kiwis/KiWIS?datasource=0&service=kisters&type=queryServices&request=getTimeseriesValueLayer&format=objson&crs=local&dateformat=UNIX&metadata=true&timeseriesgroup_id=68492&md_returnfields=custom_attributes%2Cstation_id%2Cstation_no%2Cstation_name%2Cparametertype_id%2Cparametertype_name%2Cts_unitsymbol",
  opened,
  nls = {
    error: "Beim Laden ist ein Fehler aufgetreten",
  },
}) {
  const mapStore = rootStore.mapStore;
  const { map = null, mapView = null } = mapStore?.mapReady ? mapStore : {};

  // const legendDiv = useRef();
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { layer, store } = useMemo(() => {
    const layerstore = new AbflussStore(
      layerurl,
      RootStore.config.proxyURL + serviceurl,
      attribute
    );
    const { layer, store } = new StoreLayer(
      {
        renderer,
        popupTemplate: createPopupTemplate(),
        idField: "ObjectID",
        geometryType: "polygon",
      },
      layerstore
    );
    clickEvent(RootStore.mapStore.mapView, layer);
    clickEvent(RootStore.mapStore.sceneView, layer);

    return { layer, store };
  }, [layerurl, serviceurl, attribute]);

  const layerWrapper = useMemo(() => {
    if (layer && map && mapView) {
      return new Layer(layer, map, mapView);
    } else {
      return null;
    }
  }, [layer, map, mapView]);

  useEffect(() => {
    if (layerWrapper) {
      console.log("Abflussspende useEffect layerWrapper addToMap ");
      layerWrapper.addToMap(map);
    } else {
    }
    return () => {
      if (layerWrapper) {
        console.log("Abflussspende useEffect layerWrapper removeFromMap");
        layerWrapper.removeFromMap(map);
      }
    };
  }, [layerWrapper]);

  useEffect(() => {
    // reload store when hierarchy is chosen
    let aborted = false;
    let tabort = () => {};
    if (selected !== "") {
      setLoading(true);
      let { abort, promise } = store.load(selected);
      tabort = abort;
      promise
        .then(() => {
          if (!aborted) {
            setLoading(false);
            setError(false);
            RootStore.mapStore.putAllLayersOnTop();
          }
        })
        .catch((e) => {
          if (!aborted) {
            setLoading(false);
            setError(true);
            console.error("Error @ loading Store forStoreLayer", e);
          }
        });
    } else {
      store.load(null);
      setLoading(false);
      setError(false);
    }
    return () => {
      aborted = true;
      tabort();
      setError(false);
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    handleFeatureInfoOnHover(layer);
  }, [layer]);

  const options = [""].concat(hierarchies);
  const hierarchySelect = (
    <Autocomplete
      id="dischargeHierarchyLevel"
      options={options}
      label={"Hierarchiestufe"}
      value={selected}
      getOptionLabel={(item) => {
        return `${item || "Ausschalten"}`;
      }}
      onChange={(event, newValue) => {
        setSelected(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Hierarchiestufe" />
      )}
      disableClearable
    />
  );

  return (
    <div
      style={{
        margin: 5,
        padding: 15,
        minHeight: 100,
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* {loading && (
        <CircularProgress
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      )} */}

      <DataLoadingIndicator dataLoading={loading} />
      {error && <Typography>{nls.error}</Typography>}
      {hierarchySelect}
      <div id="dischargeLegend" style={{ marginTop: 15 }}>
        <Legend
          // <AbflussLegend
          legendSymbols={symbols}
          nls={{
            title: "Legende",
            legendSubtitle: "Abflussspende [l/(s*km²)]",
          }}
          accordion={false}
        />
      </div>
      <Divider sx={{ borderColor: "lightgray", marginTop: "40px" }} />
      <div style={{ marginTop: 25 }} id="feature-node"></div>
    </div>
  );
}

export default observer(Abflussspende);
