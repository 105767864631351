import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";
import HydroWebLegend from "./HydroWebLegend";
import PikettLegend from "./PikettLegend";

const Legend = observer((props) => {
  // const { currentApp, apps } = RootStore.mapStore;
  const { currentApp, apps } = RootStore;
  const isAppPikett = currentApp === apps[1] ? false : true;

  return <>{isAppPikett ? <PikettLegend /> : <HydroWebLegend />}</>;
});

export default Legend;
