import {
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const niederschlagSvg = (handleHydroLegend) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="175"
      height="115"
      viewBox="0 0 60 30"
      version="1.1"
      className="niederschlagsvg"
      id="svg-niederschlag"
      style={{
        left: "50%",
        position: "relative",
        marginLeft: "-90px",
      }}
    >
      <defs id="defs-niederschlag">
        <linearGradient
          id="lgrad-niederschlag"
          x1="0%"
          y1="100%"
          x2="0%"
          y2="0%"
        >
          <stop
            offset="0%"
            style={{ stopColor: "rgb(0,0,0)", stopOpacity: ".5" }}
          />
          <stop
            offset="100%"
            style={{ stopColor: "rgb(200,200,200)", stopOpacity: ".5" }}
          />
        </linearGradient>
        <clipPath id="outercircle-niederschlag">
          <circle cx="15" cy="15" r="14" id="circle2-niederschlag" />
        </clipPath>
        <mask id="innercycle-niederschlag">
          <rect
            x="0"
            y="0"
            width="30"
            height="30"
            id="rect5-niederschlag"
            style={{ fill: "#ffffff" }}
          />
          <circle
            cx="15"
            cy="15"
            r="5"
            id="circle7-niederschlag"
            style={{ fill: "#000000" }}
          />
        </mask>
      </defs>
      <g
        transform="matrix(0.87618916,0,0,0.87618916,17.893105,2.0976769)"
        id="g20-niederschlag"
      >
        <g clipPath="url(#outercircle-niederschlag)" id="g16-niederschlag">
          <rect
            x="0"
            y="0"
            width="30"
            height="30"
            className="dodgerBlue"
            id="rect14-niederschlag"
            style={{
              fill: "#0099ff",
              cursor: "pointer",
              pointerEvents: "auto",
            }}
            onClick={(e) => {
              handleHydroLegend(e);
            }}
          />
        </g>
        <circle
          cx="15"
          cy="15"
          r="13"
          style={{
            fill: "none",
            stroke: "url(#lgrad-niederschlag)",
            strokeWidth: "3",
          }}
          id="circle18-niederschlag"
        />
      </g>
      <g
        id="g26-niederschlag"
        transform="matrix(1.5005499,0,0,1.5005499,0.55959917,-6.3336171)"
      >
        <text
          x="9"
          y="4"
          id="text-Niederschlag"
          style={{ fontSize: "3px", fontFamily: "Calibri, Arial" }}
        >
          Niederschlag [mm]
        </text>
      </g>
    </svg>
  );
};

const abflussSvg = (handleHydroLegend) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="190"
      height="133"
      viewBox="0 0 60 47"
      version="1.1"
      className="abflusssvg"
      id="svg42"
      style={{
        left: "50%",
        position: "relative",
        marginLeft: "-110px",
      }}
    >
      <defs id="defs10">
        <linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop
            offset="0%"
            style={{ stopColor: "rgb(0,0,0)", stopOpacity: ".5" }}
          ></stop>
          <stop
            offset="100%"
            style={{ stopColor: "rgb(200,200,200)", stopOpacity: ".5" }}
          ></stop>
        </linearGradient>
        <clipPath id="outercircle">
          <circle cx="15" cy="15" r="14" id="circle2"></circle>
        </clipPath>
        <mask id="innercycle">
          <rect
            x="0"
            y="0"
            width="30"
            height="30"
            id="rect5"
            style={{ fill: "#ffffff" }}
          ></rect>
          <circle
            cx="15"
            cy="15"
            r="5"
            id="circle7"
            style={{ fill: "#000000" }}
          ></circle>
        </mask>
      </defs>
      <g
        // transform="rotate(-90deg,0)rotate(-60,29.823872,4.873011)"
        id="g32"
      >
        <g
          clipPath="url(#outercircle)"
          id="g28"
          transform="matrix(1,0,0,1,18,6)"
          style={{ cursor: "pointer", pointerEvents: "auto" }}
        >
          <path
            d="M 2,7.5 A 15,15 0 0 0 15,30 V 15 Z"
            className={"blue"}
            id="path14"
            style={{ fill: "#0000ff", opacit: "1" }}
            onClick={(e) => {
              handleHydroLegend(e);
            }}
          ></path>
          <path
            d="M 28,7.5 A 15,15 0 0 0 2,7.7 L 15,15 Z"
            className="red"
            id="path16"
            style={{ fill: "#ee0000", opacit: "1" }}
            onClick={(e) => {
              handleHydroLegend(e);
            }}
          ></path>
          <path
            d="M 28,7.5 A 15,15 0 0 1 15,30 V 15 Z"
            className="yellow"
            id="path18"
            style={{ fill: "#cc9900", opacit: "1" }}
            onClick={(e) => {
              handleHydroLegend(e);
            }}
          ></path>
        </g>
        <circle
          cx="18"
          cy="13"
          r="13"
          style={{
            opacity: "1",
            fill: "none",
            stroke: "url(#lgrad)",
            strokeWidth: "3",
          }}
          id="circle30"
          transform="matrix(1,0,0,1,15,8)"
        ></circle>
      </g>
      <text
        x="2"
        y="40"
        id="text-Abfluss"
        style={{
          fontSize: "4.5px",
          fontFamily: "Calibri, Arial",
          strokeWidth: "1.5",
        }}
      >
        Abfluss [m³/s]
      </text>
      <text
        x="37"
        y="40"
        id="text-Pegel"
        style={{
          fontSize: "4.5px",
          fontFamily: "Calibri, Arial",
          strokeWidth: "1.5",
        }}
      >
        Pegel [m.ü.M]
      </text>
      <text
        x="13"
        y="4"
        id="text-Wassertemperatur"
        style={{
          fontSize: "4.5px",
          fontFamily: "Calibri, Arial",
          strokeWidth: "1.5",
        }}
      >
        Wassertemperatur [°C]
      </text>
    </svg>
  );
};

const HydroWebLegend = observer((props) => {
  const { handleHydroLegend } = RootStore.hydroWebStore;

  return (
    <Card
      id="pikettHydroLegend"
      sx={{
        border: "1px solid lightgray",
      }}
    >
      <CardHeader
        title="Legende"
        sx={{
          paddingBottom: "0px",
          paddingTop: "5px",
          color: "dimgray",
        }}
      />
      <List
        sx={{
          width: "100%",
          maxWidth: "auto",
          bgcolor: "background.paper",
          paddingTop: "0",
          paddingBottom: "0p",
        }}
      >
        <ListItem
          alignItems="center"
          sx={{
            paddingBottom: "0",
            paddingTop: "0",
            justifyContent: "space-around",
          }}
        >
          <ListItemAvatar>
            {/* <img
              src={"images/hydro-niederschlag.JPG"}
              alt="Niederschlag"
              loading="lazy"
            /> */}
            {niederschlagSvg(handleHydroLegend)}
          </ListItemAvatar>
        </ListItem>
        <Divider
          // variant="inset"
          component="li"
          sx={{
            borderColor: "lightgray",
            marginTop: "0",
            marginBottom: "20px",
          }}
        />
        <ListItem
          alignItems="center"
          sx={{
            paddingBottom: "0",
            paddingTop: "0",
            justifyContent: "space-around",
          }}
        >
          <ListItemAvatar>
            {/* <img
              src={"images/hydro-pegel-abfluss.JPG"}
              alt="Pegel - Abfluss - Wassertemperatur"
              loading="lazy"
            /> */}
            {abflussSvg(handleHydroLegend)}
          </ListItemAvatar>
        </ListItem>
      </List>
    </Card>
  );
});

export default HydroWebLegend;
