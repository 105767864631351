import TimelineIcon from "@mui/icons-material/Timeline";
import SubjectIcon from "@mui/icons-material/Subject";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExtraMenu from "./ExtraMenu";
const Menu = ({
  openTab,
  open,
  items,
  nls,
  width,
  configStore,
  mp,
  extraMenuItems,
}) => {
  return (
    <Drawer
      sx={{
        // flexShrink: 0,
        width: open ? width : 0,
        left: 0,
        top: 50,
        bottom: 0,
        position: "absolute",
        // height: "100%",
        // position: "absolute",
        "& .MuiDrawer-paper": {
          width: open ? width : 0,
          // height: "100%",
          boxSizing: "border-box",
          position: "absolute",
          // top: 50,
          // bottom: 0,
          // left: 0,
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
      onClick={(e) => e.stopPropagation()}
    >
      <Typography variant="h5" style={{ padding: "8px 16px" }}>
        {nls.menu}
      </Typography>
      <Divider />
      <List>
        <ListItem
          button
          key={"stationinfo"}
          onClick={() => {
            openTab(-1);
          }}
        >
          <ListItemIcon>
            <SubjectIcon />
          </ListItemIcon>
          <ListItemText primary={nls.stationInfo} />
        </ListItem>
        {items.map(({ groupParam }, index) => (
          <ListItem
            button
            key={groupParam}
            onClick={() => {
              openTab(groupParam);
            }}
            selected={mp.groupParam === groupParam ? true : false}
          >
            <ListItemIcon>
              <TimelineIcon />
            </ListItemIcon>
            <ListItemText primary={configStore[groupParam]["Anzeigename"]} />
          </ListItem>
        ))}
        <ExtraMenu extraMenuItems={extraMenuItems} openTab={openTab} />
      </List>
    </Drawer>
  );
};

export default Menu;
