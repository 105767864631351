import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import SquareIcon from "@mui/icons-material/Square";
import ExpandWidget from "widgets/ExpandWidget/Widget";
import { precipitationIntensityScale } from "../config";

const legendContainerStyle = { marginTop: 1 };
const listLabelStyle = { pl: 2 };
const listItemStyle = { paddingY: 0 };
const listItemTextStyle = { marginY: 0 };

const IntensityLegend = ({ reduceInterval }) => {
  return (
    <Box sx={legendContainerStyle}>
      <ExpandWidget contentTitle="N-Intensität Legende" id="p-intensity-legend">
        <List>
          <Typography sx={listLabelStyle}>Niederschlagsintensität:</Typography>
          {precipitationIntensityScale(reduceInterval).map((item) => (
            <ListItem sx={listItemStyle} key={item.id}>
              <ListItemIcon>
                <SquareIcon htmlColor={item?.color} />
              </ListItemIcon>
              <ListItemText
                primary={item?.primaryText}
                secondary={item?.secondaryText}
                sx={listItemTextStyle}
              />
            </ListItem>
          ))}
        </List>
      </ExpandWidget>
    </Box>
  );
};

export default IntensityLegend;
