import {
  STANDARDWIDTH,
  STANDARDHEIGHT,
  STANDARDPADDING,
  drawSingleHandlers,
  drawHandlers,
} from "./constants";
import * as d3 from "d3";
import { buildTimeTickValues } from "utils/ChartUtils";
import moment from "moment";
import { precipitationIntensityScale } from "widgets/StatisticWidget/config";
import {
  moveIntensityTooltip,
  openIntensityTooltip,
  removeIntensityTooltipNode,
} from "widgets/StatisticWidget/utils";

export const addEvent = (node, type) => {
  node.on(type, function (e) {
    const {
      target: { chartdataset, chartdatasetIndex, axisMap, data, pos },
    } = e;
    let position = d3.select(e.target).node().getBoundingClientRect();
    chartdataset.handleEvent(type, {
      event: e,
      index: chartdatasetIndex,
      axisMap,
      pos,
      data,
      pagePosition: position,
    });
  });
};

export const getXY = (data) => {
  if (!data) {
    throw new Error("Dataformat not supported");
  } else if (Array.isArray(data)) {
    const [x, y] = data;
    return { x, y };
  } else if (typeof data === "object") {
    const { x, y } = data;
    return { x, y };
  } else {
    throw new Error("Dataformat not supported");
  }
};

export function drawLabel({ x, y, svg, drawInfo, fill, text = y }) {
  const { anchor, offset, fill: textfill } = drawInfo; // {anchor:"center", offset:{x:0, y:-5}
  let x1 = Array.isArray(x) ? x[0] : x;
  let x2 = Array.isArray(x) ? x[1] : x;
  let y1 = Array.isArray(y) ? y[0] : y;
  let y2 = Array.isArray(y) ? y[1] : y;

  let textx = 0;
  let texty = 0;

  switch (anchor) {
    default:
    case "center":
      textx = x1 + (x2 - x1) / 2;
      texty = y1 + (y2 - y1) / 2;
      break;
    case "begin":
      textx = x1;
      texty = y1;
      break;
  }
  svg
    .append("text")
    .attr("x", textx + offset.x)
    .attr("y", texty + offset.y)
    .attr("text-anchor", "middle")
    .attr("style", `${textfill || fill}`)
    .text(text); // TODO: formatter function
}

export function createSvg({
  node,
  height = STANDARDHEIGHT,
  width = STANDARDWIDTH,
}) {
  return d3
    .select(node)
    .append("svg")
    .attr("viewBox", `0, 0, ${width}, ${height}`)
    .attr("width", "100%")
    .attr("height", "100%");
}

export function drawAxis({
  // type = "x", // "x"|"y"
  id,
  scaleType = "linear", //currently only linear supported
  tickValues, //only set, when custom tick values should be shown
  ticks, //tick count => only used, when tickValues is not set
  domain = [0, 100], // e.g. [min, max]
  positioning = "bottom", // top|bottom|left|right //x => bottom or top | y => left or right
  tickFormat, // = (x) => x,
  padding = {
    bottom: STANDARDPADDING,
    left: STANDARDPADDING,
    right: STANDARDPADDING,
    top: STANDARDPADDING,
  },
  height = STANDARDHEIGHT,
  width = STANDARDWIDTH,
  unit,
  timeOpts,
}) {
  const {
    bottom = STANDARDPADDING,
    left = STANDARDPADDING,
    top = STANDARDPADDING,
    right = STANDARDPADDING,
  } = padding;
  //create scale
  let scale = null;
  switch (scaleType) {
    default:
      console.error(
        `ScaleType ${scaleType} currently not supported - fallback to linear`
      );
    // eslint-disable-next-line no-fallthrough
    case "time":
      scale = d3.scaleTime();
      break;
    case "linear":
      scale = d3.scaleLinear();
      break;
  }

  if (domain) {
    scale = scale.domain(domain);
  }

  //range
  let x = 0;
  let y = 0;
  switch (positioning) {
    default:
    case "bottom":
      scale = scale.range([left, width - right]);
      y = height - bottom;
      break;
    case "left":
      x = left;
      scale = scale.range([height - bottom, top]);
      break;
    case "top":
      scale = scale.range([left, width - right]);
      y = top;
      break;
    case "right":
      x = width - right;
      scale = scale.range([height - bottom, top]);
      break;
  }

  //create axis and set range
  var axis = null;
  switch (positioning) {
    case "top":
      axis = d3.axisTop(scale);
      break;
    default:
    case "bottom":
      axis = d3.axisBottom(scale);
      break;
    case "left":
      axis = d3.axisLeft(scale);
      break;
    case "right":
      axis = d3.axisRight(scale);
      break;
  }

  let min = 0;
  let max = 0;

  let buildedTickValues;
  //setup ticks
  if (tickValues) {
    buildedTickValues = tickValues;
    axis = axis.tickValues(tickValues);
    // min = scale(tickValues[0]);
    // max = scale(tickValues[tickValues.length - 1]);
  } else if (ticks) {
    axis = axis.ticks(ticks);
    // if (positioning === "bottom" || positioning === "top") {
    //   // axis = axis.ticks(parseInt(width / 100));
    // } else {
    //   axis = axis.ticks(ticks);
    // }
    // min = scale(domain[0]);
    // max = scale(domain[domain.length - 1]);
  }

  min = scale(domain[0]);
  max = scale(domain[domain.length - 1]);
  //format the ticks with tickFormat function
  if (tickFormat) axis = axis.tickFormat(tickFormat);

  //setup and format Time ticks
  if (scaleType === "time") {
    const timeTicks = buildTimeTickValues(timeOpts, width, domain);
    axis = axis.tickValues(timeTicks.tickValues);
    buildedTickValues = timeTicks.tickValues;
    // // axis = axis.tickFormat(d3.timeFormat(ticksInfo.ticksFormat));
    // axis = axis.tickFormat((value) =>
    //   moment(value).format(timeTicks.ticksFormat)
    // );
    axis = axis.tickFormat((tickValue) =>
      moment(tickValue).format(timeTicks.ticksFormat)
    );
  }

  const drawUnit = (svg) => {
    if (unit) {
      switch (positioning) {
        default:
        case "bottom":
          svg
            .append("text")
            .attr("text-anchor", "end")
            .attr("transform", `translate(${width - right}, ${height - 5})`)
            .style("font-size", 13)
            .style("color", "gray")
            .text(`[${unit}]`);
          // scale = scale.range([left, width - right]);
          // y = height - bottom;
          break;
        case "left":
          // x = left;
          // scale = scale.range([height - bottom, top]);
          svg
            .append("text")
            .attr("text-anchor", "end")
            // .attr("style", "transform-origin:center")
            // .attr("transform", `matrix(0, -1, 1, 0, 16, ${top})`)
            .attr("transform", `matrix(0, -1, 1, 0, 16, ${(height - 100) / 2})`)
            .style("font-size", 13)
            .style("color", "gray")
            .text(`[${unit}]`);
          break;
        case "top":
          // scale = scale.range([left, width - right]);
          // y = top;
          console.warn("not yet implemented");
          break;
        case "right":
          // x = width - right;
          // scale = scale.range([height - bottom, top]);
          svg
            .append("text")
            .attr("text-anchor", "end")
            // .attr("style", "transform-origin:center")
            // .attr("transform", `matrix(0, 1, -1, 0, ${width - 20},${top + 35})`)
            .attr(
              "transform",
              `matrix(0, 1, -1, 0, ${width - 20}, ${height / 2})`
            )
            .style("font-size", 13)
            .text(`${id === "y2" ? "Summe " : ""}[${unit}]`);
          // console.warn("not yet implemented");
          break;
      }
    } // otherwise nothing todo

    // if (this.axis[i].unit) {
    //   svg
    //     .append("text")
    //     .attr("text-anchor", "end")
    //     .attr("transform", `translate(410, 500)`)
    //     .style("font-size", 16)
    //     .text(`[${this.axis[i].unit}]`);
    // }
  };
  return {
    min,
    max,
    axis,
    scale,
    positioning,
    // positioning: positioning || "bottom",
    id,

    x,
    y,
    drawUnit,
    // tickFormat,
    tickValues: buildedTickValues,
    // ticks,
  };
}

export function drawDataset(dataset, axisMap, svg) {
  const { fill, outline } = dataset.drawInfo;
  const { type } = dataset;
  if (drawHandlers[type]) {
    drawHandlers[type]({ dataset, axisMap, svg });
  } else {
    dataset.data.forEach((item, index) => {
      const { colors } = item;
      const outlineColor = colors?.outline || outline;
      const fillColor = colors?.fill || fill;
      if (drawSingleHandlers[type]) {
        drawSingleHandlers[type]({
          item,
          drawInfo: {
            ...dataset.drawInfo,
            outline: outlineColor,
            fill: fillColor,
          },
          index,
          axisMap,
          xAxis: dataset.xAxis,
          yAxis: dataset.yAxis,
          dataset,
          svg,
        });
      } else {
        console.error(`Dataset type ${type} not supported (yet)`);
      }
    });
  }
}
export function drawGridLines({ axisMap, svg, color = "#FFFFFF" }) {
  // tickValues, //only set, when custom tick values should be shown
  // ticks, //tick count => only used, when tickValues is not set
  // domain = [0, 100], // e.g. [min, max]

  const xValues = [];
  const yValues = [];

  let tickValues =
    axisMap.x.tickValues ||
    axisMap.x.drawInfo.tickValues ||
    (axisMap.x.drawInfo.ticks &&
      axisMap.x.scale.ticks(axisMap.x.drawInfo.ticks));
  //x
  if (tickValues) {
    //TODO: const values = axisMap.x.axis.tickValues()
    tickValues.forEach((value) => xValues.push(axisMap.x.scale(value)));
  } else if (axisMap.x.drawInfo.ticks && axisMap.x.drawInfo.domain) {
    const [min, max] = axisMap.x.drawInfo.domain;
    const step = (max - min) / axisMap.x.drawInfo.ticks; // axisMap.x.drawInfo.ticks; // (max - min) / axisMap.x.drawInfo.ticks;
    //TODO: THIS IS SIMPLY FALSE !!!!!!
    let current = min;

    for (let i = 0; current < max; i++) {
      xValues.push(axisMap.x.scale(current));
      current += step;
    }
  } else {
    console.error("cannot draw grid lines");
    return;
  }

  const yAxis = axisMap.y ? axisMap.y : axisMap.y2;
  tickValues =
    yAxis.drawInfo.tickValues ||
    (yAxis.drawInfo.ticks && yAxis.scale.ticks(yAxis.drawInfo.ticks));
  //y
  if (tickValues) {
    tickValues.forEach((value) => yValues.push(yAxis.scale(value)));
  } else if (yAxis.drawInfo.ticks && yAxis.drawInfo.domain) {
    const [min, max] = yAxis.drawInfo.domain;
    const step = (max - min) / yAxis.drawInfo.ticks; // axisMap.y.drawInfo.ticks; //(max - min) / axisMap.y.drawInfo.ticks;
    let current = min;
    for (let i = 0; i < max; i++) {
      yValues.push(yAxis.scale(current));
      current += step;
    }
  } else {
    console.error("cannot draw grid lines");
    return;
  }

  //actual draw
  const x1 = axisMap["x"].min;
  const x2 = axisMap["x"].max;
  const y1 = axisMap["y"] ? axisMap["y"].min : axisMap["y2"].min;
  const y2 = axisMap["y"] ? axisMap["y"].max : axisMap["y2"].max;
  for (let i = 0; i < xValues.length; i++) {
    const x = xValues[i];
    svg
      .append("line")
      .attr("x1", x)
      .attr("x2", x)
      .attr("y1", y1)
      .attr("y2", y2)
      .attr("style", `stroke-width: 2; stroke: ${color}`);
  }
  for (let i = 0; i < yValues.length; i++) {
    const y = yValues[i];
    svg
      .append("line")
      .attr("y1", y)
      .attr("y2", y)
      .attr("x1", x1)
      .attr("x2", x2)
      .attr("style", `stroke-width: 2; stroke: ${color}`);
  }
}

export function drawBackground({
  x,
  y,
  height,
  width,
  svg,
  color = "#EFEFEF",
}) {
  svg
    .append("rect")
    .attr("x", x)
    .attr("y", y)
    .attr("width", width)
    .attr("height", height)
    .attr("style", `fill: ${color}; stroke: transparent`);
}

export function drawPrecipitationIntensity({ axisMap, svg, reduceInterval }) {
  const yAxis = axisMap.y ? axisMap.y : axisMap.y2;
  const minX = axisMap["x"].min;
  const maxX = axisMap["x"].max;
  // const minY = axisMap["y"] ? axisMap["y"].min : axisMap["y2"].min;
  // const maxY = axisMap["y"] ? axisMap["y"].max : axisMap["y2"].max;

  const intensityScale = precipitationIntensityScale(reduceInterval);
  intensityScale.forEach((element) => {
    const { color, range } = element;
    const minRangeScaled = yAxis.scale(range[0]);
    const maxRangeScaled = yAxis.scale(range[1]);

    const leftTopPoint = `${minX},${maxRangeScaled}`;
    const leftBottomPoint = `${minX},${minRangeScaled}`;
    const rightBottomPoint = `${maxX},${minRangeScaled}`;
    const rightTopPoint = `${maxX},${maxRangeScaled}`;

    svg
      .append("polygon") // attach a polygon
      // .style("stroke", "lavender") // colour the line
      .style("fill", color) // remove any fill colour
      .attr(
        "points",
        `${leftTopPoint}, ${leftBottomPoint}, ${rightBottomPoint}, ${rightTopPoint}`
      )
      .on("mouseover", (options) => openIntensityTooltip(options, element))
      .on("mousemove", (options) => moveIntensityTooltip(options))
      .on("mouseout", () => {
        removeIntensityTooltipNode();
      });
  });
}
