import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

const LayerLegend = ({ layer }) => {
  const getLegendGroupHeading = (layer) => {
    if (layer.legendGroups && layer.legendGroups.length > 0) {
      const { heading } = layer.legendGroups[0];
      return heading;
    } else {
      return null;
    }
  };

  if (layer && layer.legend) {
    return (
      <>
        <Typography>{getLegendGroupHeading(layer)}</Typography>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            // paddingTop: "0",
            paddingBottom: "0",
          }}
        >
          {layer.legend.map((item, index) => (
            <ListItem
              key={item.label}
              alignItems="flex-start"
              sx={{ padding: "0", pb: "1px" }}
            >
              <ListItemAvatar
                sx={{
                  alignSelf: "center",
                  minWidth: item.width,
                  minHeight: item.height,
                  margin: "0",
                }}
              >
                <img
                  src={`data:image/png;base64,${item.imageData}`}
                  alt={item.label}
                  loading="lazy"
                />
              </ListItemAvatar>
              <ListItemText
                primary={item.label}
                sx={{ alignSelf: "center", pl: 2, margin: "0" }}
              />
            </ListItem>
          ))}
        </List>
      </>
    );
  }

  return <Typography pt={1}>Leider keine Legende vorhanden!</Typography>;
};

export default LayerLegend;
