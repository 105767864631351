import { observer } from "mobx-react-lite";
import React from "react";
import { styled } from "@mui/material/styles";
import { CardContent } from "@mui/material";
import TabWidget, { TabContent } from "widgets/TabWidget";
import SearchContent from "./SearchContent";
import RootStore from "stores/RootStore";
import MeasurementsContent from "./MeasurementsContent";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ListAltIcon from "@mui/icons-material/ListAlt";

const CardContentNoPadding = styled(CardContent)(`
  padding-top:0;
  &:last-child {
    padding-bottom: 0;
  }
`);

const Widget = observer((props) => {
  const { currentApp, apps } = RootStore;
  const isAppPikett = currentApp === apps[1] ? false : true;
  const { pikettTabValue, hydrowebTabValue, setHydroPikettTabValue } =
    RootStore.uiStore;
  const tabValue = isAppPikett ? pikettTabValue : hydrowebTabValue;

  const tabContents = [
    {
      id: "search-tab",
      tabTitle: "Suche",
      icon: <TravelExploreIcon />,
      content: (
        <SearchContent
          isAppPikett={isAppPikett}
          CardContentNoPadding={CardContentNoPadding}
        />
      ),
    },
    {
      id: "measurements-tab",
      tabTitle: "Messwerte",
      icon: <ListAltIcon />,
      content: <MeasurementsContent isAppPikett={isAppPikett} />,
    },
  ];

  return (
    <TabWidget tabValue={tabValue} onTabSelect={setHydroPikettTabValue}>
      {tabContents.map((tabContent) => (
        <TabContent
          key={tabContent.id}
          id={tabContent.id}
          tabTitle={tabContent.tabTitle}
          icon={tabContent.icon}
        >
          {tabContent.content}
        </TabContent>
      ))}
    </TabWidget>
  );
  // return (
  //   <TabWidget
  //     firstTabContent={
  //       <SearchContent
  //         isAppPikett={isAppPikett}
  //         CardContentNoPadding={CardContentNoPadding}
  //       />
  //     }
  //     secondTabContent={<MeasurementsContent isAppPikett={isAppPikett} />}
  //   />
  // );
});

export default Widget;
