import React, { useEffect, useRef } from "react";
import Canvg from "canvg";

import {
  STANDARDWIDTH,
  STANDARDHEIGHT,
  STANDARDPADDING,
  // drawHandlers,
  // drawSingleHandlers,
} from "./constants";

import {
  createSvg,
  drawAxis,
  drawDataset,
  drawGridLines,
  drawBackground,
  drawPrecipitationIntensity,
} from "./utils";
import RootStore from "stores/RootStore";

export default class Chart {
  constructor({
    height = STANDARDHEIGHT,
    width = STANDARDWIDTH,
    padding = STANDARDPADDING,
    bgcolor = "#EFEFEF",
    gridLineColor = "rgba(0,0,0,0.1)",
    tooltipOptions,
  }) {
    if (typeof padding === "number") {
      this.padding = {
        bottom: padding * 1,
        left: padding * 1,
        top: padding * 1,
        right: padding * 1,
      };
    } else if (!padding) {
      this.padding = {
        bottom: STANDARDPADDING,
        left: STANDARDPADDING,
        top: STANDARDPADDING,
        right: STANDARDPADDING,
      };
    } else {
      this.padding = {
        bottom:
          padding.bottom !== undefined ? padding.bottom * 1 : STANDARDPADDING,
        left: padding.left !== undefined ? padding.left * 1 : STANDARDPADDING,
        top: padding.top !== undefined ? padding.top * 1 : STANDARDPADDING,
        right:
          padding.right !== undefined ? padding.right * 1 : STANDARDPADDING,
      };
    }
    this.gridLineColor = gridLineColor;
    this.bgcolor = bgcolor;
    this.height = height;
    this.width = width;
    this.node = document.createElement("div");
    //this.svg = createSvg(this);
    this.axis = [];
    this.datasets = [];
    this.tooltipOptions = tooltipOptions;
    this.tooltipListener = [];
  }

  updateAxis(currentStore, stores) {
    const computeMinY = (min, max) => {
      return min - (max - min) * 0.075;
    };
    const computeMaxY = (min, max) => {
      return max + (max - min) * 0.075;
    };
    let miny = Infinity,
      maxy = -Infinity;
    if (!currentStore.visible) {
      if (currentStore.min.y < miny) {
        miny = currentStore.min.y;
      }
      if (currentStore.max.y > maxy) {
        maxy = currentStore.max.y;
      }
    }
    const storesToCheck = stores.filter((sto) => sto !== currentStore);
    storesToCheck.forEach((store) => {
      if (store.visible) {
        if (store.min.y < miny) {
          miny = store.min.y;
        }
        if (store.max.y > maxy) {
          maxy = store.max.y;
        }
      }
    });
    const axisID = currentStore.chartData.yAxis;
    const targetAxis = this.axis.filter(
      (ax) => (ax.id ? ax.id : ax[`${axisID}`].id) === axisID
    );
    targetAxis[0].domain = [
      miny === 0 ? 0 : computeMinY(miny, maxy),
      computeMaxY(miny, maxy),
    ];

    this.axis = this.axis.map((ax) =>
      targetAxis.id === ax.id ? targetAxis : ax
    );
    currentStore.setVisible(!currentStore.visible);
  }

  addAxis(axis) {
    this.axis.push(axis);
    this.axis[`${axis.id}`] = axis;
  }

  removeAxis(axisID) {
    console.log("removeAxis");
    RootStore.mapStore.removedYAxis = this.axis.filter(
      (ax) => (ax.id ? ax.id : ax[`${axisID}`].id) === axisID
    )[0];
    this.axis = this.axis.filter(
      (ax) => (ax.id ? ax.id : ax[`${axisID}`].id) !== axisID
    );
  }

  destroy() {
    if (this.svg) {
      this.svg.remove();
      this.svg = null;
    }
  }

  addDatasetTooltipListener(dataset) {
    if (this.tooltipOptions) {
      this.tooltipListener.push(
        dataset.on("mouseover", (options) => {
          this.openTooltip(dataset, options);
        })
      );
      this.tooltipListener.push(
        dataset.on("mouseout", (options) => {
          this.closeTooltip(dataset, options);
        })
      );
    }
  }

  initTooltips(tooltipOptions) {
    this.tooltipListener.forEach((listener) => {
      listener.remove();
    });
    this.tooltipListener = [];
    if (tooltipOptions) {
      this.datasets.forEach((dataset) => {
        this.addDatasetTooltipListener(dataset);
      });
    }
    this.tooltipOptions = tooltipOptions;
  }

  createTooltipNode() {
    const node = document.createElement("div");
    node.style.position = "absolute";
    node.style.display = "none";
    node.style.background = "rgba(0, 0, 0, 0.6)";
    node.style.color = "#FFF";
    node.style.padding = "10px";
    node.style.borderRadius = "4px";
    node.style.zIndex = "9999";
    node.style.transition =
      "opacity 0.5s ease-in-out, height 0.5s ease-in-out, width 0.5s ease-in-out";
    node.className = "d3-chart-tooltip";
    this.tooltipNode = node;

    document.body.appendChild(node);
  }

  removeTooltipNode() {
    if (this.tooltipNode) {
      this.tooltipNode.remove();
      this.tooltipNode = null;
    }
  }

  getTooltipContent(options) {
    const {
      data: { x, y },
      dataset: { formatY, name },
      axisMap,
    } = options;
    const { function: generator, content, formatX } = this.tooltipOptions;
    const axisMapY = axisMap.y ? axisMap.y : axisMap.y2;
    if (generator) {
      return generator(options, this.tooltipOptions);
    } else {
      if (typeof content === "string") {
        return content
          .replace(/\$\{x\}/gi, `${formatX ? formatX(x) : x}`)
          .replace(/\$\{unit-x\}/gi, axisMap.x.drawInfo.unit || "")
          .replace(/\$\{y\}/gi, `${formatY ? formatY(y) : y}`)
          .replace(/\$\{unit-y\}/gi, axisMapY.drawInfo.unit || "")
          .replace(/\$\{name\}/gi, name);
      }
    }
    return null;
  }

  openTooltip(dataset, options) {
    this.closeTooltip();
    if (this.tooltipOptions) {
      const { offset } = this.tooltipOptions;
      this.createTooltipNode();
      const {
        //  event, index, axisMap, pos,
        pagePosition,
      } = options;
      const node = this.tooltipNode;
      // set position
      const { x, y, width, height } = pagePosition;
      const { x: offsetX = 15, y: offsetY = 15 } = offset || {};
      node.style.top = y + height / 2 + offsetX + "px";
      node.style.left = x + width / 2 + offsetY + "px";
      //offset 5 => maybe in options aber mit default wert 5

      // create Content
      const content = this.getTooltipContent(options);
      if (content) {
        if (content instanceof HTMLElement) {
          node.appendChild(content);
        } else {
          node.innerHTML = content; // TODO: ??
        }
        // show it
        node.style.display = "block";
      }
    }
  }

  closeTooltip(dataset, options) {
    this.removeTooltipNode();
  }

  addDataset(dataset) {
    const yAxis = this.axis[dataset.yAxis];
    if (!yAxis) {
      console.error(
        `yAxis ${dataset.yAxis} has to be added to the chart before.`
      );
    } else {
      if (yAxis.format === undefined && dataset.formatY) {
        // TODO: maybe later other override rules
        yAxis.format = dataset.formatY;
      }
      this.datasets.push(dataset);
      this.addDatasetTooltipListener(dataset);

      dataset.on("change", () => {
        this.draw();
      });
    }
  }

  toPng() {
    return new Promise((resolve, reject) => {
      const { height, width } = this;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.height = height + "px";
      canvas.width = width + "px";
      canvas.style.pointerEvents = "none";
      canvas.style.opacity = "0";
      canvas.style.position = "absolute";
      canvas.style.top = "0";
      canvas.style.left = "0";
      document.body.appendChild(canvas);
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(0, 0, width, height);

      // get svg string - https://stackoverflow.com/a/25470004/16952103
      const serializer = new XMLSerializer();
      const svg = serializer.serializeToString(this.svg.nodes()[0]);

      const obj = Canvg.fromString(ctx, svg);
      obj
        .render()
        .then(() => {
          canvas.toBlob(
            function (blob) {
              const url = canvas.toDataURL("image/png", 1);
              const data = ctx.getImageData(0, 0, width, height);
              // const burl = URL.createObjectURL(blob);
              // window.open(burl);

              resolve({ url, data, svg });
            },
            "image/png",
            1
          );
        })
        .catch((e) => {
          console.error("Could not convert Chart to png", e);
          reject(e);
        });
    });
  }

  draw() {
    console.log("chart draw");
    window.someChart = this;
    // const start = new Date();
    this.destroy();
    const { height, width, padding } = this;
    // const { bottom, left, top, right } = padding;

    const svg = createSvg(this);
    this.svg = svg;
    const axisMap = {};

    let bgx = 0;
    let bgy = 0;
    let bgwidth = 0;
    let bgheight = 0;
    for (let i = 0; i < this.axis.length; i++) {
      if (this.axis[i].visible === false) {
        // undefined is equal to visible = true !
        continue;
      }
      const meta = {
        drawInfo: this.axis[i],
        ...drawAxis({ padding, height, width, ...this.axis[i] }),
      };
      const { min, max, axis, id, x, y, drawUnit, positioning /*, scale */ } =
        meta;
      if (positioning === "bottom" || positioning === "top") {
        bgx = min;
        bgwidth = max - min;

        svg
          .append("g")
          .attr("transform", `translate(${x}, ${y})`)
          .style("font-size", 12)
          .call(axis)
          .selectAll("text")
          .attr("transform", "translate(-10,10)rotate(-45)")
          .style("text-anchor", "end")
          .style("color", "gray");
      } else {
        bgy = max;
        bgheight = min - max;
        svg
          .append("g")
          .attr("transform", `translate(${x}, ${y})`)
          .style("font-size", 12)
          .style("color", "gray")
          .call(axis);
      }
      drawUnit(svg);
      axisMap[id] = meta;
    }
    if (this.bgcolor) {
      drawBackground({
        x: bgx,
        y: bgy,
        height: bgheight,
        width: bgwidth,
        svg,
        color: this.bgcolor,
        // color = "#CDCDCD",
      });
    }

    if (this.datasets.length > 0 && this.datasets[0].drawIntensity) {
      const reduceInterval = this.datasets[0].reduceInterval;
      drawPrecipitationIntensity({ axisMap, svg, reduceInterval });
    }

    if (this.gridLineColor) {
      drawGridLines({
        axisMap,
        svg,
        color: this.gridLineColor,
        // color = "#FFFFFF"
      });
    }
    for (let i = 0; i < this.datasets.length; i++) {
      const dataset = this.datasets[i];
      // console.log(dataset.visible);
      if (dataset.visible) drawDataset(dataset, axisMap, svg);
    }
    this.axisMap = axisMap;
    // const end = new Date();
    // console.log(`time needed to draw ${end - start}ms`);
  }
}

export const ChartComponent = ({ chart, style }) => {
  const ref = useRef();

  useEffect(() => {
    while (ref.current.children.length) {
      ref.current.children[0].remove();
    }
    chart?.node && ref.current.appendChild(chart.node);
    chart && chart.draw();
  }, [chart]);

  // chart && chart.draw();
  // console.log("redraw Chart");
  return <div ref={ref} style={style}></div>;
};
