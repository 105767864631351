import DownloadList from "./DownloadList";
import DownloadReport from "./DownloadReport";

const ExtraMenuItemContent = ({ mp, extraMenuItems }) => {
  // console.log(mp);
  const primaryKey = mp.groupParam;

  if (extraMenuItems[primaryKey].downloadParameters.length > 0) {
    return <DownloadList mp={mp} extraMenuItems={extraMenuItems} />;
  } else {
    return <DownloadReport mp={mp} extraMenuItems={extraMenuItems} />;
  }
};

export default ExtraMenuItemContent;
