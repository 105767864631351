import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";

const getTitle = (catchmentArea) => {
  return `Einzugsgebiet: ${catchmentArea.catchmentAreaInfo.name}`;
};

const Header = ({ open, close, catchmentArea, nls, setOpen }) => {
  const theme = useTheme();
  const { main: mainColor, contrastText } = theme.palette.primary;

  return (
    <div
      style={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        flexGrow: 0,
        flexShrink: 0,
        height: 35,
        width: "100%",
        padding: 5,
        boxSizing: "border-box",
        background: mainColor,
        color: contrastText,
      }}
    >
      <IconButton
        color="inherit"
        aria-label={open ? nls.closeMenu : nls.openMenu}
        onClick={() => {
          setOpen(!open);
        }}
        style={{ marginRight: 15, flexGrow: 0 }}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        component="div"
        style={{
          fontWeight: "bold",
          fontSize: 18,
          flexGrow: 1,
          cursor: "move",
        }}
        className="dragHandleClass"
      >
        {getTitle(catchmentArea)}
      </Typography>
      <IconButton
        color="inherit"
        aria-label={nls.close}
        onClick={() => {
          close();
        }}
        style={{ marginLeft: 15, flexGrow: 0 }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default Header;
