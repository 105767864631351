import React from "react";
import { GGITreeViewItemLabel } from "./TreeViewItemLabel";

export const renderThemeItemsFromData = (
  treeItems,
  currentTreeDepth,
  treeItemLabelKeys,
  treeItemMenuOptions,
  handleMenuOptionsSelection
) => {
  return treeItems.map((treeItemData) => {
    let children = undefined;
    if (treeItemData.children && treeItemData.children.length > 0) {
      const nextTreeDepth = currentTreeDepth + 1;
      children = renderThemeItemsFromData(
        treeItemData.children,
        nextTreeDepth,
        treeItemLabelKeys,
        treeItemMenuOptions,
        handleMenuOptionsSelection
      );
    }
    return (
      <div
        key={treeItemData.ID}
        nodeId={treeItemData.ID}
        children={children}
        isSelectable
        label={treeItemLabelKeys[currentTreeDepth](treeItemData)}
        content={
          <GGITreeViewItemLabel
            treeItemData={treeItemData}
            label={treeItemLabelKeys[currentTreeDepth](treeItemData)}
            treeItemMenuOptions={treeItemMenuOptions[currentTreeDepth]}
            handleMenuOptionsSelection={handleMenuOptionsSelection}
          />
        }
      />
    );
  });
};

export const renderTreeItemsFromData = (
  treeItems,
  currentTreeDepth,
  treeItemLabelKeys,
  treeItemMenuOptions,
  handleMenuOptionsSelection
) => {
  const isCheckbox = false;
  return treeItems.map((treeItemData) => {
    let children = undefined;
    if (treeItemData.children && treeItemData.children.length > 0) {
      const nextTreeDepth = currentTreeDepth + 1;
      children = renderTreeItemsFromData(
        treeItemData.children,
        nextTreeDepth,
        treeItemLabelKeys,
        treeItemMenuOptions,
        handleMenuOptionsSelection
      );
    }
    return (
      <div
        key={treeItemData.ID}
        nodeId={treeItemData.ID}
        children={children}
        label={treeItemLabelKeys[currentTreeDepth](treeItemData)}
        content={
          <GGITreeViewItemLabel
            treeItemData={treeItemData}
            label={treeItemLabelKeys[currentTreeDepth](treeItemData)}
            isCheckbox={isCheckbox}
            colorKey="Farbe"
            treeItemMenuOptions={treeItemMenuOptions[currentTreeDepth]}
            handleMenuOptionsSelection={handleMenuOptionsSelection}
          />
        }
      />
    );
  });
};
