import { observable, action, computed, makeObservable } from "mobx";
import de from "../locales/de";
import en from "../locales/en";

class I18n {
  labels = {};

  _loaders = {};

  constructor() {
    makeObservable(this, {
      labels: observable,
      loadLocale: action,
      isRTL: computed,
      otherLanguage: computed,
      otherLanguageLabel: computed,
    });
  }

  loadLocale(locale) {
    if (!locale) return;
    this.locale = locale;

    if (this.isRTL) {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
    if (locale === "de") {
      this.labels = de;
    } else {
      this.labels = en;
    }
  }

  get isRTL() {
    return this.locale === "ar";
  }

  get otherLanguage() {
    return this.locale === "en" ? "de" : "en";
  }

  get otherLanguageLabel() {
    return this.locale === "en" ? this.labels.de : this.labels.en;
  }
}

export default new I18n();
