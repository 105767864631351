import React, { useEffect, useState } from "react";
import RootStore from "stores/RootStore";
import { observer } from "mobx-react-lite";
import TooltipWidget from "widgets/TooltipWidget/TooltipWidget";

const ErrorMessageTooltip = observer(() => {
  const { loadingErrorMessage } = RootStore.hydroWebFeatures;
  const [openTooltip, setOpenTooltip] = useState(false);

  const onCloseHandle = () => {
    setOpenTooltip(false);
  };

  useEffect(() => {
    if (loadingErrorMessage.length) {
      setOpenTooltip(true);
    }
  }, [loadingErrorMessage]);

  return (
    <TooltipWidget
      open={openTooltip}
      onCloseHandle={onCloseHandle}
      textMessage={loadingErrorMessage}
    />
  );
});

export default ErrorMessageTooltip;
