import React, { useState } from "react";
import { Steps } from "intro.js-react";
import { observer } from "mobx-react-lite";
import RootStore from "stores/RootStore";

const Widget = () => {
  const { introStepsEnabled, introSteps, exitIntrosteps, handleMultiPage } =
    RootStore.uiStore;

  const [steps, setSteps] = useState(introSteps);

  return (
    <div>
      <Steps
        enabled={introStepsEnabled}
        options={{ nextLabel: "Weiter", prevLabel: "Zurück", skipLabel: "x"/* das x oben rechts */, doneLabel: "Fertig" }}
        steps={introSteps}
        initialStep={0}
        ref={(introSteps) => {
          setSteps(introSteps);
        }}
        onExit={exitIntrosteps}
        onBeforeChange={(nextStepIndex, nextElement) => {
          handleMultiPage(nextStepIndex);
          if (introSteps[nextStepIndex]) {
            steps.updateStepElement(nextStepIndex);
          }
        }}
      />
    </div>
  );
};

export default observer(Widget);
