import React, { useCallback } from "react";

const WidgetDragger = ({
  className,
  minWidgetAreaWidth,
  maxWidgetAreaWidth,
  setWidgetAreaWidth,
}) => {
  const handleMouseDown = (e) => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const pauseEvent = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  };

  const handleMouseMove = useCallback((e) => {
    pauseEvent(e);
    const newWidth = document.body.offsetWidth - e.clientX;
    if (newWidth > minWidgetAreaWidth && newWidth < maxWidgetAreaWidth) {
      setWidgetAreaWidth(newWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div onMouseDown={(e) => handleMouseDown(e)} className={className}></div>
  );
};

export default WidgetDragger;
