import { Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const MeasurementsButton = observer((props) => {
  const { isAppPikett } = props;
  const { handleRefreshButton } = RootStore.hydroWebStore;
  return (
    <Button
      id="pikett-hydroweb-refresh"
      fullWidth
      variant="outlined"
      onClick={() =>
        // isAppPikett
        //   ? RootStore.pikettFeatures.refresh()
        //   : RootStore.hydroWebFeatures.refresh()
        handleRefreshButton(isAppPikett)
      }
    >
      Daten neu laden
    </Button>
  );
});

export default MeasurementsButton;
