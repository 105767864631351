import { observer } from "mobx-react-lite";
import React from "react";
import FilterAspects from "./FilterAspects";
import Legend from "./Legend";
import SearchAspects from "./SearchAspects";

const SearchContent = observer((props) => {
  const { CardContentNoPadding, isAppPikett } = props;

  return (
    <div style={{ margin: "15px" }}>
      <Legend />
      <SearchAspects CardContentNoPadding={CardContentNoPadding} />
      <FilterAspects
        isAppPikett={isAppPikett}
        CardContentNoPadding={CardContentNoPadding}
      />
    </div>
  );
});

export default SearchContent;
