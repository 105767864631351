import { Button, Step, StepButton, Stepper, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";
import MeasuringPointsList from "./MeasuringPointsList";
import Regions from "./Regions";
import Themes from "./Themes";

const RegionsThemesSelectionSteps = observer((props) => {
  const {
    steps,
    activeStep,
    completedSteps,
    handleStep,
    handleNextStep,
    handleBackStep,
    handleResetSteps,
    isThemeSelectionStepCompleted,
    selectedThemesTimeSeries,
  } = RootStore.mapScopeThemeStore;

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        alternativeLabel
        id="envis-serach-steps"
      >
        {Object.entries(steps).map((label, index) => (
          <Step
            key={label[0]}
            completed={completedSteps[index]}
            id={`envis-step-${index}`}
          >
            <StepButton
              color="inherit"
              onClick={handleStep(index)}
              // sx={{ padding: "20px 16px 16px 16px" }}
            >
              {label[0]}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            id="backToRegionSelection"
            variant="outlined"
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBackStep}
            sx={{ mr: 1 }}
          >
            Zurück
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            id={
              activeStep === 1 && selectedThemesTimeSeries.length > 0
                ? "showOnTheMap"
                : "nextToThemesSelection"
            }
            variant="contained"
            onClick={handleNextStep}
            sx={{ mr: 1 }}
            disabled={activeStep > 1 ? true : false}
          >
            {activeStep === 1 && selectedThemesTimeSeries.length > 0
              ? "Auf Karte anzeigen"
              : "Weiter"}
          </Button>
        </Box>
        <Box sx={{ mt: 2, mb: 1 }}>
          <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
            <Regions />
          </Box>
          <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
            <Themes />
          </Box>
          {activeStep === 0 || activeStep === 1 ? (
            <React.Fragment />
          ) : !isThemeSelectionStepCompleted() ? (
            "Keine Themen wurde ausgewählt. Deswegen wurde keine Daten auf der Karte angezeigt. Bitte wählen Sie mindestens ein Thema!"
          ) : (
            <>
              <Box sx={{ mt: 2, mb: 1 }}>
                <MeasuringPointsList />
              </Box>
              <Button
                variant="outlined"
                color="warning"
                onClick={handleResetSteps}
              >
                Suche Zurücksetzen
              </Button>
            </>
          )}
        </Box>
      </React.Fragment>
    </Box>
  );
});

export default RegionsThemesSelectionSteps;
