/* eslint-disable max-classes-per-file */
import {
  makeObservable,
  observable,
  action,
  computed /* , observe */,
  reaction,
  when,
} from "mobx";

import APIHelper from "libs/APIHelper";
// import { observer } from 'mobx-react';

const sortLayer = (layers) => {
  return (
    layers
      // .filter((layer) => APIHelper.hasRenderer(layer))
      .sort((a, b) => {
        let result = 0;
        if (
          APIHelper.getUniqueIdentifierNumberOfLayer(a) <
          APIHelper.getUniqueIdentifierNumberOfLayer(b)
        ) {
          result = -1;
        } else if (
          APIHelper.getUniqueIdentifierNumberOfLayer(a) >
          APIHelper.getUniqueIdentifierNumberOfLayer(b)
        ) {
          result = 1;
        }
        return result;
      })
      .sort((a, b) => {
        let result = 0;
        const dimA = APIHelper.getLayersDimension(a);
        const dimB = APIHelper.getLayersDimension(b);
        if (dimA < dimB) {
          result = 1;
        } else if (dimA > dimB) {
          result = -1;
        }
        return result;
      })
  );
};

class Layers {
  // layers = [];

  role = null;
  pendingRole = null;

  layerManager = null;

  selectedTemplate = null;

  isLoading = false;

  loadingPromise = null;

  currentEditRule = null;

  editorLayers = [];

  selection = [];

  selectionPending = false;

  roleLayers = [];

  operationalLayers = [];

  get layers() {
    return this.roleLayers.concat(this.operationalLayers);
  }

  get selectionManager() {
    return this.layerManager && this.layerManager.selectionManager;
  }

  constructor() {
    makeObservable(this, {
      layers: computed,
      roleLayers: observable,
      operationalLayers: observable,
      selectionPending: observable,
      role: observable.ref,
      pendingRole: observable.ref,
      layerManager: observable.ref,
      selectedTemplate: observable.ref,
      isLoading: observable.ref,
      loadingPromise: observable.ref,
      currentEditRule: observable.ref,
      editorLayers: observable.ref,
      selection: observable.ref,
      selectionManager: computed,
      setSelectedTemplate: action,
      deselectTemplate: action,
      setRole: action,
      addLayer: action,
      addRoleLayer: action,
      removeAllRoleLayer: action,
      removeRoleLayer: action,
      removeLayer: action,
      map: computed,
      view: computed,
      showEditRule: action,
      hideEditRule: action,
      setLayerVisibleByName: action,
      hideOptionalLayersExcept: action,
      showOptionalLayersExcept: action,
      setLayerManager: action,
    });
    when(
      () => this.layerManager,
      () => {
        this.selectionManager.addEventListener(
          "selection-start",
          function (e) {
            if (e.data && e.data.error) {
              this.selectionPending = false;
            } else {
              this.selectionPending = true;
            }
          }.bind(this)
        );
        this.selectionManager.addEventListener(
          "selection-end",
          function (e) {
            this.selectionPending = false;
          }.bind(this)
        );
      }
    );
  }

  setRole(role) {
    this.role = role;
  }

  setLayerManager(lm) {
    this.layerManager = lm;
  }

  setSelectedTemplate(tpl) {
    this.selectedTemplate = tpl;
  }

  deselectTemplate() {
    this.selectedTemplate = null;
  }

  setRoleLayers(layers) {
    this.removeAllRoleLayer();
    sortLayer(layers).forEach((layer) => {
      this.addRoleLayer(layer);
    });
  }

  addLayer(layer) {
    const wrapper = new Layer(layer, this.map, this.view);
    this.operationalLayers.push(wrapper);
    return wrapper;
  }

  addRoleLayer(layer) {
    const wrapper = new Layer(layer, this.map, this.view);
    this.roleLayers.push(wrapper);
    return wrapper;
  }

  removeAllRoleLayer() {
    while (this.roleLayers.length) {
      this.removeRoleLayer(this.roleLayers[0].layer);
    }
  }

  removeRoleLayer(layer) {
    let index = -1;
    this.roleLayers.forEach((l, i) => {
      if (l.layer === layer || (layer && l.layer === layer.layer)) {
        index = i;
      }
    });
    if (index !== -1) {
      const toRemove = this.roleLayers.splice(index, 1)[0];
      toRemove.cleanup();
      return toRemove;
    }
    return false;
  }

  removeLayer(layer) {
    let index = -1;
    this.operationalLayers.forEach((l, i) => {
      if (l.layer === layer || (layer && l.layer === layer.layer)) {
        index = i;
      }
    });
    if (index !== -1) {
      const [toRemove] = this.operationalLayers.splice(index, 1)[0];
      toRemove.cleanup();
      return toRemove;
    }
    return false;
  }

  getLayerByName(name) {
    const layer = this.layers.filter((l) => {
      // eslint-disable-next-line eqeqeq
      return l.name == name; // string comparison
    });
    if (layer && layer.length) {
      return layer[0];
    }
    return null;
  }

  getLayerById(id) {
    const layer = this.layers.filter((l) => {
      // eslint-disable-next-line eqeqeq
      return l.id == id; // string comparison
    });
    if (layer && layer.length) {
      return layer[0];
    }
    return null;
  }

  getLayerByUrl(url) {
    const layer = this.layers.filter((l) => {
      // eslint-disable-next-line eqeqeq
      return l.url == url; // string comparison
    });
    if (layer && layer.length) {
      return layer[0];
    }
    return null;
  }

  getPermissionsForLayer(player) {
    const layer = this.getLayer(player);
    return layer && layer.permissions;
  }

  getLayer(name) {
    if (typeof name === "object") {
      // assume its an layer object
      let layer = null;
      this.layers.forEach((l) => {
        if (
          l.layer === name ||
          (name && name.layer && l.layer === name.layer)
        ) {
          layer = l;
        }
      });
      return layer;
    }
    return (
      this.getLayerByName(name) ||
      this.getLayerByName(name) ||
      this.getLayerByUrl(name)
    );
  }

  get map() {
    return this.layerManager && this.layerManager.map;
  }

  get view() {
    return this.layerManager && this.layerManager.view;
  }

  showEditRule(layerStoreObject, setPrevious) {
    if (this.currentEditRule) this.hideEditRule();
    this.layerManager.showEditRule(layerStoreObject.layer, setPrevious);
    // TODO: show
  }

  hideEditRule(unsetPrevious) {
    if (this.currentEditRule) {
      // TODO: hide
      this.layerManager.resetEditRule(unsetPrevious !== false); // when undefined reset it
    }
  }

  setLayerVisibleByName(name, visibility) {
    const layer = this.getLayer(name);
    if (layer) {
      layer.setVisibility(visibility);
    }
  }

  addChangedHandler(callback) {
    APIHelper.onLayersChanged(this.map, callback);
  }

  hideOptionalLayersExcept(showLayers) {
    // TODO: also operational LAyers or only Role Layers???
    const toShow = showLayers || []; // ? showLayers.map((l) => l.name) : [];
    this.layers.forEach((layer) => {
      if (layer.readonly) {
        layer.setVisibility(
          toShow.indexOf(layer.name) !== -1 || toShow.indexOf(layer.id) !== -1
        );
      }
    });
  }

  showOptionalLayersExcept(hideLayers) {
    const toHide = hideLayers || [];
    this.layers.forEach((layer) => {
      if (layer.readonly) {
        layer.setVisibility(
          toHide.indexOf(layer.name) !== -1 || toHide.indexOf(layer.id) !== -1
        );
      }
    });
  }

  addLoadingPromise() {
    console.warn("not implemented", "addLoadingPromise", arguments);
  }

  getLayerSelectionCharackteristics(withoutValues) {
    const result = {};
    this.layers.forEach((layer) => {
      const { name, id, url } = layer;
      const layername = name || id || url;
      result[layername] = layer.getSelectionCharackteristics(withoutValues);
    });
    return result;
  }

  moveLayer(layer, position) {
    let index = -1;
    this.layers.forEach((l, i) => {
      if (l.layer === layer.layer) {
        index = i;
      }
    });
    if (index !== -1) {
      const layerobj = this.layers.splice(index, 1)[0];
      this.layers.splice(position, 0, layerobj);
      APIHelper.setLayerIndexOnMap(layerobj.layer, position + 1, this.map);
    } else {
      console.error(
        "You tried to move a layer that is unknown to the LayerStore."
      );
      // debugger;
    }
  }

  // TODO: reorder
}

const LayerStore = new Layers();

class LayerWrapper {
  layer;

  symbols;

  permissions;

  visibility;

  checked;

  addToMap(map) {
    APIHelper.addToMap(map, this.layer);
  }

  removeFromMap(map) {
    APIHelper.removeFromMap(map, this.layer);
  }

  get visible() {
    return this.visibility;
  }

  set visible(visible) {
    this.setVisibility(visible);
  }

  visibleAtMapScale = true;

  selectable;

  _opacity;

  get opacity() {
    return this._opacity;
  }

  set opacity(value) {
    this.setOpacity(this.layer, value);
  }

  id;

  url;

  loaded;

  unique;

  name;

  legendInfo;

  fields = [];

  editRuleDisplayed = false;

  geometryType = null;

  displyfield = "";

  get maps() {
    const aliasMap = {};
    const fieldMap = {};
    if (this.featureLayer) {
      for (let i = 0; i < this.fields.length; i++) {
        const { name, alias } = this.fields[i];
        if (alias) {
          aliasMap[alias] = alias;
          aliasMap[name] = alias;
          fieldMap[alias] = this.fields[i];
        } else {
          aliasMap[name] = name;
        }
        fieldMap[name] = this.fields[i];
      }
    }
    return {
      field: fieldMap,
      alias: aliasMap,
    };
  }

  listeners = [];

  constructor(layer, map, view) {
    const id = APIHelper.getLayerID(layer);
    this.checked = true; // hauptsächlich für additionalLayers
    this.loaded = false;
    const renderer = APIHelper.getRenderer(layer);
    const renderersymbol = APIHelper.getRendererSymbol(renderer);
    let symbols = [];
    if (renderersymbol) {
      symbols.push({
        symbol: renderersymbol,
        label: APIHelper.getLayerID(layer),
      });
    } else if (renderer) {
      symbols = APIHelper.getRendererSymbols(renderer);
    }
    symbols.geometryType = APIHelper.getLayerGrapicsType(layer);

    this.geometryType = APIHelper.getLayerGrapicsType(layer);
    symbols.rendererType = APIHelper.getRendererType(renderer);

    this.symbols = symbols;

    this.layer = layer;
    this.url = APIHelper.getLayerURL(layer);
    this.id = id;
    this.name = APIHelper.getLayerName(layer);
    this.unique = APIHelper.getUniqueIdentifierNumberOfLayer(layer);
    this.visibility = APIHelper.isLayerVisible(layer);
    this._opacity = APIHelper.getLayerOpacity(layer);
    const permissions = layer && layer.layerInfo;
    this.permissions = permissions;
    this.visibleAtMapScale = APIHelper.isLayerVisibleAtMapScale(
      layer,
      map,
      view
    );

    this.featureLayer = APIHelper.isFeatureLayer(layer);

    if (this.featureLayer) {
      this.selectable = // hiess vorher editable
        permissions &&
        (permissions.update_attribute ||
          permissions.update_geometry ||
          permissions.insert ||
          permissions.delete);
      this.fields = APIHelper.getFields(layer);
      this.displayField = layer
        ? APIHelper.getDisplayField(layer) || "displayfield"
        : "displayfield";
    } else {
      this.selectable = false;
      this.displayField = undefined;
    }

    this.legendInfo = null;
    this.legendState = "not loaded";
    makeObservable(this, {
      layer: observable.ref,
      permissions: observable.ref,
      symbols: observable,
      visibility: observable,
      visible: computed,
      visibleAtMapScale: observable,
      loaded: observable,
      selectable: observable,
      checked: observable.ref,
      setChecked: action,
      //symb_opacityols: observable,
      opacity: computed,
      id: observable,
      url: observable,
      unique: observable,
      name: observable,
      // type: observable,
      fields: observable,
      editRuleDisplayed: observable,
      geometryType: observable.ref,
      displyfield: observable,
      maps: computed,
      idField: computed,
      setVisibility: action,
      setOpacity: action,
      editRule: computed,
      readonly: computed,
      showEditRule: action,
      hideEditRule: action,
      legendInfo: observable,
      legendState: observable,
    });
    this.listeners.push(
      APIHelper.onLayerLoadedChange(
        layer,
        (loaded) => {
          this.loaded = loaded;
        },
        true
      )
    );
    this.listeners.push(
      APIHelper.registerVisibilityChangeEvent(layer, (visible) => {
        this.visibility = visible;
      })
    );
    this.listeners.push(
      APIHelper.registerOpacityChangeEvent(layer, (opacity) => {
        this._opacity = opacity;
      })
    );
    this.listeners.push(
      APIHelper.addOnZoomEndListener(
        map,
        () => {
          this.visibleAtMapScale = APIHelper.isLayerVisibleAtMapScale(
            layer,
            map,
            view
          );
        },
        view
      )
    );

    reaction(
      () => LayerStore.currentEditRule,
      (value, prev, reac) => {
        this.editRuleDisplayed =
          this.permissions && value && value === this.editRule;
      },
      { fireImmediately: true }
    );

    if (!this.symbols?.length && this.url) this.refreshLegendInfo(2);
    // TODO: scalerange
  }

  refreshLegendInfo(retries) {
    if (this.url) {
      this.legendState = "loading";
    }
    return new Promise((resolve, reject) => {
      setTimeout(
        () => {
          if (this.url) {
            const shouldRetry = retries > 0;
            if (retries === undefined || shouldRetry) {
              APIHelper.getLegendInfo(this.url)
                .then((infos) => {
                  this.legendInfo = infos;
                  this.legendState = "loaded";
                  resolve(infos);
                })
                .catch((e) => {
                  if (shouldRetry) {
                    console.warn(
                      `Error with receiving the Legend for ${this.url}, will try again`,
                      e
                    );
                    this.refreshLegendInfo(retries - 1);
                  } else {
                    console.error(
                      `Error with receiving the Legend for ${this.url}`,
                      e
                    );
                    this.legendState = "error";
                    reject(e);
                  }
                });
            }
          }
        },
        Math.floor(Math.random() * 100) + 100 //wait randomly between 100 und 200 milliseconds
      );
    });
  }

  setDefinitionExpression(definitionExpression) {
    APIHelper.setDefinitionExpression(this.layer, definitionExpression);
  }

  setChecked(value) {
    this.checked = !!value;
  }

  get idField() {
    return APIHelper.getObjectIdField(this.layer);
  }

  moveLayer(position) {
    LayerStore.moveLayer(this, position);
  }

  setOpacity(opacity) {
    APIHelper.setLayerOpacity(this.layer, opacity);
    this._opacity = opacity;
  }

  setVisibility(visible) {
    if (visible && this.visibility !== true) {
      APIHelper.showLayer(this.layer);
      this.visibility = true;
    } else if (!visible && this.visibility !== false) {
      APIHelper.hideLayer(this.layer);
      this.visibility = false;
    }
  }

  cleanup() {
    while (this.listeners.length > 0) {
      this.listeners.pop().remove();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  hasEditRule() {
    return !!this.editRule;
  }

  // eslint-disable-next-line class-methods-use-this
  get editRule() {
    return this.permissions && this.permissions.editRule;
  }

  /* editRuleDisplayed() {
    return (
      this.permissions &&
      LayerStore.currentEditRule &&
      LayerStore.currentEditRule.rule === this.editRule
    );
  } */

  showEditRule() {
    if (!this.editRuleDisplayed) {
      LayerStore.showEditRule(this);
    }
  }

  hideEditRule() {
    if (this.editRuleDisplayed) {
      LayerStore.hideEditRule();
    }
  }

  get readonly() {
    return (
      !this.permissions ||
      !(
        this.permissions.delete ||
        this.permissions.insert ||
        this.permissions.update_geometry ||
        this.permissions.update_attribute
      )
    );
  }

  getFieldCharackteristics(fieldName) {
    const { fields } = this;
    let codedValues = null;
    // let field = null;
    if (fields) {
      for (let i = 0; i < fields.length; i++) {
        const tfield = fields[i];
        if (tfield.name === fieldName || tfield.alias === fieldName) {
          // field = tfield;
          // codedValues
          if (tfield.domain && tfield.domain.codedValues) {
            codedValues = tfield.domain.codedValues;
          }
          break;
        }
      }
    }

    if (codedValues) {
      return new Promise((resolve) => resolve(codedValues));
    }
    return APIHelper.getDistinctValuesForField(this.layer, fieldName);
  }

  getSelectionCharackteristics(withoutValues) {
    const { fields, id, url } = this;
    const result = [];
    for (let i = 0; fields && i < fields.length; i++) {
      const { name, alias } = fields[i];
      const temp = {
        name,
        alias,
        layer: this.name || id || url,
      };
      if (!withoutValues) {
        temp.values = this.getFieldCharackteristics(name);
      }
      result.push(temp);
    }
    return result;
  }

  show() {
    APIHelper.showLayer(this.layer);
    // this.visibility = true;
  }

  hide() {
    APIHelper.hideLayer(this.layer);
    // this.visibility = false;
  }

  setSelectable(value) {
    if (this.featureLayer && this.selectable !== value) {
      LayerStore.layerManager.setLayerDisabled(this.layer, !!value);
      this.selectable = value;
    }
  }

  applyEdits(adds, updates, deletes) {
    if (this.featureLayer)
      return APIHelper.applyEdits(this.layer, adds, updates, deletes);
    else console.error("applyEdits is only supported for FeatureLayes");
  }

  refresh() {
    return APIHelper.refreshLayer(this.layer);
  }
  // reaction für visibility und opacity und selectable?
}
export const Layer = LayerWrapper; // observer(LayerStore, LayerWrapper);
export default LayerStore;

// TODO:
/*

  @action setLayerVisibleByName(name, visible) {
    const array = this.layers.slice();
    for (let i = 0; i < array.length; i++) {
      const layer = array[i];
      if (
        (APIHelper.getLayerName(layer) || APIHelper.getLayerID(layer)) === name
      ) {
        if (visible && this.layersVisibility[i] !== true) {
          APIHelper.showLayer(layer);
        } else if (!visible && this.layersVisibility[i] !== false) {
          APIHelper.hideLayer(layer);
        }
        this.layersVisibility[i] = !!visible;
        break;
      }
    }
  }

  @action getReadonlyLayersNames() {
    const layers = [];
    for (let i = 0; i < this.layersPermissions.length; i++) {
      const permissions = this.layersPermissions[i];
      const readonly =
        !permissions ||
        !(
          permissions.delete ||
          permissions.insert ||
          permissions.update_geometry ||
          permissions.update_attribute
        );
      if (readonly) {
        const layer = this.layers[i];
        layers.push(
          APIHelper.getLayerName(layer) || APIHelper.getLayerID(layer),
        );
      }
    }
    return layers;
  }

  @action hideOptionalLayersExcept(showLayers) {
    const layers = this.getReadonlyLayersNames();
    const toShow = showLayers ? showLayers.map((l) => l.name) : [];
    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i];
      const show = toShow.indexOf(layer) !== -1;
      // console.log(`${show ? 'show' : 'hide'} ${layer}`);
      this.setLayerVisibleByName(layer, show);
    }
  }

  @action showOptionalLayersExcept(hideLayers) {
    const toHide = hideLayers || [];
    const layers = this.getReadonlyLayersNames();
    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i];
      const show = toHide.indexOf(layer) === -1;

      // console.log(`${show ? 'show' : 'hide'} ${layer}`);
      this.setLayerVisibleByName(layer, show);
    }
  }
 */
