import { round } from "libs/EnVISUtils";
import RootStore from "stores/RootStore";
import moment from "moment";
import { Switch, Typography } from "@mui/material";
import { Observer } from "mobx-react";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import BarChartIcon from "@mui/icons-material/BarChart";

const MAX_INTEGER = Number.MAX_SAFE_INTEGER || 9007199254740991;

const INTERVALS = {
  millisecond: {
    major: true,
    size: 1,
    steps: [1, 2, 5, 10, 20, 50, 100, 250, 500],
  },
  second: {
    major: true,
    size: 1000,
    steps: [1, 2, 5, 10, 30],
  },
  minute: {
    major: true,
    size: 60000,
    steps: [1, 2, 5, 10, 30],
  },
  hour: {
    major: true,
    size: 3600000,
    steps: [1, 2, 3, 6, 12],
  },
  day: {
    major: true,
    size: 86400000,
    steps: [1, 2, 5],
  },
  week: {
    major: false,
    size: 604800000,
    steps: [1, 2, 3, 4],
  },
  month: {
    major: true,
    size: 2.628e9,
    steps: [1, 2, 3],
  },
  quarter: {
    major: false,
    size: 7.884e9,
    steps: [1, 2, 3, 4],
  },
  year: {
    major: true,
    size: 3.154e10,
  },
};

const UNITS = Object.keys(INTERVALS);

const graphPeriodText = (app, period, field, isDataReduced) => {
  const isValueAccumulated = field === "ts_value2";
  let text = "";
  if (app === "enz") {
    switch (period) {
      case "p1m":
        text = "Auf den Tag kummulierte Stundensummen";
        break;
      case "p2d":
        text = "Stundenmittelwerte (prov., ungeprüfte Daten!)";
        break;
      case "p1w":
        text = "Stundenmittelwerte (prov., ungeprüfte Daten!)";
        break;
      case "p13m":
        text = "Summierte Stundenmittelwerte (prov., ungeprüfte Daten!)";
        break;
      default:
        text = "gemessene Werte";
        break;
    }
  } else if (app === "hydroweb") {
    switch (period) {
      case "p2d":
        text = `${
          isValueAccumulated ? "10-Minutensumme" : "10-Minutenmittel"
        } (prov., ungeprüfte Daten!)`;
        break;
      case "p1w":
        text = `${
          isValueAccumulated ? "Stundensummewerte" : "Stundenmittelwerte"
        } (prov., ungeprüfte Daten!)`;
        break;
      case "p1m":
        text = `${
          isValueAccumulated ? "Tagessummewerte" : "Tagesmittelwerte"
        } (prov., ungeprüfte Daten!)`;
        break;
      case "p13m":
        text = "Summierte Stundenmittelwerte (prov., ungeprüfte Daten!)";
        break;
      default:
        text = "gemessene Werte";
        break;
    }
  } else {
    switch (period) {
      case "p2d":
        text = isValueAccumulated ? "10-Minutensumme" : "10-Minutenmittel";
        break;
      case "p1w":
        text = isValueAccumulated ? "Stundensummewerte" : "Stundenmittelwerte";
        break;
      case "p1m":
        text = isValueAccumulated ? "Tagessummewerte" : "Tagesmittelwerte";
        break;
      case "p12m":
        text = isDataReduced
          ? `6 ${
              isValueAccumulated ? "Stundensummewerte" : "Stundenmittelwerte"
            }`
          : "gemessene Werte";
        break;
      case "p1y":
        text = isDataReduced
          ? `12 ${
              isValueAccumulated ? "Stundensummewerte" : "Stundenmittelwerte"
            }`
          : "gemessene Werte";
        break;
      case "p5y":
        text = isDataReduced
          ? `${isValueAccumulated ? "Tagessummewerte" : "Tagesmittelwerte"}`
          : "gemessene Werte";
        break;
      case "p10y":
        text = isDataReduced
          ? `${isValueAccumulated ? "Tagessummewerte" : "Tagesmittelwerte"}`
          : "gemessene Werte";
        break;
      default:
        text = "gemessene Werte";
        break;
    }
  }
  return text;
};

const determineUnit = (minUnit, min, max, capacity) => {
  const ilen = UNITS.length;
  let i, interval, factor;

  for (i = UNITS.indexOf(minUnit); i < ilen - 1; ++i) {
    interval = INTERVALS[UNITS[i]];
    factor = interval.steps
      ? interval.steps[interval.steps.length - 1]
      : MAX_INTEGER;
    if (Math.ceil((max - min) / (factor * interval.size)) <= capacity) {
      return UNITS[i];
    }
  }
  return UNITS[ilen - 1];
};

const determineMajorUnit = (unit) => {
  for (var i = UNITS.indexOf(unit) + 1, ilen = UNITS.length; i < ilen; ++i) {
    if (INTERVALS[UNITS[i]].major) {
      return UNITS[i];
    }
  }
};

const determineStepSize = (min, max, unit, capacity) => {
  const range = max - min;
  const interval = INTERVALS[unit];
  const milliseconds = interval.size;
  const steps = interval.steps;
  let i, ilen, factor;
  if (!steps) {
    return Math.ceil(range / ((capacity || 1) * milliseconds));
  }
  for (i = 0, ilen = steps.length; i < ilen; ++i) {
    factor = steps[i];
    if (Math.ceil(range / (milliseconds * factor)) <= capacity) {
      break;
    }
  }
  return factor;
};

const generate = (timeOpts, domain, minor, major, capacity) => {
  const min = domain[0];
  const max = domain[1];
  const weekday = minor === "week" ? timeOpts.isoWeekday : false;
  const majorTicksEnabled = false;
  const interval = INTERVALS[minor];
  let first = moment(min);
  let last = moment(max);
  let ticks = [];
  let time;

  const stepSize = determineStepSize(min, max, minor, capacity);

  // For 'week' unit, handle the first day of week option
  if (weekday) {
    first = first.isoWeekday(weekday);
    last = last.isoWeekday(weekday);
  }

  // Align first/last ticks on unit
  first = first.startOf(weekday ? "day" : minor);
  last = last.startOf(weekday ? "day" : minor);

  // Make sure that the last tick include max
  if (last < max) {
    last.add(1, minor);
  }

  time = moment(first);

  if (majorTicksEnabled && major && !weekday && !timeOpts.round) {
    // Align the first tick on the previous `minor` unit aligned on the `major` unit:
    // we first aligned time on the previous `major` unit then add the number of full
    // stepSize there is between first and the previous major time.
    time.startOf(major);
    time.add(~~((first - time) / (interval.size * stepSize)) * stepSize, minor);
  }

  for (; time < last; time.add(stepSize, minor)) {
    ticks.push(+time);
  }

  ticks.push(+time);

  return ticks;
};

const ticksFromTimestamps = (values, majorUnit) => {
  var ticks = [];
  var i, ilen, value, major;
  for (i = 0, ilen = values.length; i < ilen; ++i) {
    value = values[i];
    major = majorUnit ? value === +moment(value).startOf(majorUnit) : false;
    if (major) debugger;
    ticks.push({
      value: value,
      major: major,
    });
  }
  return ticks;
};

export const updatePeriodToNextOne = (
  currentPeriod,
  periodsOption,
  setSelectedPeriod,
  setIsShowingPeriodTooltip
) => {
  const indexOfCurrentPeriod = periodsOption.findIndex((object) => {
    return object.value === currentPeriod;
  });
  const indexOfNextPeriod = indexOfCurrentPeriod + 1;
  if (indexOfNextPeriod < periodsOption.length) {
    setSelectedPeriod(periodsOption[indexOfNextPeriod]);
    setIsShowingPeriodTooltip(true);
    console.info(
      "Keine Daten für die Zeitperiode vorhanden. Nächste Periode wird ausgewählt."
    );
  } else {
    console.info("Keine Daten für alle Zeitperiode vorhanden.");
  }
};

export const grpahInfo = (app, chart, nachkommastellen) => {
  const { formulateDateTime } = RootStore.hydroWebStore;
  const chartStore = chart?.stores[0];
  console.log(chartStore);
  const lastValue = chartStore?.lastValue;
  const isDataReduced = chartStore?.isDataReduced;
  const periodText = graphPeriodText(
    app,
    chartStore?.period,
    chartStore?.Feld,
    isDataReduced
  );
  let text = "";
  if (lastValue) {
    const date = formulateDateTime(lastValue.x);
    text =
      "Letzte Messung: " +
      round(lastValue.y, nachkommastellen) +
      " " +
      chartStore.Einheit +
      " am " +
      date;
  }
  return (
    <>
      <small>
        {periodText}
        <br></br>
        {text}
      </small>
    </>
  );
};

export const createLegend = (stores, nls, chart) => {
  return (
    <div style={{ width: 300 }}>
      <Typography variant="h6">{nls.legend}</Typography>
      {stores.map((store, i) => {
        const { Art, Farbe, Anzeigename, Feld } = store;
        // console.log("createLegend: ", Art, Farbe, Anzeigename, Feld);
        let legendName;
        if (Feld === "ts_summe") {
          legendName = `Summe  ${Anzeigename}`;
        } else if (
          Feld &&
          Feld.toUpperCase &&
          Feld.indexOf("Gefahrenstufe ") !== -1
        ) {
          legendName = Feld.replace("Gefahrenstufe ", "GS");
        } else {
          legendName = Anzeigename;
        }

        let symbol = <div />;
        if (Art === "line") {
          symbol = <ShowChartIcon style={{ fill: Farbe }} />;
        } else if (Art === "column") {
          symbol = <BarChartIcon style={{ fill: Farbe }} />;
        }

        const isAtLeastOneStoreVisible = (stores) => {
          // console.log(stores.some((store) => store.visible));
          return stores.some((store) => store.visible);
        };

        const hasAtLeasteOneStoreTheSameYAxis = (stores, store) => {
          const theOtherStores = stores.filter((sto) => sto !== store);
          return (
            theOtherStores.some((sto) => sto.chartData.yAxis === "y") &&
            store.chartData.yAxis === "y"
          );
        };

        return (
          <div key={Anzeigename + i}>
            <Observer>
              {() => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Switch
                        checked={store.visible}
                        // onClick={() => {
                        //   if (hasAtLeasteOneStoreTheSameYAxis(stores, store)) {
                        //     console.log("The yAxix should be updated");
                        //     chart.chart.updateAxis(store, stores);
                        //     // chart.chart?.draw();
                        //   } else {
                        //     if (
                        //       store.Feld === "ts_value" ||
                        //       store.Feld === "ts_value2" ||
                        //       store.Feld === "ts_summe"
                        //     ) {
                        //       if (store.visible) {
                        //         chart.chart.removeAxis(store.chartData.yAxis);
                        //       }
                        //       if (!store.visible) {
                        //         chart.chart.addAxis(
                        //           RootStore.mapStore.removedYAxis
                        //         );
                        //       }
                        //     }
                        //   }
                        //   store.setVisible(!store.visible);
                        // }}
                        onClick={() => {
                          if (hasAtLeasteOneStoreTheSameYAxis(stores, store)) {
                            console.log("The yAxix should be updated");
                            chart.chart.updateAxis(store, stores);
                          } else {
                            store.setVisible(!store.visible);
                            if (
                              store.Feld === "ts_value" ||
                              store.Feld === "ts_value2" ||
                              store.Feld === "ts_summe"
                            ) {
                              if (store.visible) {
                                // chart.chart.addAxis(RootStore.mapStore.removedYAxis);
                                // chart.update();
                                chart.chart.axisMap[
                                  store.chartData.yAxis
                                ] = true;
                              }
                              if (!store.visible) {
                                // chart.chart.removeAxis(store.chartData.yAxis);
                                // chart.chart?.draw();
                                chart.chart.axisMap[
                                  store.chartData.yAxis
                                ] = false;
                              }
                              chart.update();
                            }
                          }
                        }}
                        disabled={
                          stores.length > 1
                            ? !isAtLeastOneStoreVisible(
                                stores.filter((sto) => sto !== store)
                              )
                            : true
                        }
                      />
                    </div>
                    <div style={{ marginLeft: 5 }}>{symbol}</div>
                    <div style={{ marginLeft: 5 }}>
                      <Typography>{legendName}</Typography>
                    </div>
                  </div>
                );
              }}
            </Observer>
          </div>
        );
      })}
    </div>
  );
};

export const buildTimeTickValues = (timeOpts, chartWidth, domain) => {
  let min = domain[0];
  let max = domain[1];
  // const capacity = getLabelCapacity(chartWidth, min);
  const timeTickLabelWidth = 62;
  const capacity = Math.floor(chartWidth / timeTickLabelWidth);
  const unit = determineUnit(UNITS[0], min, max, capacity);
  const majorUnit = determineMajorUnit(unit);
  let timestamps = [];
  let ticks = [];
  let i, ilen, timestamp;
  timestamps = generate(timeOpts, domain, unit, majorUnit, capacity);
  // Remove ticks outside the min/max range
  for (i = 0, ilen = timestamps.length; i < ilen; ++i) {
    timestamp = timestamps[i];
    if (timestamp >= min && timestamp <= max) {
      ticks.push(timestamp);
    }
  }

  const finalTicks = ticksFromTimestamps(ticks, majorUnit);
  const displayFormat = timeOpts.displayFormats[unit];
  const finalTicksVlaues = finalTicks.map((x) => x.value);
  const timeTicks = {
    tickValues: finalTicksVlaues,
    ticksFormat: displayFormat,
    // unit: unit,
    // majorUnit: majorUnit,
    // timeOpts: timeOpts,
  };
  // const labels = convertTicksToLabels(finalTicks, majorUnit, unit, timeOpts);
  // console.log("buildTimeTickValues labels", labels);
  return timeTicks;
};

// export const createLegend = (stores, nls, chart) => {
//   return (
//     <div style={{ width: 300, display: "flex", flex: 1 }}>
//       {/* <Typography constiant="h6">{nls.legend}</Typography> */}
//       {stores.map((store, i) => {
//         const { Art, Farbe, Anzeigename, Feld } = store;
//         console.log("createLegend: ", Art, Farbe, Anzeigename, Feld);
//         let legendName;
//         if (Feld === "ts_summe") {
//           legendName = `Summe  ${Anzeigename}`;
//         } else if (
//           Feld &&
//           Feld.toUpperCase &&
//           Feld.indexOf("Gefahrenstufe ") !== -1
//         ) {
//           legendName = Feld.replace("Gefahrenstufe ", "GS");
//         } else {
//           legendName = Anzeigename;
//         }

//         let symbol = <div />;
//         if (Art === "line") {
//           symbol = <ShowChartIcon style={{ fill: Farbe }} />;
//         } else if (Art === "column") {
//           symbol = <BarChartIcon style={{ fill: Farbe }} />;
//         }

//         const isAtLeastOneStoreVisible = (stores) => {
//           console.log(stores.some((store) => store.visible));
//           return stores.some((store) => store.visible);
//         };

//         const hasAtLeasteOneStoreTheSameYAxis = (stores, store) => {
//           const theOtherStores = stores.filter((sto) => sto !== store);
//           return (
//             theOtherStores.some((sto) => sto.chartData.yAxis === "y") &&
//             store.chartData.yAxis === "y"
//           );
//         };

//         return (
//           <div key={Anzeigename + i}>
//             <Observer>
//               {() => {
//                 return (
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       alignItems: "center",
//                     }}
//                   >
//                     <div style={{ width: "50px" }}>
//                       <Switch
//                         checked={store.visible}
//                         onClick={() => {
//                           if (hasAtLeasteOneStoreTheSameYAxis(stores, store)) {
//                             console.log("The yAxix should be updated");
//                             chart.chart.updateAxis(store, stores);
//                             // chart.chart?.draw();
//                           } else {
//                             if (
//                               store.Feld === "ts_value" ||
//                               store.Feld === "ts_value2" ||
//                               store.Feld === "ts_summe"
//                             ) {
//                               if (store.visible) {
//                                 chart.chart.removeAxis(store.chartData.yAxis);
//                               }
//                               if (!store.visible) {
//                                 chart.chart.addAxis(
//                                   RootStore.mapStore.removedYAxis
//                                 );
//                               }
//                             }
//                           }
//                           store.setVisible(!store.visible);
//                         }}
//                         disabled={
//                           stores.length > 1
//                             ? !isAtLeastOneStoreVisible(
//                                 stores.filter((sto) => sto !== store)
//                               )
//                             : true
//                         }
//                       />
//                     </div>
//                     <div>{symbol}</div>
//                     <div style={{ marginRight: "25px" }}>
//                       <Typography>{legendName}</Typography>
//                     </div>
//                   </div>
//                 );
//               }}
//             </Observer>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// const getLabelCapacity = (chartWidth, exampleTime) => {
//   // const exampleLabel = tickFormatFunction(moment(exampleTime), 0, []);
//   const exampleLabel = DateTime.fromMillis(exampleTime);
//   // const  tickLabelWidth = getLabelWidth(exampleLabel);
//   const tickLabelWidth = 62;
//   // const innerWidth = isHorizontal() ? me.width : me.height;
//   return Math.floor(chartWidth / tickLabelWidth);
// };

// export const formatTimeTicks = (targetTick, unit, majorUnit, timeOpts) => {
//   const tick = moment(targetTick.value);
//   const time = tick.valueOf();
//   const minorFormat = timeOpts.displayFormats[unit];
//   const majorFormat = timeOpts.displayFormats[majorUnit];
//   const majorTime = tick.clone().startOf(majorUnit).valueOf();
//   const majorTickOpts = targetTick.major;
//   const major = majorTickOpts && majorUnit && majorFormat && time === majorTime;
//   const format = major ? majorFormat : minorFormat;
//   if (majorTickOpts) {
//     console.log(major);
//     console.log(minorFormat);
//     console.log(majorFormat);
//     console.log(format);
//   }
//   // const label = tick.format(format);
//   return format;
// };

// const tickFormatFunction = (tick, index, ticks, majorUnit, unit, timeOpts) => {
//   var time = tick.valueOf();
//   const minorFormat = timeOpts.displayFormats[unit];
//   const majorFormat = timeOpts.displayFormats[majorUnit];
//   var majorTime = tick.clone().startOf(majorUnit).valueOf();
//   var majorTickOpts = {
//     enabled: false,
//   };
//   var major =
//     majorTickOpts.enabled && majorUnit && majorFormat && time === majorTime;
//   var label = tick.format(major ? majorFormat : minorFormat);
//   var tickOpts = major ? majorTickOpts : ticks.minor;
//   var formatter = valueOrDefault(tickOpts.callback, tickOpts.userCallback);
//   return formatter ? formatter(label, index, ticks) : label;
// };

// const convertTicksToLabels = (ticks, majorUnit, unit, timeOpts) => {
//   var labels = [];
//   var i, ilen;
//   for (i = 0, ilen = ticks.length; i < ilen; ++i) {
//     labels.push(
//       tickFormatFunction(
//         moment(ticks[i].value),
//         i,
//         ticks,
//         majorUnit,
//         unit,
//         timeOpts
//       )
//     );
//   }
//   return labels;
// };
