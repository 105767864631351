import React from "react";
import TooltipWidget from "widgets/TooltipWidget/TooltipWidget";

const PeriodTooltip = ({ open, onCloseHandle }) => {
  return (
    <TooltipWidget
      open={open}
      onCloseHandle={onCloseHandle}
      textMessage={
        "Keine Daten für die Zeitperiode vorhanden. Nächste Periode wird ausgewählt!"
      }
    />
  );
};

export default PeriodTooltip;
