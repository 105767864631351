import { Box, Typography } from "@mui/material";
import { ChartComponent } from "components/chart/Chart";
import React from "react";
import IntensityLegend from "./IntensityLegend";

const graphStyle = {
  p: 0,
  paddingTop: 5,
  marginTop: 5,
  marginBottom: 5,
  borderRadius: 5,
  border: "solid 1px lightgray",
};

const DiagramView = ({ diagram, reduceInterval }) => {
  const graph = (
    <div style={graphStyle}>
      <ChartComponent chart={diagram.chart} />
      <Typography mt="-30px" fontSize="12px" textAlign={"center"}>
        [Datum]
      </Typography>
    </div>
  );

  const intensityLegend =
    //  diagram?.stores[0].chartData?.drawIntensity && <IntensityLegend reduceInterval={reduceInterval}/>
    diagram?.chart?.datasets[0]?.drawIntensity && (
      <IntensityLegend reduceInterval={reduceInterval} />
    );

  return (
    <>
      {diagram?.chart?.datasets[0].data.length < 50000 ? (
        <>
          {graph}
          {intensityLegend}
        </>
      ) : (
        <Box marginY={5} textAlign={"center"}>
          <Typography>
            Viele Daten für die Darstellung des Diagramms!
          </Typography>
          <Typography>Bitte wählen Sie anderes Intervall</Typography>
        </Box>
      )}
    </>
  );
};

export default DiagramView;
