import React from "react";
import { observer } from "mobx-react";
import { withStyles, makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import RootStore from "../stores/RootStore";
import { removeHashFromUrl } from "utils/UrlParameterUtils";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #d3d4d5",
  },
}));

const AppBarMainMenu = observer((props) => {
  const { widgets, activeWidgetId, openWidget } = RootStore.uiStore;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="open menu"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
      >
        {widgets.map((widget) => (
          <StyledMenuItem
            key={widget.id}
            onClick={() => {
              openWidget(widget.id);
              // window.location.hash = widget.id;
              removeHashFromUrl();
            }}
            selected={activeWidgetId === widget.id}
          >
            <ListItemIcon>{widget.icon}</ListItemIcon>
            <ListItemText sx={{ ml: 2 }} primary={widget.title} />
          </StyledMenuItem>
        ))}
      </Menu>
    </div>
  );
});

export default AppBarMainMenu;
