import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import EsriMap from "./EsriMap";
import WidgetContainer from "./WidgetContainer";
import RootStore from "../stores/RootStore";

// const widgetAreaWidth = 400;
// const openCloseSpeed = "0.8s";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    position: "absolute",
    top: 48,
    [theme.breakpoints.up("md")]: {
      bottom: 0,
      right: (props) => (props.isWidgetOpen ? props.widgetAreaWidth : 0),
    },
    [theme.breakpoints.down("md")]: {
      bottom: (props) => (props.isWidgetOpen ? "50%" : 0),
      right: 0,
    },
    left: 0,
    borderRight: "1px solid #bbbbbb",
    display: "flex",
  },
  widgetContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: 0,
    bottom: 0,
    [theme.breakpoints.up("md")]: {
      justifyContent: "unset",
      top: 50,
      width: (props) => (props.isWidgetOpen ? props.widgetAreaWidth : 0),
    },
    height: "auto",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      top: (props) => (props.isWidgetOpen ? "50vh" : "auto"),
      height: (props) => (props.isWidgetOpen ? "auto" : 0),
      justifyContent: "end",
      left: 0,
    },
    // transition: `width ${openCloseSpeed}`,
    // overflow: "hidden",
  },
  widgetContent: {
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 100px)",
    },
    [theme.breakpoints.down("md")]: {
      height: "calc(50vh - 35px)",
    },
    overflow: "hidden auto",
  },
  drawer: {
    flexShrink: 0,
  },
  dragger: {
    width: "5px",
    cursor: "ew-resize",
    padding: "4px 0 0",
    borderTop: "1px solid #ddd",
    position: "absolute",
    top: -1,
    bottom: 0,
    left: 0,
    // zIndex: 100,
    backgroundColor: "#2683a78a",
    // transition: "width 0.1s",
    [theme.breakpoints.up("md")]: {
      display: (props) => (props.isWidgetOpen ? "block" : "none"),
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  foldButton: {
    position: "absolute",
    marginLeft: "-50px",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      transform: "none",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: -50,
      transform: "rotate(90deg)",
    },
    flex: 1,
    background: "#2683a7",
    borderRadius: "50%",
    padding: "12px",
    minWidth: "20px",
    transition: "width 0.8s",
  },
  widgetHeader: {
    width: "100%",
    height: "35px",
    color: "white",
    backgroundColor: "#006d97",
    fontWeight: "bold",
    fontSize: "larger",
    display: (props) => (props.isWidgetOpen ? "block" : "none"),
  },
  widgetHeaderTitle: {
    lineHeight: "35px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "absolute",
    left: "16px",
  },
  widgetGuider: {
    float: "right",
    width: "35px",
    paddingTop: "5px",
    textAlign: "center",
    cursor: "pointer",
  },
}));

const MainArea = observer(({ widgets, children }) => {
  const {
    isWidgetOpen,
    widgetAreaWidth,
    activeWidget,
    widgetTitle,
    minWidgetAreaWidth,
    maxWidgetAreaWidth,
    setWidgetAreaWidth,
    handleOpenCloseWidget,
  } = RootStore.uiStore;
  const classes = useStyles({
    isWidgetOpen: isWidgetOpen,
    widgetAreaWidth: widgetAreaWidth,
  });

  return (
    <>
      <EsriMap className={classes.mapContainer}></EsriMap>
      {children}
      <WidgetContainer
        widget={activeWidget}
        widgetTitle={widgetTitle}
        classes={classes}
        isWidgetOpen={isWidgetOpen}
        widgetAreaWidth={widgetAreaWidth}
        minWidgetAreaWidth={minWidgetAreaWidth}
        maxWidgetAreaWidth={maxWidgetAreaWidth}
        setWidgetAreaWidth={setWidgetAreaWidth}
        handleOpenCloseWidget={handleOpenCloseWidget}
      />
    </>
  );
});

export default MainArea;
