import { BarDataset } from "components/chart/types/bar";
import { LineDataset } from "components/chart/types/line";
import { round } from "libs/EnVISUtils";
import { action, makeObservable, observable } from "mobx";
import RootStore from "stores/RootStore";
export class StatisitcFeatureStore {
  constructor(props) {
    const {
      Art,
      Farbe,
      Feld,
      Strichstaercke,
      Anzeigename,
      Einheit,
      groupParam,
      baseUrl,
      Nachkommastellen,
      feature,
      showPoints = false,
    } = props;
    this.Art = Art;
    this.Farbe = Farbe;
    this.Feld = Feld;
    this.Strichstaercke = parseFloat(Strichstaercke);
    this.groupParam = groupParam;
    this.baseUrl = baseUrl;
    this.Anzeigename = Anzeigename;
    this.Einheit = Einheit;
    this.Nachkommastellen = Nachkommastellen;
    this.feature = feature;
    this.visible = true;
    this.loading = false;
    this.loadingListener = [];
    this.showPoints = showPoints;
    makeObservable(this, {
      loading: observable.ref,
      setLoading: action,
      visible: observable.ref,
      setVisible: action,
      updateChartData: action,
    });
    const name = `${this.Anzeigename}`;

    switch (Art) {
      default:
      case "line":
        this.chartData = new LineDataset({
          formatY: (y) => {
            return round(y, this.Nachkommastellen);
          },
          fill: this.Farbe,
          data: [],
          yAxis: this.hasy2 ? "y2" : "y",
          strokeWidth: this.Strichstaercke + 1,
          name,
        });
        this.setShowPoints(this.showPoints);
        break;
      case "column":
        this.chartData = new BarDataset({
          formatY: (y) => {
            return round(y, this.Nachkommastellen);
          },
          fill: this.Farbe,
          outline: "transparent",
          data: [],
          yAxis: "y",
          strokeWidth: this.Strichstaercke + 1,
          name,
        });
        break;
    }
  }

  setVisible(visible) {
    this.visible = visible !== undefined ? visible : this.visible;
    this.chartData?.setVisible(visible !== undefined ? visible : this.visible);
  }

  setChartData(data) {
    this.chartData?.setData(data !== undefined ? data : this.data);
  }

  setShowPoints(visible) {
    this.chartData.drawInfo.pointRadius = visible ? 5 : 0;
    this.showPoints = visible;
  }

  updateChartData(calculatedData) {
    const { visible } = this;
    this.data = calculatedData.data;
    this.min = calculatedData.min;
    this.max = calculatedData.max;
    this.lastValue = calculatedData.lastValue;
    this.avg = calculatedData.avg;
    this.chartData.drawIntensity = calculatedData.drawIntensity;
    this.chartData.reduceInterval = this.reduceInterval;
    console.log(
      "%c -------> Diagram data length",
      "background: lightgreen",
      calculatedData?.data?.length
    );

    if (this.Feld === "ts_value2") {
      this.topLargestValues = this.findTopLargestValuesInObjectArray(
        calculatedData?.data,
        "y",
        10
      );
      this.showIntensity = true;
    }

    calculatedData.data !== undefined && this.setChartData(calculatedData.data);
    visible !== undefined && this.setVisible(visible);
  }

  setDateRange(dateRange, noFetch = false, reduceInterval) {
    console.log("setDateRange");
    // console.log("-------------------");
    // const toDate = addMinutes(dateRange[1], 1);
    // console.log(toDate);
    this.fromDate = dateRange[0].toISOString();
    this.toDate = dateRange[1].toISOString();
    this.reduceInterval = reduceInterval;
    noFetch || this.fetch();
  }

  get url() {
    // return "https://www.ag.ch/app/envis.proxy/proxy.ashx?https://www.ag.ch/app/hydrometrie/kiwis/KiWIS?datasource=0&service=kisters&type=queryServices&request=getTimeseriesValues&format=dajson&dateformat=UNIX&ts_id=26286042&metadata=true&md_returnfields=ts_id%2Cparametertype_id%2Cts_unitsymbol&from=2021-01-01T00%3A00%3A00.000Z&to=2021-12-31T24%3A00%3A00.000Z";
    return (
      RootStore.config.proxyURL +
      this.baseUrl +
      `&request=getTimeseriesValues&format=dajson&dateformat=UNIX&ts_id=${this.feature.ts_id}` +
      `&metadata=true&md_returnfields=ts_id%2Cparametertype_id%2Cts_unitsymbol` +
      `${this.fromDate ? "&from=" + encodeURIComponent(this.fromDate) : ""}` +
      `${this.toDate ? "&to=" + encodeURIComponent(this.toDate) : ""}`
    );
  }

  setLoading(loading) {
    this.loading = loading;
    this.loadingListener.forEach((listener) => {
      try {
        listener(this.loading, this);
      } catch (e) {
        console.error("Error in loading listener", e);
      }
    });
  }

  applyData(raw = []) {
    let data = [];
    for (let i = 0; i < raw.length; i++) {
      let [x, y] = raw[i];
      if (y !== null) {
        y = round(y, this.Nachkommastellen);
        data.push({ x, y });
      } else {
        console.log(`Value "${i}" is "null"`);
      }
    }

    this.rawdDataCalculated = this.calculateRawData(data);

    const reduceInterval = this.reduceInterval;
    const calculatedData = reduceInterval
      ? this.reduceValues(data, reduceInterval)
      : this.rawdDataCalculated;

    this.updateChartData(calculatedData);
    this.onChange && this.onChange();
  }

  updateChartDataByInterval = (reduceInterval) => {
    this.reduceInterval = reduceInterval;
    if (reduceInterval) {
      const calculatedData = this.reduceValues(
        this.rawdDataCalculated.data,
        reduceInterval
      );
      this.updateChartData(calculatedData);
    } else {
      this.updateChartData(this.rawdDataCalculated);
    }
    this.onChange && this.onChange();
  };

  reduceValues(rawData, reduceInterval) {
    let min = { x: Infinity, y: Infinity };
    let max = { x: -Infinity, y: -Infinity };
    let lastValue = { x: -Infinity, y: -Infinity };
    let sum = 0;

    const intervalls = {};
    for (let i = 0; i < rawData.length; i++) {
      const value = rawData[i];
      let index = `${value.x - (value.x % reduceInterval)}`;
      if (this.Feld !== "ts_value") {
        const newXVlaue = value.x - 60000; // because the value represents the sum of rain in the last 10 minutes
        index = `${newXVlaue - (newXVlaue % reduceInterval)}`;
      }
      if (!intervalls[index]) intervalls[index] = [];
      intervalls[index].push(value);
    }

    const data = Object.keys(intervalls).map((key) => {
      const interval = intervalls[key];
      let x = 0;
      let y = 0;
      interval.forEach(({ y: ycurr, x: xcurr }) => {
        y += Number(ycurr);
        x = xcurr;
      });

      if (this.Feld !== "ts_value2" && this.Feld !== "ts_summe") {
        x = Math.round(interval[Math.floor(interval.length / 2)].x);
        y /= interval.length;
      }

      if (x < min.x) {
        min.x = x;
      }
      if (x > max.x) {
        max.x = x;
        lastValue.x = x;
        lastValue.y = y;
      }
      if (y < min.y) {
        min.y = round(y, this.Nachkommastellen);
      }
      if (y > max.y) {
        max.y = round(y, this.Nachkommastellen);
      }
      sum = sum + y;
      return { x, y: round(y, this.Nachkommastellen) };
    });

    const avg = round(sum / data.length, 5);

    const drawIntensity =
      this.Feld === "ts_value2" &&
      (reduceInterval === 3600000 || reduceInterval === 600000);

    return {
      data,
      min,
      max,
      lastValue,
      avg,
      drawIntensity,
    };
  }

  calculateRawData(raw) {
    let min = { x: Infinity, y: Infinity };
    let max = { x: -Infinity, y: -Infinity };
    let lastValue = { x: -Infinity, y: -Infinity };
    let sum = 0;

    const data = raw.map((value) => {
      const x = Number(value.x);
      const y = Number(value.y);
      sum = sum + y;
      if (x < min.x) {
        min.x = x;
      }
      if (x > max.x) {
        max.x = x;
        lastValue.x = x;
        lastValue.y = y;
      }
      if (y < min.y) {
        min.y = y;
      }
      if (y > max.y) {
        max.y = y;
      }
      return { x, y };
    });
    const avg = round(sum / raw.length, 5);
    return { data, min, max, lastValue, avg };
  }

  fetch() {
    let aborted = false;
    const abort = () => {
      aborted = true;
    };

    if (this.currentFetch) {
      this.currentFetch.abort();
    }

    this.setLoading(true);
    fetch(this.url)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (!aborted) {
          this.currentFetch = null;
          const [raw] = response;
          if (!this.Einheit) this.Einheit = raw.ts_unitsymbol;
          this.applyData(raw.data);
          this.setLoading(false);
        }
      })
      .catch((e) => {
        if (!aborted) {
          this.currentFetch = null;
          this.onError && this.onError(e);
          this.setLoading(false);
          console.error("Error while loading timeseries", e);
        }
      });
    this.currentFetch = { abort };
  }

  abort() {
    if (this.currentFetch) {
      this.currentFetch.abort();
      this.setLoading(false);
      this.currentFetch = null;
    }
  }

  findTopLargestValuesInObjectArray(array, targetKey, outputLength) {
    return array
      .sort((a, b) => b[targetKey] - a[targetKey])
      .slice(0, outputLength);
    // .map((item) => {
    //   return { x: item.x, y: round(item.y, this.Nachkommastellen) };
    // });
  }
}
