import { Autocomplete, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const ClimateTopicsSelection = observer((props) => {
  const {
    noClimateTopic,
    allClimateTopics,
    selectedClimateTopic,
    handleClimateTopicChange,
  } = RootStore.climateStore;

  // const [selectedTheme, setSelectedTheme] = useState();

  return (
    <Autocomplete
      id="climate-topic-selection"
      sx={{
        boxShadow:
          "0px 5px 5px -3px rgb(0 109 151 / 45%), 0px 8px 10px 1px rgb(0 109 151 / 14%), 0px 3px 14px 2px rgb(0 109 151 / 12%)",
        borderRadius: 1,
        marginBottom: "20px",
      }}
      options={allClimateTopics}
      value={selectedClimateTopic || noClimateTopic}
      label={"Klimathema"}
      getOptionLabel={(option) => option.name || ""}
      renderInput={(params) => (
        <TextField {...params} fullWidth label="Klimathema" />
      )}
      disableClearable
      onChange={(event, newValue) => {
        // console.log("%c newTopicValue", "background: lightblue", newValue);
        handleClimateTopicChange(newValue);
      }}
      isOptionEqualToValue={(option, value) => {
        const optionName = selectedClimateTopic?.name || noClimateTopic.name;
        return option.name === optionName;
      }}
    />
  );
});

export default ClimateTopicsSelection;
