import { observer } from "mobx-react-lite";
import RootStore from "stores/RootStore";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import LayerLegend from "./LayerLegend";

const renderWindLegendItem = (svgHeight, text) => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        marginTop: -2,
        marginLeft: -2,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height={svgHeight}
        viewBox="0 0 16 16"
        id="IconChangeColor"
      >
        <path
          fillRule="evenodd"
          d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          id="mainIconPathAttribute"
          strokeWidth="0"
          fill="#0c5ada"
        ></path>
      </svg>
      <Typography>{text}</Typography>
    </Stack>
  );
};

const ExtraClimateLayersLegend = observer(
  ({ classes, dividerTextAlign, showLegend }) => {
    const { windSpeedStatisitcs, coldAirProductionRateLayer } =
      RootStore.climateStore;

    return (
      <Box id="climate-extra-layer-legend">
        <Divider className={classes.divider} textAlign={dividerTextAlign}>
          <Chip className={classes.chip} label="Legende" />
        </Divider>

        {showLegend.windSpeed && (
          <Stack direction="column" paddingTop={1}>
            <Typography fontSize={"medium"}>
              Windgeschwindigkeit (m/s)
            </Typography>
            {renderWindLegendItem("75", `< ${windSpeedStatisitcs?.maxWG}`)}
            {renderWindLegendItem("25", `> ${windSpeedStatisitcs?.minWG}`)}
          </Stack>
        )}

        {showLegend.coldAirProductionRate && (
          <Stack direction="column" paddingTop={1}>
            <Typography>Kaltluftproduktionsrate</Typography>
            <LayerLegend layer={coldAirProductionRateLayer} />
          </Stack>
        )}
      </Box>
    );
  }
);

export default ExtraClimateLayersLegend;
