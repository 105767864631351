import { List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";
import ExpandWidget from "widgets/ExpandWidget/Widget";

const envisLegends = [
  { ID: "legend01", imageSource: "images/3Messwerte.JPG", text: "3 Messwerte" },
  { ID: "legend02", imageSource: "images/6Messwerte.JPG", text: "6 Messwerte" },
  {
    ID: "legend03",
    imageSource: "images/20Stationen.JPG",
    text: "20 Stationen",
  },
];

const RegionsLegend = (props) => {
  return (
    <ExpandWidget contentTitle="Legende" id="regionLegend">
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingTop: "0",
          paddingBottom: "0",
        }}
      >
        {envisLegends.map((legend) => {
          return (
            <ListItem
              key={legend.ID}
              alignItems="flex-start"
              sx={{ padding: "0" }}
            >
              <ListItemAvatar sx={{ margin: "0" }}>
                <img
                  src={legend.imageSource}
                  alt={legend.text}
                  loading="lazy"
                />
              </ListItemAvatar>
              <ListItemText
                primary={legend.text}
                sx={{ alignSelf: "center" }}
              />
            </ListItem>
          );
        })}
      </List>
    </ExpandWidget>
  );
};

export default RegionsLegend;
