import Extent from "@arcgis/core/geometry/Extent";
import Point from "@arcgis/core/geometry/Point";
import { action, computed, makeObservable, observable, toJS } from "mobx";
// import { round } from "libs/utils";
import { round } from "libs/EnVISUtils";
import Query from "@arcgis/core/tasks/support/Query";
import { DateTime } from "luxon";
class HydroWebStore {
  pikettGroupParam = "97689,557";
  pikettStations = [];
  pikettStationsNamesSorted = [];
  pikettStationsNumbersSorted = [];
  hydroWebStations = [];
  hydroWebStationsNamesSorted = [];
  hydroWebStationsNumbersSorted = [];
  hydrowebSelectedStationName = null;
  pikettSelectedStationName = null;
  hydrowebSelectedStationNumber = null;
  pikettSelectedStationNumber = null;
  hydrowebSelectedCity = null;
  pikettSelectedCity = null;
  hydroCityList = null;
  hydrowebFilterParameters = {};
  hydrowebDataMaster = [];
  hydrowebCatchmentArea = [];
  pikettFilterParameters = {};
  pikettDataMaster = [];
  pikettCatchmentArea = [];
  pikettDataMasters = [];
  pikettCatchmentAreas = [];
  hydrowebDataMasters = [];
  hydrowebCatchmentAreas = [];
  parameterType = [];
  parameterTypes = [
    {
      id: "Abfluss-Pegel-Wassertemperatur",
      value: "Abfluss, Pegel, Wassertemperatur",
    },
    { id: "Niederschlag", value: "Niederschlag" },
  ];
  isPikettFlagVisible = true;
  isHydroWebNiederschlagFlagVisible = true;
  isHydroWebAbflussFlagVisible = true;
  isHydroWebPegelFlagVisible = true;
  isHydroWebTemperaturFlagVisible = true;
  measurementsColumns = [
    {
      field: "col1",
      headerName: "Station",
      flex: 1,
      whiteSpace: "pre-wrap",
      editable: false,
    },
    {
      field: "col2",
      headerName: "Parameter",
      flex: 1,
      whiteSpace: "pre-wrap",
      editable: false,
    },
    {
      field: "col3",
      headerName: "Wert",
      flex: 1,
      whiteSpace: "pre-wrap",
      editable: false,
    },
    {
      field: "col4",
      headerName: "Datum",
      flex: 1,
      whiteSpace: "pre-wrap",
      editable: false,
    },
  ];
  selectedMeasurementsTime = {};
  isDatePicker = false;
  maxMeasurementsDate = new Date().setHours("00") - 86400000;
  dataLoading = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      hydrowebSelectedStationName: observable,
      pikettSelectedStationName: observable,
      hydrowebSelectedStationNumber: observable,
      pikettSelectedStationNumber: observable,
      hydrowebSelectedCity: observable,
      pikettSelectedCity: observable,
      hydrowebDataMaster: observable,
      hydrowebCatchmentArea: observable,
      pikettDataMaster: observable,
      pikettCatchmentArea: observable,
      hydrowebFilterParameters: observable,
      pikettFilterParameters: observable,
      parameterType: observable,
      isPikettFlagVisible: observable,
      isHydroWebNiederschlagFlagVisible: observable,
      isHydroWebAbflussFlagVisible: observable,
      isHydroWebPegelFlagVisible: observable,
      isHydroWebTemperaturFlagVisible: observable,
      selectedMeasurementsTime: observable,
      isDatePicker: observable,
      dataLoading: observable,
      setSelectedStationName: action,
      setSelectedStationNumber: action,
      setSelectedCity: action,
      setFilterParameters: action,
      setDataMaster: action,
      setCatchmentArea: action,
      handleMeasurementsDate: action,
      searchAspects: computed,
      filterAspects: computed,
      measurementsRows: computed,
    });

    this.pikettStations = toJS(
      this.rootStore.pikettFeatures?.data[this.pikettGroupParam]
    );
    this.pikettStationsNamesSorted =
      this.pikettStations && [...this.pikettStations].sort(this.compareNames);
    this.pikettStationsNumbersSorted =
      this.pikettStations && [...this.pikettStations].sort(this.compareNumbers);

    this.hydroWebStations = toJS(this.rootStore.hydroWebFeatures?.stationsList);
    this.hydroWebStationsNamesSorted =
      this.hydroWebStations &&
      [...this.hydroWebStations].sort(this.compareNames);
    this.hydroWebStationsNumbersSorted =
      this.hydroWebStations &&
      [...this.hydroWebStations].sort(this.compareNumbers);

    this.hydroWebStations?.forEach((staion) => {
      if (!this.hydrowebDataMasters.includes(staion.Datenherr_lang)) {
        this.hydrowebDataMasters.push(staion.Datenherr_lang);
      }
      if (!this.hydrowebCatchmentAreas.includes(staion.Einzugsgebiet)) {
        this.hydrowebCatchmentAreas.push(staion.Einzugsgebiet);
      }
    });
    this.hydroCityList = this.rootStore.envisHydroConfig.HydroCityList;

    this.pikettStations?.forEach((staion) => {
      if (!this.pikettDataMasters.includes(staion.Datenherr_lang)) {
        this.pikettDataMasters.push(staion.Datenherr_lang);
      }
      if (!this.pikettCatchmentAreas.includes(staion.Einzugsgebiet)) {
        this.pikettCatchmentAreas.push(staion.Einzugsgebiet);
      }
    });

    this.measurementsTimeOptions = toJS(
      this.rootStore.envisHydroConfig.HydroLetzteMessungen.filter(
        (option) => option.key !== "1h"
      )
    );
    this.selectedMeasurementsTime = this.measurementsTimeOptions.find(
      (option) => option.key === "0h"
    );
  }

  handleRefreshButton = (isAppPikett) => {
    // this.dataLoading = true;
    if (isAppPikett) {
      this.rootStore.pikettFeatures.refresh();
    } else {
      this.rootStore.hydroWebFeatures.hydroWebDate = null;
      this.rootStore.hydroWebFeatures.refresh();
    }
  };

  isDateChanged = (newValue) => {
    return (
      newValue.getTime() !==
      this.rootStore.hydroWebFeatures.hydroWebDate?.getTime()
    );
  };

  handleMeasurementsDate = (newValue) => {
    // this.dataLoading = true;
    if (this.isDateChanged(newValue)) {
      console.log("handleMeasurementsDate: Date has been changed");
      this.rootStore.hydroWebFeatures.hydroWebDate = newValue;
      this.rootStore.hydroWebFeatures.refresh();
    }
  };

  setMeasurementsTime = (newValue) => {
    this.selectedMeasurementsTime = newValue;
    if (newValue.key === "datepicker") {
      this.isDatePicker = true;
    } else {
      this.isDatePicker = false;
      // this.dataLoading = true;
      if (this.rootStore.hydroWebFeatures.hydroWebDate !== null) {
        this.rootStore.hydroWebFeatures.hydroWebDate = null;
        this.rootStore.hydroWebFeatures.refresh();
      }
    }
  };

  setPathOpacity = (e) => {
    let showGraphic;
    if (e.target.style.opacity === "0.65") {
      e.target.style.opacity = "1";
      showGraphic = true;
    } else {
      e.target.style.opacity = "0.65";
      showGraphic = false;
    }
    return showGraphic;
  };

  handleHydroLegend = (e) => {
    const { layer, showflags } = this.rootStore.mapStore.hydroWebLayer;
    const showGraphic = this.setPathOpacity(e);
    const attributeColor = e.target.className.baseVal;
    console.log(attributeColor);
    let pathParam;
    if (attributeColor === "blue") {
      pathParam = "557";
      this.isHydroWebAbflussFlagVisible = showGraphic;
    } else if (attributeColor === "red") {
      pathParam = "548";
      this.isHydroWebTemperaturFlagVisible = showGraphic;
    } else if (attributeColor === "yellow") {
      pathParam = "559";
      this.isHydroWebPegelFlagVisible = showGraphic;
    } else if (attributeColor === "dodgerBlue") {
      pathParam = "523";
      this.isHydroWebNiederschlagFlagVisible = showGraphic;
    }

    showflags[pathParam] = showGraphic;
    layer.graphics.items.forEach((graphic) => {
      graphic.attributes.staionThemesData.forEach((theme) => {
        // eslint-disable-next-line no-unused-vars
        const [group, param] = theme.groupParam.split(",");
        if (param === pathParam) {
          theme.textGraphic.visible = showGraphic;
        }
      });
    });
  };

  handlePikettLegend = (e) => {
    const { layer } = this.rootStore.mapStore.pikettLayer;
    const showGraphic = this.setPathOpacity(e);
    this.isPikettFlagVisible = showGraphic;
    this.rootStore.mapStore.pikettLayer.showPikettFlags = showGraphic;
    layer.graphics.items.forEach((graphic) => {
      graphic.attributes.staionThemesData.forEach((theme) => {
        theme.textGraphic.visible = showGraphic;
      });
    });
  };

  formulateDateTime = (timestamp) => {
    let date = new Date(timestamp);
    // let month = "" + (date.getMonth() + 1) + "";
    // month = month.length === 1 ? "0" + month : month;
    // date =
    //   (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
    //   "." +
    //   month +
    //   "." +
    //   date.getFullYear() +
    //   " " +
    //   (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
    //   ":" +
    //   (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
    // return date;
    return DateTime.fromMillis(date.getTime()).toFormat("dd.MM.yyyy HH:mm"); // beacuse the type of timestamp for pikket features is number but its string for Hydroweb features
  };

  formulateValue = (store, feature, isPikett) => {
    const groupParam = isPikett
      ? this.pikettGroupParam
      : `${store.hydrometrieTSGruppe},${feature["parametertype_id"]}`;
    const tsconfig = store.timeSeriesconfig[groupParam];
    let value = round(feature.ts_value, tsconfig.Nachkommastellen);

    value += " " + feature.ts_unitsymbol;
    return value;
  };

  get measurementsRows() {
    const { currentApp, apps } = this.rootStore;
    const isPikett = currentApp === apps[0] ? true : false;
    const featuresStore = isPikett
      ? this.rootStore.pikettFeatures
      : this.rootStore.hydroWebFeatures;

    const rows = featuresStore.measurementsFeatures.map((feature, index) => {
      return {
        id: feature.station_id + String(index),
        col1: feature.station_name,
        col2: feature.parametertype_name,
        col3: this.formulateValue(featuresStore, feature, isPikett),
        col4: this.formulateDateTime(feature.timestamp),
        station_carteasting: feature.station_carteasting,
        station_cartnorthing: feature.station_cartnorthing,
      };
    });
    return rows;
  }

  get searchAspects() {
    const { currentApp, apps } = this.rootStore;
    const searchAspects = [
      {
        id: "station_name",
        title: "Name/Ort",
        options:
          currentApp === apps[1]
            ? this.hydroWebStationsNamesSorted
            : this.pikettStationsNamesSorted,
        value:
          currentApp === apps[1]
            ? this.hydrowebSelectedStationName
            : this.pikettSelectedStationName,
        renderOptionLabel: (option) =>
          option.station_name + " (" + option.station_no + ")" || "",
        isOptionEqualToValue: (option, value) =>
          option.station_id === value.station_id,
      },
      {
        id: "station_number",
        title: "Nummer",
        options:
          currentApp === apps[1]
            ? this.hydroWebStationsNumbersSorted
            : this.pikettStationsNumbersSorted,
        value:
          currentApp === apps[1]
            ? this.hydrowebSelectedStationNumber
            : this.pikettSelectedStationNumber,
        renderOptionLabel: (option) =>
          option.station_no + " (" + option.Datenherr_kurz + ")" || "",
        isOptionEqualToValue: (option, value) =>
          option.station_id === value.station_id,
      },
      {
        id: "city",
        title: "Gemeinde",
        options: this.hydroCityList,
        value:
          currentApp === apps[1]
            ? this.hydrowebSelectedCity
            : this.pikettSelectedCity,
        renderOptionLabel: (option) => option.Name || "",
        isOptionEqualToValue: (option, value) => option.Name === value.Name,
      },
    ];
    return searchAspects;
  }

  get filterAspects() {
    const { currentApp, apps } = this.rootStore;
    const filterAspects = [
      {
        id: "datenherr",
        title: "Datenherr",
        options:
          currentApp === apps[1]
            ? this.hydrowebDataMasters
            : this.pikettDataMasters,
        value:
          currentApp === apps[1]
            ? this.hydrowebDataMaster
            : this.pikettDataMaster,
      },
      {
        id: "einzugsgebiete",
        title: "Einzugsgebiete",
        options:
          currentApp === apps[1]
            ? this.hydrowebCatchmentAreas
            : this.pikettCatchmentAreas,
        value:
          currentApp === apps[1]
            ? this.hydrowebCatchmentArea
            : this.pikettCatchmentArea,
      },
    ];
    return filterAspects;
  }

  setFilterParameters = (key, newValues) => {
    const { currentApp, apps } = this.rootStore;
    const filterParameters =
      currentApp === apps[1]
        ? this.hydrowebFilterParameters
        : this.pikettFilterParameters;
    filterParameters[key] = newValues;
    const filter = {
      parameter: toJS(filterParameters),
    };
    if (currentApp === apps[1]) {
      this.rootStore.hydroWebFeatures.setFilter(filter);
    } else if (currentApp === apps[0]) {
      this.rootStore.pikettFeatures.setFilter(filter);
    }
  };

  setParameterType = (newValue) => {
    this.parameterType = newValue;
  };

  setDataMaster = (newValue) => {
    const { currentApp, apps } = this.rootStore;
    // this.dataMaster = newValue;
    if (currentApp === apps[1]) {
      this.hydrowebDataMaster = newValue;
    } else if (currentApp === apps[0]) {
      this.pikettDataMaster = newValue;
    }
  };

  setCatchmentArea = (newValue) => {
    const { currentApp, apps } = this.rootStore;
    // this.catchmentArea = newValue;
    if (currentApp === apps[1]) {
      this.hydrowebCatchmentArea = newValue;
    } else if (currentApp === apps[0]) {
      this.pikettCatchmentArea = newValue;
    }
  };

  structuringFilterParameters = (values) => {
    let structuredFilterParameters = [];
    values.forEach((value) => {
      const nameValue = typeof value === "string" ? value.split(",") : value;
      const nameValueTrimmed = nameValue.map((value) => value.trim());
      structuredFilterParameters.push(...nameValueTrimmed);
    });
    return structuredFilterParameters;
  };

  handleChangeParameterType = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === "string" ? value.split(",") : value;
    this.setParameterType(newValue);
    const parametertypeNames = this.structuringFilterParameters(newValue);
    this.setFilterParameters("parametertype_name", parametertypeNames);
  };

  handleChangeDataMaster = (newValue) => {
    this.setDataMaster(newValue);
    this.setFilterParameters("Datenherr_lang", newValue);
  };

  handleChangeCatchmentArea = (newValue) => {
    this.setCatchmentArea(newValue);
    this.setFilterParameters("Einzugsgebiet", newValue);
    this.queryCatchmentAreaGeometry(newValue);
  };

  queryCatchmentAreaGeometry = (newValue) => {
    const einzugsgebieteConfig = this.rootStore.envisHydroConfig.Einzugsgebiete;
    const idAttribute = einzugsgebieteConfig["IDAttribute"];
    const ezgs = [];
    for (let i = 0; i < newValue.length; i++) {
      const ezg = einzugsgebieteConfig["Einzugsgebiete"][newValue[i]];
      if (ezg && ezg.Nr) ezgs.push(ezg.Nr);
    }
    const expression = idAttribute + " IN ('" + ezgs.join("','") + "')";
    const { catchmentAreaLayer } = this.rootStore.mapStore.hydroWebLayer;
    if (ezgs.length) {
      catchmentAreaLayer.definitionExpression = expression;
      this.zoomToQueriedLayer(catchmentAreaLayer, expression);
    } else {
      catchmentAreaLayer.definitionExpression = "1=2";
    }
  };

  zoomToQueriedLayer = (layer, expression) => {
    const query = new Query();
    query.where = expression;
    const { mapView, sceneView } = this.rootStore.mapStore;
    layer.queryExtent(query).then(function (results) {
      sceneView.goTo(results.extent); // go to the extent of the results satisfying the query
      mapView.goTo(results.extent);
    });
  };

  handleSelectedfilters = (event, filter) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === "string" ? value.split(",") : value;
    if (filter === this.filterAspects[0].id) {
      this.handleChangeDataMaster(newValue);
    } else if (filter === this.filterAspects[1].id) {
      this.handleChangeCatchmentArea(newValue);
    }
  };

  setSelectedStationName = (newValue) => {
    const { currentApp, apps } = this.rootStore;
    // this.selectedStationName = newValue;
    if (currentApp === apps[1]) {
      this.hydrowebSelectedStationName = newValue;
    } else if (currentApp === apps[0]) {
      this.pikettSelectedStationName = newValue;
    }
  };

  setSelectedStationNumber = (newValue) => {
    const { currentApp, apps } = this.rootStore;
    // this.selectedStationNumber = newValue;
    if (currentApp === apps[1]) {
      this.hydrowebSelectedStationNumber = newValue;
    } else if (currentApp === apps[0]) {
      this.pikettSelectedStationNumber = newValue;
    }
  };

  setSelectedCity = (newValue) => {
    const { currentApp, apps } = this.rootStore;
    // this.selectedCity = newValue;
    if (currentApp === apps[1]) {
      this.hydrowebSelectedCity = newValue;
    } else if (currentApp === apps[0]) {
      this.pikettSelectedCity = newValue;
    }
  };

  compareNames = (a, b) => {
    if (a.station_name < b.station_name) return -1;
    if (a.station_name > b.station_name) return 1;
    return 0;
  };

  compareNumbers = (a, b) => {
    if (a.station_no < b.station_no) return -1;
    if (a.station_no > b.station_no) return 1;
    return 0;
  };

  zoomToStation = (selectedStation) => {
    const centerPoint = new Point(
      selectedStation.station_carteasting,
      selectedStation.station_cartnorthing,
      this.rootStore.mapStore.map.spatialReference
    );
    const targetViewPoint = { center: centerPoint, scale: 4000 };
    // for (const view of this.rootStore.mapStore.views) {
    //   view.goTo(targetViewPoint, this.rootStore.mapStore.animationViewPoint);
    // }
    this.rootStore.mapStore.mapView.goTo(
      targetViewPoint,
      this.rootStore.mapStore.animationViewPoint
    );
    this.rootStore.mapStore.sceneView.goTo(
      targetViewPoint,
      this.rootStore.mapStore.animationViewPoint
    );
    // this.rootStore.mapStore.setAppViewPoint(targetViewPoint);
  };

  resetAllSearchSelects = () => {
    this.setSelectedStationName(null);
    this.setSelectedStationNumber(null);
    this.setSelectedCity(null);
  };

  handleSelectedSearchValue = (searchValue, reason, search) => {
    this.resetAllSearchSelects();
    if (search === this.searchAspects[2].id) {
      this.handleSelectCity(searchValue, reason);
    } else {
      this.handleSelectStation(searchValue, reason, search);
    }
  };

  handleSelectStation = (station, reason, search) => {
    if (reason === "clear") {
      this.resetAllSearchSelects();
    } else {
      const selectedStation = this.hydroWebStationsNamesSorted.find(
        (elem) => elem.station_id === station.station_id
      );
      if (search === this.searchAspects[0].id) {
        this.setSelectedStationName(selectedStation);
      } else if (search === this.searchAspects[1].id) {
        this.setSelectedStationNumber(selectedStation);
      }
      if (selectedStation) {
        this.zoomToStation(selectedStation);
      }
    }
  };

  handleSelectCity = (city, reason) => {
    if (reason === "clear") {
      this.setSelectedCity(null);
    } else {
      const selectedCity = this.hydroCityList.find(
        (elem) => elem.Name === city.Name
      );
      this.setSelectedCity(selectedCity);
      if (selectedCity) {
        this.zoomToCity(selectedCity);
      }
    }
  };

  zoomToCity = (selectedCity) => {
    const xt = selectedCity.Extent;
    const targetViewPoint = new Extent(
      xt.Xmin,
      xt.Ymin,
      xt.Xmax,
      xt.Ymax,
      this.rootStore.mapStore.map.spatialReference
    );
    // for (const view of this.rootStore.mapStore.views) {
    //   view.goTo(targetViewPoint, this.rootStore.mapStore.animationViewPoint);
    // }
    this.rootStore.mapStore.mapView.goTo(
      targetViewPoint,
      this.rootStore.mapStore.animationViewPoint
    );
    this.rootStore.mapStore.sceneView.goTo(
      targetViewPoint,
      this.rootStore.mapStore.animationViewPoint
    );
    // this.rootStore.mapStore.setAppViewPoint(targetViewPoint);
  };
}

export default HydroWebStore;
