import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useState } from "react";
import RootStore from "stores/RootStore";
import { Rnd } from "react-rnd";
import MPWidget from "widgets/MeasuringPoint/Widget";
import CatchmentAreaWidget from "widgets/CatchmentArea/Widget";

const style = {
  // margin: "15px",
  background: "#FFF",
  overflow: "hidden",
  zIndex: 10,
  boxShadow: "0 0 8px 0 rgb(0 0 0 / 20%)",
};

const WidgetMobileWidth = 360;
const WidgetMobileHeight = 450;
const widgetMobileMinimumWidth = 310;
const widgetMobileMinimumHeight = 450;
const WidgetDesktopMinimumWidth = 450;
const WidgetDesktopMinimumHeight = 575;

const getChart = (mp, catchmentArea) => {
  let chart = null;
  if (mp) {
    chart = RootStore.mapStore.measuringPointChart;
  } else if (catchmentArea) {
    chart = RootStore.mapStore.catchmentAreaChart;
  }
  // console.log("getChart chart", chart);
  return chart;
};

const Widget = (props) => {
  const mp = toJS(RootStore.mapStore.selectedMeasuringPoint);
  const catchmentArea = RootStore.mapStore.clickedCatchmentArea;
  const factor = mp || catchmentArea;
  // console.log(">>>>>>>>>>>>>>>>>>> mp", mp);
  // console.log(">>>>>>>>>>>>>>>>>>> catchmentArea", catchmentArea);
  let widgetContent;
  if (mp) {
    widgetContent = <MPWidget />;
  } else if (catchmentArea) {
    widgetContent = <CatchmentAreaWidget />;
  }

  const [WidgetMPDesktopWidth, setWidgetMPDesktopWidth] = useState(500);
  const [WidgetMPPDesktopHeight, setWidgetMPPDesktopHeight] = useState(575);
  const [WidgetDesktopPositionX, setWidgetDesktopPositionX] = useState(100);
  const [WidgetDesktopPositionY, setWidgetDesktopPositionY] = useState(200);
  const [WidgetMobilePositionX, setWidgetMobilePositionX] = useState(0);
  const [WidgetMobilePositionY, setWidgetMobilePositionY] = useState(50);

  const updateChartByResizing = (ref, delta) => {
    setWidgetMPDesktopWidth(ref.offsetWidth);
    setWidgetMPPDesktopHeight(ref.offsetHeight);
    const chart = getChart(mp, catchmentArea);
    if (chart?.chart?.datasets[0].data.length) {
      // chart.loading = false;
      chart.chart.width += delta.width;
      chart.chart.height += delta.height;
      chart.chart?.draw();
      // chart?.update();
    }
    chart.loading = false;
    console.log("Resize Stop");
  };

  const { isMobileView } = RootStore.uiStore;
  console.log("isMobileView", isMobileView);

  return (
    <>
      {factor && (
        <Rnd
          id={"measuring-point-dialog"}
          style={style}
          default={{
            x: isMobileView ? WidgetMobilePositionX : WidgetDesktopPositionX,
            y: isMobileView ? WidgetMobilePositionY : WidgetDesktopPositionY,
            width: WidgetMPDesktopWidth,
            height: WidgetMPPDesktopHeight,
          }}
          size={{
            width: isMobileView ? WidgetMobileWidth : WidgetMPDesktopWidth,
            height: isMobileView ? WidgetMobileHeight : WidgetMPPDesktopHeight,
          }}
          position={{
            x: isMobileView ? WidgetMobilePositionX : WidgetDesktopPositionX,
            y: isMobileView ? WidgetMobilePositionY : WidgetDesktopPositionY,
          }}
          minWidth={
            isMobileView ? widgetMobileMinimumWidth : WidgetDesktopMinimumWidth
          }
          minHeight={
            isMobileView
              ? widgetMobileMinimumHeight
              : WidgetDesktopMinimumHeight
          }
          disableDragging={false}
          dragHandleClassName={"dragHandleClass"}
          onDragStop={(e, d) => {
            isMobileView
              ? setWidgetMobilePositionX(d.x)
              : setWidgetDesktopPositionX(d.x);
            isMobileView
              ? setWidgetMobilePositionY(d.y)
              : setWidgetDesktopPositionY(d.y);
          }}
          enableResizing={{
            bottomRight: isMobileView ? false : true,
            right: isMobileView ? false : true,
            bottom: isMobileView ? false : true,
          }}
          resizeHandleClasses={["bottomRight", "right", "bottom"]}
          // lockAspectRatio={true}
          bounds="window"
          onResizeStart={(e, direction, ref, delta) => {
            console.log("Resize Start");
            const chart = getChart(mp, catchmentArea);
            if (chart) {
              chart.loading = true;
            }
          }}
          onResizeStop={(e, direction, ref, delta) => {
            updateChartByResizing(ref, delta);
          }}
        >
          {widgetContent}
        </Rnd>
      )}
    </>
  );
};

export default observer(Widget);
