import { Autocomplete, Card, CardHeader, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const SearchAspects = observer((props) => {
  const { CardContentNoPadding } = props;
  const { searchAspects, handleSelectedSearchValue } = RootStore.hydroWebStore;

  // const zofingen = hydroWebStationsNamesSorted.filter(
  //   (station) => station.station_name === "Zofingen"
  // );
  // console.log("zofingen", zofingen);

  return (
    <Card
      id="pikettHydroSearch"
      sx={{
        marginTop: "15px",
        border: "1px solid lightgray",
        color: "dimgray",
      }}
    >
      <CardHeader
        title="Suche"
        sx={{
          padding: "8px",
        }}
      />
      <CardContentNoPadding>
        {searchAspects.map((searchAspect) => {
          return (
            <Autocomplete
              key={searchAspect.id}
              sx={{ marginBottom: "15px" }}
              size={"small"}
              renderInput={(params) => (
                <TextField {...params} fullWidth label={searchAspect.title} />
              )}
              options={searchAspect.options}
              getOptionLabel={(option) =>
                searchAspect.renderOptionLabel(option)
              }
              value={searchAspect.value}
              fullWidth
              onChange={(e, value, reason) => {
                handleSelectedSearchValue(value, reason, searchAspect.id);
              }}
              isOptionEqualToValue={(option, value) =>
                searchAspect.isOptionEqualToValue(option, value)
              }
            />
          );
        })}
      </CardContentNoPadding>
    </Card>
  );
});

export default SearchAspects;
