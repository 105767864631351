import { action, makeObservable, observable, reaction } from "mobx";
import RootStore from "stores/RootStore";
import Chart from "components/chart/Chart";
import { DateTime } from "luxon";

const limitDistance = 0.9;

export class EnVISChart {
  constructor({
    stores = [],
    width,
    height,
    title,
    showPoints = false,
    updateLegend,
  }) {
    this.width = width;
    this.height = height;
    this.chart = null;
    this.title = title;
    this.showPoints = showPoints;
    this.stores = stores;
    this.updateLegend = () => {
      updateLegend(this);
    };
    this.stores.forEach((store) => {
      store.onChange = () => {
        this.createChart();
      };
    });
    this.loading = stores.reduce((acc, curr) => {
      return acc || curr.loading;
    }, false);

    makeObservable(this, {
      chart: observable.ref,
      loading: observable.ref,
      update: action,
    });
    reaction(
      () => stores.map((store) => !!store.loading),
      () => {
        // console.log("update loading");
        // console.log("reaction start");
        const loading = stores.reduce((acc, curr) => {
          return acc || curr.loading;
        }, false);
        if (loading === false) {
          // console.log("reaction end");
          this.initialize();
          this.createChart();
        }
        this.loading = loading;
        // if (loading === false) {
        //   this.update();
        // }
      }
    );
  }

  download(downloadURL) {
    console.log(downloadURL);
    window.open(downloadURL);
  }

  initialize() {
    const {
      max: { y: max },
    } = this.stores[0];
    for (let i = 1; i < this.stores.length; i++) {
      const store = this.stores[i];
      const {
        min: { y: min },
      } = store;
      if (store.Grenzwert) {
        if (min < max * limitDistance) {
          store.setVisible(true);
        } else {
          store.setVisible(false);
        }
      } else {
        this.resetDangerStoreVisibility(store, max);
        // store.setVisible(true);
      }
    }
    this.updateLegend();
  }

  setPeriod(
    period,
    reduceInterval = 0,
    noFetch = false,
    periodsOption,
    setSelectedPeriod,
    setIsShowingPeriodTooltip
  ) {
    this.stores.forEach((store) => {
      store.setPeriod(
        period,
        reduceInterval,
        noFetch,
        periodsOption,
        setSelectedPeriod,
        setIsShowingPeriodTooltip
      ); // stores will be loaded here first time
    });
  }

  setShowPoints(visible) {
    this.stores.forEach((store) => store.setShowPoints(visible));
    this.chart?.draw(); // redraw
    this.showPoints = visible;
  }

  resetDangerStoreVisibility = (store, maximum) => {
    if (maximum) {
      if (
        store.Feld !== "ts_value" &&
        store.Feld !== "ts_value2" &&
        store.Feld !== "ts_summe"
      ) {
        const min = store.min.y;
        if (min < maximum * limitDistance) {
          store.setVisible(true);
        } else {
          store.setVisible(false);
        }
      }
    }
  };

  createChart() {
    console.log("createChart");
    // console.log(" createChart this.stores", this.stores);
    let minx = Infinity,
      maxx = -Infinity,
      miny = Infinity,
      maxy = -Infinity;
    const chart = new Chart({
      height: RootStore.mapStore.measuringPointChart.chart
        ? RootStore.mapStore.measuringPointChart.chart.height
        : this.height,
      width: RootStore.mapStore.measuringPointChart.chart
        ? RootStore.mapStore.measuringPointChart.chart.width
        : this.width,
      padding: { left: 69, bottom: 90, right: 20, top: 10 },
      bgcolor: null, //"#F5F5F5",
      tooltipOptions: {
        formatX: (x) => {
          return DateTime.fromMillis(x).toFormat("dd.MM.yyyy HH:mm");
        },
        content: `<h2 style="margin-top:0;">\${name}</h2>
        Datum: \${x} <br>
        Wert: \${y} \${unit-y}`,
      },
    });
    const datasets = [];
    let min2 = null;
    let max2 = null;
    // this.hasy2 = true;
    this.stores.forEach((store) => {
      const { min, max, chartData, data, hasy2 } = store;
      if (!min || !max || !data) {
        return; // not all stores loaded yet => do not append to chart
      }
      if (store.visible) {
        if (hasy2) {
          if (!min2) {
            min2 = Infinity;
            max2 = -Infinity;
          }
          if (min.y < min2) {
            min2 = min.y;
          }
          if (max.y > max2) {
            max2 = max.y;
          }

          if (min.x < minx) {
            minx = min.x;
          }
          if (max.x > maxx) {
            maxx = max.x;
          }
        } else {
          if (min.y < miny) {
            miny = min.y;
          }
          if (min.x < minx) {
            minx = min.x;
          }
          if (max.y > maxy) {
            maxy = max.y;
          }
          if (max.x > maxx) {
            maxx = max.x;
          }
        }
        // datasets.push(chartData);
      }

      datasets.push(chartData);
    });
    chart.addAxis({
      positioning: "bottom",
      id: "x",
      ticks: 5,
      // tickFormat: (x) => DateTime.fromMillis(x).toFormat("dd.MM.yy"), //"dd.MM.yyyy HH:mm"), // format for date
      domain: [minx, maxx],
      unit: null,
      scaleType: "time",
      // data: this.stores[0]?.data,
      timeOpts: {
        round: false, // none, or override with week, month, year, etc.
        isoWeekday: false, // override week start day - see http://momentjs.com/docs/#/get-set/iso-weekday/
        displayFormats: {
          month: "M.YYYY",
          day: "D.M.YY",
          week: "D.M.YY",
          quarter: "[Q]Q - YYYY",
          year: "YYYY",
          hour: "D.M H:00",
          minute: "H:mm:ss",
          // month: "%m.%Y",
          // day: "%d.%m.%y",
          // week: "%d.%m.%y",
          // quarter: "[Q]Q - YYYY",
          // year: "%Y",
          // hour: "%d.%m %H:00",
          // minute: "%H:%M",
        },
      },
    });
    const computeMinY = (min, max) => {
      return min - (max - min) * 0.075;
    };
    const computeMaxY = (min, max) => {
      return max + (max - min) * 0.075;
    };

    const isThemeWindDirection =
      this.stores[0].groupParam === RootStore.windDirectionGroupParam;
    const yAxisDomain = isThemeWindDirection
      ? [0, 360]
      : [miny === 0 ? 0 : computeMinY(miny, maxy), computeMaxY(miny, maxy)];

    chart.addAxis({
      positioning: "left",
      id: "y",
      ticks: isThemeWindDirection ? null : 5,
      tickValues: isThemeWindDirection
        ? [0, 60, 120, 180, 240, 300, 360]
        : null,
      // domain: [miny > 0 ? 0 : miny, maxy],
      domain: yAxisDomain,
      unit: this.stores[0].Einheit,
      visible: miny !== Infinity || maxy !== -Infinity,
    });

    if (min2 !== null) {
      chart.padding.right = 69;
      chart.addAxis({
        positioning: "right",
        id: "y2",
        ticks: 5,
        // domain: [min2 > 0 ? 0 : min2, max2],
        domain: [
          min2 === 0 ? 0 : computeMinY(min2, max2),
          computeMaxY(min2, max2),
        ],
        unit: this.stores[0].Einheit,
        visible: true,
      });
    }

    datasets.forEach((dataset) => chart.addDataset(dataset));
    this.chart = chart;
    // console.log(" createChart this.chart", this.chart);
  }

  update() {
    this.stores.forEach((store) => {
      store.fetch();
    });
  }
}
