import { StatisitcFeatureStore } from "utils/StatisitcFeatureStore";

const getTimeseriesConfigForDiagram = ({ topicConfig, feature }) => {
  const timeseriesConfig = [];
  topicConfig?.Reihen.forEach((tseries) => {
    const { Feld } = tseries;
    if (
      Feld !== "ts_value" &&
      Feld !== "ts_value2" &&
      Feld !== "ts_summe" &&
      !feature[Feld]
    ) {
    } else {
      const {
        Anzeigename,
        ts_unitsymbol,
        GruppenID,
        TypID,
        BasisURL,
        Nachkommastellen,
      } = topicConfig;

      timeseriesConfig.push({
        ...tseries,
        ...{
          Anzeigename,
          Einheit: ts_unitsymbol,
          groupParam: `${GruppenID},${TypID}`,
          baseUrl: BasisURL,
          Nachkommastellen,
          feature,
        },
      });
    }
  });
  return timeseriesConfig;
};

export const getDiagramStores = ({ topicConfig, feature }) => {
  const timeseriesConfig = getTimeseriesConfigForDiagram({
    topicConfig,
    feature,
  });

  return timeseriesConfig
    .filter(
      (tsConfig) =>
        tsConfig.Feld === "ts_value" || tsConfig.Feld === "ts_value2"
    )
    .map((config) => {
      return new StatisitcFeatureStore(config);
    });
};

export const removeIntensityTooltipNode = () => {
  const tooltipnode = document.getElementById("p-intensity-tooltip");
  if (tooltipnode) {
    tooltipnode.remove();
  }
};

const createIntensityTooltipNode = (options, scaleColor) => {
  // create Tooltip node
  const node = document.createElement("div");
  node.id = "p-intensity-tooltip";
  node.style.position = "absolute";
  node.style.display = "none";
  node.style.background = scaleColor;
  node.style.color = "FFF";
  node.style.textShadow =
    "white -1px 1px 20px, white 1px 1px 20px, white 1px -1px 20px, white -1px -1px 20px";
  node.style.padding = "10px";
  node.style.borderRadius = "4px";
  node.style.zIndex = "9999";
  node.style.transition =
    "opacity 0.5s ease-in-out, height 0.5s ease-in-out, width 0.5s ease-in-out";

  // set position
  const { x, y } = options;
  node.style.top = y - 65 + "px";
  node.style.left = x - 200 + "px";

  return node;
};

export const openIntensityTooltip = (options, scaleElement) => {
  //  remove any intensity tooltip if exists
  removeIntensityTooltipNode();

  // create Tooltip node
  const node = createIntensityTooltipNode(options, scaleElement.color);
  document.body.appendChild(node);

  // Append content
  const content = `<h4 style="margin:0;overflow: hidden;">Niederschlagsintensität</h4> <h5 style="margin:0; overflow: hidden;margin-top: 10px;text-align: center;">${scaleElement.primaryText}</h5>`;
  if (content instanceof HTMLElement) {
    node.appendChild(content);
  } else {
    node.innerHTML = content;
  }

  // display Tooltip
  node.style.display = "block";
};

export const moveIntensityTooltip = (options) => {
  const tooltipnode = document.getElementById("p-intensity-tooltip");
  if (tooltipnode) {
    const { x, y } = options;
    tooltipnode.style.top = y - 65 + "px";
    tooltipnode.style.left = x - 200 + "px";
  }
};
