import RootStore from "stores/RootStore";
import PeriodTooltip from "./PeriodTooltip";
import PeriodSelect from "./PeriodSelect";
import FooterButtons from "./FooterButtons";

const GraphFooter = ({
  options,
  nls,
  fullscreen,
  setFullscreen,
  // showPoints,
  // setShowPoints,
  download,
  update,
  isShowingPeriodTooltip,
  handleClosePeriodTooltip,
}) => {
  // console.log(">>>>>> render GraphFooter");

  return (
    <div
      style={{
        flexGrow: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "#efefef",
      }}
    >
      <PeriodSelect
        style={{
          width: "75%",
          flexGrow: 0,
          margin: "9px",
          backgroundColor: "white",
        }}
        value={RootStore.mapStore.selectedPeriod}
        setValue={(value) => {
          RootStore.mapStore.selectedPeriod = value;
        }}
        options={options}
        nls={nls}
      />

      <FooterButtons
        nls={nls}
        fullscreen={fullscreen}
        setFullscreen={setFullscreen}
        download={download}
        update={update}
      />

      <PeriodTooltip
        open={isShowingPeriodTooltip}
        onCloseHandle={handleClosePeriodTooltip}
      />
    </div>
  );
};

export default GraphFooter;
