export const intervalOptions = [
  // {
  //   label: "gemessene Werte",
  //   reduceInterval: 0,
  // },
  {
    label: "10-Min-Intervall",
    reduceInterval: 600000,
    comment: "1000*60*10 = 10 Minuten",
  },
  {
    label: "20-Min-Intervall",
    reduceInterval: 1200000,
    comment: "1000*60*20 = 20 Minuten",
  },
  {
    label: "30-Min-Intervall",
    reduceInterval: 1800000,
    comment: "1000*60*30 = 30 Minuten",
  },
  {
    label: "60-Min-Intervall",
    reduceInterval: 3600000,
    comment: "1000*60*60 = 60 Minuten",
  },
  {
    label: "2-Std-Intervall",
    reduceInterval: 7200000,
    comment: "1000*60*60*2 = 2 Std",
  },
  {
    label: "4-Std-Intervall",
    reduceInterval: 14400000,
    comment: "1000*60*60*4 = 4 Std",
  },
  {
    label: "8-Std-Intervall",
    reduceInterval: 28800000,
    comment: "1000*60*60*8 = 8 Std",
  },
  {
    label: "12-Std-Intervall",
    reduceInterval: 43200000,
    comment: "1000*60*60*12 = 12 Std",
  },
  {
    label: "24-Std-Intervall",
    reduceInterval: 86400000,
    comment: "1000*60*60*24 = 24 Std",
  },
];

export const precipitationIntensityScale = (reduceInterval) => {
  const isOneHourInterval = reduceInterval === 3600000;
  const intervalMinutes = `${isOneHourInterval ? 60 : 10}`;

  return [
    {
      id: "intensity-scale-01",
      primaryText: "Sehr stark",
      secondaryText: `Niederschlagshöhe in ${intervalMinutes} Minuten ≥ ${
        isOneHourInterval ? "50,0" : "8,3"
      } mm`,
      color: "rgb(0 0 280/ 80%)",
      range: isOneHourInterval ? [50, 1000] : [8.3, 100],
    },
    {
      id: "intensity-scale-02",
      primaryText: "Stark",
      secondaryText: `Niederschlagshöhe in ${intervalMinutes} Minuten ≥ ${
        isOneHourInterval ? "10,0" : "1,7"
      } mm`,
      color: "rgb(29 145 192 / 80%)", //  "rgb(100 100 280 / 60%)",
      range: isOneHourInterval ? [10, 50] : [1.7, 8.3],
    },
    {
      id: "intensity-scale-03",
      primaryText: "Mäßig",
      secondaryText: `Niederschlagshöhe in ${intervalMinutes} Minuten ≥ ${
        isOneHourInterval ? "2,5" : "0,5"
      } mm bis < ${isOneHourInterval ? "10" : "1,7"} mm`,
      color: "rgb(76 193 128/ 80%)", // "rgb(150 150 280 / 60%)",
      range: isOneHourInterval ? [2.5, 10] : [0.5, 1.7],
    },
    {
      id: "intensity-scale-04",
      primaryText: "Leicht",
      secondaryText: `Niederschlagshöhe in ${intervalMinutes} Minuten <  ${
        isOneHourInterval ? "2,5" : "0,5"
      } mm`,
      color: "rgb(199 233 180 /80%)", // "rgb(200 200 280 / 60%)",
      range: isOneHourInterval ? [0, 2.5] : [0, 0.5],
    },
  ];
};
