import ReactDOM from "react-dom";
// import { round } from "./utils";
import { round, onClick, changeCursor } from "libs/EnVISUtils";
import { connectLayerWithStore } from "./StoreLayer";
import {
  extendObservable,
  action,
  makeObservable,
  observable,
  when,
  reaction,
  toJS,
} from "mobx";
import { clusterSym } from "../configs/envisSymbols";
import StoreLayer from "./StoreLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import APIHelper from "./APIHelper";
import RootStore from "stores/RootStore";
import { Typography } from "@mui/material";
import SimpleTable from "./SimpleTable";
import symbols from "../configs/symbols";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import Polygon from "@arcgis/core/geometry/Polygon";
import Extent from "@arcgis/core/geometry/Extent";
import * as GeometryEngine from "@arcgis/core/geometry/geometryEngine";
import Feature from "@arcgis/core/widgets/Feature";
import Graphic from "@arcgis/core/Graphic";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import Font from "@arcgis/core/symbols/Font";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import Color from "@arcgis/core/Color";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as projection from "@arcgis/core/geometry/projection";
import MarkerSymbol from "@arcgis/core/symbols/MarkerSymbol";

export default class HydroLayer {
  // app = "pikett";
  constructor({
    app,
    store,
    highlightSymbol = {
      type: "simple-fill",
      style: "solid",
      color: [0, 0, 255, 0.1],
      outline: {
        type: "simple-line",
        style: "solid",
        color: [0, 0, 255, 0.6],
        width: 3,
      },
    },
  }) {
    const layer = new GraphicsLayer({
      id: app + "-GraphicsLayer",
      title: app + "-Layer",
      listMode: "hide",
    });
    this.highlightSymbol = highlightSymbol;
    this.graphics = [];
    connectLayerWithStore(layer, store, (graphics, fields) => {
      this.setFeatures(graphics);
    });
    this.layer = layer;
    this.app = app;
    this.store = store;
    this.watcher = [];
    this.hoverGraphicLayer = new GraphicsLayer({
      title: "Hover Graphic Layer",
      listMode: "hide",
    });
    this.pikettFlagsAnchors = RootStore.envisHydroConfig.PikettFlags;
    // this.pikettNiedrigwasser = [
    //   {
    //     station_no: "FG_0362",
    //     __grenzwert: 0.2,
    //     meldung: "Keine Wasserentnahme möglich!",
    //   },
    //   {
    //     station_no: "FG_0361",
    //     grenzwert: 0.1,
    //     meldung: "Keine Wasserentnahme möglich!",
    //   },
    //   {
    //     station_no: "FG_0342",
    //     grenzwert: 0.1,
    //     meldung: "Keine Wasserentnahme möglich!",
    //   },
    //   {
    //     station_no: "FG_2091",
    //     grenzwert: 600,
    //     meldung: "Keine Wasserentnahme möglich!",
    //   },
    // ];
    this.pikettNiedrigwasser = RootStore.envisHydroConfig.Niedrigwasser;
    this.pikettFlagGeometry = {
      rings: [
        [
          [2, 5],
          [2, 16],
          [24, 16],
          [24, 5],
          [2, 5],
        ],
      ],
    };
    this.hydroFlagGeometry = {
      rings: [
        [
          [6, 7],
          [6, 14],
          [19, 14],
          [19, 7],
          [6, 7],
        ],
      ],
    };
    this.showflags = {
      523: true, // dodger blau niederschlage
      557: true, //blau Abfluss
      559: true, //pegel gelb
      548: true, //rot wassertemperatur
    };
    this.showPikettFlags = true;
    this.graphicSizes = { small: 11, medium: 20, big: 40 };
    this.symbolScaleParametrs = this.scaleSymbols();
    this.warningLayer = new GraphicsLayer();
    if (store.graphics) {
      this.setFeatures(store.graphics);
    }
    this.einzugsgebieteConfig = RootStore.envisHydroConfig.Einzugsgebiete;
    this.catchmentAreaLayer = new FeatureLayer({
      listMode: "hide",
      url: this.einzugsgebieteConfig["AGSLayer"],
      renderer: {
        type: "simple",
        symbol: {
          type: "simple-fill", // autocasts as new SimpleFillSymbol()
          color: [198, 219, 239, 0.15],
          style: "solid",
          outline: {
            // autocasts as new SimpleLineSymbol()
            color: [255, 255, 255, 0.15],
            width: 1,
          },
        },
      },
      definitionExpression: "1=2",
      geometryType: "polygon",
      outFields: ["*"],
    });
  }

  setFeatures(graphics) {
    this.layer.removeAll();
    this.warningLayer?.removeAll();
    this.graphics = graphics.concat([]);
    this.drawGraphics();
  }

  dangerLevelsColors = () => {
    // Gefahrenstufen
    let dangerColors = {};
    dangerColors["gs2"] = [255, 255, 0, 175];
    dangerColors["gs3"] = [205, 130, 0, 175]; // [244, 151, 23, 125];
    dangerColors["gs4"] = [255, 31, 36, 175]; // [229, 28, 32, 125];
    dangerColors["gs5"] = [128, 0, 0, 175];
    dangerColors["lowWater"] = [0, 0, 225, 175]; // niedrigwasser
    return dangerColors;
  };

  getNiedrigwasser = (stationNumber, currentValue) => {
    if (this.pikettNiedrigwasser) {
      for (let i = 0; i < this.pikettNiedrigwasser.length; i++) {
        const item = this.pikettNiedrigwasser[i];
        if (item.station_no === stationNumber) {
          if (!item.grenzwert) return item.meldung || "";
          else if (item.grenzwert >= currentValue) return item.meldung || "";
        }
      }
    }
    return false;
  };

  specifyFlagColor = (graphicAttribute, lowWater) => {
    let color = [200, 200, 200, 175];
    const dangerLevelsColors = this.dangerLevelsColors();
    const gs5 = Number(
      graphicAttribute.feature["Gefahrenstufe 5"].split(" ")[0]
    );
    const gs4 = Number(
      graphicAttribute.feature["Gefahrenstufe 4"].split(" ")[0]
    );
    const gs3 = Number(
      graphicAttribute.feature["Gefahrenstufe 3"].split(" ")[0]
    );
    const gs2 = Number(
      graphicAttribute.feature["Gefahrenstufe 2"].split(" ")[0]
    );
    const currentValue = graphicAttribute.currentValue;
    if (lowWater !== false) {
      color = dangerLevelsColors["lowWater"];
    } else if (gs5 && currentValue >= gs5) {
      color = dangerLevelsColors["gs5"];
    } else if (gs4 && currentValue >= gs4) {
      color = dangerLevelsColors["gs4"];
    } else if (gs3 && currentValue >= gs3) {
      color = dangerLevelsColors["gs3"];
    } else if (gs2 && currentValue >= gs2) {
      color = dangerLevelsColors["gs2"];
    }
    return color;
  };

  getPointSymbolData(offset, flagoffset, txt, flagColor, lowWater) {
    return {
      type: "CIMPointSymbol",
      symbolLayers: [
        {
          name: "Text Station Values",
          type: "CIMVectorMarker",
          enable: true,
          size: 20,
          colorLocked: true,
          anchorPointUnits: "Relative",
          frame: { xmin: -5, ymin: -5, xmax: 5, ymax: 5 },
          markerGraphics: [
            {
              type: "CIMMarkerGraphic",
              geometry: { x: 0, y: 0 },
              symbol: {
                type: "CIMTextSymbol",
                fontFamilyName: "Arial",
                // fontStyleName: "Bold",
                height: 4,
                horizontalAlignment: "Center",
                offsetX: offset.x, //offset.x + 17,
                offsetY: offset.y, //offset.y - 10,
                symbol: {
                  type: "CIMPolygonSymbol",
                  symbolLayers: [
                    {
                      type: "CIMSolidFill",
                      enable: true,
                      color: lowWater ? [225, 225, 225, 255] : [0, 0, 0, 255],
                    },
                  ],
                },
                verticalAlignment: "Center",
              },
              textString: String(txt),
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
        },
        {
          name: "Text Low Water",
          type: "CIMVectorMarker",
          enable: lowWater ? true : false,
          size: 20,
          colorLocked: true,
          anchorPointUnits: "Relative",
          frame: { xmin: -5, ymin: -5, xmax: 5, ymax: 5 },
          markerGraphics: [
            {
              type: "CIMMarkerGraphic",
              geometry: { x: 0, y: 0 },
              symbol: {
                type: "CIMTextSymbol",
                fontFamilyName: "Arial",
                // fontStyleName: "Bold",
                height: 4,
                horizontalAlignment: "Center",
                offsetX: offset.x, //offset.x + 17,
                offsetY: offset.y + 8,
                symbol: {
                  type: "CIMPolygonSymbol",
                  symbolLayers: [
                    {
                      type: "CIMSolidFill",
                      enable: true,
                      color: [0, 0, 0, 255],
                    },
                  ],
                },
                haloSymbol: {
                  type: "CIMPolygonSymbol",
                  symbolLayers: [
                    {
                      type: "CIMSolidFill",
                      enable: true,
                      color: [225, 225, 225, 255],
                    },
                  ],
                },
                haloSize: 2,
                verticalAlignment: "Center",
              },
              textString: String(lowWater),
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
        },
        {
          name: "Flag Station Values",
          type: "CIMVectorMarker",
          enable: true,
          anchorPoint: {
            x: flagoffset.x, //-(flagoffset.x + 20) / 10,
            y: flagoffset.y, //-(flagoffset.y - 15) / 10,
          },
          anchorPointUnits: "Relative",
          size: 38,
          frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
          markerGraphics: [
            {
              type: "CIMMarkerGraphic",
              geometry:
                this.app === "pikett"
                  ? this.pikettFlagGeometry
                  : this.hydroFlagGeometry,
              symbol: {
                type: "CIMPolygonSymbol",
                symbolLayers: [
                  {
                    type: "CIMSolidFill",
                    enable: true,
                    color: flagColor,
                  },
                ],
              },
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
        },
      ],
    };
  }

  specifyOffsets = (pSize, pPosition) => {
    let _offsetText, _offsetFlag;
    if (pSize === this.graphicSizes.big) {
      _offsetText = 30;
      _offsetFlag = 27;
    } else if (pSize === this.graphicSizes.medium) {
      _offsetText = this.app === "pikett" ? 26 : 24;
      _offsetFlag = this.app === "pikett" ? 25 : 24;
    } else {
      // pSize === 11
      _offsetText = 24;
      _offsetFlag = 24;
    }
    const textOffset = { x: 0, y: 0 };
    const flagOffset = { x: 0, y: 0 };
    switch (pPosition) {
      case "bottomleft":
        textOffset.x = -_offsetText + (this.app === "pikett" ? 11.5 : 15);
        textOffset.y = -_offsetText + 14;
        flagOffset.x = (_offsetFlag - (this.app === "pikett" ? 15 : 17)) / 10;
        flagOffset.y = (_offsetFlag - 17.5) / 10;
        break;
      case "bottomright":
        textOffset.x = _offsetText - (this.app === "pikett" ? 11.5 : 16);
        textOffset.y = -_offsetText + 14;
        flagOffset.x = (-_offsetFlag + (this.app === "pikett" ? 20 : 22)) / 10;
        flagOffset.y = (_offsetFlag - 17.5) / 10;
        break;
      case "topleft":
        textOffset.x = -_offsetText + 11.5;
        textOffset.y = _offsetText - 14.5;
        flagOffset.x = (_offsetFlag - 15) / 10;
        flagOffset.y = (-_offsetFlag + 20) / 10;
        break;
      case "topright":
        textOffset.x = _offsetText - 11.5;
        textOffset.y = _offsetText - 14.5;
        flagOffset.x = (-_offsetFlag + 20) / 10;
        flagOffset.y = (-_offsetFlag + 20) / 10;
        break;

      case "right":
        textOffset.x = _offsetText - 8;
        textOffset.y = 0;
        flagOffset.x = (-_offsetFlag + 18) / 10;
        flagOffset.y = 0.1;
        break;
      case "left":
        textOffset.x = -_offsetText + 8;
        textOffset.y = 0;
        flagOffset.x = (_offsetFlag - 13) / 10;
        flagOffset.y = 0.1;
        break;

      case "bottom":
        textOffset.x = 0;
        textOffset.y = -_offsetText + 14;
        flagOffset.x = 0.25;
        flagOffset.y = (_offsetFlag - 17.5) / 10;
        break;
      case "top":
      default:
        textOffset.x = 0;
        textOffset.y = _offsetText - 14.5;
        flagOffset.x = 0.25;
        flagOffset.y = (-_offsetFlag + 20) / 10;
        break;
    }
    return { textOffset: textOffset, flagOffset: flagOffset };
  };

  createFlag = (graphicAttributes, pSize, geometry) => {
    graphicAttributes.staionThemesData.forEach((theme) => {
      const [group, param] = theme.groupParam.split(",");
      const pPosition = theme.pPosition ? theme.pPosition : "top";
      const { textOffset, flagOffset } = this.specifyOffsets(pSize, pPosition);
      let txt = theme.currentValue;
      let flagColor = [200, 200, 200, 175];
      let lowWater = false;
      const graphicVisibility =
        this.app === "pikett" ? this.showPikettFlags : this.showflags[param];
      if (this.app === "pikett") {
        const lowWater = this.getNiedrigwasser(
          graphicAttributes.station_no,
          theme.currentValue
        );
        txt = this.formulatingText(theme, lowWater);
        flagColor = this.specifyFlagColor(theme, lowWater);
      }
      const cimSymbol = {
        type: "cim",
        data: {
          type: "CIMSymbolReference",
          symbol: this.getPointSymbolData(
            textOffset,
            flagOffset,
            txt,
            flagColor,
            lowWater
          ),
        },
      };
      const textGraphic = new Graphic({
        geometry,
        symbol: cimSymbol,
        visible: graphicVisibility,
      });
      theme.textGraphic = textGraphic;
      this.warningLayer?.add(textGraphic);
      // if (graphicAttributes.station_no === "FG_0346") {
      //   console.log(graphicAttributes);
      // }
    });
  };

  formulatingText = (graphicAttribute, lowWater) => {
    let txt = "";
    const date = new Date(graphicAttribute.timestamp);
    let daytxt = "" + date.getDate();
    if (daytxt.length == 1) daytxt = "0" + daytxt;
    let monthtxt = "" + (date.getMonth() + 1);
    if (monthtxt.length == 1) monthtxt = "0" + monthtxt;

    let hourtxt = "" + date.getHours();
    if (hourtxt.length == 1) hourtxt = "0" + hourtxt;
    let minutetxt = "" + date.getMinutes();
    if (minutetxt.length == 1) minutetxt = "0" + minutetxt;
    const unit = graphicAttribute.unit;
    txt = graphicAttribute.currentValue;
    txt +=
      " " +
      unit +
      "\n" +
      hourtxt +
      ":" +
      minutetxt +
      " " +
      daytxt +
      "." +
      monthtxt +
      "";
    // if (lowWater !== false) {
    //   txt += lowWater;
    // }
    return txt;
  };

  scaleSymbols = (mapLevel) => {
    const scale = RootStore.mapStore.activeView
      ? RootStore.mapStore.activeView.scale
      : RootStore.mapStore.viewScale;
    // console.log("scaleSymbols scale", scale);
    let pSize = this.graphicSizes.small;
    let hoverSize = null;
    // const showWarningLayer = scale <= 82000 || this.app === "pikett";
    const showWarningLayer =
      this.app === "pikett" ? scale <= 652000 : scale <= 82000;
    if (scale >= 134000) {
      pSize = this.graphicSizes.small;
      hoverSize = 20;
    } else if (scale > 10000 && scale < 134000) {
      pSize = this.graphicSizes.medium;
      hoverSize = null;
    } else {
      pSize = this.graphicSizes.big;
      hoverSize = null;
    }
    return {
      pSize,
      hoverSize,
      showWarningLayer,
    };
  };

  getGraphicData = (graphic) => {
    const attributes = APIHelper.getAttributes(graphic);
    const stationThemesWithPosition = this.specifyPosition(attributes);
    let staionThemesData = [];
    stationThemesWithPosition.forEach((theme) => {
      const { groupParam, index } = theme;
      // const tsconfig =
      //   this.app === "pikett"
      //     ? RootStore.pikettZeitreihenConfig[groupParam]
      //     : RootStore.hydroZeitreihenConfig[groupParam];
      const tsconfig = this.store.timeSeriesconfig[groupParam];
      const feature = this.store.dataFiltered[groupParam][index];
      if (!tsconfig.zusatzReihe || !tsconfig.hideTab) {
        const name = tsconfig.Anzeigename;
        const color = tsconfig.Farbe;
        const feature = this.store.dataFiltered[groupParam][index];
        const themeData = {
          pPosition: theme.pPosition,
          timestamp: feature.timestamp,
          currentValue: round(feature.ts_value, tsconfig.Nachkommastellen),
          key: name,
          color,
          groupParam,
          feature,
        };
        if (feature.ts_unitsymbol) {
          themeData.unit = feature.ts_unitsymbol;
        }
        staionThemesData.push(themeData);
      }
    });
    const graphicData = {
      showTooltip: 1,
      ...attributes,
      staionThemesData,
    };
    // if (attributes.station_no === "FG_0346") {
    //   console.log(graphicData);
    // }
    return graphicData;
  };

  containsAll = (arr1, arr2) => {
    return arr1.every((i) => arr2.includes(i));
  };

  specifyPosition = (graphicAttribute) => {
    const stationThemes = JSON.parse(graphicAttribute.station);
    let stationThemesData;
    if (this.app === "pikett") {
      stationThemesData = stationThemes
        .map((theme) => {
          let pPosition = "top";
          if (this.pikettFlagsAnchors[graphicAttribute.station_no]) {
            pPosition = this.pikettFlagsAnchors[graphicAttribute.station_no];
          }
          const stationThemesWithPosition = { ...theme, pPosition };
          return stationThemesWithPosition;
        })
        .filter((e) => e !== null);
    } else {
      const threePositions = {
        557: "bottomleft", //blau Abfluss
        559: "bottomright", //pegel gelb
        548: "top", //rot wassertemperatur
        523: "top", //dodger blau niederschlage
      };
      const stationThemesParams = stationThemes
        .map((theme) => {
          const { groupParam, index } = theme;
          const [group, param] = groupParam.split(",");
          return param;
        })
        .filter((e) => e !== null);
      if (stationThemesParams.length === 3) {
      }
      let twoPositions = {};
      if (stationThemesParams.length === 2) {
        if (this.containsAll(stationThemesParams, ["559", "548"])) {
          twoPositions = {
            559: "bottom", //pegel gelb
            548: "top", //rot wassertemperatur
          };
        } else if (this.containsAll(stationThemesParams, ["559", "557"])) {
          twoPositions = {
            559: "bottom", //pegel gelb
            557: "top", //blau Abfluss
          };
        } else if (this.containsAll(stationThemesParams, ["557", "548"])) {
          twoPositions = {
            557: "bottom", //blau Abfluss
            548: "top", //rot wassertemperatur
          };
        }
      }
      stationThemesData = stationThemes
        .map((theme) => {
          const { groupParam, index } = theme;
          const [group, param] = groupParam.split(",");
          let pPosition = "top";
          if (stationThemes.length === 3) {
            pPosition = threePositions[param];
          } else if (stationThemes.length === 2) {
            pPosition = twoPositions[param];
          } else if (stationThemes.length === 1) {
            pPosition = "top";
          }
          const stationThemesWithPosition = { ...theme, pPosition };
          return stationThemesWithPosition;
        })
        .filter((e) => e !== null);
    }
    return stationThemesData;
  };

  drawGraphics() {
    this.layer.removeAll();
    this.warningLayer.visible = this.symbolScaleParametrs.showWarningLayer;
    this.warningLayer.removeAll();
    this.graphics.forEach((graphic) => {
      const geometry = APIHelper.getGeometry(graphic);
      const graphicAttributes = this.getGraphicData(graphic);
      const symbol = APIHelper.getSymbol(graphic);
      symbol.width = this.symbolScaleParametrs.pSize;
      symbol.height = this.symbolScaleParametrs.pSize;
      this.layer.add({ geometry, attributes: graphicAttributes, symbol });
      this.createFlag(
        graphicAttributes,
        this.symbolScaleParametrs.pSize,
        geometry
      );
    });
  }

  fetchCurrentValues(graphics) {
    graphics.forEach((graphic) => {
      const attributes = APIHelper.getAttributes(graphic);
      const configStore = RootStore.pikettZeitreihenConfig;
      const data = this.store.indicesFiltered.station[
        attributes.station_id
      ].map(({ groupParam, index }, stationIndex) => {
        // console.log(groupParam);
        const tsconfig = configStore[groupParam];
        const feature = this.store.dataFiltered[groupParam][index];
        const url = this.url(tsconfig.BasisURL, feature.ts_id);
        this.fetchData(url, attributes, graphic);
      });
    });
  }

  fetchData(url, attributes, graphic) {
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        const [raw] = response;
        // console.log(raw);
        attributes["unit"] = raw.ts_unitsymbol;
        attributes["timestamp"] = raw.data[0][0];
        attributes["currentValue"] = raw.data[0][1];
        return attributes;
      })
      .then((attributes) => {
        this.drawGraphics(attributes, graphic);
      })
      .catch((e) => {
        console.error("Error while loading timeseries", e);
      });
  }

  url(BasisURL, ts_id) {
    return (
      // this.proxy +
      // this.baseUrl +
      // "https://test.giscon.de/Proxy/Proxy.ashx?" +
      RootStore.config.proxyURL +
      BasisURL +
      `&request=getTimeseriesValues&format=dajson&dateformat=UNIX&ts_id=${ts_id}` +
      `&metadata=true&md_returnfields=ts_id%2Cparametertype_id%2Cts_unitsymbol`
    );
  }

  // zoomToStation(station_id) {}

  // featureClicked(feature, station_no, groupParam, index, stationIndex) {
  //   // const tsconfig =
  //   //   this.app === "pikett"
  //   //     ? RootStore.pikettZeitreihenConfig[groupParam]
  //   //     : RootStore.hydroZeitreihenConfig[groupParam];
  //   const tsconfig = this.store.timeSeriesconfig[groupParam];
  //   RootStore.mapStore.openMeasuringPointWidget(
  //     station_no,
  //     this.app,
  //     groupParam,
  //     feature
  //   );
  //   RootStore.mapStore.clickedCatchmentArea = null;
  // }

  getAttributeColor = (graphic, imageData) => {
    // console.log(imageData);
    let attributeColor;
    const colorArray = graphic.attributes.colors.split(",");
    colorArray.forEach((color) => {
      const graphicColorToHex = this.convertHexToRgb(color);
      // console.log(graphicColorToHex);
      if (
        this.tollerance(graphicColorToHex.r, imageData[0], 55) &&
        this.tollerance(graphicColorToHex.g, imageData[1], 55) &&
        this.tollerance(graphicColorToHex.b, imageData[2], 55)
      ) {
        // console.log(">>>>> Tolerance");
        attributeColor = this.convertRgbToHex(
          graphicColorToHex.r,
          graphicColorToHex.g,
          graphicColorToHex.b
        );
      }
    });
    return attributeColor;
  };

  defineHoverdAttributeKey = (graphic, view) => {
    let options = {
      area: {
        x: event.x,
        y: event.y,
        width: 1,
        height: 1,
      },
    };
    view
      .takeScreenshot(options)
      .then((screenshot) => {
        const xCtx = screenshot.data;
        return xCtx;
      })
      .then((xCtx) => {
        // console.log(xCtx);
        const imageData = xCtx.data;
        return imageData;
      })
      .then((imageData) => {
        const attributeColor = this.getAttributeColor(graphic, imageData);
        // console.log(attributeColor);
      });
  };

  onHover(event, graphic, view) {
    // console.log("HydroLayer Hover");
    // this.defineHoverdAttributeKey(graphic, view);
    this.hoverGraphicLayer.removeAll();
    const hoverGraphic = graphic.clone();
    if (this.symbolScaleParametrs.hoverSize) {
      hoverGraphic.symbol.width = this.symbolScaleParametrs.hoverSize;
      hoverGraphic.symbol.height = this.symbolScaleParametrs.hoverSize;
      this.hoverGraphicLayer.add(hoverGraphic);
      // this.hoverGraphicLayer.graphics.push(this.hoverGraphic);
    }
    hoverGraphic.attributes.staionThemesData.forEach((theme) => {
      this.hoverGraphicLayer?.add(theme.textGraphic);
      if (theme.textGraphic) {
        theme.textGraphic.visible = true;
      }
    });
  }

  getNewSymbol(graphic) {
    const circle1 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><rect x="0" y="0" width="30" height="30" fill="color1"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';
    const circle2 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><rect x="0" y="0" width="30" height="15" fill="color1"/><rect x="0" y="15" width="30" height="15" fill="color2"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';
    const circle3 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><path d="M 2 7.5 A 15 15 0 0 0 15 30 L 15 15 Z" fill="color1"/><path d="M 28 7.5 A 15 15 0 0 0 2 7.7 L 15 15 Z" fill="color2"/><path d="M 28 7.5 A 15 15 0 0 1 15 30 L 15 15 Z" fill="color3"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';
    const circle4 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" version="1.1"><defs><linearGradient id="lgrad" x1="0%" y1="100%" x2="0%" y2="0%" ><stop offset="0%" style="stop-color:rgb(0,0,0);stop-opacity:.5" /><stop offset="100%" style="stop-color:rgb(200,200,200);stop-opacity:.5" /></linearGradient> <clipPath id="outercircle"><circle cx="15" cy="15" r="14"/></clipPath><mask id="innercycle"><rect x="0" y="0" width="30" height="30" fill="#FFF"/><circle cx="15" cy="15" r="5" fill="#000"/></mask></defs><circle cx="15" cy="15" r="5" fill="none" stroke-width="1" stroke="#356D96"/><g clip-path="url(#outercircle)"><rect x="0" y="0" width="15" height="15" fill="color1"/><rect x="15" y="0" width="15" height="15" fill="color2"/><rect x="15" y="15" width="15" height="15" fill="color3"/><rect x="0" y="15" width="15" height="15" fill="color4"/></g><circle cx="15" cy="15" r="12" fill="none" style="opacity:1" stroke="url(#lgrad)" stroke-width="4"/></svg>';
    const colorArray =
      this.app === "pikett"
        ? graphic.attributes[0].colors.split(",")
        : graphic.attributes.colors.split(",");
    let symbolUrl;
    if (colorArray.length === 1) {
      symbolUrl =
        "data:image/svg+xml;base64," +
        window.btoa(circle1.replace("color1", colorArray[0]));
    } else if (colorArray.length === 2) {
      symbolUrl =
        "data:image/svg+xml;base64," +
        window.btoa(
          circle2
            .replace("color1", colorArray[0])
            .replace("color2", colorArray[1])
        );
    } else if (colorArray.length === 3) {
      symbolUrl =
        "data:image/svg+xml;base64," +
        window.btoa(
          circle3
            .replace("color1", colorArray[0])
            .replace("color2", colorArray[1])
            .replace("color3", colorArray[2])
        );
    } else if (colorArray.length === 4) {
      symbolUrl =
        "data:image/svg+xml;base64," +
        window.btoa(
          circle4
            .replace("color1", colorArray[0])
            .replace("color2", colorArray[1])
            .replace("color3", colorArray[2])
            .replace("color4", colorArray[3])
        );
    }
    return {
      url: symbolUrl,
      type: "picture-marker",
      width: 20,
      height: 20,
    };
  }

  colorToHex = (color) => {
    const hex = color.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  convertRgbToHex = (r, g, b) => {
    const hexColor =
      "#" + this.colorToHex(r) + this.colorToHex(g) + this.colorToHex(b);
    return hexColor;
  };

  convertHexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  tollerance = (a, b, c) => {
    return Math.abs(a - b) < c;
  };

  openCatchmentAreaWidget = (graphic) => {
    // console.log(graphic);
    const catchmentAreasConfig = RootStore.envisHydroConfig.Einzugsgebiete;
    const idAttribute = catchmentAreasConfig["IDAttribute"];
    const catchmentAreasInfo = catchmentAreasConfig.Einzugsgebiete;
    const nachkommastellen =
      catchmentAreasConfig.Nachkommastellen !== "undefined"
        ? catchmentAreasConfig.Nachkommastellen
        : 2;
    let catchmentAreaInfo;
    Object.keys(catchmentAreasInfo).forEach((name) => {
      if (
        catchmentAreasInfo[name].Nr &&
        catchmentAreasInfo[name].Nr === graphic.attributes[idAttribute]
      ) {
        catchmentAreaInfo = catchmentAreasInfo[name];
        catchmentAreaInfo.name = name;
        catchmentAreaInfo.groesse = graphic.attributes["Shape.STArea()"];
        catchmentAreaInfo.Nachkommastellen = nachkommastellen;
      }
    });
    // RootStore.mapStore.clickedCatchmentArea = {
    //   feature: graphic,
    //   app: this.app,
    //   tab: "-1",
    //   catchmentAreaInfo,
    // };
    RootStore.mapStore.setClickedCatchmentArea({
      feature: graphic,
      app: this.app,
      tab: "-1",
      catchmentAreaInfo,
    });
  };

  watchViewScale = (view) => {
    return view.watch("scale", (newValue, oldValue, propertyName, target) => {
      // console.log('view.watch("scale") this.app', this.app);
      const newScaleParameters = this.scaleSymbols(newValue);
      this.warningLayer.visible = newScaleParameters.showWarningLayer;
      // if (this.symbolScaleParametrs.pSize !== newScaleParameters.pSize) {
      //   this.symbolScaleParametrs = newScaleParameters;
      //   this.drawGraphics();
      // }
    });
  };

  pointerMoveEvent = (view) => {
    return view.on("pointer-move", (event) => {
      view.hitTest(event).then((response) => {
        if (response.results.length) {
          const graphic = response.results.filter((result) => {
            // check if the graphic belongs to the layer of interest
            return result.graphic.layer === this.layer;
          })[0]?.graphic;
          changeCursor(graphic, view);
          if (graphic) {
            this.onHover(event, graphic, view);
          } else {
            this.hoverGraphicLayer.removeAll();
            this.highlightCatchmentArea(response, view);
          }
        } else {
          this.hoverGraphicLayer.removeAll();
        }
      });
    });
  };

  highlightCatchmentArea = (response, view) => {
    const graphic = response.results.filter((result) => {
      // check if the graphic belongs to the layer of interest
      return result.graphic.layer === this.catchmentAreaLayer;
    })[0]?.graphic;
    if (graphic) {
      const hoverGraphic = graphic.clone();
      hoverGraphic.symbol = {
        type: "simple-fill",
        style: "solid",
        color: [255, 255, 255, 0.2],
        outline: {
          color: [0, 0, 0, 0.75],
          width: 2,
        },
      };
      this.hoverGraphicLayer.add(hoverGraphic);
    }
  };

  clickEvent = (view) => {
    return view.on("click", (event) => {
      view.hitTest(event).then((response) => {
        if (response.results.length) {
          // const graphic = response.results[0].graphic;
          const graphic = response.results.filter((result) => {
            // check if the graphic belongs to the layer of interest
            return result.graphic.layer === this.layer;
          })[0]?.graphic;
          if (graphic) {
            onClick(graphic, this.store, this.app);
            // RootStore.mapStore.clickedCatchmentArea = null;
            RootStore.mapStore.setClickedCatchmentArea(null);
          } else {
            const catchmentAreaLayerGraphic = response.results.filter(
              (result) => {
                // check if the graphic belongs to the catchmentAreaLayer of interest
                return result.graphic.layer === this.catchmentAreaLayer;
              }
            )[0]?.graphic;
            if (catchmentAreaLayerGraphic) {
              this.openCatchmentAreaWidget(catchmentAreaLayerGraphic);
              RootStore.mapStore.selectedMeasuringPoint = null;
            } else {
              onClick(null);
            }
          }
        } else {
          console.log(
            "hitTest.response.results.length is ",
            response.results.length
          );
          onClick(null);
        }
      });
    });
  };

  addToMap(map) {
    this.map = map;
    this.watcher?.forEach((watcher) => watcher.remove());
    this.watcher = [];
    RootStore.mapStore.mapView.popup.autoOpenEnabled = false;
    RootStore.mapStore.sceneView.popup.autoOpenEnabled = false;
    this.watcher.push(this.watchViewScale(RootStore.mapStore.mapView));
    this.watcher.push(this.watchViewScale(RootStore.mapStore.sceneView));
    this.watcher.push(this.pointerMoveEvent(RootStore.mapStore.mapView));
    this.watcher.push(this.pointerMoveEvent(RootStore.mapStore.sceneView));
    this.watcher.push(this.clickEvent(RootStore.mapStore.mapView));
    this.watcher.push(this.clickEvent(RootStore.mapStore.sceneView));

    APIHelper.addToMap(map, this.catchmentAreaLayer);
    APIHelper.addToMap(map, this.layer);
    APIHelper.addToMap(map, this.warningLayer);
    APIHelper.addToMap(map, this.hoverGraphicLayer);
  }

  putLayerOnTop(map) {
    APIHelper.putLayerOnTop(map, this.catchmentAreaLayer);
    APIHelper.putLayerOnTop(map, this.layer);
    APIHelper.putLayerOnTop(map, this.warningLayer);
    APIHelper.putLayerOnTop(map, this.hoverGraphicLayer);
  }

  removeFromMap(map) {
    this.watcher?.forEach((watcher) => watcher.remove());
    this.watcher = [];
    APIHelper.removeFromMap(map, this.catchmentAreaLayer);
    APIHelper.removeFromMap(map, this.layer);
    APIHelper.removeFromMap(map, this.warningLayer);
    APIHelper.removeFromMap(map, this.hoverGraphicLayer);
  }

  removeCatchmentAreaLayerFromMap(map) {
    APIHelper.removeFromMap(map, this.catchmentAreaLayer);
  }
}
