import { Button } from "@mui/material";
import React from "react";
import CachedIcon from "@mui/icons-material/Cached";

const UpdateDiagramSizeButton = ({ onClick }) => {
  return (
    <Button
      title="Diagrammgröße aktualisieren"
      size="small"
      style={{
        border: 0,
        // minWidth: isMobileView ? "35px" : "64px",
        minWidth: "35px",
        backgroundColor: "#efefef",
        marginRight: "10px",
      }}
      onClick={() => {
        onClick();
      }}
    >
      <CachedIcon />
    </Button>
  );
};

export default UpdateDiagramSizeButton;
