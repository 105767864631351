import { Autocomplete, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const ThemaSelection = observer((props) => {
  const {
    noTopic,
    statisticTopicsConfig,
    selectedStatisticTopic,
    handleStatisticTopicChange,
  } = RootStore.statisticStore;

  return (
    <Autocomplete
      id="statistic-topic-selection"
      sx={{
        boxShadow:
          "0px 5px 5px -3px rgb(0 109 151 / 45%), 0px 8px 10px 1px rgb(0 109 151 / 14%), 0px 3px 14px 2px rgb(0 109 151 / 12%)",
        borderRadius: 1,
        marginBottom: "20px",
      }}
      options={statisticTopicsConfig}
      value={selectedStatisticTopic || noTopic}
      label={"Thema"}
      getOptionLabel={(option) => option.Anzeigename || ""}
      renderInput={(params) => (
        <TextField {...params} fullWidth label="Thema Auswahl" />
      )}
      disableClearable
      onChange={(event, newValue) => {
        handleStatisticTopicChange(newValue);
      }}
      isOptionEqualToValue={(option, value) => {
        const optionName =
          selectedStatisticTopic?.Anzeigename || noTopic.Anzeigename;
        return option.Anzeigename === optionName;
      }}
    />
  );
});

export default ThemaSelection;
