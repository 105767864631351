import React from "react";
import RootStore from "stores/RootStore";

const WidgetHeader = ({ widgetTitel, classes }) => {
  return (
    <div className={classes.widgetHeader}>
      <div title={widgetTitel} className={classes.widgetHeaderTitle}>
        {widgetTitel}
      </div>
      <div
        id="openIntro"
        title="Hilfe aufrufen"
        className={classes.widgetGuider}
        onClick={RootStore.uiStore.openIntroSteps}
      >
        ?
      </div>
    </div>
  );
};

export default WidgetHeader;
