import React from "react";
import PrecipitationDisplayType from "./PrecipitationDisplayType";
import TransparencySlider from "components/TransparencySlider";
import DatePickerWrapper from "./DatePickerWrapper";
import RootStore from "stores/RootStore";
import { observer } from "mobx-react";
// import { Chip, Divider } from "@mui/material";

// const precipitationParameters = [
//   { label: "Transparenz", component: <TransparencySlider /> },
//   { label: "Datum", component: <DatePickerWrapper /> },
//   {
//     label: "Darstellung Gebietsniederschlag",
//     component: <PrecipitationDisplayType />,
//   },
// ];

const PrecipitationParameters = observer(({ classes, dividerTextAlign }) => {
  // return precipitationParameters.map((para) => (
  //   <>
  //     <Divider className={classes.divider} textAlign="center">
  //       <Chip className={classes.chip} label={para.label} />
  //     </Divider>
  //     {para.component}
  //   </>
  // ));
  const { wmsLayerOpacity, handleTransparency } =
    RootStore.regionPrecipitationStore;

  return (
    <>
      <TransparencySlider
        classes={classes}
        dividerTextAlign={dividerTextAlign}
        opacityValue={wmsLayerOpacity}
        onChangeOpacity={handleTransparency}
      />
      <DatePickerWrapper
        classes={classes}
        dividerTextAlign={dividerTextAlign}
      />
      <PrecipitationDisplayType
        classes={classes}
        dividerTextAlign={dividerTextAlign}
      />
    </>
  );
});

export default PrecipitationParameters;
