import { List, ListItem, ListItemText } from "@mui/material";
import React from "react";

const listStyle = {
  width: "fit-content",
  bgcolor: "lavender",
  // marginLeft: "20px",
  mt: "10px",
  // mb: "10px",
  borderRadius: 2,
  alignSelf: "center",
  padding: 0,
  // boxShadow: "3px 3px 13px 2px rgb(2 103 143 / 73%)",
};
const listItemStyle = { paddingY: 0 };
const listItemTextStyle = { marginY: 0 };

const StationMinMaxAvg = ({ min, max, avg, unit }) => {
  return (
    <List sx={listStyle}>
      <ListItem key={`section-Minimum`} sx={listItemStyle}>
        <ListItemText
          primary={`Minimum: ${min} ${unit}`}
          sx={listItemTextStyle}
        />
      </ListItem>

      <ListItem key={`section-Maximum`} sx={listItemStyle}>
        <ListItemText
          primary={`Maximum: ${max} ${unit}`}
          sx={listItemTextStyle}
        />
      </ListItem>

      <ListItem key={`section-Avg`} sx={listItemStyle}>
        <ListItemText
          primary={`Durchschnitt: ${avg} ${unit}`}
          sx={listItemTextStyle}
        />
      </ListItem>
    </List>
  );
};

export default StationMinMaxAvg;
