import React from "react";
import RootStore from "stores/RootStore";
import { observer } from "mobx-react-lite";
import { renderThemeItemsFromData } from "./TreeViewItems";
import { treeItemLabelKeys, treeItemMenuOptions } from "utils/TreeViewUtils";
import { TreeView as GGITreeView } from "@giscon/ui.tree-view";

const ThemesSelection = observer((props) => {
  const {
    treeViewItemsThemesTimeSeries,
    expandedThemesTimeSeries,
    selectedThemesTimeSeries,
    setSelectedThemesTimeSeries,
    setExpandedThemesTimeSeries,
    filterTextThemesTimeSeries,
    setFilterTextThemesTimeSeries,
  } = RootStore.mapScopeThemeStore;

  return (
    <>
      <GGITreeView
        treeContainerStyle={{
          height: "calc(100vh - 395px)",
          overflow: "hidden auto",
        }}
        showToggleExpandAll
        showToggleSelectAll
        isFilterable
        onNodeSelect={(event, nodeIds) => setSelectedThemesTimeSeries(nodeIds)}
        onFilterTextChange={(event, filterText) =>
          setFilterTextThemesTimeSeries(filterText)
        }
        defaultFilterText={filterTextThemesTimeSeries}
        defaultSelectedNodeIds={selectedThemesTimeSeries}
        defaultExpandedNodeIds={expandedThemesTimeSeries}
        onNodeExpand={(event, nodeIds) => setExpandedThemesTimeSeries(nodeIds)}
        expandOnContentClick
        multiSelect
      >
        {renderThemeItemsFromData(
          treeViewItemsThemesTimeSeries,
          0,
          treeItemLabelKeys,
          treeItemMenuOptions
        )}
      </GGITreeView>
    </>
  );
});

export default ThemesSelection;
