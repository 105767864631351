import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "@arcgis/core/assets/esri/themes/light/main.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./theme";

// import MPWidget from "widgets/MeasuringPoint/Widget";
import ChartWindow from "widgets/ChartWindow/Widget";
import RootStore from "stores/RootStore";
import PrecipitationAnimation from "widgets/RegionPrecipitationWidget/components/PrecipitationAnimation";
import PrecipitationErrorMessage from "widgets/RegionPrecipitationWidget/components/PrecipitationErrorMessage";
// import WMSLayerLoading from "widgets/RegionPrecipitationWidget/components/WMSLayerLoading";
import "intro.js/introjs.css";
import WidgetIntro from "widgets/Intro/Widget";

const { widgets } = RootStore.uiStore;
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme()}>
      <App widgets={widgets}>
        {/* <MPWidget /> */}
        <ChartWindow />
        <PrecipitationAnimation />
        <PrecipitationErrorMessage />
        {/* <WMSLayerLoading /> */}
        <WidgetIntro />
      </App>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
