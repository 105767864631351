import React, { useEffect } from "react";
import { observer } from "mobx-react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import APIHelper from "libs/APIHelper";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { useTheme, makeStyles } from "@mui/styles";
import { useRef } from "react";
const useStyles = makeStyles((theme) => ({
  root: {},
  legendRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
    justifyContent: "flex-start",
    "&>div:first-child": {
      width: 50,
      marginRight: 10,
      flexGrow: 0,
    },
    "&>div:last-child": {
      flexGrow: 1,
    },
  },
  legendImage: { marginRight: 10 },
  headerDiv: {},
  contentDiv: {},
}));

function LegendItem({
  styles,
  label,
  // only set if from renderer
  symbol,
  //from here on only set if legendInfo from server
  height,
  width,
  imageData,
  contentType,
}) {
  const standardStyles = useStyles(useTheme());
  const actualStyles = { ...standardStyles, ...styles };
  const ref = useRef();
  useEffect(() => {
    if (ref.current && symbol) {
      // if symbol isset => render it in div
      ref.current.innerHTML = "";
      APIHelper.renderLayerSymbolInElement(
        {
          symbol,
        },
        ref.current
      );
    }
    // }, [ref.current, symbol]);
  }, [symbol]);

  return (
    <div className={actualStyles.legendRow}>
      <div className={actualStyles.legendImage} ref={ref}>
        {!symbol && imageData && (
          <img
            alt={label}
            src={`data:${contentType};base64,${imageData}`}
            height={height}
            width={width}
          />
        )}
      </div>
      <div>{label}</div>
    </div>
  );
}

function LegendContent({ legendInfo, symbols }) {
  if (legendInfo) {
    return (
      <>
        {legendInfo.map(
          ({ label, height, width, imageData, contentType }, i) => (
            <LegendItem
              {...{ label, height, width, imageData, contentType }}
              key={`${label}-server-${i}`}
            />
          )
        )}
      </>
    );
  } else if (symbols) {
    return (
      <>
        {symbols.map(({ label, symbol }, i) => (
          <LegendItem
            label={label}
            symbol={symbol}
            key={`${label}-renderer-${i}`}
          />
        ))}
      </>
    );
  } else {
    return <></>;
  }
}

function Wrapper({ accordion, header, content, style }) {
  const styles = useStyles(useTheme());
  if (accordion) {
    return (
      <Accordion defaultExpanded style={{ boxShadow: "unset" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={style}>
          {header}
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          {content}
        </AccordionDetails>
      </Accordion>
    );
  } else {
    return (
      <div style={style}>
        <div className={styles.headerDiv}>{header}</div>
        <div className={styles.contentDiv}>{content}</div>
      </div>
    );
  }
}

function Legend({
  map,
  view,
  LayerStore,
  layer = null,
  accordion = true,
  // legendInfo,
  // error,
  // loading,
  nls: propNls,
  style,
  legendSymbols,
}) {
  const {
    title = "Legende",
    loadingError = "Beim Laden der Legende ist ein Fehler aufgetreten",
    noData = "Keine Legenden Daten zu dem aktuellen Layer verfügbar oder kein Layer ausgewählt",
  } = propNls;
  const nls = { title, loadingError, noData };
  const legendInfo = layer?.legendInfo; // symbols that are received by asking the server
  const symbols = legendSymbols || layer?.symbols; // symbols {label, symbol} that are set in a renderer
  // const error = layer?.legendState === "error";
  const loading = layer ? layer.legendState === "loading" : !symbols;

  const name = legendInfo?.layerName ? legendInfo.layerName : "";

  const header = (
    <Typography variant="h6">{`${nls.title}${
      name ? " - " + name : ""
    }`}</Typography>
  );
  const legendSubtitle = propNls?.legendSubtitle;

  const content = (
    <>
      {loading && <CircularProgress />}
      {!loading && legendSubtitle && (
        <Typography
          sx={{ mb: "10px" }}
          variant="subtitle1"
        >{`${legendSubtitle}`}</Typography>
      )}
      {!loading && symbols && <LegendContent symbols={symbols} nls={nls} />}
      {!loading && !symbols && legendInfo && (
        <LegendContent legendInfo={legendInfo} nls={nls} />
      )}
    </>
  );

  return (
    <Wrapper
      accordion={accordion}
      header={header}
      content={content}
      style={style}
    />
  );
}

export default observer(Legend);
