import { Autocomplete, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";

const MeasurementsTimeSelection = observer((props) => {
  // const options = RootStore.envisHydroConfig.HydroLetzteMessungen;
  // const [selectedOption, setSelectedOption] = useState(options[0]);
  const {
    selectedMeasurementsTime,
    measurementsTimeOptions,
    setMeasurementsTime,
  } = RootStore.hydroWebStore;

  return (
    <Autocomplete
      sx={{
        borderRadius: 1,
        marginBottom: "15px",
      }}
      size="small"
      disableClearable
      options={measurementsTimeOptions}
      value={selectedMeasurementsTime}
      label={"Wählen Messungen Zeit"}
      getOptionLabel={(option) => option.name || ""}
      renderInput={(params) => (
        <TextField {...params} fullWidth label="Wählen Messungen Zeit" />
      )}
      onChange={(event, newValue) => {
        console.log(newValue);
        setMeasurementsTime(newValue);
      }}
    />
  );
});

export default MeasurementsTimeSelection;
