import TimelineIcon from "@mui/icons-material/Timeline";
import SubjectIcon from "@mui/icons-material/Subject";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const Menu = ({ openTab, open, menuItems, nls, width, catchmentArea }) => {
  return (
    <List>
      <ListItem
        button
        key={"einzugsgebietsinfo"}
        onClick={() => {
          openTab(-1);
        }}
      >
        <ListItemIcon>
          <SubjectIcon />
        </ListItemIcon>
        <ListItemText primary={nls.stationInfo} />
      </ListItem>
      {menuItems.map((item) => (
        <ListItem
          button
          key={item}
          onClick={() => {
            openTab(item);
          }}
          // selected={mp.groupParam === groupParam ? true : false}
        >
          <ListItemIcon>
            <TimelineIcon />
          </ListItemIcon>
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );
};

export default Menu;
