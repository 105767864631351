import { Card, CardContent, CardHeader } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import ServiceLayerSelector from "./ServiceLayerSelector";
import RootStore from "stores/RootStore";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import RegionsSelectionRemoveButton from "./RegionsSelectionRemoveButton";
import LayerStore from "libs/Layers";
import APIHelper from "libs/APIHelper";

const useStyles = makeStyles({
  cardStyle: {
    marginBottom: "15px",
    border: "1px solid lightgray",
    color: "dimgray",
  },
});

const selectRegion = (selected) => {
  if (!selected) {
    RootStore.mapScopeThemeStore.handleSelectRegion(null);
  } else {
    const { layerURL, ids } = selected;
    APIHelper.queryFeaturesByIdsOnLayerUrl(layerURL, ids, LayerStore.token)
      .then((features) => {
        const geometry = APIHelper.unionOfGeomnetries(
          features.map((feature) => APIHelper.getGeometry(feature))
        );
        const attributes = APIHelper.getAttributes(features[0]);
        RootStore.mapScopeThemeStore.handleSelectRegion(
          selected,
          geometry,
          attributes
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }
};

const RegionSelection = observer((props) => {
  const { selectedRegion } = RootStore.mapScopeThemeStore;
  const classes = useStyles();
  const sources = RootStore.envisHydroConfig.Gebietssuchen;

  return (
    <Card className={classes.cardStyle}>
      <CardHeader title="Gebietsauswahl" sx={{ padding: "8px" }} />
      <CardContent sx={{ paddingTop: 0, paddingBottom: "15px !important" }}>
        <div id="regionSelection">
          {sources.map((source) => {
            const region = toJS(selectedRegion); // cause mobx returns a proxy even to subproperties
            return (
              <ServiceLayerSelector
                key={source.ID}
                selectedEntity={
                  region?.option?.layerURL === source.URL ? region.option : null
                }
                handleSelectEntity={(region) => {
                  selectRegion(region);
                }}
                layerURL={source.URL}
                searchAttribute={source.SuchSpalte}
                // idAttribute={source.idAttribute}
                idAttribute="OBJECTID"
                inputLabel={source.AnzeigeName}
              />
            );
          })}
        </div>
        <RegionsSelectionRemoveButton />
      </CardContent>
    </Card>
  );
});

export default RegionSelection;
