import { Button } from "@mui/material";
import React from "react";
import RootStore from "stores/RootStore";

const RegionsSelectionRemoveButton = (props) => {
  const { selectedRegion, handleRemoveAllRegionSelection } =
    RootStore.mapScopeThemeStore;
  return (
    <Button
      id="clearRegionSelection"
      variant="outlined"
      fullWidth
      onClick={() => handleRemoveAllRegionSelection()}
      disabled={selectedRegion === "" ? true : false}
    >
      Gebietsauswahl aufheben
    </Button>
  );
};

export default RegionsSelectionRemoveButton;
